module.exports = function($compile) {
  return {
    restrict: 'E',
    scope: {
      dataset: '=dataset',
      colors: '=colors',
      selectedColorIndex: '=ngModel'
    },
    template: [].join(''),
    link: function(scope, element, attrs) {
      scope.selectedColorIndex = scope.selectedColorIndex || 0;

      scope.colors.forEach(function(color, index) {
        var child = document.createElement('a');
        child.className = 'color-switcher';
        child.style.backgroundColor = 'rgb(' + color.slice(0, 3).join(', ') + ')';
        child.setAttribute('ng-click', 'updateColor(\'' + index + '\')');
        child.setAttribute('ng-class', '{highlighted: (selectedColorIndex === ' + index + ')}');

        $compile(child)(scope);
        element[0].appendChild(child);
      });

      scope.updateColor = function(newColorIndex) {
        scope.selectedColorIndex = parseInt(newColorIndex);
      };

      scope.$watch(
        function() {
          return scope.selectedColorIndex;
        },
        function(newValue, oldValue) {
          var color = scope.colors[newValue];
          scope.dataset.datasets[0].fillColor = 'rgba(' + color.join(', ') + ')';
          scope.dataset.datasets[0].strokeColor = 'rgba(' + color.slice(0, 3).join(', ') + ', 1.0)';
        });

      return true;
    }
  };
};
