module.exports = function(app) {
  app.service('antenna.tv',
              ['$http', '$q', '$interval', require('./antenna/tv')]);

  app.service('antenna.fm',
              ['$http', '$q', '$interval', require('./antenna/fm')]);

  app.service('antenna-length.tv',
              [require('./antenna-length/tv')]);

  app.service('antenna-length.fm',
              [require('./antenna-length/fm')]);

  app.service('business-logic',
              [require('./business-logic')]);

  app.service('values',
              ['$state', '$q', '$timeout', require('./values')]);

  app.service('pattern-parser',
              [require('./pattern-parser')]);

  app.service('pattern-data',
              ['$http', '$q', 'pattern-parser', require('./pattern-data')]);

  app.service('fcc-overlay-data',
              ['$http', '$q', 'pattern-parser', require('./fcc-overlay-data')]);

  app.service('transmission-line',
              ['$http', '$q', require('./transmission-line')]);

  app.service('calculation',
              [require('./calculation')]);

  app.service('pdf',
              ['$rootScope', '$compile', '$filter', '$document', '$state', '$window', 'business-logic', 'values', 'pattern-data', require('./pdf')]);

  app.service('export',
              ['$state', 'values', 'pattern-data', require('./export')]);

  app.service('mechanical',
              [require('./mechanical')]);

  app.service('unload-warning',
              ['$window', '$filter', require('./unload-warning')]);
};
