var filters = {
  'inToCm': function(input) {
    var feet = input / 12;
    var meters = feet * 0.3048;
    return meters * 100;
  },
  'ftToM': function(input) {
    return input * 0.3048;
  },
  'lbToKg': function(input) {
    return input * 2.20462;
  },
  'sqftToSqm': function(input) {
    return input * Math.pow(0.3048, 2);
  },
  'mphToKmh': function(input) {
    return input * 1.60934;
  },
  'degFToDegC': function(input) {
    return ((input - 32) * 5/9);
  }
};

var mappedFilters = {};

Object.keys(filters).forEach(function(filterName) {
  var filterFunction = filters[filterName];
  mappedFilters[filterName] = function(input) {
    return filterFunction(parseFloat(input));
  };
});

module.exports = mappedFilters;
