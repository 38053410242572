module.exports = function($scope, PDFService, Values,  ExportService) {
  $scope.tabElementValues = Values.preserve($scope, 'tabElementValues', {
    zoom: '-10 to 90°',
    exhibitNumber: null,
    chartColor: 1,
    remarks: ''
  });

  $scope.datasets = Values.preserve($scope, 'datasets', [
    {
      fillColor: 'rgba(33, 91, 168, 0.2)',
      strokeColor: 'rgba(33, 91, 168, 1.0)',
      data: []
    }
  ]);

  $scope.tabElementOptions = {
    zoom: [
      '-3 to 11°',
      '-6 to 22°',
      '-10 to 90°'
    ]
  };

  $scope.canPrint = function() {
    return PDFService.canPreparePages(['elevation']);
  };

  $scope.printButtonTitle = function() {
    return ($scope.canPrint() ? '' : 'Complete this tab to print results.');
  };

  $scope.print = function() {
    var pages = PDFService.preparePages(['elevation']);
    PDFService.scopeMethods.print(pages);
  };

  $scope.export = function($event, type) {
    var link = angular.element($event.currentTarget);
    var result = ExportService.formats[type].elevation($scope.patternData, $scope);
    if($scope.patternData.elevation.drawingNumber == ' '){
      result.filename = result.filename.replace(' ', $scope.uiElementValues.elevationPattern);
    }else if($scope.patternData.azimuth.drawingNumber == ' '){
      result.filename = result.filename.replace(' ', $scope.uiElementValues.azimuthPattern);
    }
    
    if (window.navigator && window.navigator.msSaveBlob) {
      window.navigator.msSaveBlob(result.contents, result.filename);
    }
    else {
      link.attr('href', URL.createObjectURL(result.contents));
      link.attr('download', result.filename);
    }
  };

  $scope.highPower = false;
  $scope.$watch(function() {
    return $scope.patternRecords.azimuth;
  }, function(value) {
    var Design = $scope.patternRecords.azimuth.Design.substr(0, 3);
    $scope.highPower = ($scope.patternRecords.azimuth.Class[0] == 'High Power' && ['ETT', 'WB', 'DSB', 'TLP'].indexOf(Design) == -1);
  });

  $scope.chart = {
    data: {
      labels: [],
      datasets: $scope.datasets
    },
    options: {
      animation: false,
      responsive: false,
      pointLabelFontSize: 10,
      pointDot: false,
      showTooltips: false,
      scaleOverride: true,
      scaleSteps: 10,
      scaleStepWidth: .1,
      scaleStartValue: 0,
      showLabels: false,
      scaleShowLabels: true,
      scaleFontSize: 10,
      scaleShowGridLines : false,
      scaleGridLineWidth: 1,
      scaleShowVerticalLines: false,
      scaleShowXTicks: false,
      scaleShowHorizontalLines: false,
      verticalGridCount: 10
    },
    colors: [
      [191, 13, 62, 0.2],
      [33, 91, 168, 0.2],
      [255, 153, 51, 0.2],
      [76, 78, 86, 0.2],
      [28, 131, 0, 0.2]
    ]
  };

  function updateChart() {
    var patternData = $scope.patternData.elevation.data;
    var constraint = $scope.tabElementValues.zoom
          .match(/(\-?\d+)/g);
    var min = parseFloat(constraint[0]);
    var max = parseFloat(constraint[1]);
    var labelIncrement = (max - min > 99 ? 10 : 1);

    $scope.datasets[0].data = [];
    $scope.chart.data.labels = [];

    Object.keys(patternData)
      .map(function(key) {
        return {
          key: parseFloat(key),
          value: patternData[key].relative
        };
      })
      .filter(function(item) {
        return (item.key >= min && item.key <= max);
      })
      .sort(function(m, n) {
        if (m.key < n.key) return -1;
        if (m.key > n.key) return 1;
        return 0;
      })
      .forEach(function(item) {
        $scope.datasets[0].data.push(item.value / 100);
        $scope.chart.data.labels.push(item.key % labelIncrement === 0 ? item.key : '');
      });
  };

  $scope.$watch(
    function() {
      return $scope.tabElementValues.zoom;
    },
    function(newValue, oldValue) {
      if (newValue !== null && $scope.patternData.elevation) {
        updateChart();
      };
    }
  );
};
