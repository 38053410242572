module.exports = function($stateProvider, $urlRouterProvider) {
  $urlRouterProvider.otherwise('/tv');
  $urlRouterProvider.when('/tv/detail', '/tv/detail/azimuth');
  $urlRouterProvider.when('/fm/detail', '/fm/detail/azimuth');

  $stateProvider
    .state('tv', {
      url: '/tv',
      views: {
        sidebar: {
          templateUrl: 'view/tv-sidebar.html?v=' + window.buildDate,
          controller: 'tv-sidebar'
        },
        summary: {
          templateUrl: 'view/tv-summary.html?v=' + window.buildDate,
          controller: 'tv-summary'
        }
      }
    })
    .state('tv.detail', {
      url: '/detail',
      templateUrl: 'view/detail.html?v=' + window.buildDate,
      controller: 'detail',
      onExit: function() {
        jQuery('#antennaModal').modal('hide');
      }
    })
    .state('tv.detail.azimuth', {
      url: '/azimuth',
      templateUrl: 'view/detail/azimuth.html?v=' + window.buildDate,
      controller: 'detail.azimuth'
    })
    .state('tv.detail.elevation', {
      url: '/elevation',
      templateUrl: 'view/detail/elevation.html?v=' + window.buildDate,
      controller: 'detail.elevation'
    })
    .state('tv.detail.summary', {
      url: '/summary',
      templateUrl: 'view/detail/summary.html?v=' + window.buildDate,
      controller: 'detail.summary'
    })
    .state('tv.detail.specs', {
      url: '/specs',
      templateUrl: 'view/detail/specs.html?v=' + window.buildDate,
      controller: 'detail.specs'
    })
    .state('tv.detail.print', {
      url: '/print',
      templateUrl: 'view/detail/print.html?v=' + window.buildDate,
      controller: 'detail.print'
    });
  
  $stateProvider
    .state('fm', {
      url: '/fm',
      views: {
        sidebar: {
          templateUrl: 'view/fm-sidebar.html?v=' + window.buildDate,
          controller: 'fm-sidebar'
        },
        summary: {
          templateUrl: 'view/fm-summary.html?v=' + window.buildDate,
          controller: 'fm-summary'
        }
      }
    })
    .state('fm.detail', {
      url: '/detail',
      templateUrl: 'view/detail.html?v=' + window.buildDate,
      controller: 'detail',
      onExit: function() {
        jQuery('#antennaModal').modal('hide');
      }
    })
    .state('fm.detail.azimuth', {
      url: '/azimuth',
      templateUrl: 'view/detail/azimuth.html?v=' + window.buildDate,
      controller: 'detail.azimuth'
    })
    .state('fm.detail.elevation', {
      url: '/elevation',
      templateUrl: 'view/detail/elevation.html?v=' + window.buildDate,
      controller: 'detail.elevation'
    })
    .state('fm.detail.summary', {
      url: '/summary',
      templateUrl: 'view/detail/summary.html?v=' + window.buildDate,
      controller: 'detail.summary'
    })
    .state('fm.detail.specs', {
      url: '/specs',
      templateUrl: 'view/detail/specs.html?v=' + window.buildDate,
      controller: 'detail.specs'
    })
    .state('fm.detail.print', {
      url: '/print',
      templateUrl: 'view/detail/print.html?v=' + window.buildDate,
      controller: 'detail.print'
    });
};
