module.exports = function($http, $q) {
  var self = this;
  var readyDefer = $q.defer();
  var floatFields = [
    "const2a",
    "const3b",
    "const5a",
    "const6b",
    "const7c",
    "Resistance",
    "LinePressure",
    "Temperature"
  ];

  self.data = {};
  self.hasData = readyDefer.promise;

  self.getLineTypes = function() {
    var defer = $q.defer();

    self.hasData
      .then(function() {
        defer.resolve(Object.keys(self.data));
      })
      .catch(function(err) {
        defer.reject(err);
      });

    return defer.promise;
  };

  self.getLineSizesFor = function(type) {
    var defer = $q.defer();

    self.hasData
      .then(function() {
        var result = null;
        if (self.data[type]) {
          result = Object.keys(self.data[type]);
        };
        
        defer.resolve(result);
      })
      .catch(function(err) {
        defer.reject(err);
      });

    return defer.promise;
  };
  
  $http({
    method: 'GET',
    url: '/antenna-data/transmission.json'
  })
    .then(function(result) {
      var data = result.data;
      
      Object.keys(data).forEach(function(type) {
        var lines = data[type];
        Object.keys(lines).forEach(function(lineName) {
          var line = data[type][lineName];
          floatFields.forEach(function(fieldName) {
            line[fieldName] = parseFloat(line[fieldName]);
          });
        });
      });
      
      self.data = data;
      readyDefer.resolve(data);
    })
    .catch(function(err) {
      self.data = {};
      readyDefer.reject(err);
    });
};
