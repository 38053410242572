module.exports = function () {
  this.calculate = function(design, channel, frequency, elevationPattern, azimuthShape) {//see if you can pass in design rather than antenna, and change accordingly for both functions
    var H2, H3, H4 = null;
    var Mslots = parseInt(elevationPattern.substr(0,2));// = scope.mechspec.RealSlots; //assuming Mslots is Layers from tvMechData, need to assign that to scope
    var Frequency = frequency || null;
    var Mch = channel; //note, channel is used for FM, Mch for television
    
    var Wavelength = function(Frequency) {
      return 11802.9 / Frequency / 12;
    }; //provides the wavelength in feet of a particular channel (really the frequency of the channel)

    var WaveL = Wavelength(Frequency);  //added, WaveL may be somehting else, but for now it's equal to Wavelength(Frequency)
    if (design.length > 3 && ["DCP", "BB1", "TLS"].indexOf(design.substring(0,3)) == -1) design = design.substring(0,3);
    else if (["-", ".", "/", "_"].indexOf(design.substring(3,4)) != -1) design = design.substring(0,5);
    else (design = design.substring(0,4));

    var SingleSectionH2 = NSection = null;
    
    var NumberOfSections = function(NumberOfLayers) {
      var result = (NumberOfLayers <= 12 ? 1 : NumberOfLayers / 8);
      return result;
    };
    var NSection = NumberOfSections(Mslots);
    var TypeNumBays = parseInt(elevationPattern.substring(0,2));
    var LayersPerSection = function(TypeNumBays) {return (TypeNumBays === 12 ? 12 : 8)};
    // var fm = (selectors.antennaService ? selectors.antennaService.toLowerCase === 'fm' : false);
    // var tv = !fm;      
    // if (tv) {
    
    
    var THP_num_col = 12;      //' the number of cells in a row of THB_length (used to make equation dynamic.  STILL IN USE
    var isOmni = false;
    /*if (scope.filterOptions.azimuthData !== null && typeof scope.filterOptions.azimuthData !== 'undefined')*/ 
    isOmni = (azimuthShape.toLowerCase() == "omni");
   
    if (design === "ETT") { //(going to have to find something within azimuth patterns to figure out when we have ETT in the design)
      if (isOmni) H2 = (Mslots + 2.25) * Wavelength(Frequency) + 1; //Can probably call wavelength without any arguments as frequency should be available
      else H2 = (Mslots + 2.5) * Wavelength(Frequency) + 1; //dimensions are feet (12 inches)              
    }
    else if (design == "JTT") H2 = (Mslots + 2.25) * WaveL + 2; // 'dimensions in feet

    else if(design == "JTH" || design == "GTH") { //because apparently these 2 are the same
      H2 = (Mslots + 0.5) * WaveL + 2;
      //' per AJS,PJ,JCB,EHM,RLM meeting 07/01/2001 all GTH antenna are the same length
      //'            If IsOmni Then
    }
    else if(["DSB", "DSBc", "TLS-V", "TLS", "BB4", "BB8", "BB12"].indexOf(design) != -1)/* design == "DSB" || design == "DSBc" || design == "TLS-V") */{
      // ' mslots = 8 or 12 or 16 or 24
      NSection = NumberOfSections(Mslots); //need to figure out NumberofSections and LayersPerSection functions
      SingleSectionH2 = WaveL * (LayersPerSection(Mslots) + 1) + 2.25 / 12; // 'this formula is from "TLP&DSBspecs_Rev7.xls"
      H2 = NSection * SingleSectionH2 + (NSection - 1) / 6; // 'this formula is from "TLP&DSBspecs_Rev7.xls"  
    }
    else if(design == "DSC" || design == "JSC") H2 = (Mslots + 2) * WaveL + 2; //WaveL is probably the same as Wavelength(Frequency)

    else if(design == "TLP" || design == "TLPc" || design == "DL") {//TLPc will never be found (design is shortened to 3 chars), it's just there for comparison with legacy code
      var Space = 0;
      if (channel <= 30) {
        SingleSectionH2 = WaveL * (LayersPerSection(TypeNumBays) + 1) + 1.125 / 12;
        Space = (2 * WaveL) - (0.75 * WaveL + 0.375 / 12) - (0.75 * WaveL + 0.5 / 12);
      }
      else {
        SingleSectionH2 = WaveL * (LayersPerSection(TypeNumBays) + 0.5) + 1.125 / 12;
        NSection = NumberOfSections(TypeNumBays);
        Space = (2 * WaveL) - (0.5 * WaveL + 0.375 / 12) - (0.5 * WaveL + 0.5 / 12);
      }
      H2 = (NSection * SingleSectionH2) + ((NSection - 1) * (Space));
      // 'org DASP code: H2 = 1.0625 * Mslots * WaveL + ((Mslots / 8) - 1) * WaveL + 0.075; // 'mslots = 8 or 16 or 24 or 32
    }
    else if(design == "TDM") {      
      //Need to figure out what Mch is, I think we have Mslots from somewhere
      //the logic behind this bit was If Mslots = (7,6 or 5) and if Mch = (2-6) set H2 and H3 to certain values.
      // it was around 50 lines of code before, now it's closer to 20 -- 2/25/16
      var TDMH2H3obj = {
        '7': {
          H2array: [0,0,96.7,90,79.4,71.6,67.9],
          H3array: [0,0,48.9,46.4,40.3,37,35.5]},
        '6': {
          H2array: [0,0,82.2,76.9,67.4,61.1,58.2],
          H3array: [0]
        }, //H3 is H2/2 and the map function on an array looks at each element (in this case e).  Very clever, thanks for the help, Aaron
        '5': {
          H2array: [0,0,67.5,63.5,55.5,49.5,46.5],
          H3array: [0,0,34.4,33.1,28.4,25.4,23.8]}
      };
      TDMH2H3obj['6'].H3array = TDMH2H3obj['6'].H2array.map(function(e) {return e/2;});
      H2 = TDMH2H3obj[Mslots].H2array[Mch + 1];//the + 1 could be removed in favor of taking off the 0 at the front of the array
      H3 = TDMH2H3obj[Mslots].H3array[Mch + 1];            
    }
    else if(design == "TCL") {
      var TCLvars = function() { //available channels 7-13, H3 is apparently not available.  Array is set with the starting position corresponding to 7
        var select = Mch - 7;
        var TCLGain = parseFloat(elevationPattern.substring(3,6)) / 10; //find a way to get the gain from the elevation pattern 
        if (Mslots === 2 && TCLGain <= 5.5) return [72.8, 72.8 / 2];
        else if (Mslots === 2 && TCLGain > 5.5 && TCLGain < 6.3) return [0, 0 / 2];
        else if (TCLGain >= 6.3 && Mslots === 2) {
          var H2array = [93.9,93.2,92.6,92,91.4,90.9,90.4];
          var result = H2array[select];
          return [result, result / 2];
        } //' H3 for the TCL with 2 slots is H2/2
        else if (Mslots === 3) {
          var H2array = [129,124,120,115,113,110,106];
          var H3array = [65.1, 62.5, 60.7, 58, 57.1, 55.5, 53.4];
          return [H2array[select], H3array[select]];  
        };                
      };
      var TCLresult = TCLvars();
      H2 = TCLresult[0];
      H3 = TCLresult[1];

    }
    else if (design == "TFU") { // set variables length and center of radiation for TFU-UT
      H2 = 3.74;
      H3 = 1.87;//Round(H2/2, 2);
    }
    else if(design.substring(0,2) == "TF") { //102 lines of code originally, Mslots and Mch to set H2 and H3 over channels 2-13 
      var TFH2H3 = function() {
        var TFobj = []; //object to hold values for H2 and H3 aka antenna length and center of radiation
        switch(Mslots) {//needs to be able to see Mslots and Mch
        case 1: 
          if (Mch <= 3) TFobj = [25, 17.9];
          else if (Mch <= 6) TFobj = [23, 17.7];
          break;
        case 2:
          if (Mch <= 3) TFobj = [32, 16.4];
          else if (Mch <= 6) TFobj = [26, 13.5];
          else if (Mch <= 13) TFobj = [16, 9.3];
          break;
        case 3:
          if (Mch <= 3) TFobj = [49, 24.8];
          else if (Mch <= 6) TFobj = [40, 20.4];
          // '3 layer high band doesn't exit
          break;
        case 4:
          if (Mch <= 3) TFobj = [66, 33.3];
          else if (Mch <= 6) TFobj = [55, 27.9];
          else if (Mch <= 13) TFobj = [26, 13.6];
          break;
        case 5:
          if (Mch <= 3) TFobj = [84.3, 43];
          else if (Mch <= 6) TFobj = [69.3, 35.2];
          // '5 layer high band doesn't exist
          break;          
        case 6:
          if (Mch <= 3) TFobj = [101, 51.2];
          else if (Mch <= 6) TFobj = [83, 41.8];
          else if (Mch <= 13) TFobj = [37.3, 19.2];
          break;
        case 8:
          if (Mch <= 6) TFobj = [999, 999];
          else if (Mch <= 13) TFobj = [51, 26];
          //no low band I guess.  They just used 999 as a dummy value
          break;
        case 10:
          if (Mch <= 6) TFobj = [999, 999];
          else if (Mch <= 13) TFobj = [62.5, 31.8];
          break;
        case 12:
          if (Mch <= 6) TFobj = [999, 999];
          else if (Mch <= 13) TFobj = [74, 37.7];
          break;
        case 18:
          TFobj = [888, 888];
          console.log('888, uhoh!  Currently TF cannot handle 18 slots');
          break;
        default:
          TFobj = [777, 777];
          console.log('oops, 777!  Something is wrong, somehow you slipped through the antenna length handler. Please report the error');
          break;
        }
        return TFobj;
      };
      H2 = TFH2H3()[0];
      H3 = TFH2H3()[1];
    }
    else if(design.substring(0,2) == "TW") { //H3 not available.  Mslots is the parameter, and Mch (or TWchannel) is used to select the appropriate H2 (antenna length) from the array
      var TWH2obj = { //this case (TW) used to be 70 lines of code!
        '7': [50.9, 49.5, 48.1, 46.9, 45.7, 44.6, 43.6],
        '9': [59.2, 57.5, 55.9, 54.5, 53.1, 51.8, 50.5],
        '12': [75.9, 73.6, 71.5, 69.6, 67.7, 66, 64.4],
        '15': [95.3, 92.5, 89.8, 87.2, 84.9, 82.6, 80.5]
      };
      var TWchannel = Mch - 7;
      Mslots = parseInt(elevationPattern.substr(3,2));
      H2 = TWH2obj[Mslots][TWchannel];
      var setTWH3 = function() {
        var result = 0;
        var H3array = [1.9, 1.75, 1.65, 1.65, 1.35, 1.2, 1.15];
        result = (H2 / 2) + H3array[TWchannel];
        return result;
      };
      H3 = setTWH3();               
    }
    else if(design == "THA" || design == "THB") { //' Harris") { panels were 1,2,3,4,5,6,8,10 & 12 types for channels 2,3; 4,5,6; 7-13
      //THP_num_columns = 12, THP_num_rows = 3;  Those variables were set to keep track of THB_length (a 3x12 array);
      var THB_length =[12.4, 29.5, 46.6, 63.6, 80.7, 97.8, 1.00, 1.00, 1.00, 1, 1, 1, 
                       9.7, 23.8, 37.9, 52.00, 66.00, 80.1, 1.00, 1.00, 1.00, 1, 1, 1,
                       4.6, 9.9, 15.1, 20.4, 25.6, 30.9, 1, 41.4, 1, 51.9, 1, 62.4]; //now a single array of length 36, based of a 3x12 array
      var irow = 0;
      var icheck = 0;
      if (Mch <=3) irow = 0;
      else if (Mch <= 6) irow = 1;
      else if (Mch <= 13) irow = 2;
      
      if (Mslots < 1) Mslots = 1;
      if (THP_num_col >= 0 && irow >= 0) icheck = irow * THP_num_col + Mslots - 1; //no need to check for < 0 now.  suspicion - use layers from elevpattern        
      if (icheck >= THB_length.length) icheck = THB_length.length - 1;

      H2 = THB_length[icheck]; //note, THP_num_col is really just 12, but keeping it in case somehow it helps us later

    }
    else if(design == "THV") {  //' slotted coax for channels 7->13
      if (Mslots < 10) {
        H2 = ((Mslots + 1.6) * WaveL + 1.75); //'changed 3/30/2004 by CRR to agree with THV-Antenna Mechanical Specifications Ver 1.0 (THVspecs.XLS)
        H3 = (Mslots / 2 + 1.35) * WaveL; //'    changed 3/30/2004 by CRR to agree with THV-Antenna Mechanical Specifications Ver 1.0 (THVspecs.XLS)
      }
      else {
        H2 = ((Mslots + 0.5) * WaveL + 3.333); //'changed 3/30/2004 by CRR to agree with THV-Antenna Mechanical Specifications Ver 1.0 (THVspecs.XLS)
        H3 = H2 / 2; //'                         changed 3/30/2004 by CRR to agree with THV-Antenna Mechanical Specifications Ver 1.0 (THVspecs.XLS)
      };
    }
    else if(design == "TUA" || design == "TUB" || design == "TUC" || design == "TUD" || design == "TUE" || design == "TUM" || design == "TUL") { //check left 3 letters of design for these
      H2 = 1.3 + (Mslots * 3.8);
      H3 = 1 + (Mslots * 1.9);

    }
    else if(design == "DLP") {
      var Lambda = 11802.9 / Frequency;
      var L = (Lambda * (Mslots - 0.25) + 32);
      var Lfinned = Mslots * (0.8 * Lambda + 1);
      //Lunfinned = L - Lfinned;
      H2 = (L / 12);
      //H2 = (7.75 * Lambda + 32) / 12;// Lambda - divides 11802.9 by the frequency, and CInt - converts to Int
      H3 = ((H2 / 2) + 1);//Simpler version of code that dielectric had: H3 = (H2 - 2) / 2 + 2
    }
    else if (design == 'WB') {
      var H2 = ((7*21+26)/12)*NSection+((NSection-1)*1.25)/12;
    }
    else H2 = 0; //or possible default    
    //End switch or whatever is doing the cases above
    if (H2 === 0) H3 = H4 = 0;
    else H4 = H2 + 4;   // ' revised DCA specification changed from 3 foot to 4 foot
    // ' lighting protector 01/01/00 see Andre & Dan so all protectors are 4 foot long
    if (H2 !== 0  && H3 === 0 || H3 === undefined || H3 === null) { //if nothing prior to this has set H3
      H3 = H2 / 2; // to replace ElseIf DES <> "TF" And DES <> "TDM" And DES <> "THV" And DES <> "TUA" And DES <> "TUB" And DES <> "TUC" And DES <> "TUD" And DES <> "TUE" And DES <> "TUM" And DES <> "TUL" Then ' H3 for these is handled above (at the same time as H2)
    }
    return { //H2 is antenna length, H3 is center of radiation, H4 is with lightning protector
      H2: H2,
      H3: H3,
      H4: H4
    };
  };
};
