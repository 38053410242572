var jQuery = require('jquery');

module.exports = function($scope, $rootScope, $state, Values, CalculationService, TvAntennaService) {
  var detailElementValues = {
    callLetters: null,
    location: null,
    customer: null,
    useVpolCheck: null,
    // PeakVpol: $scope.businessLogicValues.maxPeakVpol ? $scope.businessLogicValues.maxPeakVpol : 75,
    ERPVpolSplit: 50,
    today: new Date()
  };

  var states = {
    topLevel: null,
    lastChild: null
  };

  $scope.handleCloseModal = function() {
    $state.go($scope.states.topLevel);
  };

  $scope.$on('$destroy', function() {
    ['azimuth', 'elevation'].forEach(function(type) {
      if ($scope.savedPatternData[type]) {
        $scope.patternData[type] = $scope.savedPatternData[type];
        $scope.savedPatternData[type] = null;
      };
    });
  });

  // find the name of the top-level state from which we were called,
  // so we can go back to it on modal close
  if (states.topLevel === null) {
    states.topLevel = $state.$current;
    while (states.topLevel.parent.name !== '') {
      states.topLevel = states.topLevel.parent;
    };
    states.topLevel = states.topLevel.name;
  };

  $scope.couldNavigate = ((! $scope.savedPatternData.azimuth)
                        && (! $scope.savedPatternData.elevation)
                        && $scope.patternData.azimuth
                        && $scope.patternData.elevation
                        && $scope.uiElementValues.antennaType
                        && (states.topLevel == 'fm' || $scope.uiElementValues.antennaClass)
                        && ($scope.uiElementValues.frequency || $scope.uiElementValues.channel));
  $scope.canNavigate = true;

  $scope.detailElementValues = Values.preserve($scope,
                                               'detailElementValues',
                                               detailElementValues,
                                               states.topLevel + '.detail');

  $scope.states = Values.preserve($scope,
                                  'states',
                                  states,
                                  states.topLevel + '.detail');

  // Default the "use vpol" box to checked whenever we have vpol
  if ($scope.detailElementValues.useVpolCheck === null) {
    $scope.detailElementValues.useVpolCheck = !!$scope.patternData.azimuthVpol;
  };

  $scope.$watch(
    function() {
      return $state.$current;
    },
    function(newValue) {
      if (newValue.name === $scope.states.topLevel + '.detail') {
        $scope.states.lastChild = null;
      } else {
        $scope.states.lastChild = newValue.name
          .split(/\./)
          .reverse()[0];
      };
    });  

  // NB modal gets hidden in the detail route's onExit handler (see
  // /src/routes.js#L24) because otherwise ui-router will destroy the
  // modal element before bootstrap is through tearing it down, which
  // breaks bootstrap :(
  
  // NB ideally we'd handle modal creation in the route's onEnter
  // handler, but we can't in this case because we need scope and
  // state information in order to do so - we could still do it in the
  // route, but only by defining this controller there, which would be
  // messy
  jQuery('#antennaModal').on('shown.bs.modal', function() {
    var $modal = $(this);
    setTimeout(function (e) {
        $(document).off('focusin.bs.modal');
    }, 750);
  }).modal('show');

  jQuery('#antennaModal').on('hidden.bs.modal', function(event) {
    $scope.handleCloseModal();
  });
  
  if ($state.current.name.split('.').length < 3) {
    $state.go($scope.states.topLevel
            + '.detail.'
            + ($scope.states.lastChild || 'azimuth'));
  }
  function getUniqueAntennaID() {
    var azIdentifier, elIdentifier;
    if ($rootScope.beastMode) {
        azIdentifier = $scope.patternData.azimuth ? $scope.patternData.azimuth.drawingNumber : 'customAz';
        elIdentifier = $scope.patternData.elevation ? $scope.patternData.elevation.drawingNumber : 'customEl';
    } else {
        azIdentifier = $scope.patternRecords.azimuth.AzPat;
        elIdentifier = $scope.patternRecords.elevation.ElPat;
    }
    return azIdentifier + '_' + elIdentifier;
  };
  $scope.$watch( //change the Vpol if and only if a new azimuth pattern is selected
    function() {
      return $scope.patternData.azimuth
      && $scope.patternData.azimuthVpol
      && $scope.patternData.elevation;
    },
    function(value) {
      $scope.detailElementValues.PeakVpol = $scope.businessLogicValues.maxPeakVpol;
      var beastMode = ($rootScope.beastMode == true) ? 1 : 0;
      var PatRec = $scope.patternRecords;
      if (beastMode) {
        if ($scope.patternData.azimuth)  {
          PatRec.azimuth = TvAntennaService.find('azimuth', 'AzPat', $scope.patternData.azimuth.drawingNumber);
        };
        if (!PatRec.azimuth || typeof PatRec.azimuth == 'undefined') {
          PatRec.azimuth = { //default value to keep application happy
            "MinChannel": 2,
            "MaxChannel": 69,
            "AzPat": "3BP260",
            "Shape": "Custom",
            "Design": "Custom",
            "AzGain": 2.6,
            "PoleDiam": 7,
            "MatchCode": 3.3,
            "Class": [
                "High Power"
            ],
            "MinFreq": 57,
            "MaxFreq": 803
          };
        };
        if ($scope.patternData.elevation)  {
          PatRec.elevation = TvAntennaService.find('elevation', 'ElPat', $scope.patternData.elevation.drawingNumber);
        };
        if (!PatRec.elevation || typeof PatRec.elevation == 'undefined') {
          PatRec.elevation = { //default value to keep application happy
            "Type":"V Deltawing panel",
            "Design":"Custom",
            "ElPat":"01H011000",
            "ElGain":"1.1",
            "BT":"0",
            "Mount":"Side",
            "Class":["High Power"]
          };
        };
      }      

      var uniqueAntennaName = getUniqueAntennaID();
      if ($scope.patternRecords.azimuth.IsFixed) {
        if(uniqueAntennaName.includes("TUL")) {
          $scope.detailElementValues.ERPVpolSplit = 40;
        }
        else if ($scope.patternRecords.azimuth.PeakValue) {

          var elGain = $scope.businessLogicValues.rmsMainLobeGain;
          var hPolGain = $scope.patternData.azimuth.gain;
          var vPolGain = $scope.patternData.azimuthVpol.gain;

          //$scope.detailElementValues.PeakVpol = parseFloat($scope.patternRecords.azimuth.PeakValue);
          $scope.usePeak = true;
          $scope.detailElementValues.ERPVpolSplit = CalculationService.getERPFromPeak(
                $scope.patternRecords.azimuth.PeakValue,
                elGain,
                hPolGain,
                vPolGain) * 100;
        }
        else if ($scope.patternRecords.azimuth.ERPSplit) {
          $scope.detailElementValues.ERPVpolSplit = parseFloat($scope.patternRecords.azimuth.ERPSplit);
          $scope.usePeak = false;
        }
      }
      else if ($scope.detailElementValues.uniqueAntennaName != uniqueAntennaName) {
        $scope.detailElementValues.uniqueAntennaName = getUniqueAntennaID();
        $scope.detailElementValues.ERPVpolSplit = 50;
      }
    });
};
