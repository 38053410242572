module.exports = function($state, $q, $timeout) {
  var preserved = {};
  var listenerRemover = {};
  var attachDefers = {};
  var updateTimeout;

  /* Preserve/fetch API */
  
  this.preserve = function($childScope, key, defaultValue, preserveKey) {
    preserveKey = preserveKey || $state.$current.name;

    if (typeof preserved[preserveKey] === 'undefined') {
      preserved[preserveKey] = {};
    };

    if (typeof listenerRemover[preserveKey] === 'undefined') {
      listenerRemover[preserveKey] = {};
    };

    if (typeof defaultValue !== 'undefined'
        && typeof preserved[preserveKey][key] === 'undefined') {
      preserved[preserveKey][key] = defaultValue;
    };

    listenerRemover[preserveKey][key] = $childScope.$on('$destroy', function() {
      preserved[preserveKey][key] = $childScope[key];
    });

    $childScope.$watch(
      function() {
        return $childScope[key];
      },
      function(newValue, oldValue) {
        $timeout.cancel(updateTimeout);
        updateTimeout = $timeout(function() {
          if (oldValue === null || typeof oldValue !== 'object') {
            preserved[preserveKey][key] = newValue;
            return;
          };
          
          Object.keys(newValue).forEach(function(valueKey) {
            if (newValue[valueKey] !== oldValue[valueKey]) {
              preserved[preserveKey][key][valueKey] = newValue[valueKey];
            };
          });
        }, 50);
      }, true);

    return preserved[preserveKey][key] || undefined;
  };

  this.fetch = function(preserveKey, key) {
    if (! preserved[preserveKey]) {
      return undefined;
    };
    
    return preserved[preserveKey][key];
  };

  /* Expose/attach API */
  
  this.expose = function($childScope, key, defaultValue) {
    var preserveKey = $state.$current.name;
    var value = this.preserve($childScope, key, defaultValue);

    if (attachDefers[preserveKey] && attachDefers[preserveKey][key]) {
      attachDefers[preserveKey][key].resolve(preserved[preserveKey][key]);
      delete attachDefers[preserveKey][key];
    };

    return value;
  };

  this.attach = function(preserveKey, key) {
    var defer;

    if (attachDefers[preserveKey] && attachDefers[preserveKey][key]) {
      defer = attachDefers[preserveKey][key];
    } else {
      defer = $q.defer();
    };

    if (preserved[preserveKey] && preserved[preserveKey][key]) {
      defer.resolve(preserved[preserveKey][key]);
    } else {
      if (typeof attachDefers[preserveKey] === 'undefined') {
        attachDefers[preserveKey] = {};
      };
      attachDefers[preserveKey][key] = defer;
    };

    return defer.promise;
  };

  /* Utility methods */
  
  this.unpreserve = function(key) {
    var preserveKey = $state.$current.name;
    
    listenerRemover[preserveKey][key]();
    delete preserved[preserveKey][key];
  };

  this.fetchAll = function() {
    return JSON.parse(JSON.stringify(preserved));
  };
};
