module.exports = function($scope, $rootScope, PDFService, CalculationService, BusinessLogicService, Values, MechService, AntennaLengthService) {
  $scope.tabElementValues = Values.preserve($scope, 'tabElementValues', {
    windSpec: 'G',
    includeIce: false,
    iceThickness: .5, 
    windSpeed: 90, 
    windSpeedIce: 40, 
    towerHeight: 1000,
    elevationOnTower: 1000, 
    heightAboveTerrain: 80,
    exposureCategory: 'C',
    topographicCategory: '1',
    structureClass: 'II'
  });
  $scope.root = $rootScope;

  $scope.tabElementOptions = {
    structureClass: {
      'I': 'Lowest Risk Category',
      'II': 'Medium Risk Category Most Common',
      'III': 'Highest Risk Category'
    },
    exposureCategory: {
      'B': 'Obstructed Terrain',
      'C': 'Partially Obstructed Terrain',
      'D': 'Open Terrain(Shoreline)'
    },
    topographicCategory: {
      '1': 'Flat Rolling Terrain',
      '2': 'Near Edge of Drop Off',
      '3': 'Hilltop',
      '4': 'Top of Ridge'
    },
  };

  $scope.businessLogicValues = {  };

  $scope.canPrint = function() {
    return PDFService.canPreparePages(['specs']) && $scope.businessLogicValues.antennaLengthData;
  };

  $scope.printButtonTitle = function() {
    return ($scope.canPrint() ? '' : 'Complete this tab to print results.');
  };

  $scope.print = function() {
    var pages = PDFService.preparePages(['specs']);
    PDFService.scopeMethods.print(pages);
  };

  BusinessLogicService.add({
    forceCoefOverArea: {
      dependencies: function($scope) {
        return BusinessLogicService.get('antennaLengthData', $scope);
      },
      update: function($scope) {
        var length = BusinessLogicService.get('antennaLengthData', $scope).length;
        return CalculationService.forceCoefOverArea(length);
      }
    },
    momentArm: {
      dependencies: function($scope) {
        return BusinessLogicService.get('antennaLengthData', $scope)
          && BusinessLogicService.get('mechanicalData', $scope);
      },
      update: function($scope) {
        var lengthData = BusinessLogicService.get('antennaLengthData', $scope);
        var mechData = BusinessLogicService.get('mechanicalData', $scope);

        if (mechData.momentArm) {
          return mechData.momentArm;
        } else {
          return CalculationService.momentArm(lengthData.length, (lengthData.lightningProtectorLength || 0));
        };
      }
    },
    areaOfFlats: {
      dependencies: function($scope) {
        return BusinessLogicService.get('antennaLengthData', $scope);
      },
      update: function($scope) {
        var length = BusinessLogicService.get('antennaLengthData', $scope).length;
        
        return CalculationService.areaOfFlats(length);
      }
    }
  });

  function updateControllerBusinessLogic() {
    BusinessLogicService.updateAll($scope);
    $scope.businessLogicValues = BusinessLogicService.getAll();
  };

  function getMechanicalData() {
    var tabEV = $scope.tabElementValues;
    var uiEV = $scope.uiElementValues;
    var patRec = $scope.patternRecords;

    var getParams = function(withIce) {
      return [
          tabEV.windSpec,
          withIce,
          (withIce == true) ? tabEV.iceThickness: 0,
          tabEV.windSpeed,
          tabEV.windSpeedIce,
          parseInt(patRec.elevation.ElPat.substr(0, 2)) / 8,
          tabEV.elevationOnTower, 
          tabEV.towerHeight,
          tabEV.heightAboveTerrain,
          tabEV.exposureCategory, 
          parseInt(tabEV.topographicCategory), 
          tabEV.structureClass,
          patRec.azimuth.AzPat,
          patRec.elevation.ElPat,
          uiEV.channel,
          uiEV.frequency,
          uiEV.radome,
          $scope.detailElementValues.useVpolCheck
      ];
    }
    var params = getParams(false);
    var mechanicalData = MechService
        .byDesign(patRec.azimuth.Design, uiEV.radome)
        .get(uiEV.channel,
             $scope.patternData.azimuth.drawingNumber,
             $scope.patternData.elevation.drawingNumber,
             params);

    if (tabEV.includeIce) {
      params = getParams(true);
      var mechanicalIceData = MechService
          .byDesign(patRec.azimuth.Design, uiEV.radome)
          .get(uiEV.channel,
               $scope.patternData.azimuth.drawingNumber,
               $scope.patternData.elevation.drawingNumber,
               params);
      mechanicalData = angular.extend(mechanicalData, {
        forceCoefIce: mechanicalIceData.forceCoef,
        weight_wIce: mechanicalIceData.weight,
        iceWeight: mechanicalIceData.iceWeight,
        weight: mechanicalIceData.weight - mechanicalIceData.iceWeight
      });
    }
    $scope.businessLogicValues.mechanicalData = mechanicalData;
  };
  function getAntennaLengthData() {
    var patRec = $scope.patternRecords;
    var azimuth = patRec.azimuth;
    var uiEV = $scope.uiElementValues;
    var lengths = AntennaLengthService.calculate(azimuth.Design, uiEV.channel, uiEV.frequency, patRec.elevation.ElPat, azimuth.Shape);
    var ALdata = {
      'length': lengths.H2,
      'centerOfRadiation': lengths.H3,
      'lengthWithProtector': patRec.elevation.Mount != 'Side' ? lengths.H4 : null,
      'lightningProtectorLength': (lengths.H4 - lengths.H2)
    };     
      $scope.businessLogicValues.antennaLengthData = ALdata;
  };

  updateControllerBusinessLogic();

  $scope.$watch(function() {
      return $scope.uiElementValues.radome;
    },
    function(newValue, oldValue) {
      $scope.tabElementValues.radome = newValue;
    });
    
    $scope.$watch(function() {
      return $scope.uiElementValues.channel;
    },
    function(newValue, oldValue) {
      getAntennaLengthData();
      getMechanicalData();
  });

  $scope.$watch(function() {
      return $scope.tabElementValues;
    },
    function(newValue, oldValue) {
      if (($scope.patternRecords.azimuth !== null)
      && ($scope.patternRecords.elevation !== null)) {  
        getAntennaLengthData();
        getMechanicalData();
        var tEV = $scope.tabElementValues;
      };
  }, true);
};
