module.exports = function(ENVIRONMENT, BUILDDATE, $scope, $cookies, $window) {
  var staleThreshold = (new Date().getTime()) - (30 * 86400000); // thirty days ago
  
  $scope.showStaleWarning = (ENVIRONMENT === 'offline' && BUILDDATE < staleThreshold);
  $scope.hasAcceptedDisclaimer = ($cookies.get('acceptedDisclaimer') === 'yes');

  $scope.$root.dataLoaded = false;
  $scope.accept = function() {    
    $cookies.put('acceptedDisclaimer', 'yes');    
    $scope.hasAcceptedDisclaimer = true;
  };
  
  $scope.deny = function() {
    $cookies.put('acceptedDisclaimer', 'no');
    if (ENVIRONMENT === 'offline') {
      $window.close();
    } else {
      $window.location.href = 'http://www.dielectric.com';
    };
  };

  $scope.getBrowserName = function() {
    var browserName  = navigator.appName;
    var nAgt = navigator.userAgent;

    // In Opera 15+, the true version is after "OPR/" 
    if ((verOffset=nAgt.indexOf("OPR/"))!=-1) {
     browserName = "Opera";
    }
    // In older Opera, the true version is after "Opera" or after "Version"
    else if ((verOffset=nAgt.indexOf("Opera"))!=-1) {
     browserName = "Opera";
    }
    // In MSIE, the true version is after "MSIE" in userAgent
    else if ((verOffset=nAgt.indexOf("MSIE"))!=-1) {
     browserName = "Internet Explorer";
    }
    // In MSIE, the true version is after "MSIE" in userAgent
    else if ((verOffset=nAgt.indexOf("Trident"))!=-1) {
     browserName = "Internet Explorer";
    }
    // In MSIE, the true version is after "MSIE" in userAgent
    else if ((verOffset=nAgt.indexOf("Edge"))!=-1) {
     browserName = "Edge";
    }
    // In Chrome, the true version is after "Chrome" 
    else if ((verOffset=nAgt.indexOf("Chrome"))!=-1) {
     browserName = "Chrome";
    }
    // In Safari, the true version is after "Safari" or after "Version" 
    else if ((verOffset=nAgt.indexOf("Safari"))!=-1) {
     browserName = "Safari";
    }
    // In Firefox, the true version is after "Firefox" 
    else if ((verOffset=nAgt.indexOf("Firefox"))!=-1) {
     browserName = "Firefox";
    }
    // In most other browsers, "name/version" is at the end of userAgent 
    else if ( (nameOffset=nAgt.lastIndexOf(' ')+1) < 
              (verOffset=nAgt.lastIndexOf('/')) ) 
    {
     browserName = nAgt.substring(nameOffset,verOffset);
     if (browserName.toLowerCase()==browserName.toUpperCase()) {
      browserName = navigator.appName;
     }
    }

    return browserName;
  }
  $scope.browserName = $scope.getBrowserName();
};
