module.exports = function() {
  return {
    scope: {
      update: '=update',
      model: '=spinnerModel'
    },
    link: function(scope, element, attrs) {
      var precision;
      var precisionParts;
      var precisionMinSlice;
      var min = parseFloat(element.prop('min'));
      var max = parseFloat(element.prop('max'));
      
      var step = parseFloat(element.prop('step'));
      if (isNaN(step)) {
        step = 1;
      };

      var floorToPrecision = function(value, precision) {
        return Math.floor(value * Math.pow(10, precision)) / Math.pow(10, precision);
      };

      // Get value precision (# of decimal places) from the min
      // value given on the directive
      precisionParts = min.toString().split(/\./);
      if (precisionParts.length === 1) {
        precision = 0;
      } else {
        precision = precisionParts[1].length;
      };
      precisionMinSlice = 1 / Math.pow(10, precision);

      var listener = function(event, value) {
        if (event.type === 'keyup' && !isNaN(parseFloat(value))
            && (value[value.length-1] === '.' || parseFloat(value) < min)) {
          return;
        }
        var buffer = max * 10; //to allow typing number greater than maximum to reach maximum

        // Simple validation cases: not a number, or outside the control's range
        if (isNaN(value)) {
          value = null;
        } else if (value < min && event.type !== 'keyup') {
          value = min;
        } else if (value > max && ! scope.model || value > buffer) {
          value = max;
        } else if (value > max && scope.model) { //set value to what it was if you hit another number 'by mistake'
          value = scope.model;
        } else if (value > min && event.type === 'keyup' && value <= max/10) {
          return;
        }
        
        // Round incoming value to the same precision as the control's range
        if (event.type !== 'keyup' && value !== null) {
          value = floorToPrecision(value, precision);

          // If the step isn't 1, force the value to correctness for
          // the given range and step
          if (!jQuery(element).spinner('isValid')) {
            while (value > min && !jQuery(element).spinner('isValid')) {
              value = floorToPrecision(value - precisionMinSlice, precision);
              jQuery(event.target).val(value);
            };
            if (value < min) {
              value = min;
            };
          };
        };
        
        jQuery(event.target).val(!value ? '' : value);
        scope.$apply(function() {
          scope.model = value;
          if (typeof scope.update === 'function') {
            scope.update(value);
          };
        });
      };

      scope.$watch(
        function() {
          return scope.model;
        },
        function(newValue, oldValue) {
          jQuery(element).val(newValue);
        });

      jQuery(element).spinner({
        min: min,
        max: max,
        animate: true,
        step: step,
        spin: function(event, spinner) {
          listener(event, parseFloat(spinner.value));
        },
        change: function(event, spinner) {
          listener(event, parseFloat(jQuery(event.currentTarget).val()));
        }
      });

      element.on('keyup', function(event) {
        var input = jQuery(event.target).val().toString();
        // var value = parseFloat(input);
        listener(event, input);
      });
    }
  };
};
