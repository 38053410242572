module.exports = function($timeout) {
  return {
    scope: {
      model: '=ngModel',
      value: '=ngValue',
      disabled: '=ngDisabled'
    },
    link: function(scope, element, attrs) {
      var label = element.next('label');

      $timeout(function() {
        scope.$watch(
          function() {
            return scope.model;
          },
          function(newValue, oldValue) {
            if (newValue === scope.value) {
              element.attr('checked', true);
              $(element).iCheck('update');
            } else {
              element.attr('checked', false);
              $(element).iCheck('update');
            };
          });

        scope.$watch(
          function() {
            return scope.disabled;
          },
          function(newValue, oldValue) {
            if (typeof newValue === 'undefined') return;

            if (newValue) {
              if (scope.model === scope.value) {
                scope.model = null;
              };
              element.prop('disabled', true);
              element.addClass('disabled');
              $(element).iCheck('update');
              label.addClass('disabled');
            } else {
              element.prop('disabled', false);
              element.removeClass('disabled');
              $(element).iCheck('update');
              label.removeClass('disabled');
            };
          }
        );

        $(element).iCheck({
		      radioClass: 'icheckbox_square-blue'
        })
          .on('ifClicked', function(event) {
            if (element.prop('checked') === true) {
              $(element).iCheck('uncheck');
              element.prop('checked', false);
              scope.$apply(function() {
                scope.model = null;
              });
            }
          })
          .on('ifChecked', function(event) {
            element.prop('checked', true);
            scope.$apply(function() {
              scope.model = scope.value;
            });
          });
      });
    }
  };
};
