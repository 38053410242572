module.exports = function() {
  var self = this;

  self.helpers = {
    simplePeakPower: function(frequency, tl) {
      return parseFloat(tl.const2a) * Math.pow(frequency, parseFloat(tl.const3b));
    },
    checkFrequencyVsDiameter: function(frequency, diameter, value) {
	    if ((frequency > 620 && diameter.charAt(0) == 9) ||
		      (frequency > 704 && diameter.charAt(0) == 8)) {
        return "N/A";
      };
      return value;
    },
    pad: function(integer, format) {
	    var str = "" + integer;
	    var ans = format.substring(0, format.length - str.length) + str;
	    return ans;
    }
  };

  self.calc = {
    channelToFrequency: function(channel) {
		  if (channel == 0) return null;
      if (channel < 5) {
			  return 57 + 6 * (channel - 2);
		  } else if (channel < 7) {
			  return 79 + 6 * (channel - 5);
		  } else if (channel < 14) {
			  return 177 + 6 * (channel - 7);
		  } else if (channel < 70) {
			  return 473 + 6 * (channel - 14);
		  } else if (channel > 200 && channel < 301) {
			  return (channel - 201) * 0.2 + 88.1 ;
		  } else {
			  return null;
      };
    },

    frequencyToChannel_tv: function(frequency) {
      var channelBaseFrequencies =
            [null, 57, 63, 69, 79, 85, 177, 183, 189, 195, 201, 207, 213, 473, 479, 485, 491, 497, 503, 509, 515, 521, 527, 533, 539, 545, 551, 557, 563, 569, 575, 581, 587, 593, 599, 605, 611, 617, 623, 629, 635, 641, 647, 653, 659, 665, 671, 677, 683, 689, 695, 701, 707, 713, 719, 725, 731, 737, 743, 749, 755, 761, 767, 773, 779, 785, 791, 797, 803, 809];
      
      if (frequency < 57 || frequency > 809) {
        return null;
      };
      
      for (var i = 0; i < channelBaseFrequencies.length; i++) {
        var thisFreq = channelBaseFrequencies[i];
        var nextFreq = channelBaseFrequencies[i + 1];
        if (frequency >= thisFreq && frequency < nextFreq) {
          return i+1;
        };
      };

      return null;
    },

    frequencyToChannel_fm: function(frequency) {
      return ((frequency - 88.1) * 5 + 201);
    },

    frequencyToChannel: function(frequency) {
      if ((Math.floor(frequency) !== frequency)
          && frequency >= 88.1 && frequency <= 107.9) {
        return self.calc.frequencyToChannel_fm(frequency);
      } else {
        return self.calc.frequencyToChannel_tv(frequency);
      };
    },

    channelToBand:function(channel) {
      if (channel == 1)
        return "";
      else if (channel >= 2 && channel <= 3)
        return "L";
      else if (channel >= 4 && channel <= 6)
    	  return "M";
      else if (channel >= 7 && channel <= 13)
        return "H";
      else if (channel >= 14 && channel <= 95)
        return "";
      else
        return "";
    },

    antennaType: {
      fm: function(antenna) {
        var total_panels = antenna.numBays * antenna.slotsPerLayer;
        var type = '';
	      if (antenna.design === 'DCR') {
          var feed = antenna.elevationPattern.substr(1, 1);
	        type = "DCR-" + antenna.elevationPattern.substr(4, 1)
            + antenna.numBays + feed;
	      }
	      else if (antenna.design === "FMVEE") {
	        type = "FMV-"
            + antenna.azType + "-"
            + antenna.numBays + "FM/"
            + total_panels;
	      }
	      else { // FM Panels
	        var MODEL = antenna.design.substr(0, 4);
	        if (! antenna.azType) {
	          type = MODEL + "-" + antenna.numBays;
	        }
	        else {
	          type = MODEL + "-"
              + antenna.azType + "-"
              + self.helpers.pad(antenna.numBays, "0") + "FM/"
              + self.helpers.pad(total_panels, "0");
	        }
	      }
	      return type;
      },
      tv: function(antenna) {
        var type = '';
        var radome = antenna.withRadome ? '-R' : '';
        var design = antenna.design;
        var azType = antenna.azType;
        var elType = antenna.elType;
        var azDelimiterPos;
        var tempTypeAZ;
        var MODEL;
        var total_panels;
        var feed_size = '';
        var spline_type;
        var typeNumBays;
        var slotsPerLayer;
        var design3 = design.substr(0,3);
        var VpolText = antenna.useVpol ? "/VP" : "";

        azType = (azType != null && typeof azType != 'undefined') ? azType : antenna.azimuthPattern;
        azType = azType.toUpperCase();
        azDelimiterPos = azType.indexOf('-');

        if (azType == 'O6SP-A')
          azDelimiterPos = 0;

        // if (azType.substr(0, 5) == 'TLS-V') {
        //   azDelimiterPos = 0;
        //   azType = azType.substr(azType.length - 7);
        // }

        if (azDelimiterPos !== -1) {
          design = azType.substr(0, azDelimiterPos);
          azType = azType.substr(azDelimiterPos + 1);
        }

        tempTypeAZ = azType;
        typeNumBays = design == 'DCR' ? parseInt(antenna.elevationPattern.substr(2, 2)) : parseInt(antenna.elevationPattern.substr(0, 2));
        slotsPerLayer = antenna.azimuthPattern.substring(
          azDelimiterPos + 1, 
          Math.max(
            antenna.azimuthPattern.indexOf('-', azDelimiterPos + 1), 
            antenna.azimuthPattern.length
          )
        )
        .replace(/[^\d]/g, '');

        if (design == "TCL" || design == "TDM") {
          type = antenna.elType;

        }
			  else if (design == "TF") {
          type = "TF-" + typeNumBays + self.channelToBand(antenna.channel) + "T";
        }
			  else if (design == "TW") {
          type = antenna.elType + antenna.channel + radome;
        }
			  else if (design == "TCP") {
          type = antenna.elType + radome + " " + tempTypeAZ;
        }
			  else if (design.substr(0, 2) == "TH") {
	        MODEL = design.substr(2, 1);
	        if (MODEL == "V") {
	          // type = "TH" + MODEL + "-" + typeNumBays + "A" + antenna.channel + radome + " " + tempTypeAZ;
            type = design3 + "-" + typeNumBays + "A" + antenna.channel + radome + " " + tempTypeAZ;
	        }
	        else {
	          total_panels = typeNumBays * parseInt(slotsPerLayer);
	          if (tempTypeAZ === "") {
	            // type = "TH" + MODEL + "-" + typeNumBays + "-1" + radome;
              type = design3 + "-" + typeNumBays + "-1" + radome;
	          }
	          else {
	            if (tempTypeAZ.length > 2)
	              tempTypeAZ = tempTypeAZ.substr(0, tempTypeAZ.length - 1);
	            // type = "TH" + MODEL + "-" + tempTypeAZ + "-" + parseInt(typeNumBays) + "/" + parseInt(total_panels) + "-1" + radome;
              type = design3 + "-" + tempTypeAZ + "-" + parseInt(typeNumBays) + "/" + parseInt(total_panels) + "-1" + radome;
	          }
	        }
	      }
			  else if (design.substr(0, 2) == "TU") {
          MODEL = design.substr(2, 1);
          total_panels = slotsPerLayer * typeNumBays;
          
	        if (antenna.antClass.toUpperCase() == "HIGH POWER") {
	          feed_size = '';
	          if (antenna.antType.toUpperCase() == "CPOL PANEL - HIGH POWER")
	            feed_size = "H";
	          else
	            feed_size = typeNumBays > 8 ? "H" : "U";
	        }
	        else {
	          if (antenna.antType.toUpperCase() == "HPOL PANEL - LOW POWER")
	            feed_size = "L";
	          else
	            feed_size = "M";
	        }
          
	        if (true)
	          spline_type = "T";
	        else
	          feed_size = "something else U";
	        
	        
	        if (tempTypeAZ === "") {
	          // type = "TU" + MODEL + "-" + typeNumBays + "-1" + radome;
            type = design3 + "-" + typeNumBays + "-1" + radome;
	        }
	        else {
	          if (tempTypeAZ.length > 2)
	            tempTypeAZ = tempTypeAZ.substr(0, tempTypeAZ.length - 1);
	          type = design3 + "-" + tempTypeAZ + "-" + self.helpers.pad(typeNumBays, "00") + "/" + self.helpers.pad(total_panels, "00") + feed_size + "-" + spline_type;
	        }//replaced "TU" + MODEL with design3 in the previous line
        }
			  else if (design == "TLP") {
	        if (typeNumBays == 8)
	          type = antenna.elType + tempTypeAZ;
	        else {
	          if (antenna.antType.toUpperCase().indexOf("CUSTOM") > -1)
	            type = antenna.elType + tempTypeAZ + " (C)";
	          else
	            type = antenna.elType + tempTypeAZ;
	        }

          type += VpolText + radome;
	      }
			  else if (design == "DL") {
          type = "DL-" + typeNumBays;
        }
			  else if (design == "DLP") {
          type = "DLP-" + typeNumBays + tempTypeAZ;
        }
			  else if (design == "DSB") {
	        if (antenna.antType.toUpperCase().indexOf("CUSTOM") > -1)
	          type = antenna.elType + "-" + tempTypeAZ + radome + " (C)";
	        else
	          type = antenna.elType + "-" + tempTypeAZ + radome;
	      }
			  else if (design.substring(0,3) == "TLS" || design == "TLS") {
          // type = antenna.elType + tempTypeAZ;  //old code

            var RadomeText = radome + " ";
            var AntTypePostfix = (design.substring(0,4) == "TLSV") ? "Broadband" : antenna.channel;
            var gain = typeNumBays * slotsPerLayer;
            AntennaDesignation = "TLS-V" + gain + VpolText + RadomeText + "(" + AntTypePostfix + ")";
            type = AntennaDesignation;
        }
        else if (design == "WB")
          type = 'TFU-' +  typeNumBays + 'WB';
        else 
          type = antenna.elType + radome + " " + tempTypeAZ;
        return type.replace(/\r|\n/g, '');
      }      
    },
    
    maxAvgPower: function(length, frequency, psig, tl, tlType) {
      if (tlType.match(/flexline/i)) {
        return self.calc._maxAvgPower_Flex(length, frequency, psig, tl);
      } else {
        return self.calc._maxAvgPower_nonFlex(frequency, psig, tl);
      };
    },

    _maxAvgPower_Flex: function(length, frequency, psig, tl) {
      var attenuation = self.calc._attenuation_Flex(length, frequency, tl);
      var calculatedPower = tl[0][0] / (attenuation / (length / 100));
      var maximumPower = tl[0][1];

      return Math.min(calculatedPower, maximumPower);
    },

    _maxAvgPower_nonFlex: function(frequency, psig, tl) {
      var power = self.helpers.simplePeakPower(frequency, tl);
      var resistance = parseFloat(tl.Resistance);
      psig = psig || 0;
      if (tl.Type === 'EHT' && psig === 0) {
        power *= ((resistance === 75) ? 0.875 : 0.885);
      } else if (tl.Type !== 'EHT' && psig === 15) {
        power *= ((resistance === 75) ? 1.22 : 1.21);
      };
      return power;
    },

    maxDTVPower: function(length, frequency, psig, tl, tlType, derate) {
      return self.calc.maxAvgPower(length, frequency, psig, tl, tlType) * derate;
    },

    peakTVPower: function(length, frequency, psig, tl, tlType, derate) {
      var peakPower;
      if (tl.Type === 'EHT') {
        peakPower = self.helpers.simplePeakPower(frequency, tl)
          * ((tl.Resistance === 75) ? 0.875 : 0.885);
      } else {
        peakPower = self.calc.maxAvgPower(length, frequency, psig, tl, tlType);
      };
      peakPower = peakPower / 0.7;
      return peakPower * derate;
    },

    attenuation: function(length, frequency, tl, tlType) {
      if (tlType.match(/flexline/i)) {
        return self.calc._attenuation_Flex(length, frequency, tl);
      } else {
        return self.calc._attenuation_nonFlex(length, frequency, tl);
      };
    },

    _attenuation_Flex: function(length, frequency, tl) {
      return ((tl[1][0] * Math.pow(frequency, 0.5))
              + (tl[1][1] * frequency))
        * length / 100;
    },

    _attenuation_nonFlex: function(length, frequency, tl) {
      length = length || 20;
      var diameter = parseInt(tl.Diameter);
      if (diameter < 8 || (diameter === 9 && frequency <= 620)) {
        return tl.const5a
          * Math.pow(tl.const6b, frequency)
          * Math.pow(frequency, tl.const7c)
          * (length / 100);
      } else if (diameter === 8 && frequency < 704) {
        return tl.const5a
          * Math.pow(frequency, tl.const6b)
          * (length / 100);
      } else {
        console.error('Invalid parameters to _attenuation_nonFlex', [].slice.call(arguments));
        return null;
      };
    },
    
    asymmetricalLoss_hpol: function(gain, hpolFactor) {
      hpolFactor = hpolFactor || 1;
      return Math.sqrt(1/gain) * hpolFactor;
    },

    asymmetricalLoss_vpol: function(gain, vpolFactor) {
      return Math.sqrt(1/gain) * vpolFactor;
    },
    
    percentPower_hpol: function(loss_hpol, loss_vpol) {
      // actually a factor (i.e. [0..1]), not a percentage
      var sum = Math.pow(loss_hpol, 2) + Math.pow(loss_vpol, 2);
      return Math.pow(loss_hpol, 2) / sum;
    },

    percentPower_vpol: function(loss_hpol, loss_vpol) {
      // actually a factor (i.e. [0..1]), not a percentage
      var sum = Math.pow(loss_hpol, 2) + Math.pow(loss_vpol, 2);
      return Math.pow(loss_vpol, 2) / sum;
    },

    ERP_Hpol_percentage: function(ERP_hpol, ERP_vpol) {
      // actually a percentage
      return (ERP_hpol / (ERP_hpol + ERP_vpol)) * 100;
    },

    ERP_Vpol_percentage: function(ERP_hpol, ERP_vpol) {
      // actually a percentage
      return (ERP_vpol / (ERP_hpol + ERP_vpol)) * 100;
    },

    efficiency: function(length, frequency, tl, tlType) {
      var attenuation = self.calc.attenuation(length, frequency, tl, tlType);
      return Math.pow(10, (-attenuation)/10);
    },

    maximumInputPower_TV: function(initialInputPower, channel, azimuth, elevation, DTV) {
      var actualInputPower;
      
      var LowIndex, HighIndex, LowChannel, HighChannel;
      var module_index;
      
      var TypeNumBays = elevation.Design === 'DCR'
            ? parseInt(elevation.ElPat.substr(2, 2))
            : parseInt(elevation.ElPat.substr(0, 2));

      // Analog TV
      if (azimuth.Design === 'TLP') {
        var TLPsATV = [8, 8.6, 6.4, 12, 8.6, 6.4, 16, 6.1, 4.5, 24, 7, 5.3,32, 10, 7.5];
        var TLPcATV = [8, 0, 0, 12, 0, 0, 16, 13, 9.7, 24, 15, 11.3, 32, 21.4, 16.1];
          // the following code is also used to select indices for the
          // DTV version of TLP power ratings;
          if (TypeNumBays === 8) {
            module_index = 0;
          } else if (TypeNumBays === 12) {
            module_index = 3;
          } else if (TypeNumBays >= 16 && TypeNumBays <= 32) {
            module_index = (TypeNumBays / 8) * 3;
          };
          LowIndex = module_index + 1;
          HighIndex = LowIndex + 1;
          LowChannel = 14;
          HighChannel = 69;
          if (azimuth.Design.substr(azimuth.Design.length - 1).toUpperCase() === "C") {
            //custom;
            initialInputPower = TLPcATV[LowIndex]
              - (((channel - LowChannel) / (HighChannel - LowChannel))
                 * (TLPcATV[LowIndex] - TLPcATV[HighIndex]));
          } else { // standard;
            initialInputPower = TLPsATV[LowIndex]
              - (((channel - LowChannel) / (HighChannel - LowChannel))
                 * (TLPsATV[LowIndex] - TLPsATV[HighIndex]));
          };
      };

        // because of an error in the orginal power rating design
        // specefications of the DSB antenna; (slot rating vs the
        // cable ratings vs mode of operation) the power ratings of;
        // the DSB series antennas are obtain here rather than from
        // the resource file information.; bypass if anything other
        // than DSB antenna;
        if (azimuth.Design === "DSB") {
          var DSBsATV = [8, 23, 23, 23, 23, 12, 27.6, 27.6, 25.7, 23.1, 16, 19.5, 17.5, 15.8, 14.5, 24, 23.2, 20.9, 18.8, 17.2, 32, 0, 0, 0, 0];
          var DSBcATV = [8, 0, 0, 0, 0, 12, 0, 0, 0, 0, 16, 33.5, 30.3, 27, 23.9, 24, 39.9, 36, 32.1, 28.5, 32, 55.9, 50.5, 45, 39.9];
          // the following code is also used to select indices for the
          // DTV version of DSB power ratings;
          if (TypeNumBays === 8) {
            module_index = 0;
          } else if (TypeNumBays === 12) {
            module_index = 5;
          } else if (TypeNumBays >= 16 && TypeNumBays <= 32) {
            module_index = (TypeNumBays / 8) * 5;
          };
          if (channel >= 14 && channel <= 30) {
            LowIndex = module_index + 1;
            HighIndex = LowIndex + 1;
            LowChannel = 14;
            HighChannel = 30;
          } else if (channel >= 31 && channel <= 51) {
            LowIndex = module_index + 2;
            HighIndex = LowIndex + 1;
            LowChannel = 31;
            HighChannel = 51;
          } else if (channel >= 52 && channel <= 69) {
            LowIndex = module_index + 3;
            HighIndex = LowIndex + 1;
            LowChannel = 52;
            HighChannel = 69;
          };
          if (azimuth.Design.substr(azimuth.Design.length - 1).toUpperCase() === "C") {
            //custom;
            initialInputPower = DSBcATV[LowIndex]
              - (((channel - LowChannel) / (HighChannel - LowChannel))
                 * (DSBcATV[LowIndex] - DSBcATV[HighIndex]));
          } else { // standard;
            initialInputPower = DSBsATV[LowIndex]
              - (((channel - LowChannel) / (HighChannel - LowChannel))
                 * (DSBsATV[LowIndex] - DSBsATV[HighIndex]));
          };
        };

        actualInputPower = initialInputPower;
      
      if (DTV) {
        // Digital TV
        if (azimuth.Design === "GTH"
            || azimuth.Design.substr(0, 2) === "TU"
            || azimuth.Design.substr(0, 2) === "DL"
            || azimuth.Design === "TLS"
            || azimuth.Design === "WB") {
          actualInputPower = initialInputPower * 0.7;
        } else if (azimuth.Design === "TLP") {
          // in order to have a more realistic TLP power rating
          // (since the MechTable has the channel; ranges set to
          // 14->69, the MechTable power rating is set @ ch 69.; we
          // implement the ratio method of the published
          // specification sheet.;
          var TLPsDTV = [8, 5, 5, 12, 5, 5, 16, 4, 3, 24, 4.9, 3.7, 32, 7, 5.2];
          var TLPcDTV = [8, 0, 0, 12, 0, 0, 16, 8, 7, 24, 8.8, 7.9, 32, 12.5, 11.2];
          // the following code is also used to select indices for the DTV version of TLP power ratings;
          if (TypeNumBays === 8) {
            module_index = 0;
          } else if (TypeNumBays === 12) {
            module_index = 3;
          } else if (TypeNumBays >= 16 && TypeNumBays <= 32) {
            module_index = (TypeNumBays / 8) * 3;
          };
          LowIndex = module_index + 1;
          HighIndex = LowIndex + 1;
          LowChannel = 14;
          HighChannel = 69;
          if (azimuth.Design.substr(azimuth.Design.length - 1).toUpperCase() === "C") { //custom;
            initialInputPower = TLPcDTV[LowIndex]
              - (((channel - LowChannel) / (HighChannel - LowChannel))
                 * (TLPcDTV[LowIndex] - TLPcDTV[HighIndex]));
          } else { // standard;
            initialInputPower = TLPsDTV[LowIndex]
              - (((channel - LowChannel) / (HighChannel - LowChannel))
                 * (TLPsDTV[LowIndex] - TLPsDTV[HighIndex]));
          };
          actualInputPower = initialInputPower;

        } else if (azimuth.Design === "DSB" || azimuth.Design === "DSBc") {
          // because of an error in the orginal power rating design
          // specefications of the DSB antenna; (slot rating vs the
          // cable ratings vs mode of operation) the power ratings
          // of; the DSB series antennas are obtain here rather than
          // from the resource file information.; module_index =
          // TypeNumBays - 1 // bias back to "0";

          //    #layers, 14,   30,   51,   69   channels;
          var DSBsDTV = [8, 10, 10, 10, 10, 12, 12, 12, 12, 12, 16, 13.6, 12.3, 11.1, 10.1, 24, 16.2, 14.6, 13.2, 12.1, 32, 22.7, 20.5, 18.5, 16.9];
          var DSBcDTV = [8, 0, 0, 0, 0, 12, 0, 0, 0, 0, 16, 18.8, 18.8, 18.8, 16.7, 24, 21.8, 21.8, 21.8, 19.9, 32, 25, 25, 25, 25];
          if (azimuth.Design.substr(azimuth.Design.length - 1).toUpperCase() === "C") { //custom;
            initialInputPower = DSBcDTV[LowIndex]
              - (((channel - LowChannel) / (HighChannel - LowChannel))
                 * (DSBcDTV[LowIndex] - DSBcDTV[HighIndex]));
          } else { // standard;
            initialInputPower = DSBsDTV[LowIndex]
              - (((channel - LowChannel) / (HighChannel - LowChannel))
                 * (DSBsDTV[LowIndex] - DSBsDTV[HighIndex]));
          };
          actualInputPower = initialInputPower;
        } else if (azimuth.Design.substr(0, 2) === "TH"
                   || azimuth.Design.substr(0, 2) === "TF") {
          actualInputPower = initialInputPower * 0.7;   // org was 0.8 for 20% Aural... 11/04/2002 changed to 10% aural;
        } else {
          actualInputPower = initialInputPower / 2.3;
        };
      } else {
        // FM
        actualInputPower = initialInputPower;
      };
      return actualInputPower;
    },

    maximumInputPower_FM: function(azimuth, elevation) {
      var powerRating;
      
      var azDelimiterPos = azimuth.AzPat.indexOf('-');
      var azDelimiterPos2 = azimuth.AzPat.indexOf('-', azDelimiterPos + 1);
      
      var slotsPerLayer = azimuth.AzPat.substring(
        azDelimiterPos + 1, 
        azDelimiterPos2 > -1 ? azDelimiterPos2 : azimuth.AzPat.length
        )
      .replace(/[^\d]/g, '');
      
      var TypeNumBays = elevation.Design === 'DCR'
            ? parseInt(elevation.ElPat.substr(2, 2))
            : parseInt(elevation.ElPat.substr(0, 2));

      var MaxPower7_8Flex = 6.6; //Peak power handling of 7/8 flexline according to "Wg_tl att and power rating.xls"
      var MaxPower7_8Rigid = 4.6; //Peak power handling of 7/8 rigid tl according to "Wg_tl att and power rating.xls"

      var NumOfBays = slotsPerLayer * TypeNumBays;
      var actualInputPower;

      var antennaType = elevation.Type[0];

      switch(antennaType){
        case "LSeries": 
          actualInputPower = 1;
          break;
        case "MSeries": 
          powerRating = [0, 18, 36, 40, 40, 40, 40, 40, 40, 0, 40, 0, 40, 0, 40]; //the first entry has an index of 0
          actualInputPower = powerRating[NumOfBays];
          break;
        case "CSeries":
          powerRating = [0, 10, 20, 30, 40, 40, 40, 40, 40, 0, 40, 0, 40, 0, 40];
          actualInputPower = powerRating[NumOfBays];
          break;
        case "HSeries": 
          powerRating = [0, 4, 8, 12, 12, 12, 12, 12, 12, 0, 12, 0, 12, 0, 12];
          actualInputPower = powerRating[NumOfBays];
          break;
        case "DCBR": 
          actualInputPower = 0.9 * NumOfBays * MaxPower7_8Flex; // 0.9 is derating factor
          break;
        case "DCPE": 
          actualInputPower = 0.9 * 2 * NumOfBays * MaxPower7_8Rigid;
          break;
        case "DCPJ": 
          actualInputPower = 0.9 * NumOfBays * MaxPower7_8Rigid;
          break;
        case "FMVee":   
          powerRating = [0, 0, 125, 0, 135, 0, 185, 0, 185, 0, 185, 0, 250]; //the first entry has an index of 0
          actualInputPower = powerRating[TypeNumBays]; //Mslots refers to the number of layers
          break;
      }
      return actualInputPower;
    },

    ERPfromTPO: function(tpo, gain, length, frequency, tl, tlType, psig) {
      var erp = tpo * self.calc.efficiency(length, frequency, tl, tlType) * gain;
      return erp;
    },

    TPOfromERP: function(erp, gain, length, frequency, tl, tlType, psig) {
      var tpo = erp / gain / self.calc.efficiency(length, frequency, tl, tlType);
      return tpo;
    },

    antennaWeight: function() {
      return Math.floor((Math.random() * 20) + 10) * 10;
    },
    
    lightningProtectorLength: function() {
      return 4;
    },

    centerOfRadiation: function(lengthWithProtector) {
      return lengthWithProtector / 2;
    },

    forceCoefOverArea: function(antennaLength) {
      return 1.4 * Math.pow((antennaLength/4), 2);
    },

    momentArm: function(antennaLength, lightningProtectorLength) {
      var forceCoef = self.calc.forceCoefOverArea(antennaLength);
      return forceCoef / lightningProtectorLength;
    },

    areaOfFlats: function(antennaLength) {
      var forceCoef = self.calc.forceCoefOverArea(antennaLength);
      return forceCoef / 1.4;
    },

    maxPeakVpol: function(hpolPattern, vpolPattern, azimuth, elpattern) {
      var maxExceed = 0;
      var maxPeakVpol = 100;

      hpolPattern.data.forEach(function(value, azimuth) {
        var hpol = value;
        var vpol = vpolPattern.data[azimuth];
        
        if (((vpol.relative > hpol.relative) || (vpol.decibel > hpol.decibel))
            && (vpol.relative - hpol.relative > maxExceed)) {
          maxExceed = vpol.relative - hpol.relative;
          maxPeakVpol = Math.floor((hpol.relative / vpol.relative) * 100);
        };
      });

      return maxPeakVpol;
    },
    
    getERPFromPeak: function(peakValue, elGain, hPolGain, vPolGain) {
      var peakVpol = peakValue;    
      var peakHpol = 1;    

      var rmsVpol = Math.sqrt(1 / (vPolGain * elGain)) * peakVpol;
      var rmsHpol = Math.sqrt(1 / (hPolGain * elGain)) * peakHpol;

      var powerDivider = (( rmsHpol * rmsHpol ) + (rmsVpol * rmsVpol));
      var percentPowerHPol = rmsHpol * ( rmsHpol / powerDivider);
      var percentPowerVpol = rmsVpol * ( rmsVpol / powerDivider);

      var peakHpolGain = percentPowerHPol * hPolGain * elGain;
      var peakVpolGain = percentPowerVpol * vPolGain * elGain;

      var vPolSplit = peakVpolGain / (peakVpolGain + peakHpolGain);
      var hPolSplit = peakHpolGain / (peakVpolGain + peakHpolGain);

      return vPolSplit;
    }
  };

  Object.keys(self.calc).forEach(function(calcFun) {
    self[calcFun] = self.calc[calcFun];
  });
};
