module.exports = function(app) {
  app.directive('icheckRadioCheckbox',
                ['$timeout', require('./icheck-radio-checkbox')]);

  app.directive('icheckRadio',
                ['$timeout', '$parse', require('./icheck-radio')]);

  app.directive('icheckCheckbox',
                ['$timeout', require('./icheck-checkbox')]);

  app.directive('slider',
                ['$parse', '$timeout', require('./slider')]);

  app.directive('spinner',
                [require('./spinner')]);

  app.directive('stepper',
                [require('./stepper')]);

  app.directive('scroller',
                ['$timeout', '$document', require('./scroller')]);

  app.directive('patternSelector',
                ['$compile', '$filter', 'pattern-data', require('./pattern-selector')]);

  app.directive('toggle',
                [require('./toggle')]);

  app.directive('patternColorSwitcher',
                ['$compile', require('./pattern-color-switcher')]);

  app.directive('clampedNumberInput',
                [require('./clamped-number-input')]);

  app.directive('patternUploader',
                ['$filter', '$location', '$window', 'pattern-parser', require('./pattern-uploader')]);
};
