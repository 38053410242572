module.exports = function() {
    //var Decimal = require('decimal.js');
    
    var self = this;
    var sq = function(val) {
      return  Math.pow(val, 2);
    };
    var Sqr = function(val) {
      return  Math.sqrt(val);
    };
    var Truth = function(target) {
      return (defined(target) && target == true);
    };
    var defined = function(target) {
      return (typeof target != 'undefined');
    };

    var _this; //to represent the pattern being called, like DLP
    var A;
    var Adir1;
    var Adir2;
    var ADLPB1, ADLPB2;
    var ADLPC1, ADLPC2;
    var Amax;
    var AntennaDesignation;
    var antennaH2;
    var ArevC;
    var ArevCmax;
    var ArevF;
    var ArevFmax;
    var BarePole;
    var BarePoleArea;
    var BarePoleIce;
    var channel;
    var chkIce;
    var chkVPol;
    var count = {
      AppurtDrag: 0,
      Gust: 0,
      Importance: 0,
      Kdg: 0,
      Kzg: 0,
      Kztg: 0,
      Qzg: 0,
      lambda: 0
    }; // counter used to see how many times functions are run, just increment counter.functionName inside the function
    var Dpipe;
    var Elbow;
    var ElbowArea;
    var ElbowWeight;
    var EndFlangeIce;
    var EndFlanges;
    var EndLugIce;
    var EndLugs;
    var EndSeals;
    var Feedline;
    var FeedLineArea;
    var FeedLineIce;
    var FeedLineLength;
    var FeedlineWeight;
    var FinalWeight;
    var FinnedArea;
    var FinnedLength;
    var FinnedWeight;
    var FinWeight;
    var FlatFinnedArea;
    var frequency;
    var G;
    var gain;
    var GasStop;
    var GasStopIce;
    var GasStopWeight;
    var HarnessWeight;
    var HasRadome;
    var Ice;
    var IceWeight;
    var Inner;
    var L, L31;
    var lambda; // = 11802.854 / frequency
    var lambda31; // lambda at channel 31, used for DLP
    var Lug;
    var LugArea;
    var LugIce;
    var LugLength;
    var LugWeight;
    var Lfinned;
    var Lunfinned;
    var Misc;
    var n;
    var NLayers;
    var numberFeedLines;
    var numberOfSections, NumberSections;
    var optBrdBnd;
    var optRadome;
    var optSlotCover;
    var pattern;
    var patternShaperWeight;
    var Pi = Math.PI;
    var PipeSize;
    var PipeWall;
    var PN48959;
    var PN62538;
    var PN75483;
    var PN75539;
    var PN75553;
    var PN75554;
    var PN75730;
    var PN76922;
    var PN79819;
    var PoleWeight;
    var PowerDividerArea;
    var PowerDividerWeight;
    var PowerDividerIceWeight;
    var Q;
    var Radome;
    var RadDia; // Radome Diameter
    var RadomeIce;
    var RadomeNote;
    var RadomeWeight;
    var RDir1;
    var RDir2;
    var Reducer;
    var ReducerArea;
    var ReducerIce;
    var RMax;
    var RoundFinnedArea;
    var RowLoc;
    var S;
    var SingleSectionH2;
    var SlotCoverIce;
    var SlotCovers;
    var SlotCoverWeight;
    var Tee;
    var TeeIce;
    var TeeWeight;
    var TL;
    var TLIce;
    //var PowerDividerArea;
    //var PowerDividerWeight;
    var TLWeight;
    var TotalWeight;
    var Weight;
    var WeldonLug;
    var Wice;
    var windSpec;
    var Y, y;
    
    var Round = function(number, precision) {
      var helper =  Math.pow(10, precision);
      if (typeof precision != 'number' || precision == null) {precision = 0;};
      return  Math.round(number * helper) / helper;
    }

    self.values ={};
    self.helpers = {
      lambda: function(frequency) {
        count.lambda++;
        if (defined(frequency)){
            return 11802.854 / frequency;
        }
      },
        matta: function(channel) {
            count.matta++;
            if (channel >= 14 && channel <= 24) {
             return 4.125;
            } else if (channel >= 25 && channel <= 38) {
             return 3.625;
            } else if (channel >= 39 && channel <= 53) {
             return 3.1875;
            } else if (channel >= 54 && channel <= 69) {
             return 2.75;
            };
        },
        // function to handle calculations for RadDia (radome diamter)
        calcRadDia: function(patternLetter, optStdRad, optOptIce, optUserInp, OptBestFit) {
          count.calcRadDia++;
          // if optUserInp == true then leave RadDia alone
          var Matta = self.helpers.matta;
          var BestFit = self.helpers.BestFit;
          //var Cos =  Math.cos;
          //var Sin =  Math.sin;
          lambda = lambda;
          Pi = Math.PI;
          var autoCalc = ((!OptBestFit || OptBestFit == false) && (!optUserInp || optUserInp == false));
          patternLetter = patternLetter.toUpperCase();
          
          if (optUserInp && optUserInp == true) { // allow user input under certain conditions
          }
          else if ($.inArray(patternLetter, ['D', 'G', 'H', 'I', 'J', 'O']) != -1) {
            var x1, x2, x3, y1, y2, y3;
          }
          if (optUserInp !== true) {
            switch (patternLetter) {
              case "A":
                Dpipe = 5;
                if (channel <= 20) {
                  Dpipe = 6;
                }
                RadDia = optStdRad == true ? 2 * 0.75 + Dpipe + 0.25 : 2 * Matta(channel) + Dpipe + 0.25;
                break;
              case "B":
                RadDia =  Matta(channel) + 3.5 + 0.25; // same for both cases for B
                break;
              case "C":
                RadDia = 2 * Matta(channel) + 3.5 + 0.25; // same for both cases
                break;
              case "D":
                if (autoCalc == true || Truth(OptBestFit)) {
                  x1 = 0;
                  y1 = 3.5 / 2 + Matta(channel);
                  x2 = (3.5 / 2 + lambda / 4) * Math.cos(10 * Pi / 180);
                  y2 = (3.5 / 2 + lambda / 4) * Math.sin(10 * Pi / 180);
                  x3 = -(3.5 / 2 + lambda / 4) * Math.cos(10 * Pi / 180);
                  y3 = (3.5 / 2 + lambda / 4) * Math.sin(10 * Pi / 180);
                  RadDia = Round(2 * BestFit(x1, y1, x2, y2, x3, y3) + 0.25, 3);
                }
                break;
              case "E":
              case "F":
                if (autoCalc == true || OptBestFit == true) {
                  RadDia = Round(2 * (3.5 / 2 + 0.4 * lambda) * Math.cos(10 * Pi / 180) + 0.25, 3);
                }
                break;
              case "G":
                if (autoCalc == true || OptBestFit == true) {
                  x1 = 0;
                  y1 = 3.5 / 2 + Matta(channel);
                  x2 = lambda / 4;
                  y2 = -(3.5 / 2 + 1);
                  x3 = -lambda / 4;
                  y3 = -(3.5 / 2 + 1);
                }
                RadDia = Round(2 * BestFit(x1, y1, x2, y2, x3, y3) + 0.25, 3);
                break;
              case "H":
                 if (autoCalc == true || OptBestFit == true) {
                    x1 = 0;
                    y1 = 3.5 / 2 + Matta(channel)
                    x2 = (3.5 / 2 + 0.4 * lambda) * Math.sin(20 * Pi / 180);
                    y2 = -(3.5 / 2 + 0.4 * lambda) * Math.cos(20 * Pi / 180);
                    x3 = -(3.5 / 2 + 0.4 * lambda) * Math.sin(20 * Pi / 180);
                    y3 = -(3.5 / 2 + 0.4 * lambda) * Math.cos(20 * Pi / 180);
                    RadDia = Round(2 * BestFit(x1, y1, x2, y2, x3, y3) + 0.25, 3);
                 }
                 break;
               case "I":
               case "J":
                if (autoCalc == true || OptBestFit == true) {
                  var IJconst = patternLetter == 'I' ? 0.4 : 0.37;
                  x1 = 0;
                  y1 = 3.5 / 2 + Matta(channel);
                  x2 = (3.5 / 2 + IJconst * lambda) * Math.sin(40 * Pi / 180);
                  y2 = -(3.5 / 2 + IJconst * lambda) * Math.cos(40 * Pi / 180);
                  x3 = -(3.5 / 2 + IJconst * lambda) * Math.sin(40 * Pi / 180);
                  y3 = -(3.5 / 2 + IJconst * lambda) * Math.cos(40 * Pi / 180);
                  RadDia = Round(2 * BestFit(x1, y1, x2, y2, x3, y3) + 0.25, 3);
                }
                break;
              case "M":
                if (autoCalc == true || OptBestFit == true) {
                  RadDia = Round(2 * (3.5 / 2 + 0.4 * lambda) * Math.cos(15 * Pi / 180) + 0.25, 3);
                }
                break;
              case "N":
                if (autoCalc == true || OptBestFit == true) {
                  RadDia = Round(2 * Matta(channel) + 3.5 + 0.25, 3);
                }
                break;
              case "O":
                if (autoCalc == true || OptBestFit == true) {
                  x1 = 0;
                          y1 = 3.5 / 2 + Matta(channel);
                          x2 = 0.3 * lambda * Math.sin(35 * Pi / 180);
                          y2 = -3.5 / 2 - 1 - 0.3 * lambda * Math.cos(35 * Pi / 180);
                          x3 = -0.3 * lambda * Math.sin(35 * Pi / 180);
                          y3 = -3.5 / 2 - 1 - 0.3 * lambda * Math.cos(35 * Pi / 180);
                          RadDia = Round(2 * BestFit(x1, y1, x2, y2, x3, y3) + 0.25, 3);
                }
                break;        
            }
          }
        },
        band: function(channel) {
          count.band++;
            if (channel >= 2 && channel <= 3) {
              return 'L';
            } else if (channel >= 4 && channel <= 6) {
              return 'M';
            } else if (channel >= 7 && channel <= 13) {
              return 'H';
            } else {
              return undefined;
            };
          },
        Kiz: function(TowerElevation) { 
          count.Kiz++;
          return  Math.min( Math.pow(TowerElevation/33, 0.1), 1.4);
        },
        Kztg: function(TowerElevation, TowerHeight, ExposeCat, TopoCat) {
            count.Kztg++;
            var exposeData = self.helpers.parseExposeCat(ExposeCat);
            var Kt = 0; 
            var f = 0;
            var Kh = 0;
            var Kztg = 0;
            // Select appropriate constants from Table 2.5
            switch(TopoCat) {
                case 1:
                    Kztg = 1;
                    break;
                case 2: // 2-D Ridge
                    Kt = 0.43;
                    f = 1.25;
                    
                    break;
                case 3: // 2-D Escarpment
                    Kt = 0.53;
                    f = 2;
                    break;
                case 4: // non huricane shoreline
                    Kt = 0.72;
                    f = 1.5;
                    break;
            };

            Kh =  Math.exp(f * TowerElevation / TowerHeight);

            if (Kh) {
              Kztg =  Math.pow((1 + (exposeData.Ke * Kt) / Kh), 2);
            }
            return Kztg;
        },
        Importance: function(StructCat) {
          count.Importance++;
            if (StructCat == 'I') return 0.87; //supposed to be (1 / 1.15)
            else if (StructCat == 'II') return 1;
            else if (StructCat == 'III') return 1.15;
        },
        parseExposeCat: function(ExposeCat) {
            var result = {
                zg: 0,
                alpha: 0, 
                Kzmin: 0,
                Ke:  0
            }
            var ExpCat = ExposeCat.toUpperCase();
            switch (ExpCat) {
                case 'B':
                    result.zg = 1200;
                    result.alpha = 7;
                    result.Kzmin = 0.7;
                    result.Ke = 0.9;
                    break;
                case 'C':
                    result.zg = 900;
                    result.alpha = 9.5;
                    result.Kzmin = 0.85;
                    result.Ke = 1;
                    break;
                case 'D':
                    result.zg = 700;
                    result.alpha = 11.5;
                    result.Kzmin = 1.03;
                    result.Ke = 1.1;
                    break;
            }
            return result;
        },
        Kzg: function(TowerHeight, ExposeCat) {
            count.Kzg++;
            var exposeData = self.helpers.parseExposeCat(ExposeCat);

            var value = 2.01 *  Math.pow(TowerHeight / exposeData.zg, 2 / exposeData.alpha);
            var Kzg = value < exposeData.Kzmin ? exposeData.Kzmin :  Math.min(value, 2.01);
            return Kzg;
        },
        AppurtDrag: function(windSpec, radomeDiameter, antennaLength, elevation, windSpeed, towerHeight, exposureCat, structureClass, topographicCat, ice) {
          count.AppurtDrag++;
              //Calculates the Drag Cofficient Ca for round members EIA 222C, F, or G
              //Calculate Aspect Ratio
              var aspectRatio = antennaLength * 12 / radomeDiameter;
              var drag;
              windSpec = windSpec.toUpperCase();
              //C Spec
              if (windSpec == 'C') {
                  drag = 2 / 3;
              }
              //F Spec
              else if (windSpec == 'F') {
                  if (aspectRatio <= 7) {
                      drag = 0.8;
                  }
                  else if (aspectRatio > 7 && aspectRatio < 25) {
                      drag = 0.8 + (1.2 - 0.8) * (aspectRatio - 7) / (25 - 7);
                  }
                  else {
                      drag = 1.2;
                  }
              }
              //G Spec
              else if (windSpec == 'G') {
                  //Calculate C
                  if (!ice) {
                      coef =  Math.pow(self.helpers.Importance(structureClass) * self.helpers.Kztg(elevation, towerHeight, exposureCat, topographicCat) * self.helpers.Kzg(elevation, exposureCat), 0.5) * windSpeed * radomeDiameter / 12;
                  }
                  else {
                      coef = 2;
                  }
                  var Dr7, Dr25;
                  if (aspectRatio <= 7) {
                      if (coef <= 32) {
                          drag = 0.8;
                      }
                      else if (coef > 32 && coef < 64) {
                          drag = 1 - 0.0063 * coef;
                      } else {                       
                          drag = 0.6;
                      }
                  }
                  else if (aspectRatio > 7 && aspectRatio < 25) {
                  //Calculate the drag at 7 < AR < 25
                  
                      if (coef <= 32) {                      
                          Dr7 = 0.8;
                          Dr25 = 1.2;
                      }
                      else if (coef > 32 && coef < 64) {
                          Dr7 = 1 - 0.0063 * coef;
                          Dr25 = 1.8 - 0.019 * coef;
                      } else {
                          Dr7 = 0.6;
                          Dr25 = 0.6;
                      }                      
                      drag = Dr7 + (Dr25 - Dr7) * (aspectRatio - 7) / (25 - 7);
                  } else { // if AR >= 25
                      if (coef <= 32) {
                          drag = 1.2;
                      }
                      else if (coef > 32 && coef < 64) {
                          drag = 38.4 / coef;
                      } else {
                          drag = 0.6;
                      }
                }
            } // end case WindSpec = 'G'
            return drag;
        }, // end AppurtDrag
        Qzf: function(z, h, V) {
          count.Qzf++;
            //Calculates the velocity pressure coefficient for EIA 222F
            
            var Kz = self.helpers.Kzf(z);
            var Gh = self.helpers.Ghf(h);
            return 0.00256 * Kz /** Gh*/ *  Math.pow(V, 2);
        },
        Qzg: function(z, V, h, ExpCat, StructCat, TopoCat) {
          count.Qzg++;
            //Velocity Pressure coefficient section 2.6.9.10
            //Directionality Factor
            var Kd = self.helpers.Kdg(z, V);
            //Velocity Pressure Coefficient
            var Kz = self.helpers.Kzg(z, ExpCat);
            //Topographic Factor
            var Kzt = self.helpers.Kztg(z, h, ExpCat, TopoCat);
            //Importance Factor
            var i = self.helpers.Importance(StructCat);
            //Velocity Pressure
            return 0.00256 * Kz * Kzt * Kd * i *  Math.pow(V, 2);
        },
        Kzf: function(h) {
          count.Kzf++;
            //Calculates the exposure coefficient for EIA 222F
            var value =  Math.pow(h / 33, 2 / 7);
            // keep value between 1 and 2.58
            value =  Math.min(value, 2.58);
            value =  Math.max(value, 1);
            return value;
        },
        Ghf: function(h) {
          count.Ghf++;
            //Calculates the gust response factor for EIA 222F
            var value = 0.65 + 0.6 /  Math.pow(h / 33, 1 / 7);
            // keep value between 1 and 1.25
            value =  Math.min(value, 1.25);
            value =  Math.max(value, 1);
            return value;
        },
        Kdg: function(h, V) {
          count.Kdg++;
            //Calculates the directionality factor for EIA 222G
            //The latest copy I have as of 2-20-01 says from Table 2-2 says
            //for round pipes and chimneys the factor is .95.

            return 0.95;
        },
        Qzc: function(Zone, TowerHeight) {
          count.Qzc++;
          //Calculates the velocity pressure coefficient for EIA 222C
          Zone = Zone.toUpperCase();
          if (Zone == "A") {
              if (h < 300) 
                  Qzc = 30;
              else if (h >= 300 && h < 650) 
                  Qzc = 35;
              else if (h >= 650) 
                  Qzc = 50;              
          }
          if (Zone == "B") {
              if (h < 300)
                  Qzc = 40;
              else if (h >= 300 && h < 650)
                  Qzc = 48;
              else if (h >= 650)
                  Qzc = 65;              
          }
          if (Zone == "C") {
              if (h < 300)
                  Qzc = 50;
              else if (h >= 300 && h < 650)
                  Qzc = 60;
              else if (h >= 650)
                  Qzc = 85;
          }              
        },
        ImpWindIce: function(StructClass) {
          count.ImpWindIce++;
          // Evaluates the importance factor for EIA 222G
          // I = Non-Hazardous, II = Catch All, III = Life Hazardous
          return StructClass == 'I' ? 0 : 1;
        },
        ImpIceThk: function(StructClass) {
          count.ImpIceThk++;
          // Evaluates the importance factor for EIA 222G
          if (StructClass == 'I') return 0;
          else if (StructClass == 'II') return 1;
          else if (StructClass == 'III') return 1.25;
        },
        Gust: function(WindSpec, StndrdMt, TowerHeight) {
          count.Gust++;
          var gust = 1;
          var Ghf = self.helpers.Ghf;
          WindSpec = WindSpec.toUpperCase();
          switch (WindSpec) {
            case 'C': // gust = 1;
              break;
            case 'F':
              gust = StndrdMt == true ? Ghf(TowerHeight) : 1.25 * Ghf(TowerHeight);
              break;
            case 'G': // Gust Effect Factor 2.6.7.4 says the gust effect factor is 1.35 for pole structures mounted on other structures
              gust = StndrdMt == true ? 1 : 1.35;
              break;
          }
          return gust;
        },
        FlatAppurtDrag: function(windSpec, w, L, z_elevation, V_velocity, ExposeCat, StructClass, TopoCat) {
          count.FlatAppurtDrag++;
          // Calculates the Drag Cofficient Ca for round members EIA 222C, F, or G
          var fad = 1; // Flat Appurt Drag
          var aspectRatio = L * 12 / w;
          windSpec = windSpec.toUpperCase();
          switch (windSpec) {
            case 'C': //FlatAppurtDrag = 1;
              break;
            case 'F':
            case 'G': // apparently 'G' is the same as 'F' here
              if (aspectRatio <= 7) fad = 1.4;
              else if (aspectRatio > 7 && aspectRatio <= 25) 
                fad = 1.4 + (2 - 1.4) * (aspectRatio - 7) / (25 - 7);
              else fad = 2;
              break;
          }
          return fad;
        },
        tiz: function(t_ice, TowerElevation, TowerHeight, ExposeCat, TopoCat, StructClass) {
          count.tiz++;
          tiz = 2 * t_ice * self.helpers.Importance(StructClass) * self.helpers.Kiz(TowerElevation) *  Math.pow(self.helpers.Kztg(TowerElevation, TowerHeight, ExposeCat, TopoCat), 0.35);
          return tiz;
        },
        BestFit: function(x1, y1, x2, y2, x3, y3) {
          count.BestFit++;
          var Dh, Dh1, Dh2, Dk, Dk1, BestFit;
          Dh1 = (sq(x1) + sq(y1)) * (y2 - y3) - (sq(x2) + sq(y2)) * (y1 - y3) + (sq(x3) + sq(y3)) * (y1 - y2);
          Dh2 = x1 * (y2 - y3) - x2 * (y1 - y3) + x3 * (y1 - y2);
          Dh = Dh1 / (2 * Dh2);

          Dk1 = x1 * ((sq(x2) + sq(y2)) - (sq(x3) + sq(y3))) - x2 * ((sq(x1) + sq(y1)) - (sq(x3) + sq(y3))) + x3 * ((sq(x1) + sq(y1)) - (sq(x2) + sq(y2)));
          Dk = Dk1 / (2 * Dh2);
          BestFit =  Math.sqrt(sq(x1 - Dh) + sq(y1 - Dk));
          return BestFit;
        },
        Vib: function(L, inertia, area) {
          count.Vib++;
          var E = 30000000,
          density = 0.283,
          m = density * area / 386.4;
          var Vib = 3.52 * Sqr((E * inertia) / (m *  Math.pow(L * 12, 4))) / (2 * Math.PI);
        },
        DisplayCount: function() {  //display and reset count
          console.log(count);
          count = {};
        }
    };
    self.byDesign = function(design) {
      //console.log(design.substr(0,5))
      if (design.substr(0, 2) == 'TF' 
      && (design.length == 3 || (design.substr(2,2) * 1) >= 0)) { //TF[a-z] and TF[0-21] or so should all have design 'TF', but not TFUT, for example
        design = 'TF';
      }
      else if (design.substr(0,4) == 'TFU-') { // to capture TFU-UT aka TFUT
        design = 'TFUT'};
        
      if (typeof self.calc[design] !== 'undefined')
          return self.calc[design];

      return {
          get: function() {
              return self.dataDefaults;
            }
        };
    };

    self.dataDefaults = {
        weight: null,
        areaFlats: null,
        forceCoef: null,
        momentArm: null,
    };

    self._data = angular.extend({}, self.dataDefaults);

  self.calc = {
    TFUT: { // for future use
      // get: function(channel, azimuthpattern, elevationpattern) {
         
      // }
      // self._data = {
      //   totalWeight: FinalWeight,
      //   weight: (Ice_bool == true) ? TotalWeight : Weight,
      //   iceWeight: (Ice_bool == true) ? IceWeight : 0,
      //   areaFlats: (typeof ArevCMax != 'undefined') ? ArevCMax : null,
      //   forceCoef: Amax,
      //   momentArm: null
      // };
      get: function (channel, azimuthpattern, elevationpattern) {
        var band = self.helpers.band(channel);
        var typeNumBays = parseInt(elevationpattern.substr(0, 2));
        var moduleIndex = typeNumBays - 1;

        var result = {
          weight: 55,
          iceWeight: 0,
          areaFlats: null, // not calculated for this type either
          forceCoef: 3.2,
          momentArm: null, // calculated, but not currently exposed in UI
          noIceSupport: true
        };
        return result;
      }
    },
    DLP: {
      get: function(_channel, azimuthpattern, elevationpattern, params) {
            if (defined(params))
              return this.calculate.apply(this, params);
      },
      calculate: function(WindSpec, Ice_bool, t_ice, V_basic, V_basic_ice, NumberOfSections, TowerElevation, TowerHeight, TowerClearance, ExposeCat, TopoCat, StructClass, azimuthPattern, elevationPattern, Channel, Frequency, hasRadome, useVpolCheck) {            
          optRadome = hasRadome;
          chkVPol = useVpolCheck;
          _this = this; // set reference for use in patternFn's

          self.values = {
              WSpec: WindSpec,
              chkIce: Ice_bool,
              Ice: t_ice,
              V: V_basic,
              V_ice: V_basic_ice,
              numSections: NumberOfSections,
              z: TowerElevation,
              h: TowerHeight,
              baseClearance: TowerClearance, 
              ExpCat: ExposeCat,
              Topo: TopoCat,
              StrCl: StructClass,
              azimuthPattern: azimuthPattern,
              elevationPattern: elevationPattern,
              Channel: Channel
            };
          frequency = Frequency;
          channel = Channel;
          windSpec = WindSpec;  // WSpec in pattern functions
          Ice = t_ice;
          // pipe diameter
          //Dpipe = 5;
          //twall = 0.25;
          //if(Channel <= 20){
          //  Dpipe = 6;
          //};
          //if(Channel <= 20){
          //  Dpipe = 6;
          //}else{
          //  Dpipe = 5;
          //}

          Dpipe = 3.25;
          if (Channel <= 18) {
            Dpipe = 4;
          };

          gain = parseInt(elevationPattern.substr(0, 2)); // GainNumber
            //Determine number of slots per section;                
          if (gain % 8 != 0) {
              n = gain; //12 slots per section, NLayers in pattern functions
              numberOfSections = 1; //NumberSections in pattern functions
          } else {
              n = 8; //8 slots per section
              numberOfSections = gain / 8;
          };
                         
          // lambda = 11802.9 / (channel * 6 + 389); // the second one is frequency
          lambda = self.helpers.lambda(frequency);                
          L = (lambda * (n - 0.25) + 32);
          Finlength = 0.4 * lambda;

          lambda31 = self.helpers.lambda(31 * 6 + 389); // for channel 31
          L31 = (lambda31 * (n - 0.25) + 32);
          
          SingleSectionH2 = L / 12;

          if (azimuthPattern) pattern = azimuthPattern.substr(azimuthPattern.lastIndexOf('-') + 1).toUpperCase(); 
         
          S = 0.7 * lambda - 2;

          //antennaH2 = (lambda * (n * numberOfSections + 1.5 * numberOfSections - 0.5) + 2.25) / 12;
          antennaH2 = numberOfSections * SingleSectionH2 + (numberOfSections - 1) / 6;
          this.setupPatterns();
                          
          var patternFn = this['pattern' + pattern];
          
          // self.helpers.calcRadDia(pattern/*, optStdRad, optOptIce, optUserInp, OptBestFit*/);
          if (defined(patternFn)) {
              patternFn();
              if (!FinalWeight || typeof FinalWeight == 'undefined') FinalWeight = TotalWeight; 
              IceWeight = (typeof Wice != 'undefined') ? Wice : null;
              self._data = {
                  totalWeight: FinalWeight,
                  weight: (Ice_bool == true) ? TotalWeight : Weight,
                  iceWeight:(Ice_bool == true) ? IceWeight : 0,
                  areaFlats: (typeof ArevCMax != 'undefined') ? ArevCMax : null,
                  forceCoef: Amax,
                  momentArm: null
              };
            self._data.RadomeNote = RadomeNote;
            self._data.AntennaDesignation = AntennaDesignation;
            return self._data;
          }          
          return angular.extend({}, self.dataDefaults);

      },
      calcReactions: function() {
        RDir1 = Round(G * ADLPC1 * Q, 0);
        RDir2 = Round(G * ADLPC2 * Q, 0);
        RMax = Round(G * Amax * Q, 0);
      },
      calcWeight: function() {

        var Lambda31 = 11802.9 / (31 * 6 + 389); //wavelength at channel 31
        L31 = (Lambda31 * (NLayers - 0.25) + 32);
        Weight = (30 + (NLayers - 4) * 7.5 + (L - L31) * 0.23) * 1.24 + Finlength * 0.8 * Lambda * 0.125 * 2 * NLayers * 0.1;
        return Weight = Round(Weight, 0);



        //var helper = Finlength * 0.8 * lambda * 0.1875 * 0.1 * 0.25 * 16; //add helper to result regardless //old: + Finlength * 0.8 * lambda * 0.125 * 2 * NLayers * 0.1;
        //var result = (30 + (NLayers - 4) * 7.5 + (L - L31) * 0.23);
        //result *= (channel < 31) ? 1.24 : 1;
        //result = Round(result + helper, 3);
        //return result;
      },
      calcDLPCIce: function() {
        result = Pi / 4 * ( Math.pow(Dpipe + 2 * Ice, 2) -  Math.pow(Dpipe, 2)) * (L + 2 * Ice) * 0.032 + (Finlength + Ice) * (0.8 * lambda + 1 + 2 * Ice) * Ice * 2 * 2 * NLayers * 0.032;
        return result;
      },
      setupPatterns: function() {
          Pi = Math.PI;

          // 'read in antenna information
          Channel = channel; // CInt(Channel)
          NumberSections = numberOfSections; // cmbNSections.Value
          NLayers = n; // cmbNLayers.Value
          GainNumber = gain; // cmbNLayers.Value * cmbNSections.Value
          Pattern = pattern; // cmbPattern.Value - currently it is whichever pattern the function is... patternA pattern = 'A'
          h = self.values.h;
          z = self.values.z;
          V = self.values.V;
          y = h;
          Y = y;
          Ice = 0;
          Qzg = self.helpers.Qzg;
          Qzf = self.helpers.Qzf;
          Qzc = self.helpers.Qzc;
          Matta = self.helpers.matta;
          BestFit = self.helpers.BestFit;
          calcRadDia = self.helpers.calcRadDia;
          AppurtDrag = self.helpers.AppurtDrag;
          FlatAppurtDrag = self.helpers.FlatAppurtDrag;
          chkIce = self.values.chkIce;
          Lfinned = 0;
          Lunfinned = 0;
          Lambda = lambda;

          // 'get the wind spec related data
          WSpec = windSpec; //cmbWindSpec.Value
          G = self.helpers.Gust(WSpec, true, h);
          if (WSpec == "C") { // for WindSpec 'C'.  Delete if we decide not to use
              // z = txtElevation.Value
              ZoneC = ["A","B","C"][ Math.round(2 *  Math.random())]; // cmbZoneC.Value // this is a user input from a dropdown, options A, B, C - until user input available choose at random.
              Q = self.helpers.Qzc(ZoneC, z);
              G = 1;
              // Ice = 0;
              if (chkIce == true) {
                  Ice = self.values.Ice// txtTiC.Value //typical Ice input
                  Q = 7; // txtQiC.Value // this is a user input (wind pressure with ice) 7 is dummy value
              }
            }
          if (WSpec == "F") {
              Q = Qzf(z, h, V);      
              if (chkIce == true) {
                  Ice = self.values.Ice;
                  Q = 0.75 * Q;
              }
          }
          else if (WSpec == "G") {
              StrCl = self.values.StrCl; // cmbStrCl.Value
              ExpCat = self.values.ExpCat; // cmbExpCat.Value
              Topo = self.values.Topo; // cmbTopo.Value
              Q = Qzg(z, V, y, ExpCat, StrCl, Topo);
              IncludeIce = false;
              if (chkIce == true) {
                  IncludeIce = true;
                  V = self.values.V_ice; // txtViG.Value
                  DesignIce = self.values.Ice;
                  Ice = self.helpers.tiz(DesignIce, z, Y, ExpCat, Topo, StrCl);  // done elsewhere
                  Q = Qzg(z, V, y, ExpCat, StrCl, Topo);
              }
          }

          calcRadDia(pattern);
          // No option for radome with this type of antenna apparently (should verify with Dielectric)
          //RadDia = 0;
          // if (!optRadome) optRadome = false;
          // optSlotCover = !optRadome;
          // if (optRadome == true) {
          // } else if (optRadome != true) {
              AntennaDesignation = "DLP-" + String(gain) + pattern + "(" + String(Channel) + ")";
              RadomeNote = "Slot Covered";
          //    // AntType = AntennaDesignation
          // }
        },
        patternB: function() {
          //Dpipe = 3.25;
          //if (channel <= 18) {
          //  Dpipe = 4;
          //}
          Lfinned = NLayers * (0.8 * Lambda + 1);
          Lunfinned = L - Lfinned;
          self.values.h = (L / 12);


          //Calculate Areas
          //SlotCovers = Lunfinned * (Dpipe + 2 * Ice) * AppurtDrag(WSpec, Dpipe + 2 * Ice, Lunfinned / 12, z, V, y, ExpCat, StrCl, Topo, IncludeIce)
          //SmallFin = (Lfinned + 2 * Ice) * (Dpipe + 1.5 + 2 * Ice) * FlatAppurtDrag(WSpec, Dpipe + 1.5 + 2 * Ice, (Lfinned + 2 * Ice) / 12, z, V, ExpCat, StrCl, Topo)
          //ADLPB1 = (SlotCovers + SmallFin) / 144
          //txtAdir1.Value = Round(ADLPB1, 2)

          ////Direction 2 (T direction)
          //SlotCovers = (L + 2 * Ice) * (Dpipe + 2 * Ice) * AppurtDrag(WSpec, (txtRadDia.Value + 2 * Ice), L / 12, z, V, y, ExpCat, StrCl, Topo, IncludeIce)
          //ADLPB2 = (SlotCovers) / 144
          //txtAdir2.Value = Round(ADLPB2, 2)



          //Calculate Areas
          //SlotCovers = (L + 2 * Ice) * (4 + 2 * Ice) * AppurtDrag(WSpec, (RadDia + 2 * Ice), L / 12, z, V, y, ExpCat, StrCl, Topo, IncludeIce);
          //SmallFin = (L + 2 * Ice) * (0.75 + Ice) * FlatAppurtDrag(WSpec, 0.75 + Ice, (L + 2 * Ice) / 12, z, V, ExpCat, StrCl, Topo);
          SlotCovers = Lunfinned * (Dpipe + 2 * Ice) * AppurtDrag(WSpec, Dpipe + 2 * Ice, Lunfinned / 12, z, V, y, ExpCat, StrCl, Topo, IncludeIce);
          SmallFin = (Lfinned + 2 * Ice) * (Dpipe + 1.5 + 2 * Ice) * FlatAppurtDrag(WSpec, Dpipe + 1.5 + 2 * Ice, (Lfinned + 2 * Ice) / 12, z, V, ExpCat, StrCl, Topo);
          ADLPB1 = (SlotCovers + SmallFin) / 144;
          Adir1 = ADLPB1;

          //Direction 2 (T direction);
          //SlotCovers = (L + 2 * Ice) * (Dpipe + 2 * Ice) * AppurtDrag(WSpec, (RadDia + 2 * Ice), L / 12, z, V, y, ExpCat, StrCl, Topo, IncludeIce);
          SlotCovers = (L + 2 * Ice) * (Dpipe + 2 * Ice) * AppurtDrag(WSpec, (RadDia + 2 * Ice), L / 12, z, V, y, ExpCat, StrCl, Topo, IncludeIce);
          ADLPB2 = (SlotCovers) / 144;
          Adir2 = ADLPB2;

          Amax =  Math.max(Adir1, Adir2);
          Amax = Round(Amax, 2);

          //Calculate Reactions
          RDir1 = Round(G * ADLPB1 * Q, 0);
          RDir2 = Round(G * ADLPB2 * Q, 0);
          RMax = Round(G * Amax * Q, 0);

          //Weight
          Weight = (30 + (NLayers - 4) * 7.5 + (L - L31) * 0.23);
          Weight *= (Channel < 31) ? 1.24 : 1; //per Jim Butts' email 3/3
          Weight = Weight;

          //Ice Weight
          DLPBIce = Pi / 4 * ( Math.pow(Dpipe + 2 * Ice, 2) -  Math.pow(Dpipe, 2)) * (L + 2 * Ice) * 0.032;
          Wice = DLPBIce;

          //Total Weight
          TotalWeight = Weight + DLPBIce;
          TotalWeight = Round(TotalWeight, 3);

        },
        patternD: function() {

          

          //Dpipe = 3.25;
          //if (channel <= 18) {
          //  Dpipe = 4;
          //}
          //Calculate Fin Length
          if (Channel >= 14 && Channel < 30) {
              Finlength = 4.75 + (3.8 - 4.75) * (Channel - 14) / (30 - 14);
          } else if (Channel >= 30) {
              Finlength = 3.8 + (3.5 - 3.8) * (Channel - 30) / (55 - 30);
          };

          //Calculate Areas
          //SlotCovers = ((L + 2 * Ice) - NLayers * (0.8 * Lambda + 1 + 2 * Ice)) * (Dpipe + 2 * Ice) * FlatAppurtDrag(WSpec, Dpipe + 2 * Ice, ((L + 2 * Ice) - NLayers * (0.8 * Lambda + 1 + 2 * Ice)) / 12, z, V, ExpCat, StrCl, Topo)
          //FinnedArea = (2 * (Finlength + Ice + Dpipe / 2) * Cos(10 * Pi / 180)) * NLayers * (0.8 * Lambda + 1 + 2 * Ice) * FlatAppurtDrag(WSpec, 2 * (Finlength + Ice + Dpipe / 2) * Cos(10 * Pi / 180), cmbNLayers.Value * (0.8 * Lambda + 1 + 2 * Ice) / 12, z, V, ExpCat, StrCl, Topo)
          //Direction 1 (N direction);
          SlotCovers = ((L + 2 * Ice) - NLayers * (0.8 * lambda + 1 + 2 * Ice)) * (Dpipe + 2 * Ice) * FlatAppurtDrag(WSpec, Dpipe + 2 * Ice, ((L + 2 * Ice) - NLayers * (0.8 * lambda + 1 + 2 * Ice)) / 12, z, V, ExpCat, StrCl, Topo);
          FinnedArea = (2 * (Finlength + Ice + Dpipe / 2) *  Math.cos(10 * Pi / 180)) * NLayers * (0.8 * lambda + 1 + 2 * Ice) * FlatAppurtDrag(WSpec, 2 * (Finlength + Ice + Dpipe / 2) *  Math.cos(10 * Pi / 180), NLayers * (0.8 * lambda + 1 + 2 * Ice) / 12, z, V, ExpCat, StrCl, Topo);
          ADLPC1 = (SlotCovers + FinnedArea) / 144;
          Adir1 = Round(ADLPC1, 2);

          //Direction 2 (T direction);
          SlotCovers = ((L + 2 * Ice) - NLayers * (0.8 * lambda + 1 + 2 * Ice)) * (Dpipe + 2 * Ice) * AppurtDrag(WSpec, (RadDia + 2 * Ice), L / 12, z, V, y, ExpCat, StrCl, Topo, IncludeIce);
          FinnedArea = ((Finlength + Ice + Dpipe / 2) *  Math.sin(10 * Pi / 180) + Dpipe / 2 + Ice) * NLayers * (0.8 * lambda + 1 + 2 * Ice) * FlatAppurtDrag(WSpec, (Finlength + Ice + Dpipe / 2) *  Math.sin(10 * Pi / 180) + Dpipe / 2 + Ice, NLayers * (0.8 * lambda + 1 + 2 * Ice) / 12, z, V, ExpCat, StrCl, Topo);
          ADLPC2 = (SlotCovers + FinnedArea) / 144;
          Adir2 = Round(ADLPC2, 2);

          Amax =  Math.max(Adir1, Adir2);

          //Calculate Reactions
          // RDir1 = Round(G * ADLPC1 * Q, 0);
          // RDir2 = Round(G * ADLPC2 * Q, 0);
          // RMax = Round(G * Amax * Q, 0);
          _this.calcReactions();

          //Weight
          //var Lambda31 = 11802.9 / (31 * 6 + 389); //wavelength at channel 31
          //L31 = (Lambda31 * (NLayers - 0.25) + 32);
          //Weight = (30 + (NLayers - 4) * 7.5 + (L - L31) * 0.23) * 1.24 + Finlength * 0.8 * Lambda * 0.125 * 2 * NLayers * 0.1;
          //Weight = Round(Weight, 0);


          Weight = _this.calcWeight();

          //Ice Weight
          DLPCIce = _this.calcDLPCIce();
          Wice = Round(DLPCIce, 3);

          //Total Weight
          TotalWeight = Weight + DLPCIce;
          TotalWeight = Round(TotalWeight, 3);

        },
        patternH: function() {

          //Dpipe = 3.25;
          //if (channel <= 18) {
          //  Dpipe = 4;
          //}
          //Calculate Fin Length

          //calcRadDia(pattern);

          //var Lambda = 11802.854 / (Channel * 6 + 389);
          //var x1 = 0;
          //var y1 = 3.5 / 2 + Matta(Channel);
          //var x2 = (3.5 / 2 + 0.4 * Lambda) * Math.sin(20 * Pi / 180);
          //var y2 = -(3.5 / 2 + 0.4 * Lambda) * Math.cos(20 * Pi / 180);
          //var x3 = -(3.5 / 2 + 0.4 * Lambda) * Math.sin(20 * Pi / 180);
          //var y3 = -(3.5 / 2 + 0.4 * Lambda) * Math.cos(20 * Pi / 180);
          //RadDia = Round((2 * BestFit(x1, y1, x2, y2, x3, y3) + 0.25), 3);


          Finlength = 0.4 * lambda;

          //Calculate Areas
          //Direction 1 (N direction);
          SlotCovers = ((L + 2 * Ice) - NLayers * (0.8 * lambda + 1 + 2 * Ice)) * (Dpipe + 2 * Ice) * FlatAppurtDrag(WSpec, Dpipe + 2 * Ice, ((L + 2 * Ice) - NLayers * (0.8 * lambda + 1 + 2 * Ice)) / 12, z, V, ExpCat, StrCl, Topo);
          FinnedArea = (2 * (Finlength + Ice + Dpipe / 2) *  Math.sin(20 * Pi / 180)) * NLayers * (0.8 * lambda + 1 + 2 * Ice) * FlatAppurtDrag(WSpec, 2 * (Finlength + Ice + Dpipe / 2) *  Math.sin(20 * Pi / 180), NLayers * (0.8 * lambda + 1 + 2 * Ice) / 12, z, V, ExpCat, StrCl, Topo);
          ADLPC1 = (SlotCovers + FinnedArea) / 144;
          Adir1 = Round(ADLPC1, 2);

          //Direction 2 (T direction);
          //RadDia should be set to liek 14.5ish
          SlotCovers = ((L + 2 * Ice) - NLayers * (0.8 * lambda + 1 + 2 * Ice)) * (Dpipe + 2 * Ice) * AppurtDrag(WSpec, (RadDia + 2 * Ice), L / 12, z, V, y, ExpCat, StrCl, Topo, IncludeIce);
          FinnedArea = ((Finlength + Ice + Dpipe / 2) *  Math.cos(20 * Pi / 180) + Dpipe / 2 + Ice) * NLayers * (0.8 * lambda + 1 + 2 * Ice) * FlatAppurtDrag(WSpec, (Finlength + Ice + Dpipe / 2) *  Math.cos(20 * Pi / 180) + Dpipe / 2 + Ice, NLayers * (0.8 * lambda + 1 + 2 * Ice) / 12, z, V, ExpCat, StrCl, Topo);
          ADLPC2 = (SlotCovers + FinnedArea) / 144;
          Adir2 = Round(ADLPC2, 2);

          Amax =  Math.max(Adir1, Adir2);
          Amax = Round(Amax, 2);

          //Calculate Reactions
          // RDir1 = Round(G * ADLPC1 * Q, 0);
          // RDir2 = Round(G * ADLPC2 * Q, 0);
          // RMax = Round(G * Amax * Q, 0);
          _this.calcReactions();

          //Weight
          Weight = _this.calcWeight();

          //Ice Weight
          DLPCIce = _this.calcDLPCIce();
          Wice = Round(DLPCIce, 3);

          //Total Weight
          TotalWeight = Weight + DLPCIce;
          TotalWeight = Round(TotalWeight, 3);

        },
        patternJ: function() {


          //calcRadDia(pattern);

          //var Lambda = 11802.854 / (Channel * 6 + 389);
          //var x1 = 0;
          //var y1 = 3.5 / 2 + Matta(Channel);
          //x2 = (3.5 / 2 + 0.37 * Lambda) * Math.sin(40 * Pi / 180);
          //y2 = -(3.5 / 2 + 0.37 * Lambda) * Math.cos(40 * Pi / 180);
          //x3 = -(3.5 / 2 + 0.37 * Lambda) * Math.sin(40 * Pi / 180);
          //y3 = -(3.5 / 2 + 0.37 * Lambda) * Math.cos(40 * Pi / 180);
          //RadDia = Round(2 * BestFit(x1, y1, x2, y2, x3, y3) + 0.25, 3);

          //Calculate Fin Length
          Finlength = 0.4 * lambda;
          // New Finlength calc 3-3-17        
          //if (Channel >= 14 && Channel < 30)
          //Finlength = 9 + (8.25 - 9) * (Channel - 14) / (30 - 14);
          //else if (Channel >= 30)
          //Finlength = 8.25 + (4.63 - 8.25) * (Channel - 30) / (55 - 30);

          //Calculate Areas
          //Direction 1 (N direction);
          SlotCovers = ((L + 2 * Ice) - NLayers * (0.8 * lambda + 1 + 2 * Ice)) * (Dpipe + 2 * Ice) * FlatAppurtDrag(WSpec, Dpipe + 2 * Ice, ((L + 2 * Ice) - NLayers * (0.8 * lambda + 1 + 2 * Ice)) / 12, z, V, ExpCat, StrCl, Topo);
          FinnedArea = (2 * (Finlength + Ice + Dpipe / 2) *  Math.sin(57.5 * Pi / 180)) * NLayers * (0.8 * lambda + 1 + 2 * Ice) * FlatAppurtDrag(WSpec, 2 * (Finlength + Ice + Dpipe / 2) *  Math.sin(57.5 * Pi / 180), NLayers * (0.8 * lambda + 1 + 2 * Ice) / 12, z, V, ExpCat, StrCl, Topo);
          ADLPC1 = (SlotCovers + FinnedArea) / 144;
          Adir1 = Round(ADLPC1, 2);

          //Direction 2 (T direction);
          SlotCovers = ((L + 2 * Ice) - NLayers * (0.8 * lambda + 1 + 2 * Ice)) * (Dpipe + 2 * Ice) * AppurtDrag(WSpec, (RadDia + 2 * Ice), L / 12, z, V, y, ExpCat, StrCl, Topo, IncludeIce);
          FinnedArea = ((Finlength + Ice + Dpipe / 2) *  Math.cos(57.5 * Pi / 180) + Dpipe / 2 + Ice) * NLayers * (0.8 * lambda + 1 + 2 * Ice) * FlatAppurtDrag(WSpec, (Finlength + Ice + Dpipe / 2) *  Math.cos(57.5 * Pi / 180) + Dpipe / 2 + Ice, NLayers * (0.8 * lambda + 1 + 2 * Ice) / 12, z, V, ExpCat, StrCl, Topo);
          ADLPC2 = (SlotCovers + FinnedArea) / 144;
          Adir2 = Round(ADLPC2, 2);

          Amax =  Math.max(Adir1, Adir2);

          //Calculate Reactions
          // RDir1 = Round(G * ADLPC1 * Q, 0);
          // RDir2 = Round(G * ADLPC2 * Q, 0);
          // RMax = Round(G * Amax * Q, 0);
          _this.calcReactions();

          //Weight
          Weight = _this.calcWeight();

          //Ice Weight
          DLPCIce = _this.calcDLPCIce();
          Wice = Round(DLPCIce, 3);

          //Total Weight
          TotalWeight = Weight + DLPCIce;
          TotalWeight = Round(TotalWeight, 3);


        },
        patternM: function() {
          //Calculate Fin Length
          Finlength = 0.4 * lambda;
          // new Finlength as of 3-3-17
          //if (Channel >= 14 && Channel < 30)
          //Finlength = 11.75 + (7 - 11.75) * (Channel - 14) / (30 - 14);
          //else if (Channel >= 30)
          //Finlength = 7 + (4.63 - 7) * (Channel - 30) / (55 - 30);

          //Calculate Areas
          //Direction 1 (N direction);
          SlotCovers = ((L + 2 * Ice) - NLayers * (0.8 * lambda + 1 + 2 * Ice)) * (Dpipe + 2 * Ice) * FlatAppurtDrag(WSpec, Dpipe + 2 * Ice, ((L + 2 * Ice) - NLayers * (0.8 * lambda + 1 + 2 * Ice)) / 12, z, V, ExpCat, StrCl, Topo);
          FinnedArea = (2 * (Finlength + Ice + Dpipe / 2) *  Math.sin(75 * Pi / 180)) * NLayers * (0.8 * lambda + 1 + 2 * Ice) * FlatAppurtDrag(WSpec, 2 * (Finlength + Ice + Dpipe / 2) *  Math.sin(75 * Pi / 180), NLayers * (0.8 * lambda + 1 + 2 * Ice) / 12, z, V, ExpCat, StrCl, Topo);
          ADLPC1 = (SlotCovers + FinnedArea) / 144;
          Adir1 = Round(ADLPC1, 2);

          //Direction 2 (T direction);
          SlotCovers = ((L + 2 * Ice) - NLayers * (0.8 * lambda + 1 + 2 * Ice)) * (Dpipe + 2 * Ice) * AppurtDrag(WSpec, (RadDia + 2 * Ice), L / 12, z, V, y, ExpCat, StrCl, Topo, IncludeIce);
          FinnedArea = ((Finlength + Ice + Dpipe / 2) *  Math.cos(75 * Pi / 180) + Dpipe / 2 + Ice) * NLayers * (0.8 * lambda + 1 + 2 * Ice) * FlatAppurtDrag(WSpec, (Finlength + Ice + Dpipe / 2) *  Math.cos(75 * Pi / 180) + Dpipe / 2 + Ice, NLayers * (0.8 * lambda + 1 + 2 * Ice) / 12, z, V, ExpCat, StrCl, Topo);
          ADLPC2 = (SlotCovers + FinnedArea) / 144;
          Adir2 = Round(ADLPC2, 2);

          Amax =  Math.max(Adir1, Adir2);

          //Calculate Reactions
          // RDir1 = Round(G * ADLPC1 * Q, 0);
          // RDir2 = Round(G * ADLPC2 * Q, 0);
          // RMax = Round(G * Amax * Q, 0);
          _this.calcReactions();

          //Weight
          Weight = _this.calcWeight();

          //Ice Weight
          DLPCIce = _this.calcDLPCIce();
          Wice = Round(DLPCIce, 3);

          //Total Weight
          TotalWeight = Weight + DLPCIce;
          TotalWeight = Round(TotalWeight, 3);
      }

    },
    TF: {
      get: function(channel, azimuthpattern, elevationpattern) {
        var band = self.helpers.band(channel);
        var typeNumBays = parseInt(elevationpattern.substr(0, 2));
        var moduleIndex = typeNumBays - 1;
        
        var result = {
          weight: null,
          iceWeight: 0,
          areaFlats: null, // not calculated for this type either
          forceCoef: null,
          momentArm: null,  // calculated, but not currently exposed in UI
          noIceSupport: true
        };

        var weightConstants = [1675, 2625, 4600, 7550, 11725, 16775, 0, 0, 0, 0, 0, 0, 1300, 1950, 3425, 5500, 7875, 11725, 0, 0, 0, 0, 0, 0, 0, 900, 0, 1550, 0, 2700, 0, 4150, 0, 6575, 0, 9175];
        var forceCoefConstants = [35, 57, 90, 133, 174, 220, 0, 0, 0, 0, 0, 0, 30, 47, 72, 105, 136, 174, 0, 0, 0, 0, 0, 0, 0, 20, 0, 34, 0, 55, 0, 72, 0, 96, 0, 116];
        var momentArmConstants = [12.0, 15.2, 19.8, 26.2, 33.5, 40.2, 0, 0, 0, 0, 0, 0, 11.3, 12.5, 15.9, 22.7, 27.9, 32.8, 0, 0, 0, 0, 0, 0, 0, 8.9, 0, 12.8, 0, 14.4, 0, 23.0, 0, 28.4, 0, 33.2];

        switch (band) {
        case 'L':
          result.weight = weightConstants[moduleIndex];
          result.forceCoef = forceCoefConstants[moduleIndex];
          result.momentArm = momentArmConstants[moduleIndex];
          break;
        case 'M':
          result.weight = weightConstants[moduleIndex + 12];
          result.forceCoef = forceCoefConstants[moduleIndex + 12];
          result.momentArm = momentArmConstants[moduleIndex + 12];
         break;
        case 'H':
          result.weight = weightConstants[moduleIndex + 24];
          result.forceCoef = forceCoefConstants[moduleIndex + 24];
          result.momentArm = momentArmConstants[moduleIndex + 24];
          break;
        };

        return result;
      }
    },
        DSBc: {
            get: function(channel, azimuthpattern, elevationpattern) {
                // These calculations are the same as DSB
                return self.calc.DSB.get(channel, azimuthpattern, elevationpattern);
            }
        },
        TFU: {
            get: function(channel, azimuthpattern, elevationpattern) {
                // These calculations are the same as DSB
                return self.calc.DSB.get(channel, azimuthpattern, elevationpattern);
            }
        },
        DSB: { // need RadDia -- radome diameter as a user input (double-check that it's not calculated)
          get: function(_channel, azimuthpattern, elevationpattern, params) {
            if (defined(params))
              return this.calculate.apply(this, params);
          },
          setupPatterns: function() { 
            Pi = Math.PI;
              // 'read in antenna information
              Channel = channel; // CInt(Channel)
              NumberSections = numberOfSections; // cmbNSections.Value
              NLayers = n; // cmbNLayers.Value
              GainNumber = gain; // cmbNLayers.Value * cmbNSections.Value
              Pattern = pattern; // cmbPattern.Value - currently it is whichever pattern the function is... patternA pattern = 'A'
              h = self.values.h;
              z = self.values.z;
              V = self.values.V;
              Y = h;
              Ice = 0;
              Qzg = self.helpers.Qzg;
              Qzf = self.helpers.Qzf;
              AppurtDrag = self.helpers.AppurtDrag;
              FlatAppurtDrag = self.helpers.FlatAppurtDrag;
              chkIce = self.values.chkIce;

              // 'get the wind spec related data
              WSpec = windSpec; //cmbWindSpec.Value
              G = self.helpers.Gust(WSpec, true, h);
              if (WSpec == "C") {} // for WindSpec 'C'.  Delete if we decide not to use
                  // z = txtElevation.Value
                  ZoneC = ["A","B","C"][ Math.round(2 *  Math.random())]; // cmbZoneC.Value // this is a user input from a dropdown, options A, B, C - until user input available choose at random.
                  Q = self.helpers.Qzc(ZoneC, z);
                  G = 1;
                  // Ice = 0;
                  if (chkIce == true) {
                      Ice = self.values.Ice// txtTiC.Value //typical Ice input
                      Q = 7; // txtQiC.Value // this is a user input (wind pressure with ice) 7 is dummy value
                  }
              if (WSpec == "F") {
                  Q = Qzf(z, h, V);      
                  if (chkIce == true) {
                      Ice = self.values.Ice;
                      Q = 0.75 * Q;
                  }
              }
              else if (WSpec == "G") {
                  StrCl = self.values.StrCl; // cmbStrCl.Value
                  ExpCat = self.values.ExpCat; // cmbExpCat.Value
                  Topo = self.values.Topo; // cmbTopo.Value
                  Q = Qzg(z, V, Y, ExpCat, StrCl, Topo);
                  IncludeIce = false;
                  if (chkIce == true) {
                      IncludeIce = true;
                      V = self.values.V_ice; // txtViG.Value
                      DesignIce = self.values.Ice;
                      Ice = self.helpers.tiz(DesignIce, z, Y, ExpCat, Topo, StrCl);  // done elsewhere
                      Q = Qzg(z, V, Y, ExpCat, StrCl, Topo);
                  }
              }              
              // 'Report mechanical data based on antenna type
            // if (optDSB.Value == true) {}
                // 'Fill in antenna designation
                if (!optRadome) optRadome = false;
                optSlotCover = !optRadome;
                if (optRadome == true) {
                    AntennaDesignation = "TFU-" + String(gain) + "DSB-R " + pattern + "(" + String(Channel) + ")";
                    RadomeNote = "Full " + String(Round(RadDia, 2) + 0.25) + " dia Radome";
                    // AntType = AntennaDesignation
                } else if (optRadome != true) {
                    AntennaDesignation = "TFU-" + String(gain) + "DSB " + pattern + "(" + String(Channel) + ")";
                    RadomeNote = "Slot Covered";
                    // AntType = AntennaDesignation
                }
                // txtH2.Value = Round(AntennaH2, 1) // representation to user rounded to 1 place

                // 'get power divider data
                // Call (cmbNSections.Value, Ice, PowerDividerArea, PowerDividerWeight, PowerDividerIceWeight, numberFeedLines)

          },
          calculate: function(WindSpec, Ice_bool, t_ice, V_basic, V_basic_ice, NumberOfSections, TowerElevation, TowerHeight, TowerClearance, ExposeCat, TopoCat, StructClass, azimuthPattern, elevationPattern, Channel, Frequency, hasRadome, useVpolCheck) {
                optRadome = hasRadome;
                chkVPol = useVpolCheck;
                // assign needed values
                self.values = {
                  WSpec: WindSpec,
                  chkIce: Ice_bool,
                  Ice: t_ice,
                  V: V_basic,
                  V_ice: V_basic_ice,
                  numSections: NumberOfSections,
                  z: TowerElevation,
                  h: TowerHeight,
                  baseClearance: TowerClearance, 
                  ExpCat: ExposeCat,
                  Topo: TopoCat,
                  StrCl: StructClass,
                  azimuthPattern: azimuthPattern,
                  elevationPattern: elevationPattern,
                  Channel: Channel
                };
                frequency = Frequency;

                channel = Channel;
                windSpec = WindSpec;  // WSpec in pattern functions
                Ice = t_ice;
                // if (windSpec == 'G' && Ice_bool == true) {
                //   Ice = self.helpers.tiz(t_ice, TowerElevation, TowerHeight, ExposeCat, TopoCat, StructClass);
                // }
                gain = parseInt(elevationPattern.substr(0, 2)); // GainNumber
                  //Determine number of slots per section;                
                if ((gain % 8) != 0) {
                    n = gain; //12 slots per section, NLayers in pattern functions
                    numberOfSections = 1; //NumberSections in pattern functions
                } else if (gain >= 8) {
                    n = 8; //8 slots per section
                    numberOfSections = gain / 8;
                };
                               
                if (azimuthPattern) pattern = azimuthPattern.substr(azimuthPattern.lastIndexOf('-') + 1).toUpperCase();             
                // lambda = 11802.9 / (channel * 6 + 389);
                lambda = self.helpers.lambda(frequency);
                
                L = (lambda * (n + 1) + 2.25);
                S = 0.7 * lambda - 2;

                SingleSectionH2 = L / 12;

                //antennaH2 = (lambda * (n * numberOfSections + 1.5 * numberOfSections - 0.5) + 2.25) / 12;
                antennaH2 = numberOfSections * SingleSectionH2 + (numberOfSections - 1) / 6;
                self.calc.DSB.powerDivider(Ice_bool);
                self.calc.DSB.setupPatterns();
                                
                var patternFn = self.calc.DSB['pattern' + pattern];
                
                
                self.helpers.calcRadDia(pattern/*, optStdRad, optOptIce, optUserInp, OptBestFit*/);
                if (defined(patternFn)) {
                    patternFn();
                    if (!FinalWeight || typeof FinalWeight == 'undefined') FinalWeight = TotalWeight;                    
                    self._data = {
                        totalWeight: FinalWeight,
                        weight: (Ice_bool == true) ? TotalWeight : Weight,
                        iceWeight:(Ice_bool == true) ? IceWeight : 0,
                        areaFlats: (typeof ArevCMax != 'undefined') ? ArevCMax : null,
                        forceCoef: Amax,
                        momentArm: null
                    };
                    self._data.RadomeNote = RadomeNote;
                    self._data.AntennaDesignation = AntennaDesignation;
                    return self._data;
                }
                return angular.extend({}, self.dataDefaults);
          },
            powerDivider: function (Ice_bool) {
                if (!Truth(Ice_bool)) {
                  Ice = 0;
                };
                numberFeedLines = numberOfSections;
                switch (numberOfSections) {
                    case 1:
                        PowerDividerArea = 0;
                        PowerDividerWeight = 0;
                        PowerDividerIceWeight = 0;
                        // numberFeedLines = 1;
                        break;
                    case 2:
                    //PN 79967;
                        PowerDividerArea = (24 + 12 + 7.38 - 2 * (1 + 2 * Ice)) * (4.125 + 2 * Ice) + 2 * (1 + 2 * Ice) * (6.3 + 2 * Ice) + (35 - 2 * Ice) * (3.28 + 2 * Ice) + (23 - 2 * Ice) * (3.28 + 2 * Ice) + 2 * (0.5 + Ice) * (5.19 + 2 * Ice);
                        PowerDividerWeight = 72;                        
                        if (Ice_bool == true) {
                          PowerDividerIceWeight = Math.PI / 4 * 0.032 * (( Math.pow((4.125 + 2 * Ice), 2) - ( Math.pow(4.125, 2))) * (24 + 12 + 7.38) + ( Math.pow((3.28 + 2 * Ice), 2) - ( Math.pow(3.28, 2))) * (60)); //by measurement 1-21-2 JCB
                          // PowerDividerWeight += PowerDividerIceWeight;
                        }
                        // numberFeedLines = 2;
                        break;
                    case 3:
                    //PN 80205;
                        PowerDividerArea = (24 + 13 - 2 * Ice) * (6.23 + 2 * Ice) + 2 * (0.5 + Ice) * 3 * (8.125 + 2 * Ice) + (2.66 - 2 * Ice) * (4.125 + 2 * Ice) + (17.3 - 2 * Ice) * (4.125 + 2 * Ice) + 2 * 2 * (0.5 + Ice) * (6.3 + 2 * Ice) + (3.08 - 2 * Ice) * (3.28 + 2 * Ice) + (24 - 2 * Ice) * (3.28 + 2 * Ice) + 2 * (0.5 + Ice) * (5.18 + 2 * Ice) + (5.25 + 12 - 2 * Ice - 1) * (3.28 + 2 * Ice) + (34.3 - 2 * Ice) * (3.28 + 2 * Ice) + 6 * (0.5 + Ice) * (5.19 + 2 * Ice);
                        PowerDividerWeight = 121;
                        if (Ice_bool == true) {
                          PowerDividerIceWeight = Math.PI / 4 * 0.032 * (( Math.pow((6.23 + 2 * Ice), 2) - ( Math.pow(6.23, 2))) * (24 + 13) + ( Math.pow((3.28 + 2 * Ice), 2) - ( Math.pow(3.28, 2))) * (80.83) + ( Math.pow((4.125 + 2 * Ice), 2) - ( Math.pow(4.125, 2))) * (19.3)); //by measurement 1-21-2 JCB
                          // PowerDividerWeight += PowerDividerIceWeight;
                        }
                        // numberFeedLines = 3;
                        break;
                    case 4:
                    //PN 82780;
                        PowerDividerArea = (24 + 13 - 2 * Ice) * (6.23 + 2 * Ice) + 2 * (0.5 + Ice) * 3 * (8.125 + 2 * Ice) + (2.66 - 2 * Ice) * (4.125 + 2 * Ice) + (17.4 - 2 * Ice) * (4.125 + 2 * Ice) + 2 * 2 * (0.5 + Ice) * (6.3 + 2 * Ice) + (5.6 - 2 * Ice) * (3.28 + 2 * Ice) + 2 * (35 - 2 * Ice) * (3.28 + 2 * Ice) + 4 * (1 + 2 * Ice) * (5.18 + 2 * Ice) + (46 - 2 * Ice) * (3.28 + 2 * Ice) + 4 * (0.5 + Ice) * (5.19 + 2 * Ice);
                        PowerDividerWeight = 160;
                        if (Ice_bool == true) {
                          PowerDividerIceWeight = Math.PI / 4 * 0.032 * (( Math.pow((6.23 + 2 * Ice), 2) - ( Math.pow(6.23, 2))) * (24 + 13) + ( Math.pow((3.28 + 2 * Ice), 2) - ( Math.pow(3.28, 2))) * (125.6) + ( Math.pow((4.125 + 2 * Ice), 2) - ( Math.pow(4.125, 2))) * (19.3)); //by measurement 1-21-2 JCB
                          // PowerDividerWeight += PowerDividerIceWeight;
                        }
                        // numberFeedLines = 4;
                        break;
                };
                var powerDivider = {  // added variable to allow access to any of the values created herein... delete if not used
                  weight: PowerDividerWeight,
                  iceWeight: PowerDividerIceWeight,
                  feedlines: numberFeedLines,
                  area: PowerDividerArea
                }
                // return powerDivider;
            },
            patternA: function() {
              /*
              //  Public Sub PatternA() 
              // 'constants
              Pi = Math.PI;

              // 'read in antenna information
              Channel = channel; // CInt(Channel)
              NumberSections = numberOfSections; // cmbNSections.Value
              NLayers = n; // cmbNLayers.Value
              GainNumber = gain; // cmbNLayers.Value * cmbNSections.Value
              Pattern = pattern; // cmbPattern.Value - currently it is whichever pattern the function is... patternA pattern = 'A'
              console.log({
                Channel: Channel,
                NumberSections: NumberSections,
                NLayers: NLayers,
                GainNumber: GainNumber,
                Pattern: Pattern
              });
              h = self.values.h;
              z = self.values.z;
              V = self.values.V;
              Y = h;
              Ice = 0;
              Qzg = self.helpers.Qzg;
              Qzf = self.helpers.Qzf;
              AppurtDrag = self.helpers.AppurtDrag;
              FlatAppurtDrag = self.helpers.FlatAppurtDrag;
              chkIce = self.values.chkIce;

              // 'get the wind spec related data
              WSpec = windSpec; //cmbWindSpec.Value
              G = self.helpers.Gust(WSpec, true, h);
              if (WSpec == "C") {} // for WindSpec 'C'.  Delete if we decide not to use
                  // z = txtElevation.Value
                  ZoneC = ["A","B","C"][ Math.round(2 *  Math.random())]; // cmbZoneC.Value // this is a user input from a dropdown, options A, B, C - until user input available choose at random.
                  Q = self.helpers.Qzc(ZoneC, z);
                  G = 1;
                  // Ice = 0;
                  if (chkIce == true) {
                      Ice = self.values.Ice// txtTiC.Value //typical Ice input
                      Q = 7; // txtQiC.Value // this is a user input (wind pressure with ice) 7 is dummy value
                  }
              if (WSpec == "F") {
                  Q = Qzf(z, h, V);      
                  if (chkIce == true) {
                      Ice = self.values.Ice;
                      Q = 0.75 * Q;
                  }
              }
              else if (WSpec == "G") {
                  StrCl = self.values.StrCl; // cmbStrCl.Value
                  ExpCat = self.values.ExpCat; // cmbExpCat.Value
                  Topo = self.values.Topo; // cmbTopo.Value
                  Q = Qzg(z, V, Y, ExpCat, StrCl, Topo);
                  IncludeIce = false;
                  if (chkIce == true) {
                      IncludeIce = true;
                      V = self.values.V_ice; // txtViG.Value
                      DesignIce = self.values.Ice;
                      Ice = self.helpers.tiz(DesignIce, z, Y, ExpCat, Topo, StrCl);  // done elsewhere
                      Q = Qzg(z, V, Y, ExpCat, StrCl, Topo);
                  }
              }
              */
               // A Pattern
              if (optRadome != true) {
                    // Area Direction 1
                    SlotCovers = (L - S + 2 * Ice) * ((Dpipe + 3.55) + 2 * Ice) * AppurtDrag(WSpec, (Dpipe + 3.55) + 2 * Ice, (L - S + 2 * Ice) / 12, z, V, Y, ExpCat, StrCl, Topo, IncludeIce);
                    BarePole = (2 + S - 4 * Ice) * (Dpipe + 2 * Ice) * AppurtDrag(WSpec, Dpipe + 2 * Ice, (2 + S - 4 * Ice) / 12, z, V, Y, ExpCat, StrCl, Topo, IncludeIce);
                    EndFlanges = (2 + 2 * Ice) * ((Dpipe + 1.5) + 2 * Ice) * AppurtDrag(WSpec, (Dpipe + 1.5) + 2 * Ice, (2 + 2 * Ice) / 12, z, V, Y, ExpCat, StrCl, Topo, IncludeIce);
                    EndLugs = 2 * ((Dpipe + 1.5) + 2 * Ice) * (0.5 + 2 * Ice) * AppurtDrag(WSpec, (Dpipe + 1.5) + 2 * Ice, (0.5 + 2 * Ice) / 12, z, V, Y, ExpCat, StrCl, Topo, IncludeIce);
                    TL = ((9 + 60) * (3.125 + 2 * Ice) + 4 * (0.5 + 2 * Ice) * (5.19 + 2 * Ice)) * AppurtDrag(WSpec, 3.125 + 2 * Ice, 9 + 60, z, V, Y, ExpCat, StrCl, Topo, IncludeIce);
                    Feedline = (300) * (3.01 + 2 * Ice) * AppurtDrag(WSpec, 3.01 + 2 * Ice, 300 / 12, z, V, Y, ExpCat, StrCl, Topo, IncludeIce);
                    Adir1 = Round(((NumberSections * (SlotCovers + BarePole + EndFlanges + EndLugs + TL) + Feedline * numberFeedLines + PowerDividerArea)) / 144, 2);
                    Amax = ((NumberSections * (SlotCovers + BarePole + EndFlanges + EndLugs + TL) + Feedline * numberFeedLines + PowerDividerArea)) / 144;
                    
                    // Calculate the shear force
                    RDir1 = Round(G * Amax * Q, 0);
                    // console.log('SlotCover: ', {
                    //   NumberSections: NumberSections,
                    //   SlotCovers: SlotCovers,
                    //   BarePole: BarePole,
                    //   EndFlanges: EndFlanges,
                    //   EndLugs: EndLugs,
                    //   TL: TL,
                    //   Feedline: Feedline,
                    //   numberFeedLines: numberFeedLines,
                    //   PowerDividerArea: PowerDividerArea,
                    //   Adir1: Adir1,
                    //   RDir1: RDir1, 
                    //   Ice: Ice
                    //   });
                
                    //Area Direction 2
                    SlotCovers = (L - S + 2 * Ice) * ((Dpipe + 2.56) + 2 * Ice) * AppurtDrag(WSpec, (Dpipe + 2.56) + 2 * Ice, (L - S + 2 * Ice) / 12, z, V, Y, ExpCat, StrCl, Topo, IncludeIce)
                    BarePole = (2 + S - 4 * Ice) * (Dpipe + 2 * Ice) * AppurtDrag(WSpec, Dpipe + 2 * Ice, (2 + S - 4 * Ice) / 12, z, V, Y, ExpCat, StrCl, Topo, IncludeIce)
                    EndFlanges = (2 + 2 * Ice) * ((Dpipe + 1.5) + 2 * Ice) * AppurtDrag(WSpec, (Dpipe + 1.5) + 2 * Ice, SingleSectionH2, z, V, Y, ExpCat, StrCl, Topo, IncludeIce)
                    Tee = (3.84 - Ice) * (3.5 + 2 * Ice) * AppurtDrag(WSpec, 3.5 + 2 * Ice, (3.84 - Ice) / 12, z, V, Y, ExpCat, StrCl, Topo, IncludeIce)
                    GasStop = (5.19 + 2 * Ice) * (2 * 0.5 + 1.375 + Ice) * AppurtDrag(WSpec, 5.83 + 2 * Ice, SingleSectionH2, z, V, Y, ExpCat, StrCl, Topo, IncludeIce)
                    Lug = (((3.75 + Ice) * (4.1 + Ice)) - 0.5 * Math.pow((2.35 + Ice), 2)) * FlatAppurtDrag(WSpec, 3.75 + Ice, (4.1 + Ice) / 12, z, V, ExpCat, StrCl, Topo)
                    EndLugs = 2 * (12.25 + 2 * Ice) * (0.5 + 2 * Ice) * FlatAppurtDrag(WSpec, 12.25 + 2 * Ice, (0.5 + 2 * Ice) / 12, z, V, ExpCat, StrCl, Topo)
                    GasStop = (5.19 + 2 * Ice) * (2 * 0.5 + 1.375 + Ice) * AppurtDrag(WSpec, 5.19 + 2 * Ice, (2 * 0.5 + 1.375 + Ice) / 12, z, V, Y, ExpCat, StrCl, Topo, IncludeIce)
                    TL = ((22 + 15 + 60) * (3.125 + 2 * Ice) + 6 * (0.5 + 2 * Ice) * (5.19 + 2 * Ice)) * AppurtDrag(WSpec, 3.125 + 2 * Ice, (22 + 15 + 60) / 12, z, V, Y, ExpCat, StrCl, Topo, IncludeIce)
                    Feedline = (300) * (3.01 + 2 * Ice) * AppurtDrag(WSpec, 3.01 + 2 * Ice, 300 / 12, z, V, Y, ExpCat, StrCl, Topo, IncludeIce)
                    Adir2 = Round(((NumberSections * (SlotCovers + BarePole + EndFlanges + EndLugs + TL + Tee + GasStop) + Feedline * numberFeedLines + PowerDividerArea)) / 144, 2)
                    A = ((NumberSections * (SlotCovers + BarePole + EndFlanges + EndLugs + TL + Tee + GasStop) + Feedline * numberFeedLines + PowerDividerArea)) / 144
                    //Calculate the shear force
                    RDir2 = Round(G * A * Q, 0);
                    if (A > Amax) {
                        Amax = A;
                    }
                    RMax = Round(G * Amax * Q, 0);

                    //Weight
                    SlotCoverWeight = (L - S - 7) * 3 * (0.03);
                    EndSeals = 6 * 0.33; // Assumes AL end seals are used.
                    PoleWeight = Pi / 4 * 0.1 * (Math.pow(Dpipe, 2) - Math.pow((Dpipe - 2 * twall), 2)) * ((NLayers + 1) * lambda - 0.5);
                    PN62538 = 2 * 0.72; //End Flange
                    PN75553 = 1.85; //Spacer Flange
                    PN75554 = 0.62; // Tee Flange
                    PN75730 = 0.6; //Vertical Lug
                    PN76922 = 2 * 2.5; //Bolt On Lug
                    PN79819 = 2 * 5.7; //End Short
                    Inner = Pi / 4 * (Math.pow(1.625, 2) - Math.pow(1.527, 2)) * L * 0.323 + 5;
                    Misc = 5;
                    GasStopWeight = 10;
                    TLWeight = 0.23 * (22 + 60);
                    Elbow = 12;
                    FeedlineWeight = 300 * 0.148;
                    Weight = (EndSeals + PoleWeight + PN62538 + PN75553 + PN75554 + PN75730 + PN76922 + PN79819 + SlotCoverWeight + Inner + Misc + GasStopWeight + TLWeight + Elbow) * NumberSections + (FeedlineWeight) * numberFeedLines + PowerDividerWeight;
                    // Weight = Round(Weight, 3);
        
                    //Ice Weight
                    SlotCoverIce = (L - S - 7 - 2 * Ice) * (0.032) * (1.5 * (2.89) * (Math.pow((1.59 + Ice), 2) - Math.pow((1.59), 2)) + 1.5 * (0.71) * (Math.pow((2.5 + Ice), 2) - Math.pow((2.5), 2)));
                    BarePoleIce = Pi / 4 * (0.032) * (Math.pow((Dpipe + 2 * Ice), 2) - Math.pow(Dpipe, 2)) * (2 + S + 4 * Ice);
                    EndFlangeIce = Pi / 4 * (0.032) * (Math.pow(((Dpipe + 1.5) + 2 * Ice), 2) - Math.pow((Dpipe + 1.5), 2)) * (2 + 2 * Ice);
                    TeeIce = Pi / 4 * (0.032) * (Math.pow((3.5 + 2 * Ice), 2) - Math.pow((3.5), 2)) * (3.84 - Ice);
                    LugIce = ((3.75 + Ice) * (4.1 + Ice) - 0.5 * Math.pow((2.34 + Ice), 2)) * 2 * Ice * (0.032);
                    EndLugIce = (Pi / 16 * (Math.pow((6.5 + 2 * Ice), 2) - Math.pow((6.5), 2)) + (6.5 + 2 * Ice) * (9 + Ice) - (2 + Ice) * (9 + Ice)) * 2 * Ice * (0.032);
                    GasStopIce = Pi / 4 * (0.032) * (Math.pow((5.19 + 2 * Ice), 2) - Math.pow((5.19), 2)) * (2 * 0.5 + 1.375 + 2 * Ice);
                    TLIce = Pi / 4 * (0.032) * (Math.pow((3.125 + 2 * Ice), 2) - Math.pow((3.125), 2)) * (22 + 15 + 60);
                    FeedLineIce = Pi / 4 * (0.032) * (Math.pow((3.01 + 2 * Ice), 2) - Math.pow((3.01), 2)) * 300;
                    IceWeight = (SlotCoverIce + BarePoleIce + EndFlangeIce + TeeIce + LugIce + EndLugIce + GasStopIce + TLIce + FeedLineIce) * NumberSections + PowerDividerIceWeight;
                    Wice = IceWeight;
                    // console.log({ // investigate "first parens"
                    //   IceWeight: IceWeight,
                    //   NumberSections: NumberSections,
                    //   L:L,
                    //   S:S,
                    //   Pi:Pi,
                    //   lambda: lambda,
                    //   "First Parens": EndSeals + PoleWeight + PN62538 + PN75553 + PN75554 + PN75730 + PN76922 + PN79819 + SlotCoverWeight + Inner + Misc + GasStopWeight + TLWeight + Elbow,
                    //   PoleWeight: PoleWeight,
                    //   SlotCoverWeight: SlotCoverWeight,
                    //   Inner: Inner,
                    //   Misc: Misc,
                    //   EndSeals: EndSeals,
                    //   GasStopWeight: GasStopWeight,
                    //   TLWeight: TLWeight, 
                    //   Elbow: Elbow,
                    //   // NumberSections: NumberSections,
                    //   FeedlineWeight: FeedlineWeight,
                    //   // numberFeedLines: numberFeedLines,
                    //   PowerDividerWeight: PowerDividerWeight
                    // });

                    // Total Weight;
                    TotalWeight = Weight + IceWeight;
                    // TotalWeight = Round(TotalWeight, 0);
                  
                  } else if (optRadome == true) {  // Radomed;
                      Dradome = RadDia;
                      Lradome = L - S - 7;
                      //Area Direction 1
                      Radome = (Lradome + 4 * Ice) * (Dradome + 2 * Ice) * AppurtDrag(WSpec, Dradome + 2 * Ice, (Lradome + 4 * Ice) / 12 * 12, z, V, Y, ExpCat, StrCl, Topo, IncludeIce);
                      BarePole = (L - Lradome - 4 * Ice) * (Dpipe + 2 * Ice) * AppurtDrag(WSpec, Dpipe + 2 * Ice, (L - Lradome - 4 * Ice) / 12, z, V, Y, ExpCat, StrCl, Topo, IncludeIce);
                      EndFlanges = (2 + 2 * Ice) * (6.5 + 2 * Ice) * AppurtDrag(WSpec, (5 + 2 * RadDia + 0.1875 + 2 * Ice), (2 + 2 * Ice) / 12, z, V, Y, ExpCat, StrCl, Topo, IncludeIce);
                      EndLugs = 2 * (6.25 + 2 * Ice) * (0.5 + 2 * Ice) * AppurtDrag(WSpec, (5 + 2 * RadDia + 0.1875 + 2 * Ice), (0.5 + 2 * Ice) / 12, z, V, Y, ExpCat, StrCl, Topo, IncludeIce);
                      Feedline = (300) * (3.01 + 2 * Ice) * AppurtDrag(WSpec, 3.01 + 2 * Ice, 300 / 12, z, V, Y, ExpCat, StrCl, Topo, IncludeIce);
                      TL = ((9 + 60) * (3.125 + 2 * Ice) + 4 * (0.5 + 2 * Ice) * (5.19 + 2 * Ice)) * AppurtDrag(WSpec, 3.125 + 2 * Ice, (9 + 60) / 12, z, V, Y, ExpCat, StrCl, Topo, IncludeIce);
                      Adir1 = Round(((NumberSections * (Radome + BarePole + EndFlanges + EndLugs + TL) + Feedline * numberFeedLines + PowerDividerArea)) / 144, 2);
                      Amax = ((NumberSections * (Radome + BarePole + EndFlanges + EndLugs + TL) + Feedline * numberFeedLines + PowerDividerArea)) / 144;
                      RDir1 = Round(G * Amax * Q, 0);
                      // console.log('Radome: ', {
                      // NumberSections: NumberSections,
                      // SlotCovers: SlotCovers,
                      // BarePole: BarePole,
                      // EndFlanges: EndFlanges,
                      // EndLugs: EndLugs,
                      // TL: TL,
                      // Feedline: Feedline,
                      // numberFeedLines: numberFeedLines,
                      // PowerDividerArea: PowerDividerArea
                      // });

                      // Area Direction 2;
                      Radome = (Lradome + 2 * Ice) * (Dradome + 2 * Ice) * AppurtDrag(WSpec, Dradome + 2 * Ice, (Lradome + 2 * Ice) / 12 * 12, z, V, Y, ExpCat, StrCl, Topo, IncludeIce);
                      BarePole = (L - Lradome - 4 * Ice) * (Dpipe + 2 * Ice) * AppurtDrag(WSpec, Dpipe + 2 * Ice, (L - Lradome - 4 * Ice) / 12, z, V, Y, ExpCat, StrCl, Topo, IncludeIce);
                      EndFlanges = (2 + 2 * Ice) * (6.5 + 2 * Ice) * AppurtDrag(WSpec, (RadDia + 2 * Ice), (2 + 2 * Ice) / 12, z, V, Y, ExpCat, StrCl, Topo, IncludeIce);
                      EndLugs = 2 * (12.25 + 2 * Ice) * (0.5 + 2 * Ice) * FlatAppurtDrag(WSpec, 12.25 + 2 * Ice, (0.5 + 2 * Ice) / 12, z, V, ExpCat, StrCl, Topo);
                      Tee = (3.84 - Ice) * (3.5 + 2 * Ice) * AppurtDrag(WSpec, 3.5 + 2 * Ice, (3.84 - Ice) / 12, z, V, Y, ExpCat, StrCl, Topo, IncludeIce);
                      GasStop = (5.19 + 2 * Ice) * (2 * 0.5 + 1.375 + Ice) * AppurtDrag(WSpec, 5.19 + 2 * Ice, (2 * 0.5 + 1.375 + Ice) / 12, z, V, Y, ExpCat, StrCl, Topo, IncludeIce);
                      Feedline = (300) * (3.01 + 2 * Ice) * AppurtDrag(WSpec, 3.01 + 2 * Ice, 300 / 12, z, V, Y, ExpCat, StrCl, Topo, IncludeIce);
                      TL = ((22 + 15 + 60) * (3.125 + 2 * Ice) + 6 * (0.5 + 2 * Ice) * (5.19 + 2 * Ice)) * AppurtDrag(WSpec, 3.125 + 2 * Ice, (22 + 15 + 60) / 12, z, V, Y, ExpCat, StrCl, Topo, IncludeIce);
                      Adir2 = Round(((NumberSections * (Radome + BarePole + EndFlanges + EndLugs + TL + Tee + GasStop) + Feedline * numberFeedLines + PowerDividerArea)) / 144, 2);
                      A = ((NumberSections * (Radome + BarePole + EndFlanges + EndLugs + TL + Tee + GasStop) + Feedline * numberFeedLines + PowerDividerArea)) / 144;
                      RDir2 = Round(G * A * Q, 0);
                      if (A > Amax) {
                          Amax = A;
                      }
                      RMax = Round(G * Amax * Q, 0);
                      // Amax = Round(Amax, 2);

                      // Weight;
                      EndSeals = Pi * (Math.pow(Dradome, 2) - Math.pow(Dpipe, 2)) * 1 * 0.034;
                      PoleWeight = Pi / 4 * 0.1 * (Math.pow(Dpipe, 2) - Math.pow((Dpipe - 2 * twall), 2)) * ((NLayers + 1) * lambda - 0.5);
                      PN62538 = 2 * 0.72; // End Flange;
                      PN75553 = 1.85; // Spacer Flange;
                      PN75554 = 0.62; //  Tee Flange;
                      PN75730 = 0.6; // Vertical Lug;
                      PN76922 = 2 * 2.5; // Bolt On Lug;
                      PN79819 = 2 * 5.7; // End Short;
                      RadomeWeight = (0.1544 * Dradome + 2) * (L - S - 7) / 12;
                      Inner = Pi / 4 * (Math.pow(1.625, 2) - Math.pow(1.527, 2)) * L * 0.323 + 5;
                      Misc = 5;
                      GasStopWeight = 10;
                      TLWeight = 0.23 * (22 + 60);
                      Elbow = 12;
                      FeedlineWeight = 300 * 0.148;
                      Weight = (EndSeals + PoleWeight + PN62538 + PN75553 + PN75554 + PN75730 + PN76922 + PN79819 + RadomeWeight + Inner + Misc + GasStopWeight + TLWeight + Elbow) * NumberSections + (FeedlineWeight) * numberFeedLines + PowerDividerWeight;
                      // Weight = Round(Weight, 0);                      
                  
                      // Ice Weight;
                      RadomeIce = Pi / 4 * (0.032) * (Math.pow(Dradome + 2 * Ice, 2) - Math.pow(Dradome, 2)) * (L - S - 7 - 2 * Ice);
                      BarePoleIce = Pi / 4 * (0.032) * (Math.pow((Dpipe + 2 * Ice), 2) - Math.pow(Dpipe, 2)) * (L - Lradome + 4 * Ice);
                      EndFlangeIce = Pi / 4 * (0.032) * (Math.pow(((Dpipe + 1.5) + 2 * Ice), 2) - Math.pow((Dpipe + 1.5), 2)) * (2 + 2 * Ice);
                      TeeIce = Pi / 4 * (0.032) * (Math.pow((3.5 + 2 * Ice), 2) - Math.pow((3.5), 2)) * (3.84 - Ice);
                      LugIce = ((3.75 + Ice) * (4.1 + Ice) - 0.5 * Math.pow((2.34 + Ice), 2)) * 2 * Ice * (0.032);
                      EndLugIce = (Pi / 16 * (Math.pow((6.5 + 2 * Ice), 2) - Math.pow((6.5), 2)) + (6.5 + 2 * Ice) * (9 + Ice) - (2 + Ice) * (9 + Ice)) * Ice * 2 * (0.032);
                      GasStopIce = Pi / 4 * (0.032) * (Math.pow((5.19 + 2 * Ice), 2) - Math.pow((5.19), 2)) * (2 * 0.5 + 1.375 + 2 * Ice);
                      TLIce = Pi / 4 * (0.032) * (Math.pow((3.125 + 2 * Ice), 2) - Math.pow((3.125), 2)) * (22 + 15 + 60);
                      FeedLineIce = Pi / 4 * (0.032) * (Math.pow((3.01 + 2 * Ice), 2) - Math.pow((3.01), 2)) * 300;
                      IceWeight = (RadomeIce + BarePoleIce + EndFlangeIce + TeeIce + LugIce + EndLugIce + GasStopIce + TLIce + FeedLineIce) * NumberSections + PowerDividerIceWeight;
                      Wice = Round(IceWeight, 3);
                      
                      // Total Weight;
                      TotalWeight = Weight + IceWeight;
                      // TotalWeight = Round(TotalWeight, 0);
                  }

              // else if (optTLP == true) {} 
                  // if (optSlotCover == true) {} //received blank;
                  
                  // else if (optRadome == true) {
                  //         FeedLineLength = Feedline * 300;
                  //         LugLength = 1.5;
                  //         // PowerDivider(NumberSections, Ice, PowerDividerArea, PowerDividerWeight, numberFeedLines);
                  //         // Area Direction 1;
                          
                  //         Radome = (L - S - 3.875 + 2 * Ice) * (RadDia + 2 * Ice) * AppurtDrag(WSpec, (RadDia + 2 * Ice), SingleSectionH2 * 12, z, V, Y, ExpCat, StrCl, Topo, IncludeIce);
                  //         BarePole = (2 + S - 0.5 - 4 * Ice) * (5 + 2 * Ice) * AppurtDrag(WSpec, (5 + 2 * RadDia + 0.1875 + 2 * Ice), SingleSectionH2, z, V, Y, ExpCat, StrCl, Topo, IncludeIce);
                  //         LugArea = LugLength * (6.5 + 3 * Ice) * FlatAppurtDrag(WSpec, 3.75 + Ice, (4.1 + Ice) / 12, z, V, ExpCat, StrCl, Topo);
                  //         Feedline = FeedLineLength * (2 + 2 * Ice) * AppurtDrag(WSpec, 2 + 2 * Ice, FeedLineLength / 12, z, V, Y, ExpCat, StrCl, Topo, IncludeIce);
                  //         ElbowArea = (3 * (1.625 + (2 * Ice)) + (6 * (1.625 + (2 * Ice)))) * AppurtDrag(WSpec, 1.625 + (2 * Ice), 3 / 12, z, V, Y, ExpCat, StrCl, Topo, IncludeIce);
                  //         Adir1 = Round(((NumberSections * (Radome + BarePole + LugArea + ElbowArea) + Feedline * numberFeedLines + PowerDividerArea)) / 144, 2);
                  //         Amax = ((NumberSections * (Radome + BarePole + EndFlanges + EndLugs + TL) + Feedline * numberFeedLines + PowerDividerArea)) / 144;
                  //         RDir1 = Round(G * Amax * Q, 0);
                          
                  //         // Area Direction 2;              
                  //         Radome = (L - S - 3.875 + 2 * Ice) * (RadDia + 2 * Ice) * AppurtDrag(WSpec, (RadDia + 2 * Ice), SingleSectionH2 * 12, z, V, Y, ExpCat, StrCl, Topo, IncludeIce);
                  //         BarePoleArea = (2 + S - 0.5 - 4 * Ice) * (5 + 2 * Ice) * AppurtDrag(WSpec, (5 + 2 * RadDia + 0.1875 + 2 * Ice), SingleSectionH2, z, V, Y, ExpCat, StrCl, Topo, IncludeIce);
                  //         LugArea = LugLength * (11.5 + 3 * Ice) * FlatAppurtDrag(WSpec, 3.75 + Ice, (11.5 + 3 * Ice) / 12, z, V, ExpCat, StrCl, Topo);
                  //         FeedLineArea = FeedLineLength * (2 + 2 * Ice) * AppurtDrag(WSpec, 2 + 2 * Ice, FeedLineLength / 12, z, V, Y, ExpCat, StrCl, Topo, IncludeIce);
                  //         ElbowArea = (3 * (1.625 + (2 * Ice)) + (6 * (1.625 + (2 * Ice)))) * AppurtDrag(WSpec, 1.625 + (2 * Ice), 3 / 12, z, V, Y, ExpCat, StrCl, Topo, IncludeIce);
                  //         Adir2 = Round(((NumberSections * (Radome + BarePole + EndFlanges + EndLugs + TL + Tee + GasStop) + Feedline * numberFeedLines + PowerDividerArea)) / 144, 2);
                  //         A = ((NumberSections * (Radome + BarePole + EndFlanges + EndLugs + TL + Tee + GasStop) + Feedline * numberFeedLines + PowerDividerArea)) / 144;
                  //         RDir2 = Round(G * A * Q, 0);
                  //         if (A > Amax) {
                  //             Amax = A;
                  //         }
                  //         RMax = Round(G * Amax * Q, 0);
                  //         // Amax = Round(Amax, 2);
                         
                  //        // Weight;
                  //         EndSeals = Pi * ( Math.pow(5.75, 2) -  Math.pow(5, 2)) * 1 * 0.034;
                  //         PoleWeight = ((Pi / 4) * ( Math.pow(5, 2) -  Math.pow(4.5, 2)) * L) * 0.1;
                  //         RadomeWeight = 0.1544 * 6.69 * (L - S - 3.875) / 12;
                  //         HarnessWeight = (2 * 1.5) + ((0.243 * L) * 0.1) + ((0.075 * (L / 2)) * 0.322) + 0.165;
                  //         LugWeight = 7.5;
                  //         Misc = 5;
                  //         ElbowWeight = 3.5;
                  //         FeedlineWeight = Feedline * 26; // Andrew Catalog;
                         
                  //        // Ice Weight;
                  //         RadomeIce = Pi / 4 * (0.032) * ( Math.pow((6.69 + 2 * Ice), 2) -  Math.pow((6.69), 2)) * (L - S - 3.875 + 2 * Ice);
                  //         BarePoleIce = Pi / 4 * (0.032) * ( Math.pow((5 + 2 * Ice), 2) -  Math.pow(5, 2)) * (2 + S - 0.5 - 4 * Ice);
                  //         ElbowIce = (((Pi / 4) * ( Math.pow((1.625 + 2 * Ice), 2) -  Math.pow((1.625), 2)) * 5.6) + ((Pi / 4) * ( Math.pow((1.625 + 2 * Ice), 2) -  Math.pow((1.625), 2)) * 2.8)) * 0.032;
                  //         LugIce = 3 * 0.032 * (2 * (11.5 * 6.5 * Ice) + 2 * (11.5 * 0.5 * Ice) + 2 * (6.5 * 0.5 * Ice));
                  //         FeedLineIce = (Pi / 4) * ( Math.pow((1.98 + 2 * Ice), 2) -  Math.pow((1.98), 2)) * 0.032 * FeedLineLength;              
               
                  //       }
                FinalWeight = TotalWeight;
            // else if (optDLP == true) {} // for DLP antennas
            },
            patternB: function(){ // get ready to insert this into mechanical.js and test!
            //Pattern B
              if (optSlotCover == true) {
                  
                  // 'Area Direction 1
                  SlotCovers = (L - S - 7 + 2 * Ice) * (Dpipe + 2 * Ice) * FlatAppurtDrag(WSpec, Dpipe + 2 * Ice, (L - S - 7 + 2 * Ice) / 12, z, V, ExpCat, StrCl, Topo);
                  BarePole = (2 + S - 4 * Ice) * (Dpipe + 2 * Ice) * AppurtDrag(WSpec, (Dpipe + 2 * Ice), (2 + S - 4 * Ice) / 12, z, V, Y, ExpCat, StrCl, Topo, IncludeIce);
                  EndFlanges = (2 + 2 * Ice) * (5.25 + 2 * Ice) * AppurtDrag(WSpec, 5.25 + 2 * Ice, (2 + 2 * Ice) / 12, z, V, Y, ExpCat, StrCl, Topo, IncludeIce);
                  EndLugs = 2 * (5.25 + 2 * Ice) * (0.5 + 2 * Ice) * AppurtDrag(WSpec, 5.25 + 2 * Ice, (0.5 + 2 * Ice) / 12, z, V, Y, ExpCat, StrCl, Topo, IncludeIce);
                  TL = ((60 + 9) * (3.125 + 2 * Ice) + 4 * (0.5 + 2 * Ice) * (5.19 + 2 * Ice)) * AppurtDrag(WSpec, 3.125 + 2 * Ice, (60 + 9) / 12, z, V, Y, ExpCat, StrCl, Topo, IncludeIce);
                  Feedline = (300) * (3.01 + 2 * Ice) * AppurtDrag(WSpec, 3.01 + 2 * Ice, 300 / 12, z, V, Y, ExpCat, StrCl, Topo, IncludeIce);
                  Adir1 = Round(((NumberSections * (SlotCovers + BarePole + EndFlanges + EndLugs + TL) + Feedline * numberFeedLines + PowerDividerArea)) / 144, 2);
                  Amax = ((NumberSections * (SlotCovers + BarePole + EndFlanges + EndLugs + TL) + Feedline * numberFeedLines + PowerDividerArea)) / 144;
                  RDir1 = Round(G * Amax * Q, 0);

                  // 'Area Direction 2
                  SlotCovers = (L - S - 7 + 2 * Ice) * (5.375 + 2 * Ice) * FlatAppurtDrag(WSpec, 5.375 + 2 * Ice, (L - S - 7 + 2 * Ice) / 12, z, V, ExpCat, StrCl, Topo);
                  BarePole = (2 + S - 4 * Ice) * (Dpipe + 2 * Ice) * AppurtDrag(WSpec, (Dpipe + 2 * Ice), (2 + S - 4 * Ice) / 12, z, V, Y, ExpCat, StrCl, Topo, IncludeIce);
                  EndFlanges = (2 + 2 * Ice) * (5.25 + 2 * Ice) * AppurtDrag(WSpec, 5.25 + 2 * Ice, (2 + 2 * Ice) / 12, z, V, Y, ExpCat, StrCl, Topo, IncludeIce);
                  Tee = (3.84 - Ice) * (3.5 + 2 * Ice) * AppurtDrag(WSpec, 3.5 + 2 * Ice, (3.84 - Ice) / 12, z, V, Y, ExpCat, StrCl, Topo, IncludeIce);
                  Lug = (((3.75 + Ice) * (4.1 + Ice)) - 0.5 * Math.pow((2.35 + Ice), 2)) * FlatAppurtDrag(WSpec, 3.75 + Ice, (4.1 + Ice) / 12, z, V, ExpCat, StrCl, Topo);
                  EndLugs = 2 * (10.125 + 2 * Ice) * (0.5 + 2 * Ice) * FlatAppurtDrag(WSpec, 10.125 + 2 * Ice, (0.5 + 2 * Ice) / 12, z, V, ExpCat, StrCl, Topo);
                  GasStop = (5.19 + 2 * Ice) * (2 * 0.5 + 1.375 + Ice) * AppurtDrag(WSpec, 5.19 + 2 * Ice, (2 * 0.5 + 1.375 + Ice) / 12, z, V, Y, ExpCat, StrCl, Topo, IncludeIce);
                  TL = ((22 + 15 + 60) * (3.125 + 2 * Ice) + 6 * (0.5 + 2 * Ice) * (5.19 + 2 * Ice)) * AppurtDrag(WSpec, 3.125 + 2 * Ice, (22 + 15 + 60) / 12, z, V, Y, ExpCat, StrCl, Topo, IncludeIce);
                  Feedline = (300) * (3.01 + 2 * Ice) * AppurtDrag(WSpec, 3.01 + 2 * Ice, 300 / 12, z, V, Y, ExpCat, StrCl, Topo, IncludeIce);
                  Adir2 = Round(((NumberSections * (SlotCovers + BarePole + EndFlanges + EndLugs + TL + Tee + GasStop) + Feedline * numberFeedLines + PowerDividerArea)) / 144, 2);
                  A = ((NumberSections * (SlotCovers + BarePole + EndFlanges + EndLugs + TL + Tee + GasStop) + Feedline * numberFeedLines + PowerDividerArea)) / 144;
                  //Calculate the shear force
                  RDir2 = Round(G * A * Q, 0);
                  if (A > Amax) {
                      Amax = A;
                  };
                  RMax = Round(G * Amax * Q, 0);
                  // Amax = Round(Amax, 2);

                  //   console.log('SlotCover Dir 2: ', {
                  //     NumberSections: NumberSections,
                  //     SlotCovers: SlotCovers,
                  //     BarePole: BarePole,
                  //     EndFlanges: EndFlanges,
                  //     EndLugs: EndLugs,
                  //     Ice: Ice,
                  //     TL: TL,
                  //     Feedline: Feedline,
                  //     numberFeedLines: numberFeedLines,
                  //     PowerDividerArea: PowerDividerArea,
                  //     Adir1: Adir1,
                  //     RDir1: RDir1,
                  //     GasStop: GasStop,
                  //     Tee: Tee,
                  //     Lug: Lug,
                  //     Adir2: Adir2, 
                  //     Amax: Amax,
                  //     G: G,
                  //     A: A,
                  //     Q: Q,
                  //     RDir2: RDir2
                  //     });
                  //   Amax = Round(Amax, 2);
                  
                  // 'Weight
                  SlotCoverWeight = 3 * (L - 7) * (0.03);
                  PoleWeight = Pi / 4 * 0.1 * (Math.pow(Dpipe, 2) - Math.pow((Dpipe - 2 * twall), 2)) * ((NLayers + 1) * lambda - 0.5);
                  
                  PN48959 = 2 * 0.65; // 'End Flange
                  PN75553 = 1.85; // 'Spacer Flange
                  PN75554 = 0.62; // ' Tee Flange
                  PN75730 = 0.6; // 'Vertical Lug
                  PN75483 = 2 * 3.66; // 'End Short
                  PN75539 = 2 * 1.75; // 'Bolt On Lug
                  Inner = Pi / 4 * ( Math.pow(1.315, 2) -  Math.pow(1.23, 2)) * L * 0.323 + 5;
                  Misc = 5;
                  GasStopWeight = 10;
                  TLWeight = 0.23 * (22 + 60);
                  Elbow = 12; // 'Catalog Value
                  FeedlineWeight = 300 * 0.148; // 'Andrew Catalog
                  // no FinWeight for patternB per James Butts
                  Weight = (SlotCoverWeight + PoleWeight + PN48959 + PN75553 + PN75554 + PN75730 + PN75539 + PN75483 + Inner + Misc + GasStopWeight + TLWeight + Elbow) * NumberSections + FeedlineWeight * numberFeedLines + PowerDividerWeight;
                  Weight = Round(Weight, 3);
          
                  
                  // 'Ice Weight
                  SlotCoverIce = (L - S - 7 + 2 * Ice) * (0.032) * (0.5 * (3.04) * (Math.pow((1.35 + Ice), 2) - Math.pow((1.35), 2)) + 0.5 * (4.66) * (Math.pow((1.75 + Ice), 2) - Math.pow((1.75), 2)));
                  BarePoleIce = Pi / 4 * (0.032) * (Math.pow((Dpipe + 2 * Ice), 2) - Math.pow(Dpipe, 2)) * (2 + S - 4 * Ice);
                  EndFlangeIce = Pi / 4 * (0.032) * (Math.pow((5.25 + 2 * Ice), 2) - Math.pow((5.25), 2)) * (2 + 2 * Ice);
                  TeeIce = Pi / 4 * (0.032) * (Math.pow((3.5 + 2 * Ice), 2) - Math.pow((3.5), 2)) * (3.84 - Ice);
                  LugIce = ((3.75 + Ice) * (4.1 + Ice) - 0.5 * Math.pow((2.34 + Ice), 2)) * 2 * Ice * (0.032);
                  EndLugIce = (Pi / 16 * (Math.pow((5.25 + 2 * Ice), 2) - Math.pow((5.25), 2)) + (5.25 + 2 * Ice) * (7.5 + Ice) - (1.375 + Ice) * (7.5 + Ice)) * 2 * Ice * (0.032);
                  GasStopIce = Pi / 4 * (0.032) * (Math.pow((5.19 + 2 * Ice), 2) - Math.pow((5.19), 2)) * (2 * 0.5 + 1.375 + 2 * Ice);
                  TLIce = Pi / 4 * (0.032) * (Math.pow((3.125 + 2 * Ice), 2) - Math.pow((3.125), 2)) * (22 + 15 + 60);
                  FeedLineIce = Pi / 4 * (0.032) * (Math.pow((3.01 + 2 * Ice), 2) - Math.pow((3.01), 2)) * 300;
                  IceWeight = (SlotCoverIce + BarePoleIce + EndFlangeIce + TeeIce + LugIce + EndLugIce + GasStopIce + TLIce + FeedLineIce) * NumberSections + PowerDividerIceWeight;
                  //Wice = Round(IceWeight, 0)
                  
                  // 'Total Weight
                  TotalWeight = Weight + IceWeight;
                  TotalWeight = Round(TotalWeight, 3);
                  // console.log({area: {
                  //     NumberSections: NumberSections,
                  //     SlotCovers: SlotCovers,
                  //     BarePole: BarePole,
                  //     EndFlanges: EndFlanges,
                  //     EndLugs: EndLugs,
                  //     TL:TL,
                  //     Tee: Tee,
                  //     GasStop: GasStop,
                  //     Feedline: Feedline,
                  //     numberFeedLines: numberFeedLines,
                  //     PowerDividerArea: PowerDividerArea
                  //   },
                  //   weight: {
                  //     IceWeight: IceWeight,
                  //     SlotCoverWeight: SlotCoverWeight,
                  //     Inner: Inner,
                  //     Misc: Misc,
                  //     GasStopWeight: GasStopWeight,
                  //     PoleWeight: PoleWeight,
                  //     TLWeight: TLWeight,
                  //     Elbow: Elbow,
                  //     NumberSections: NumberSections,
                  //     FeedlineWeight: FeedlineWeight,
                  //     numberFeedLines: numberFeedLines,
                  //     PowerDividerWeight: PowerDividerWeight
                  //   }
                  // });
              }
              else if (optRadome == true) {
                  // 'Area Direction 1
                  Radome = (Lradome + 4 * Ice) * (Dradome + 2 * Ice) * AppurtDrag(WSpec, (Dradome + 2 * Ice), (Lradome + 4 * Ice) / 12, z, V, Y, ExpCat, StrCl, Topo, IncludeIce);
                  BarePole = (L - Lradome - 4 * Ice) * (Dpipe + 2 * Ice) * AppurtDrag(WSpec, (Dpipe + 2 * Ice), (2 + S - 4 * Ice) / 12, z, V, Y, ExpCat, StrCl, Topo, IncludeIce);
                  EndFlanges = (2 + 2 * Ice) * (5.25 + 2 * Ice) * AppurtDrag(WSpec, 5.25 + 2 * Ice, (2 + 2 * Ice) / 12, z, V, Y, ExpCat, StrCl, Topo, IncludeIce);
                  EndLugs = 2 * (5.25 + 2 * Ice) * (0.5 + 2 * Ice) * AppurtDrag(WSpec, 5.25 + 2 * Ice, (0.5 + 2 * Ice) / 12, z, V, Y, ExpCat, StrCl, Topo, IncludeIce);
                  TL = ((60 + 9) * (3.125 + 2 * Ice) + 4 * (0.5 + 2 * Ice) * (5.19 + 2 * Ice)) * AppurtDrag(WSpec, 3.125 + 2 * Ice, (60 + 9) / 12, z, V, Y, ExpCat, StrCl, Topo, IncludeIce);
                  Feedline = (300) * (3.01 + 2 * Ice) * AppurtDrag(WSpec, 3.01 + 2 * Ice, 300 / 12, z, V, Y, ExpCat, StrCl, Topo, IncludeIce);
                  Adir1 = Round(((NumberSections * (Radome + BarePole + EndFlanges + EndLugs + TL) + Feedline * numberFeedLines + PowerDividerArea)) / 144, 2);
                  Amax = ((NumberSections * (Radome + BarePole + EndFlanges + EndLugs + TL) + Feedline * numberFeedLines + PowerDividerArea)) / 144;
                  RDir1 = Round(G * Amax * Q, 0);
                  
                  // 'Area Direction 2
                  Radome = (Lradome + 4 * Ice) * (Dradome + 2 * Ice) * AppurtDrag(WSpec, (Dradome + 2 * Ice), (Lradome + 4 * Ice) / 12, z, V, Y, ExpCat, StrCl, Topo, IncludeIce);
                  BarePole = (L - Lradome - 4 * Ice) * (Dpipe + 2 * Ice) * AppurtDrag(WSpec, (Dpipe + 2 * Ice), (L - Lradome - 4 * Ice) / 12, z, V, Y, ExpCat, StrCl, Topo, IncludeIce);
                  EndFlanges = (2 + 2 * Ice) * (5.25 + 2 * Ice) * AppurtDrag(WSpec, 5.25 + 2 * Ice, (2 + 2 * Ice) / 12, z, V, Y, ExpCat, StrCl, Topo, IncludeIce);
                  Tee = (3.84 - Ice) * (3.5 + 2 * Ice) * AppurtDrag(WSpec, 3.5 + 2 * Ice, (3.84 - Ice) / 12, z, V, Y, ExpCat, StrCl, Topo, IncludeIce);
                  Lug = (((3.75 + Ice) * (4.1 + Ice)) - 0.5 *  Math.pow(2.35 + Ice, 2)) * FlatAppurtDrag(WSpec, 3.75 + Ice, (4.1 + Ice) / 12, z, V, ExpCat, StrCl, Topo);
                  EndLugs = 2 * (10.125 + 2 * Ice) * (0.5 + 2 * Ice) * FlatAppurtDrag(WSpec, 10.125 + 2 * Ice, (0.5 + 2 * Ice) / 12, z, V, ExpCat, StrCl, Topo);
                  GasStop = (5.19 + 2 * Ice) * (2 * 0.5 + 1.375 + Ice) * AppurtDrag(WSpec, 5.19 + 2 * Ice, (2 * 0.5 + 1.375 + Ice) / 12, z, V, Y, ExpCat, StrCl, Topo, IncludeIce);
                  TL = ((22 + 15 + 60) * (3.125 + 2 * Ice) + 6 * (0.5 + 2 * Ice) * (5.19 + 2 * Ice)) * AppurtDrag(WSpec, 3.125 + 2 * Ice, (22 + 15 + 60) / 12, z, V, Y, ExpCat, StrCl, Topo, IncludeIce);
                  Feedline = (300) * (3.01 + 2 * Ice) * AppurtDrag(WSpec, 3.01 + 2 * Ice, 300 / 12, z, V, Y, ExpCat, StrCl, Topo, IncludeIce);
                  Adir2 = Round(((NumberSections * (Radome + BarePole + EndFlanges + EndLugs + TL + Tee + GasStop) + Feedline * numberFeedLines + PowerDividerArea)) / 144, 2);
                  A = ((NumberSections * (Radome + BarePole + EndFlanges + EndLugs + TL + Tee + GasStop) + Feedline * numberFeedLines + PowerDividerArea)) / 144;
                  // 'Calculate the shear force
                  RDir2 = Round(G * A * Q, 0);
                  if (A > Amax) {
                      Amax = A;
                  };
                  RMax = Round(G * Amax * Q, 0);
                  Amax = Round(Amax, 2);
                  
                  // 'Weight
                  EndSeals = Pi * (Math.pow((Dradome), 2) - Math.pow(5, 2)) * 1 * 0.034;
                  RadomeWeight = 0.1544 * (Dradome) * (L - S - 7) / 12;
                  PoleWeight = Pi / 4 * 0.1 * (Math.pow(Dpipe, 2) - Math.pow((Dpipe - 2 * twall), 2)) * ((NLayers + 1) * lambda - 0.5);
                  PN48959 = 2 * 0.65; // 'End Flange
                  PN75553 = 1.85; // 'Spacer Flange
                  PN75554 = 0.62; // ' Tee Flange
                  PN75730 = 0.6; // 'Vertical Lug
                  PN75483 = 2 * 3.66; // 'End Short
                  PN75539 = 2 * 1.75; // 'Bolt On Lug
                  Inner = Pi / 4 * ( Math.pow(1.315, 2) -  Math.pow(1.23, 2)) * L * 0.323 + 5;
                  Misc = 5;
                  GasStopWeight = 10;
                  TLWeight = 0.23 * (22 + 60);
                  Elbow = 12; // 'Catalog Value
                  FeedlineWeight = 300 * 0.148; // 'Andrew Catalog
                  Weight = (RadomeWeight + EndSeals + PoleWeight + PN48959 + PN75553 + PN75554 + PN75730 + PN75539 + PN75483 + Inner + Misc + GasStopWeight + TLWeight + Elbow) * NumberSections + FeedlineWeight * numberFeedLines + PowerDividerWeight;
                  Weight = Round(Weight, 3);
          
                  
                  // 'Ice Weight
                  RadomeIce = Pi / 4 * (0.032) * (Math.pow((Dradome + 2 * Ice), 2) - Math.pow(Dradome, 2)) * (L - S - 7 - 2 * Ice);
                  BarePoleIce = Pi / 4 * (0.032) * (Math.pow((Dpipe + 2 * Ice), 2) - Math.pow(Dpipe, 2)) * (2 + S - 4 * Ice);
                  EndFlangeIce = Pi / 4 * (0.032) * (Math.pow((5.25 + 2 * Ice), 2) - Math.pow((5.25), 2)) * (2 + 2 * Ice);
                  TeeIce = Pi / 4 * (0.032) * (Math.pow((3.5 + 2 * Ice), 2) - Math.pow((3.5), 2)) * (3.84 - Ice);
                  LugIce = ((3.75 + Ice) * (4.1 + Ice) - 0.5 * Math.pow((2.34 + Ice), 2)) * 2 * Ice * (0.032);
                  EndLugIce = (Pi / 16 * (Math.pow((5.25 + 2 * Ice), 2) - Math.pow((5.25), 2)) + (5.25 + 2 * Ice) * (7.5 + Ice) - (1.375 + Ice) * (7.5 + Ice)) * 2 * Ice * (0.032);
                  GasStopIce = Pi / 4 * (0.032) * (Math.pow((5.19 + 2 * Ice), 2) - Math.pow((5.19), 2)) * (2 * 0.5 + 1.375 + 2 * Ice);
                  TLIce = Pi / 4 * (0.032) * (Math.pow((3.125 + 2 * Ice), 2) - Math.pow((3.125), 2)) * (22 + 15 + 60);
                  FeedLineIce = Pi / 4 * (0.032) * (Math.pow((3.01 + 2 * Ice), 2) - Math.pow((3.01), 2)) * 300;
                  IceWeight = (RadomeIce + BarePoleIce + EndFlangeIce + TeeIce + LugIce + EndLugIce + GasStopIce + TLIce + FeedLineIce) * NumberSections + PowerDividerIceWeight;
                  Wice = Round(IceWeight, 3);
          
                  // 'Total Weight
                  TotalWeight = Weight + IceWeight;
                  TotalWeight = Round(TotalWeight, 3);
              }

            },
            patternC: function() { 
              if (optSlotCover == true) {
          
                  Lfin = 0.8 * lambda + 1;
                  Lunfinned = L - Lfinned;
                  FinWidth = (2 * (0.1 * lambda) * Math.cos(5 * Pi / 180) + 3.5);

                  // 'Area Wind Direction1
                  FlatFinnedArea = (FinWidth + 2 * Ice) * NLayers * (Lfin + 2 * Ice) * FlatAppurtDrag(WSpec, FinWidth + 2 * Ice, NLayers * (Lfin + 2 * Ice) / 12, z, V, ExpCat, StrCl, Topo);
                  SlotCovers = (Dpipe + 2 * Ice) * (L - NLayers * (Lfin + 2 * Ice)) * FlatAppurtDrag(WSpec, Dpipe + 2 * Ice, (L - NLayers * (Lfin + 2 * Ice)) / 12, z, V, ExpCat, StrCl, Topo);
                  EndFlanges = (2 + 2 * Ice) * (5.25 + 2 * Ice) * AppurtDrag(WSpec, 5.25 + 2 * Ice, (2 + 2 * Ice) / 12, z, V, Y, ExpCat, StrCl, Topo, IncludeIce);
                  EndLugs = 2 * (5.25 + 2 * Ice) * (0.5 + 2 * Ice) * AppurtDrag(WSpec, 5.25 + 2 * Ice, (0.5 + 2 * Ice) / 12, z, V, Y, ExpCat, StrCl, Topo, IncludeIce);
                  TL = ((9 + 60) * (3.125 + 2 * Ice) + 4 * (0.5 + 2 * Ice) * (5.19 + 2 * Ice)) * AppurtDrag(WSpec, 3.125 + 2 * Ice, (60 + 9) / 12, z, V, Y, ExpCat, StrCl, Topo, IncludeIce);
                  Feedline = (300) * (3.01 + 2 * Ice) * AppurtDrag(WSpec, 3.01 + 2 * Ice, 300 / 12, z, V, Y, ExpCat, StrCl, Topo, IncludeIce);
                  Amax = ((NumberSections * (SlotCovers + EndFlanges + EndLugs + TL) + Feedline * numberFeedLines + PowerDividerArea)) / 144;
                  Adir1 = Amax;
                  RDir1 = (G * Amax * Q);

                  // 'Area Wind Direction2
                  SCheight = 5.375;
                  SlotCovers = (SCheight + 2 * Ice) * (L - 5 - 2 * Ice) * FlatAppurtDrag(WSpec, SCheight + 2 * Ice, (L - 2.25 - 2 * Ice) / 12, z, V, ExpCat, StrCl, Topo);
                  EndFlanges = (2 + 2 * Ice) * (5.25 + 2 * Ice) * AppurtDrag(WSpec, 5.25 + 2 * Ice, (2 + 2 * Ice) / 12, z, V, Y, ExpCat, StrCl, Topo, IncludeIce);
                  Tee = (3.84 - Ice) * (3.5 + 2 * Ice) * AppurtDrag(WSpec, 3.5 + 2 * Ice, (3.84 - Ice) / 12, z, V, Y, ExpCat, StrCl, Topo, IncludeIce);
                  Lug = (((3.75 + Ice) * (4.1 + Ice) - 0.5 * Math.pow((2.34 + Ice), 2))) * FlatAppurtDrag(WSpec, 3.75 + Ice, (4.1 + Ice) / 12, z, V, ExpCat, StrCl, Topo);
                  EndLugs = 2 * (10.125 + 2 * Ice) * (0.5 + 2 * Ice) * FlatAppurtDrag(WSpec, 10.125 + 2 * Ice, (0.5 + 2 * Ice) / 12, z, V, ExpCat, StrCl, Topo);
                  GasStop = (5.19 + 2 * Ice) * (2 * 0.5 + 1.375 + Ice) * AppurtDrag(WSpec, 5.19 + 2 * Ice, (2 * 0.5 + 1.375 + Ice) / 12, z, V, Y, ExpCat, StrCl, Topo, IncludeIce);
                  TL = ((22 + 15 + 60) * (3.125 + 2 * Ice) + 6 * (0.5 + 2 * Ice) * (5.19 + 2 * Ice)) * AppurtDrag(WSpec, 3.125 + 2 * Ice, (22 + 15 + 60) / 12, z, V, Y, ExpCat, StrCl, Topo, IncludeIce);
                  Feedline = (300) * (3.01 + 2 * Ice) * AppurtDrag(WSpec, 3.01 + 2 * Ice, 300 / 12, z, V, Y, ExpCat, StrCl, Topo, IncludeIce);
                  Adir2 = Round(((NumberSections * (SlotCovers + BarePole + EndFlanges + EndLugs + TL + Tee + GasStop) + Feedline * numberFeedLines + PowerDividerArea)) / 144, 2);
                  A = ((NumberSections * (SlotCovers + BarePole + EndFlanges + EndLugs + TL + Tee + GasStop) + Feedline * numberFeedLines + PowerDividerArea)) / 144;
                  // 'Calculate the shear force
                  RDir2 = Round(G * A * Q, 0);
                  if (A > Amax) {
                      Amax = A;
                  }
                  RMax = Round(G * Amax * Q, 0);
                  Amax = Round(Amax, 2);
                  
                  // 'Weight
                  SlotCoverWeight = (L - 5) * (0.02);
                  PoleWeight = Pi / 4 * 0.1 * (Math.pow(Dpipe, 2) - Math.pow((Dpipe - 2 * twall), 2)) * ((NLayers + 1) * lambda - 0.5);
                  FinWeight = (2 * NLayers) * (0.1) * (0.1 * lambda) * (0.8 * lambda + 1) * 0.125;
                  PN48959 = 2 * 0.65; // 'End Flange
                  PN75553 = 1.85; // 'Spacer Flange
                  PN75554 = 0.62; // ' Tee Flange
                  PN75730 = 0.6; // 'Vertical Lug
                  PN75483 = 2 * 3.66; // 'End Short
                  PN75539 = 2 * 1.75; // 'Bolt On Lug
                  Inner = Pi / 4 * ( Math.pow(1.315, 2) -  Math.pow(1.23, 2)) * L * 0.323 + 5;
                  Misc = 5;
                  GasStopWeight = 10;
                  TLWeight = 0.23 * (22 + 60);
                  Elbow = 12; // 'Catalog Value
                  FeedlineWeight = 300 * 0.148; // 'Andrew Catalog
                  Weight = (SlotCoverWeight + PoleWeight + FinWeight + PN48959 + PN75553 + PN75554 + PN75730 + PN75539 + PN75483 + Inner + Misc + GasStopWeight + TLWeight + Elbow) * NumberSections + FeedlineWeight * numberFeedLines + PowerDividerWeight;
                  Weight = Round(Weight, 3);
                  
                  // 'Ice Weight
                  FinnedWeight = (2 * (0.1 * lambda + Ice) * 2 * Ice + (0.5 * (3.04) * (Math.pow((1.35 + Ice), 2) - (Math.pow(1.35, 2))) + 0.5 * (4.66) * (Math.pow((1.75 + Ice), 2) - Math.pow((1.75), 2)))) * NLayers * (Lfin + 2 * Ice) * 0.032;
                  SlotCoverIce = (0.5 * (3.04) * (Math.pow((1.35 + Ice), 2) - (Math.pow(1.35, 2))) + 0.5 * (4.66) * (Math.pow((1.75 + Ice), 2) - Math.pow((1.75), 2))) * (L - NLayers * (Lfin + 2 * Ice) - 2 - 2 * Ice) * (0.032);
                  BarePoleIce = Pi / 4 * (0.032) * (Math.pow((Dpipe + 2 * Ice), 2) - Math.pow(Dpipe, 2)) * (L - 6.4 * lambda - 11 - 18 * Ice);
                  EndFlangeIce = Pi / 4 * (0.032) * (Math.pow((5.25 + 2 * Ice), 2) - Math.pow((5.25), 2)) * (2 + 2 * Ice);
                  TeeIce = Pi / 4 * (0.032) * (Math.pow((3.5 + 2 * Ice), 2) - Math.pow((3.5), 2)) * (3.84 - Ice);
                  LugIce = ((3.75 + Ice) * (4.1 + Ice) - 0.5 * Math.pow((2.34 + Ice), 2)) * 2 * Ice * (0.032);
                  EndLugIce = (Pi / 16 * (Math.pow((5.25 + 2 * Ice), 2) - Math.pow((5.25), 2)) + (5.25 + 2 * Ice) * (7.5 + Ice) - (1.375 + Ice) * (7.5 + Ice)) * 2 * Ice * (0.032);
                  GasStopIce = Pi / 4 * (0.032) * (Math.pow((5.19 + 2 * Ice), 2) - Math.pow((5.19), 2)) * (2 * 0.5 + 1.375 + 2 * Ice);
                  TLIce = Pi / 4 * (0.032) * (Math.pow((3.125 + 2 * Ice), 2) - Math.pow((3.125), 2)) * (22 + 15 + 60);
                  FeedLineIce = Pi / 4 * (0.032) * (Math.pow((3.01 + 2 * Ice), 2) - Math.pow((3.01), 2)) * 300;
                  IceWeight = (SlotCoverIce + FinnedWeight + BarePoleIce + EndFlangeIce + TeeIce + LugIce + EndLugIce + GasStopIce + TLIce + FeedLineIce) * NumberSections + DSBPowerDividerIceWeight;
                  Wice = Round(IceWeight, 0);
                  //Wice = Round(IceWeight, 3);
                  
                  // 'Total Weight
                  TotalWeight = Weight + IceWeight;
                  TotalWeight = Round(TotalWeight, 3);
              }
              else if (optRadome == true) {
                  Dradome = RadDia;
                  Lradome = (L - S - 7);
                  //Area Direction 1
                  Radome = (Lradome + 2 * Ice) * (Dradome + 2 * Ice) * AppurtDrag(WSpec, (Dradome + 2 * Ice), (Lradome + 2 * Ice) / 12, z, V, Y, ExpCat, StrCl, Topo, IncludeIce);
                  BarePole = (L - Lradome - 4 * Ice) * (Dpipe + 2 * Ice) * AppurtDrag(WSpec, Dpipe + 2 * Ice, (L - Lradome - 4 * Ice) / 12, z, V, Y, ExpCat, StrCl, Topo, IncludeIce);
                  EndFlanges = (2 + 2 * Ice) * (5.25 + 2 * Ice) * AppurtDrag(WSpec, 5.25 + 2 * Ice, (2 + 2 * Ice) / 12, z, V, Y, ExpCat, StrCl, Topo, IncludeIce);
                  EndLugs = 2 * (5.25 + 2 * Ice) * (0.5 + 2 * Ice) * AppurtDrag(WSpec, 5.25 + 2 * Ice, (0.5 + 2 * Ice) / 12, z, V, Y, ExpCat, StrCl, Topo, IncludeIce);
                  TL = ((60 + 9) * (3.125 + 2 * Ice) + 4 * (0.5 + 2 * Ice) * (5.19 + 2 * Ice)) * AppurtDrag(WSpec, 3.125 + 2 * Ice, (60 + 9) / 12, z, V, Y, ExpCat, StrCl, Topo, IncludeIce);
                  Feedline = (300) * (3.01 + 2 * Ice) * AppurtDrag(WSpec, 3.01 + 2 * Ice, 300 / 12, z, V, Y, ExpCat, StrCl, Topo, IncludeIce);
                  Adir1 = Round(((NumberSections * (Radome + BarePole + EndFlanges + EndLugs + TL) + Feedline * numberFeedLines + PowerDividerArea)) / 144, 2);
                  Amax = ((NumberSections * (Radome + BarePole + EndFlanges + EndLugs + TL) + Feedline * numberFeedLines + PowerDividerArea)) / 144;
                  RDir1 = Round(G * Amax * Q, 0);

                  //Area Direction 2
                  Radome = (Lradome + 2 * Ice) * (Dradome + 2 * Ice) * AppurtDrag(WSpec, (Dradome + 2 * Ice), (Lradome + 2 * Ice) / 12, z, V, Y, ExpCat, StrCl, Topo, IncludeIce);
                  BarePole = (L - Lradome - 4 * Ice) * (Dpipe + 2 * Ice) * AppurtDrag(WSpec, Dpipe + 2 * Ice, (L - Lradome - 4 * Ice) / 12, z, V, Y, ExpCat, StrCl, Topo, IncludeIce);
                  EndFlanges = (2 + 2 * Ice) * (5.25 + 2 * Ice) * AppurtDrag(WSpec, 5.25 + 2 * Ice, (2 + 2 * Ice) / 12, z, V, Y, ExpCat, StrCl, Topo, IncludeIce);
                  Tee = (3.84 - Ice) * (3.5 + 2 * Ice) * AppurtDrag(WSpec, 3.5 + 2 * Ice, (3.84 - Ice) / 12, z, V, Y, ExpCat, StrCl, Topo, IncludeIce);
                  Lug = (((3.75 + Ice) * (4.1 + Ice)) - 0.5 * Math.pow((2.35 + Ice), 2)) * FlatAppurtDrag(WSpec, 3.75 + Ice, (4.1 + Ice) / 12, z, V, ExpCat, StrCl, Topo);
                  EndLugs = 2 * (10.125 + 2 * Ice) * (0.5 + 2 * Ice) * FlatAppurtDrag(WSpec, 10.125 + 2 * Ice, (0.5 + 2 * Ice) / 12, z, V, ExpCat, StrCl, Topo);
                  GasStop = (5.19 + 2 * Ice) * (2 * 0.5 + 1.375 + Ice) * AppurtDrag(WSpec, 5.19 + 2 * Ice, (2 * 0.5 + 1.375 + Ice) / 12, z, V, Y, ExpCat, StrCl, Topo, IncludeIce);
                  TL = ((22 + 15 + 60) * (3.125 + 2 * Ice) + 6 * (0.5 + 2 * Ice) * (5.19 + 2 * Ice)) * AppurtDrag(WSpec, 3.125 + 2 * Ice, (22 + 15 + 60) / 12, z, V, Y, ExpCat, StrCl, Topo, IncludeIce);
                  Feedline = (300) * (3.01 + 2 * Ice) * AppurtDrag(WSpec, 3.01 + 2 * Ice, 300 / 12, z, V, Y, ExpCat, StrCl, Topo, IncludeIce);
                  Adir2 = Round(((NumberSections * (Radome + BarePole + EndFlanges + EndLugs + TL + Tee + GasStop) + Feedline * numberFeedLines + PowerDividerArea)) / 144, 2);
                  A = ((NumberSections * (Radome + BarePole + EndFlanges + EndLugs + TL + Tee + GasStop) + Feedline * numberFeedLines + PowerDividerArea)) / 144;
                  //Calculate the shear force
                  RDir2 = Round(G * A * Q, 0);
                  if (A > Amax) {
                      Amax = A;
                  }
                  RMax = Round(G * Amax * Q, 0);
                  Amax = Round(Amax, 2);
                  
                  // 'Weight
                  EndSeals = Pi * (Math.pow(Dradome, 2) - Math.pow(Dpipe, 2)) * 1 * 0.034;
                  RadomeWeight = 0.1544 * Dradome * Lradome / 12;
                  PoleWeight = Pi / 4 * 0.1 * (Math.pow(Dpipe, 2) - Math.pow((Dpipe - 2 * twall), 2)) * ((NLayers + 1) * lambda - 0.5);
                  FinWeight = (2 * NLayers) * (0.1) * (0.1 * lambda) * Lfin * 0.125;
                  PN48959 = 2 * 0.65; // 'End Flange
                  PN75553 = 1.85; // 'Spacer Flange
                  PN75554 = 0.62; // ' Tee Flange
                  PN75730 = 0.6; // 'Vertical Lug
                  PN75483 = 2 * 3.66; // 'End Short
                  PN75539 = 2 * 1.75; // 'Bolt On Lug
                  Inner = Pi / 4 * ( Math.pow(1.315, 2) -  Math.pow(1.23, 2)) * L * 0.323 + 5;
                  Misc = 5;
                  GasStopWeight = 10;
                  TLWeight = 0.23 * (22 + 60);
                  Elbow = 12; // 'Catalog Value
                  FeedlineWeight = 300 * 0.148; // 'Andrew Catalog
                  Weight = (RadomeWeight + EndSeals + PoleWeight + FinWeight + PN48959 + PN75553 + PN75554 + PN75730 + PN75539 + PN75483 + Inner + Misc + GasStopWeight + TLWeight + Elbow) * NumberSections + FeedlineWeight * numberFeedLines + PowerDividerWeight;
                  //Weight = Round(Weight, 3);
          
                  
                  // 'Ice Weight
                  RadomeIce = Pi / 4 * (0.032) * (Math.pow((Dradome + 2 * Ice), 2) - Math.pow(Dradome, 2)) * (Lradome - 2 * Ice);
                  BarePoleIce = Pi / 4 * (0.032) * (Math.pow((Dpipe + 2 * Ice), 2) - Math.pow(Dpipe, 2)) * (LBarePole - 2 * Ice);
                  EndFlangeIce = Pi / 4 * (0.032) * (Math.pow((5.25 + 2 * Ice), 2) - Math.pow((5.25), 2)) * (2 + 2 * Ice);
                  TeeIce = Pi / 4 * (0.032) * (Math.pow((3.5 + 2 * Ice), 2) - Math.pow((3.5), 2)) * (3.84 - Ice);
                  LugIce = ((3.75 + Ice) * (4.1 + Ice) - 0.5 * Math.pow((2.34 + Ice), 2)) * 2 * Ice * (0.032);
                  EndLugIce = (Pi / 16 * (Math.pow((5.25 + 2 * Ice), 2) - Math.pow((5.25), 2)) + (5.25 + 2 * Ice) * (7.5 + Ice) - (1.375 + Ice) * (7.5 + Ice)) * 2 * Ice * (0.032);
                  GasStopIce = Pi / 4 * (0.032) * (Math.pow((5.19 + 2 * Ice), 2) - Math.pow((5.19), 2)) * (2 * 0.5 + 1.375 + 2 * Ice);
                  TLIce = Pi / 4 * (0.032) * (Math.pow((3.125 + 2 * Ice), 2) - Math.pow((3.125), 2)) * (22 + 15 + 60);
                  FeedLineIce = Pi / 4 * (0.032) * (Math.pow((3.01 + 2 * Ice), 2) - Math.pow((3.01), 2)) * 300;
                  IceWeight = (RadomeIce + BarePoleIce + EndFlangeIce + TeeIce + LugIce + EndLugIce + GasStopIce + TLIce + FeedLineIce) * NumberSections + PowerDividerIceWeight;
                  Wice = Round(IceWeight, 0);

                  // 'Total Weight
                  TotalWeight = Weight + IceWeight;
                  //TotalWeight = Round(TotalWeight, 0)      
            }
          },
          patternD: function() {
            FinnedWeight = (2 * (0.25 * lambda + Ice) * 2 * Ice + (0.5 * (3.04) * ( Math.pow(1.35 + Ice, 2) - ( Math.pow(1.35, 2))) + 0.5 * (4.66) * ( Math.pow(1.75 + Ice, 2) -  Math.pow(1.75, 2)))) * NLayers * (0.8 * lambda + 1 + 2 * Ice) * 0.032;
            if (optSlotCover == true) {
                  //Area Wind Direction1
                  Lfin = 0.8 * lambda + 1
                  FinWidth = (2 * (0.25 * lambda) * Math.cos(10 * Pi / 180) + 3.5);
                  FinnedArea = (FinWidth + 2 * Ice) * NLayers * (Lfin + 2 * Ice) * FlatAppurtDrag(WSpec, FinWidth + 2 * Ice, NLayers * (Lfin + 2 * Ice) / 12, z, V, ExpCat, StrCl, Topo);
                  SlotCovers = (Dpipe + 2 * Ice) * (L - NLayers * (Lfin + 2 * Ice)) * FlatAppurtDrag(WSpec, Dpipe + 2 * Ice, (L - NLayers * (Lfin + 2 * Ice)) / 12, z, V, ExpCat, StrCl, Topo);
                  EndFlanges = (2 + 2 * Ice) * (5.25 + 2 * Ice) * AppurtDrag(WSpec, 5.25 + 2 * Ice, (2 + 2 * Ice) / 12, z, V, Y, ExpCat, StrCl, Topo, IncludeIce);
                  EndLugs = 2 * (5.25 + 2 * Ice) * (0.5 + 2 * Ice) * AppurtDrag(WSpec, 5.25 + 2 * Ice, (0.5 + 2 * Ice) / 12, z, V, Y, ExpCat, StrCl, Topo, IncludeIce);
                  TL = ((9 + 60) * (3.125 + 2 * Ice) + 4 * (0.5 + 2 * Ice) * (5.19 + 2 * Ice)) * AppurtDrag(WSpec, 3.125 + 2 * Ice, (60 + 9) / 12, z, V, Y, ExpCat, StrCl, Topo, IncludeIce);
                  Feedline = (300) * (3.01 + 2 * Ice) * AppurtDrag(WSpec, 3.01 + 2 * Ice, 300 / 12, z, V, Y, ExpCat, StrCl, Topo, IncludeIce);
                  Adir1 = Round(((NumberSections * (SlotCovers + EndFlanges + EndLugs + TL) + Feedline * numberFeedLines + PowerDividerArea)) / 144, 2);
                  Amax = ((NumberSections * (SlotCovers + EndFlanges + EndLugs + TL) + Feedline * numberFeedLines + PowerDividerArea)) / 144;
                  RDir1 = Round(G * Amax * Q, 0)

                  //Area Wind Direction2
                  SlotCovers = (5.375 + 2 * Ice) * (L - 2.25 - 2 * Ice) * FlatAppurtDrag(WSpec, 5.375 + 2 * Ice, (L - 2.25 - 2 * Ice) / 12, z, V, ExpCat, StrCl, Topo);
                  EndFlanges = (2 + 2 * Ice) * (5.25 + 2 * Ice) * AppurtDrag(WSpec, 5.25 + 2 * Ice, (2 + 2 * Ice) / 12, z, V, Y, ExpCat, StrCl, Topo, IncludeIce);
                  Tee = (3.84 - Ice) * (3.5 + 2 * Ice) * AppurtDrag(WSpec, 3.5 + 2 * Ice, (3.84 - Ice) / 12, z, V, Y, ExpCat, StrCl, Topo, IncludeIce);
                  Lug = (((3.75 + Ice) * (4.1 + Ice) - 0.5 * Math.pow((2.34 + Ice), 2))) * FlatAppurtDrag(WSpec, 3.75 + Ice, (4.1 + Ice) / 12, z, V, ExpCat, StrCl, Topo);
                  EndLugs = 2 * (10.125 + 2 * Ice) * (0.5 + 2 * Ice) * FlatAppurtDrag(WSpec, 10.125 + 2 * Ice, (0.5 + 2 * Ice) / 12, z, V, ExpCat, StrCl, Topo);
                  GasStop = (5.19 + 2 * Ice) * (2 * 0.5 + 1.375 + Ice) * AppurtDrag(WSpec, 5.19 + 2 * Ice, (2 * 0.5 + 1.375 + Ice) / 12, z, V, Y, ExpCat, StrCl, Topo, IncludeIce);
                  TL = ((22 + 15 + 60) * (3.125 + 2 * Ice) + 6 * (0.5 + 2 * Ice) * (5.19 + 2 * Ice)) * AppurtDrag(WSpec, 3.125 + 2 * Ice, (22 + 15 + 60) / 12, z, V, Y, ExpCat, StrCl, Topo, IncludeIce);
                  Feedline = (300) * (3.01 + 2 * Ice) * AppurtDrag(WSpec, 3.01 + 2 * Ice, 300 / 12, z, V, Y, ExpCat, StrCl, Topo, IncludeIce);
                  Adir2 = Round(((NumberSections * (SlotCovers + BarePole + EndFlanges + EndLugs + TL + Tee + GasStop) + Feedline * numberFeedLines + PowerDividerArea)) / 144, 2);
                  A = ((NumberSections * (SlotCovers + BarePole + EndFlanges + EndLugs + TL + Tee + GasStop) + Feedline * numberFeedLines + PowerDividerArea)) / 144;
                  //Calculate the shear force
                  RDir2 = Round(G * A * Q, 0);
                  if (A > Amax) {
                      Amax = A;
                  }
                  RMax = Round(G * Amax * Q, 0);
                  Amax = Round(Amax, 2);
                  
                  // 'Weight
                  SlotCoverWeight = (L - 2.25) * (0.02);
                  PoleWeight = Pi / 4 * 0.1 * (Math.pow(Dpipe, 2) - Math.pow((Dpipe - 2 * twall), 2)) * L;
                  FinWeight = (2 * NLayers) * (0.1) * (0.25 * lambda) * Lfin * 0.125;
                  PN48959 = 2 * 0.65; // 'End Flange
                  PN75553 = 1.85; // 'Spacer Flange
                  PN75554 = 0.62; // ' Tee Flange
                  PN75730 = 0.6; // 'Vertical Lug
                  PN75483 = 2 * 3.66; // 'End Short
                  PN75539 = 2 * 1.75; // 'Bolt On Lug
                  Inner = Pi / 4 * ( Math.pow(1.315, 2) -  Math.pow(1.23, 2)) * L * 0.323 + 5;
                  Misc = 5;
                  GasStopWeight = 10;
                  TLWeight = 0.23 * (22 + 60);
                  Elbow = 12; // 'Catalog Value
                  FeedlineWeight = 300 * 0.148; // 'Andrew Catalog
                  Weight = (SlotCoverWeight + PoleWeight + FinWeight + PN48959 + PN75553 + PN75554 + PN75730 + PN75539 + PN75483 + Inner + Misc + GasStopWeight + TLWeight + Elbow) * NumberSections + FeedlineWeight * numberFeedLines + PowerDividerWeight;
                  Weight = Round(Weight, 3);
                  
                  // 'Ice Weight
                  FinnedWeight = (2 * (0.25 * lambda + Ice) * 2 * Ice + (0.5 * (3.04) * (Math.pow((1.35 + Ice), 2) - (Math.pow(1.35, 2))) + 0.5 * (4.66) * (Math.pow((1.75 + Ice), 2) - Math.pow((1.75), 2)))) * NLayers * (Lfin + 2 * Ice) * 0.032;
                  SlotCoverIce = (0.5 * (3.04) * (Math.pow((1.35 + Ice), 2) - (Math.pow(1.35, 2))) + 0.5 * (4.66) * (Math.pow((1.75 + Ice), 2) - Math.pow((1.75), 2))) * (L - 8 * (0.8 * lambda + 1 + 2 * Ice) - 2 - 2 * Ice) * (0.032);
                  BarePoleIce = Pi / 4 * (0.032) * (Math.pow((Dpipe + 2 * Ice), 2) - Math.pow(Dpipe, 2)) * (S + 2.25);
                  EndFlangeIce = Pi / 4 * (0.032) * (Math.pow((5.25 + 2 * Ice), 2) - Math.pow((5.25), 2)) * (2 + 2 * Ice);
                  TeeIce = Pi / 4 * (0.032) * (Math.pow((3.5 + 2 * Ice), 2) - Math.pow((3.5), 2)) * (3.84 - Ice);
                  LugIce = ((3.75 + Ice) * (4.1 + Ice) - 0.5 * Math.pow((2.34 + Ice), 2)) * 2 * Ice * (0.032);
                  EndLugIce = (Pi / 16 * (Math.pow((5.25 + 2 * Ice), 2) - Math.pow((5.25), 2)) + (5.25 + 2 * Ice) * (7.5 + Ice) - (1.375 + Ice) * (7.5 + Ice)) * 2 * Ice * (0.032);
                  GasStopIce = Pi / 4 * (0.032) * (Math.pow((5.19 + 2 * Ice), 2) - Math.pow((5.19), 2)) * (2 * 0.5 + 1.375 + 2 * Ice);
                  TLIce = Pi / 4 * (0.032) * (Math.pow((3.125 + 2 * Ice), 2) - Math.pow((3.125), 2)) * (22 + 15 + 60);
                  FeedLineIce = Pi / 4 * (0.032) * (Math.pow((3.01 + 2 * Ice), 2) - Math.pow((3.01), 2)) * 300;
                  IceWeight = (SlotCoverIce + FinnedWeight + BarePoleIce + EndFlangeIce + TeeIce + LugIce + EndLugIce + GasStopIce + TLIce + FeedLineIce) * NumberSections + DSBPowerDividerIceWeight;
                  Wice = Round(IceWeight, 0);
                  
                  // 'Total Weight
                  TotalWeight = Weight + IceWeight;
                  TotalWeight = Round(TotalWeight, 3);

            } else if (optRadome == true) {
                  // 'Area Direction 1
                  Dradome = RadDia;
                  Lradome = (L - S - 7);
                  Radome = (Lradome + 2 * Ice) * (Dradome + 2 * Ice) * AppurtDrag(WSpec, Dradome + 2 * Ice, (Lradome + 2 * Ice) / 12, z, V, Y, ExpCat, StrCl, Topo, IncludeIce);
                  BarePole = (L - Lradome - 4 * Ice) * (Dpipe + 2 * Ice) * AppurtDrag(WSpec, Dpipe + 2 * Ice, (L - Lradome - 4 * Ice) / 12, z, V, Y, ExpCat, StrCl, Topo, IncludeIce);
                  EndFlanges = (2 + 2 * Ice) * (5.25 + 2 * Ice) * AppurtDrag(WSpec, 5.25 + 2 * Ice, (2 + 2 * Ice) / 12, z, V, Y, ExpCat, StrCl, Topo, IncludeIce);
                  EndLugs = 2 * (5.25 + 2 * Ice) * (0.5 + 2 * Ice) * AppurtDrag(WSpec, 5.25 + 2 * Ice, (0.5 + 2 * Ice) / 12, z, V, Y, ExpCat, StrCl, Topo, IncludeIce);
                  TL = ((60 + 9) * (3.125 + 2 * Ice) + 4 * (0.5 + 2 * Ice) * (5.19 + 2 * Ice)) * AppurtDrag(WSpec, 3.125 + 2 * Ice, (60 + 9) / 12, z, V, Y, ExpCat, StrCl, Topo, IncludeIce);
                  Feedline = (300) * (3.01 + 2 * Ice) * AppurtDrag(WSpec, 3.01 + 2 * Ice, 300 / 12, z, V, Y, ExpCat, StrCl, Topo, IncludeIce);
                  Adir1 = Round(((NumberSections * (Radome + BarePole + EndFlanges + EndLugs + TL) + Feedline * numberFeedLines + PowerDividerArea)) / 144, 2);
                  Amax = ((NumberSections * (Radome + BarePole + EndFlanges + EndLugs + TL) + Feedline * numberFeedLines + PowerDividerArea)) / 144;
                  RDir1 = Round(G * Amax * Q, 0);

                  // 'Area Direction 2
                  Radome = (Lradome + 2 * Ice) * (Dradome + 2 * Ice) * AppurtDrag(WSpec, Dradome + 2 * Ice, (Lradome + 2 * Ice) / 12, z, V, Y, ExpCat, StrCl, Topo, IncludeIce);
                  BarePole = (L - Lradome - 4 * Ice) * (Dpipe + 2 * Ice) * AppurtDrag(WSpec, Dpipe + 2 * Ice, (L - Lradome - 4 * Ice) / 12, z, V, Y, ExpCat, StrCl, Topo, IncludeIce);
                  EndFlanges = (2 + 2 * Ice) * (5.25 + 2 * Ice) * AppurtDrag(WSpec, 5.25 + 2 * Ice, (2 + 2 * Ice) / 12, z, V, Y, ExpCat, StrCl, Topo, IncludeIce);
                  Tee = (3.84 - Ice) * (3.5 + 2 * Ice) * AppurtDrag(WSpec, 3.5 + 2 * Ice, (3.84 - Ice) / 12, z, V, Y, ExpCat, StrCl, Topo, IncludeIce);
                  Lug = (((3.75 + Ice) * (4.1 + Ice)) - 0.5 * Math.pow((2.35 + Ice), 2)) * FlatAppurtDrag(WSpec, 3.75 + Ice, (4.1 + Ice) / 12, z, V, ExpCat, StrCl, Topo);
                  EndLugs = 2 * (10.125 + 2 * Ice) * (0.5 + 2 * Ice) * FlatAppurtDrag(WSpec, 10.125 + 2 * Ice, (0.5 + 2 * Ice) / 12, z, V, ExpCat, StrCl, Topo);
                  GasStop = (5.19 + 2 * Ice) * (2 * 0.5 + 1.375 + Ice) * AppurtDrag(WSpec, 5.19 + 2 * Ice, (2 * 0.5 + 1.375 + Ice) / 12, z, V, Y, ExpCat, StrCl, Topo, IncludeIce);
                  TL = ((22 + 15 + 60) * (3.125 + 2 * Ice) + 6 * (0.5 + 2 * Ice) * (5.19 + 2 * Ice)) * AppurtDrag(WSpec, 3.125 + 2 * Ice, (22 + 15 + 60) / 12, z, V, Y, ExpCat, StrCl, Topo, IncludeIce);
                  Feedline = (300) * (3.01 + 2 * Ice) * AppurtDrag(WSpec, 3.01 + 2 * Ice, 300 / 12, z, V, Y, ExpCat, StrCl, Topo, IncludeIce);
                  Adir2 = Round(((NumberSections * (Radome + BarePole + EndFlanges + EndLugs + TL + Tee + GasStop) + Feedline * numberFeedLines + PowerDividerArea)) / 144, 2);
                  A = ((NumberSections * (Radome + BarePole + EndFlanges + EndLugs + TL + Tee + GasStop) + Feedline * numberFeedLines + PowerDividerArea)) / 144;
                  //Calculate the shear force
                  RDir2 = Round(G * A * Q, 0);
                  if (A > Amax) {
                      Amax = A;
                  }
                  RMax = Round(G * Amax * Q, 0);
                  Amax = Round(Amax, 2);
                  
                  // 'Weight
                  EndSeals = Pi * (Math.pow(Dradome, 2) - Math.pow(Dpipe, 2)) * 1 * 0.034;
                  RadomeWeight = 0.1544 * Dradome * (L - S - 2.25) / 12;
                  PoleWeight = Pi / 4 * 0.1 * (Math.pow(Dpipe, 2) - Math.pow((Dpipe - 2 * twall), 2)) * L;
                  FinWeight = (2 * NLayers) * (0.1) * (0.25 * lambda) * Lfin * 0.125;
                  PN48959 = 2 * 0.65; // 'End Flange
                  PN75553 = 1.85; // 'Spacer Flange
                  PN75554 = 0.62; // ' Tee Flange
                  PN75730 = 0.6; // 'Vertical Lug
                  PN75483 = 2 * 3.66; // 'End Short
                  PN75539 = 2 * 1.75; // 'Bolt On Lug
                  Inner = Pi / 4 * ( Math.pow(1.315, 2) -  Math.pow(1.23, 2)) * L * 0.323 + 5;
                  Misc = 5;
                  GasStopWeight = 10;
                  TLWeight = 0.23 * (22 + 60);
                  Elbow = 12; // 'Catalog Value
                  FeedlineWeight = 300 * 0.148; // 'Andrew Catalog
                  Weight = (RadomeWeight + EndSeals + PoleWeight + FinWeight + PN48959 + PN75553 + PN75554 + PN75730 + PN75539 + PN75483 + Inner + Misc + GasStopWeight + TLWeight + Elbow) * NumberSections + FeedlineWeight * numberFeedLines + PowerDividerWeight;
                  Weight = Round(Weight, 3);
          
                  
                  // 'Ice Weight
                  RadomeIce = Pi / 4 * (0.032) * (Math.pow((Dradome + 2 * Ice), 2) - Math.pow(Dradome, 2)) * (L - S - 2.25);
                  BarePoleIce = Pi / 4 * (0.032) * (Math.pow((Dpipe + 2 * Ice), 2) - Math.pow(Dpipe, 2)) * (S + 2.25 - 4 * Ice);
                  EndFlangeIce = Pi / 4 * (0.032) * (Math.pow((5.25 + 2 * Ice), 2) - Math.pow((5.25), 2)) * (2 + 2 * Ice);
                  TeeIce = Pi / 4 * (0.032) * (Math.pow((3.5 + 2 * Ice), 2) - Math.pow((3.5), 2)) * (3.84 - Ice);
                  LugIce = ((3.75 + Ice) * (4.1 + Ice) - 0.5 * Math.pow((2.34 + Ice), 2)) * 2 * Ice * (0.032);
                  EndLugIce = (Pi / 16 * (Math.pow((5.25 + 2 * Ice), 2) - Math.pow((5.25), 2)) + (5.25 + 2 * Ice) * (7.5 + Ice) - (1.375 + Ice) * (7.5 + Ice)) * 2 * Ice * (0.032);
                  GasStopIce = Pi / 4 * (0.032) * (Math.pow((5.19 + 2 * Ice), 2) - Math.pow((5.19), 2)) * (2 * 0.5 + 1.375 + 2 * Ice);
                  TLIce = Pi / 4 * (0.032) * (Math.pow((3.125 + 2 * Ice), 2) - Math.pow((3.125), 2)) * (22 + 15 + 60);
                  FeedLineIce = Pi / 4 * (0.032) * (Math.pow((3.01 + 2 * Ice), 2) - Math.pow((3.01), 2)) * 300;
                  IceWeight = (RadomeIce + BarePoleIce + EndFlangeIce + TeeIce + LugIce + EndLugIce + GasStopIce + TLIce + FeedLineIce) * NumberSections + PowerDividerIceWeight;
                  Wice = IceWeight;
          
                  // 'Total Weight
                  TotalWeight = Weight + IceWeight;
                  //TotalWeight = Round(TotalWeight, 3);
          
          
            }
      
      },
      patternE: function() {

                  if ((optSlotCover == true)) {
                    // 'Area Wind Direction1
                    Lfin = 0.8 * lambda + 1;
                    FinWidth = (2 * (0.4 * lambda) * Math.cos(10 * Pi / 180) + 3.5);
                    FinnedArea = (FinWidth + 2 * Ice) * NLayers * (Lfin + 2 * Ice) * FlatAppurtDrag(WSpec, FinWidth + 2 * Ice, NLayers * (Lfin + 2 * Ice) / 12, z, V, ExpCat, StrCl, Topo);
                    SlotCovers = (Dpipe + 2 * Ice) * (L - NLayers * (Lfin + 2 * Ice)) * FlatAppurtDrag(WSpec, Dpipe + 2 * Ice, (L - NLayers * (Lfin + 2 * Ice)) / 12, z, V, ExpCat, StrCl, Topo);
                    EndFlanges = (2 + 2 * Ice) * (5.25 + 2 * Ice) * AppurtDrag(WSpec, 5.25 + 2 * Ice, (2 + 2 * Ice) / 12, z, V, Y, ExpCat, StrCl, Topo, IncludeIce);
                    EndLugs = 2 * (5.25 + 2 * Ice) * (0.5 + 2 * Ice) * AppurtDrag(WSpec, 5.25 + 2 * Ice, (0.5 + 2 * Ice) / 12, z, V, Y, ExpCat, StrCl, Topo, IncludeIce);
                    TL = ((9 + 60) * (3.125 + 2 * Ice) + 4 * (0.5 + 2 * Ice) * (5.19 + 2 * Ice)) * AppurtDrag(WSpec, 3.125 + 2 * Ice, (60 + 9) / 12, z, V, Y, ExpCat, StrCl, Topo, IncludeIce);
                    Feedline = (300) * (3.01 + 2 * Ice) * AppurtDrag(WSpec, 3.01 + 2 * Ice, 300 / 12, z, V, Y, ExpCat, StrCl, Topo, IncludeIce);
                    Adir1 = Round(((NumberSections * (SlotCovers + EndFlanges + EndLugs + TL) + Feedline * numberFeedLines + PowerDividerArea)) / 144, 2);
                    Amax = ((NumberSections * (SlotCovers + EndFlanges + EndLugs + TL) + Feedline * numberFeedLines + PowerDividerArea)) / 144;
                    RDir1 = Round(G * Amax * Q, 0);

                    // 'Area Wind Direction2
                    SlotCovers = (5.375 + 2 * Ice) * (L - 2.25 - 2 * Ice) * FlatAppurtDrag(WSpec, 5.375 + 2 * Ice, (L - 2.25 - 2 * Ice) / 12, z, V, ExpCat, StrCl, Topo);
                    EndFlanges = (2 + 2 * Ice) * (5.25 + 2 * Ice) * AppurtDrag(WSpec, 5.25 + 2 * Ice, (2 + 2 * Ice) / 12, z, V, Y, ExpCat, StrCl, Topo, IncludeIce);
                    Tee = (3.84 - Ice) * (3.5 + 2 * Ice) * AppurtDrag(WSpec, 3.5 + 2 * Ice, (3.84 - Ice) / 12, z, V, Y, ExpCat, StrCl, Topo, IncludeIce);
                    Lug = (((3.75 + Ice) * (4.1 + Ice) - 0.5 * Math.pow((2.34 + Ice), 2))) * FlatAppurtDrag(WSpec, 3.75 + Ice, (4.1 + Ice) / 12, z, V, ExpCat, StrCl, Topo);
                    EndLugs = 2 * (10.125 + 2 * Ice) * (0.5 + 2 * Ice) * FlatAppurtDrag(WSpec, 10.125 + 2 * Ice, (0.5 + 2 * Ice) / 12, z, V, ExpCat, StrCl, Topo);
                    GasStop = (5.19 + 2 * Ice) * (2 * 0.5 + 1.375 + Ice) * AppurtDrag(WSpec, 5.19 + 2 * Ice, (2 * 0.5 + 1.375 + Ice) / 12, z, V, Y, ExpCat, StrCl, Topo, IncludeIce);
                    TL = ((22 + 15 + 60) * (3.125 + 2 * Ice) + 6 * (0.5 + 2 * Ice) * (5.19 + 2 * Ice)) * AppurtDrag(WSpec, 3.125 + 2 * Ice, (22 + 15 + 60) / 12, z, V, Y, ExpCat, StrCl, Topo, IncludeIce);
                    Feedline = (300) * (3.01 + 2 * Ice) * AppurtDrag(WSpec, 3.01 + 2 * Ice, 300 / 12, z, V, Y, ExpCat, StrCl, Topo, IncludeIce);
                    Adir2 = Round(((NumberSections * (SlotCovers + BarePole + EndFlanges + EndLugs + TL + Tee + GasStop) + Feedline * numberFeedLines + PowerDividerArea)) / 144, 2);
                    A = ((NumberSections * (SlotCovers + BarePole + EndFlanges + EndLugs + TL + Tee + GasStop) + Feedline * numberFeedLines + PowerDividerArea)) / 144;
                    // 'Calculate the shear force
                    RDir2 = Round(G * A * Q, 0);
                    if ((A > Amax)) {
                      Amax = A;
                    }
                    RMax = Round(G * Amax * Q, 0);
                    Amax = Round(Amax, 2);

                    // 'Weight
                    SlotCoverWeight = (L - 7) * (0.02);
                    PoleWeight = Pi / 4 * 0.1 * (Math.pow(Dpipe, 2) - Math.pow((Dpipe - 2 * twall), 2)) * ((NLayers + 1) * lambda - 0.5);
                    FinWeight = (2 * NLayers) * (0.1) * (0.4 * lambda) * Lfin * 0.125;
                    PN48959 = 2 * 0.65; //End Flange
                    PN75553 = 1.85; //Spacer Flange
                    PN75554 = 0.62; // Tee Flange
                    PN75730 = 0.6; //Vertical Lug
                    PN75483 = 2 * 3.66; //End Short
                    PN75539 = 2 * 1.75; //Bolt On Lug
                    Inner = Pi / 4 * (Math.pow(1.315, 2) - Math.pow(1.23, 2)) * L * 0.323 + 5;
                    Misc = 5;
                    GasStopWeight = 10;
                    TLWeight = 0.23 * (22 + 60);
                    Elbow = 12; //Catalog Value
                    FeedlineWeight = 300 * 0.148; //Andrew Catalog
                    Weight = (SlotCoverWeight + PoleWeight + FinWeight + PN48959 + PN75553 + PN75554 + PN75730 + PN75539 + PN75483 + Inner + Misc + GasStopWeight + TLWeight + Elbow) * NumberSections + FeedlineWeight * numberFeedLines + PowerDividerWeight;
                    Weight = Round(Weight, 0);

                    // 'Ice Weight
                    FinnedWeight = (2 * (0.4 * lambda + Ice) * 2 * Ice + (0.5 * (3.04) * (Math.pow((1.35 + Ice), 2) - (Math.pow(1.35, 2))) + 0.5 * (4.66) * (Math.pow((1.75 + Ice), 2) - Math.pow((1.75), 2)))) * NLayers * (Lfin + 2 * Ice) * 0.032;
                    SlotCoverIce = (0.5 * (3.04) * (Math.pow((1.35 + Ice), 2) - (Math.pow(1.35, 2))) + 0.5 * (4.66) * (Math.pow((1.75 + Ice), 2) - Math.pow((1.75), 2))) * (L - 8 * (Lfin + 2 * Ice) - 2 - 2 * Ice) * (0.032);
                    BarePoleIce = Pi / 4 * (0.032) * (Math.pow((Dpipe + 2 * Ice), 2) - Math.pow(Dpipe, 2)) * (L - 6.4 * lambda - 11 - 18 * Ice);
                    EndFlangeIce = Pi / 4 * (0.032) * (Math.pow((5.25 + 2 * Ice), 2) - Math.pow((5.25), 2)) * (2 + 2 * Ice);
                    TeeIce = Pi / 4 * (0.032) * (Math.pow((3.5 + 2 * Ice), 2) - Math.pow((3.5), 2)) * (3.84 - Ice);
                    LugIce = ((3.75 + Ice) * (4.1 + Ice) - 0.5 * Math.pow((2.34 + Ice), 2)) * 2 * Ice * (0.032);
                    EndLugIce = (Pi / 16 * (Math.pow((5.25 + 2 * Ice), 2) - Math.pow((5.25), 2)) + (5.25 + 2 * Ice) * (7.5 + Ice) - (1.375 + Ice) * (7.5 + Ice)) * 2 * Ice * (0.032);
                    GasStopIce = Pi / 4 * (0.032) * (Math.pow((5.19 + 2 * Ice), 2) - Math.pow((5.19), 2)) * (2 * 0.5 + 1.375 + 2 * Ice);
                    TLIce = Pi / 4 * (0.032) * (Math.pow((3.125 + 2 * Ice), 2) - Math.pow((3.125), 2)) * (22 + 15 + 60);
                    FeedLineIce = Pi / 4 * (0.032) * (Math.pow((3.01 + 2 * Ice), 2) - Math.pow((3.01), 2)) * 300;
                    IceWeight = (SlotCoverIce + FinnedWeight + BarePoleIce + EndFlangeIce + TeeIce + LugIce + EndLugIce + GasStopIce + TLIce + FeedLineIce) * NumberSections + DSBPowerDividerIceWeight;
                    Wice = IceWeight;

                    // 'Total Weight
                    TotalWeight = Weight + IceWeight;
                    TotalWeight = Round(TotalWeight, 0);
                  } else if ((optRadome == true)) {
                    // 'Area Direction 1
                    Dradome = RadDia;
                    Lradome = (L - S - 7);
                    Lfin = 0.8 * lambda + 1;
                    Radome = (Lradome + 2 * Ice) * (Dradome + 2 * Ice) * AppurtDrag(WSpec, (Dradome + 2 * Ice), (Lradome + 2 * Ice) / 12, z, V, Y, ExpCat, StrCl, Topo, IncludeIce);
                    BarePole = (L - Lradome - 4 * Ice) * (Dpipe + 2 * Ice) * AppurtDrag(WSpec, (Dpipe + 2 * Ice), (L - Lradome - 4 * Ice) / 12, z, V, Y, ExpCat, StrCl, Topo, IncludeIce);
                    EndFlanges = (2 + 2 * Ice) * (5.25 + 2 * Ice) * AppurtDrag(WSpec, 5.25 + 2 * Ice, (2 + 2 * Ice) / 12, z, V, Y, ExpCat, StrCl, Topo, IncludeIce);
                    EndLugs = 2 * (5.25 + 2 * Ice) * (0.5 + 2 * Ice) * AppurtDrag(WSpec, 5.25 + 2 * Ice, (0.5 + 2 * Ice) / 12, z, V, Y, ExpCat, StrCl, Topo, IncludeIce);
                    TL = ((60 + 9) * (3.125 + 2 * Ice) + 4 * (0.5 + 2 * Ice) * (5.19 + 2 * Ice)) * AppurtDrag(WSpec, 3.125 + 2 * Ice, (60 + 9) / 12, z, V, Y, ExpCat, StrCl, Topo, IncludeIce);
                    Feedline = (300) * (3.01 + 2 * Ice) * AppurtDrag(WSpec, 3.01 + 2 * Ice, 300 / 12, z, V, Y, ExpCat, StrCl, Topo, IncludeIce);
                    Adir1 = Round(((NumberSections * (Radome + BarePole + EndFlanges + EndLugs + TL) + Feedline * numberFeedLines + PowerDividerArea)) / 144, 2);
                    Amax = ((NumberSections * (Radome + BarePole + EndFlanges + EndLugs + TL) + Feedline * numberFeedLines + PowerDividerArea)) / 144;
                    RDir1 = Round(G * Amax * Q, 0);

                    // 'Area Direction 2
                    Radome = (Lradome + 2 * Ice) * (Dradome + 2 * Ice) * AppurtDrag(WSpec, (Dradome + 2 * Ice), (Lradome + 2 * Ice) / 12, z, V, Y, ExpCat, StrCl, Topo, IncludeIce);
                    BarePole = (L - Lradome - 4 * Ice) * (Dpipe + 2 * Ice) * AppurtDrag(WSpec, (Dpipe + 2 * Ice), (L - Lradome - 4 * Ice) / 12, z, V, Y, ExpCat, StrCl, Topo, IncludeIce);
                    EndFlanges = (2 + 2 * Ice) * (5.25 + 2 * Ice) * AppurtDrag(WSpec, 5.25 + 2 * Ice, (2 + 2 * Ice) / 12, z, V, Y, ExpCat, StrCl, Topo, IncludeIce);
                    Tee = (3.84 - Ice) * (3.5 + 2 * Ice) * AppurtDrag(WSpec, 3.5 + 2 * Ice, (3.84 - Ice) / 12, z, V, Y, ExpCat, StrCl, Topo, IncludeIce);
                    Lug = (((3.75 + Ice) * (4.1 + Ice)) - 0.5 * Math.pow((2.35 + Ice), 2)) * FlatAppurtDrag(WSpec, 3.75 + Ice, (4.1 + Ice) / 12, z, V, ExpCat, StrCl, Topo);
                    EndLugs = 2 * (10.125 + 2 * Ice) * (0.5 + 2 * Ice) * FlatAppurtDrag(WSpec, 10.125 + 2 * Ice, (0.5 + 2 * Ice) / 12, z, V, ExpCat, StrCl, Topo);
                    GasStop = (5.19 + 2 * Ice) * (2 * 0.5 + 1.375 + Ice) * AppurtDrag(WSpec, 5.19 + 2 * Ice, (2 * 0.5 + 1.375 + Ice) / 12, z, V, Y, ExpCat, StrCl, Topo, IncludeIce);
                    TL = ((22 + 15 + 60) * (3.125 + 2 * Ice) + 6 * (0.5 + 2 * Ice) * (5.19 + 2 * Ice)) * AppurtDrag(WSpec, 3.125 + 2 * Ice, (22 + 15 + 60) / 12, z, V, Y, ExpCat, StrCl, Topo, IncludeIce);
                    Feedline = (300) * (3.01 + 2 * Ice) * AppurtDrag(WSpec, 3.01 + 2 * Ice, 300 / 12, z, V, Y, ExpCat, StrCl, Topo, IncludeIce);
                    Adir2 = Round(((NumberSections * (Radome + BarePole + EndFlanges + EndLugs + TL + Tee + GasStop) + Feedline * numberFeedLines + PowerDividerArea)) / 144, 2);
                    A = ((NumberSections * (Radome + BarePole + EndFlanges + EndLugs + TL + Tee + GasStop) + Feedline * numberFeedLines + PowerDividerArea)) / 144;
                    // 'Calculate the shear force
                    RDir2 = Round(G * A * Q, 0);
                    if ((A > Amax)) {
                      Amax = A;
                    }
                    RMax = Round(G * Amax * Q, 0);
                    Amax = Round(Amax, 2);

                    // 'Weight
                    EndSeals = Pi * (Math.pow(Dradome, 2) - Math.pow(Dpipe, 2)) * 1 * 0.034;
                    RadomeWeight = 0.1544 * Dradome * Lradome / 12;
                    PoleWeight = Pi / 4 * 0.1 * (Math.pow(Dpipe, 2) - Math.pow((Dpipe - 2 * twall), 2)) * ((NLayers + 1) * lambda - 0.5);
                    FinWeight = (2 * NLayers) * (0.1) * (0.4 * lambda) * Lfin * 0.125;
                    PN48959 = 2 * 0.65; //End Flange
                    PN75553 = 1.85; //Spacer Flange
                    PN75554 = 0.62; // Tee Flange
                    PN75730 = 0.6; //Vertical Lug
                    PN75483 = 2 * 3.66; //End Short
                    PN75539 = 2 * 1.75; //Bolt On Lug
                    Inner = Pi / 4 * (Math.pow(1.315, 2) - Math.pow(1.23, 2)) * L * 0.323 + 5;
                    Misc = 5;
                    GasStopWeight = 10;
                    TLWeight = 0.23 * (22 + 60);
                    Elbow = 12; //Catalog Value
                    FeedlineWeight = 300 * 0.148; //Andrew Catalog
                    Weight = (RadomeWeight + EndSeals + PoleWeight + FinWeight + PN48959 + PN75553 + PN75554 + PN75730 + PN75539 + PN75483 + Inner + Misc + GasStopWeight + TLWeight + Elbow) * NumberSections + FeedlineWeight * numberFeedLines + PowerDividerWeight;
                    Weight = Round(Weight, 0);


                    // 'Ice Weight
                    RadomeIce = Pi / 4 * (0.032) * (Math.pow((Dradome + 2 * Ice), 2) - Math.pow(Dradome, 2)) * (Lradome - 2 * Ice);
                    BarePoleIce = Pi / 4 * (0.032) * (Math.pow((Dpipe + 2 * Ice), 2) - Math.pow(Dpipe, 2)) * (L - Lradome - 4 * Ice);
                    EndFlangeIce = Pi / 4 * (0.032) * (Math.pow((5.25 + 2 * Ice), 2) - Math.pow((5.25), 2)) * (2 + 2 * Ice);
                    TeeIce = Pi / 4 * (0.032) * (Math.pow((3.5 + 2 * Ice), 2) - Math.pow((3.5), 2)) * (3.84 - Ice);
                    LugIce = ((3.75 + Ice) * (4.1 + Ice) - 0.5 * Math.pow((2.34 + Ice), 2)) * 2 * Ice * (0.032);
                    EndLugIce = (Pi / 16 * (Math.pow((5.25 + 2 * Ice), 2) - Math.pow((5.25), 2)) + (5.25 + 2 * Ice) * (7.5 + Ice) - (1.375 + Ice) * (7.5 + Ice)) * 2 * Ice * (0.032);
                    GasStopIce = Pi / 4 * (0.032) * (Math.pow((5.19 + 2 * Ice), 2) - Math.pow((5.19), 2)) * (2 * 0.5 + 1.375 + 2 * Ice);
                    TLIce = Pi / 4 * (0.032) * (Math.pow((3.125 + 2 * Ice), 2) - Math.pow((3.125), 2)) * (22 + 15 + 60);
                    FeedLineIce = Pi / 4 * (0.032) * (Math.pow((3.01 + 2 * Ice), 2) - Math.pow((3.01), 2)) * 300;
                    IceWeight = (RadomeIce + BarePoleIce + EndFlangeIce + TeeIce + LugIce + EndLugIce + GasStopIce + TLIce + FeedLineIce) * NumberSections + PowerDividerIceWeight;
                    Wice = Round(IceWeight, 0)

                    // 'Total Weight
                    TotalWeight = Weight + IceWeight;
                    TotalWeight = Round(TotalWeight, 0);
                  }
                  },
                  patternF: function() {
            //constants
            Pi = Math.PI;
            if ((optSlotCover == true)) {
              // 'Area Wind Direction1
              Lfin = 0.8 * lambda + 1;
              FinWidth = 2 * (0.4 * lambda) * Math.cos(10 * Pi / 180) + Dpipe;
              FinnedArea = (FinWidth + 2 * Ice) * NLayers * (Lfin + 2 * Ice) * FlatAppurtDrag(WSpec, FinWidth + 2 * Ice, NLayers * (Lfin + 2 * Ice) / 12, z, V, ExpCat, StrCl, Topo);
              SlotCovers = (Dpipe + 2 * Ice) * (L - NLayers * (Lfin + 2 * Ice)) * FlatAppurtDrag(WSpec, Dpipe + 2 * Ice, (L - NLayers * (Lfin + 2 * Ice)) / 12, z, V, ExpCat, StrCl, Topo);
              EndFlanges = (2 + 2 * Ice) * (5.25 + 2 * Ice) * AppurtDrag(WSpec, 5.25 + 2 * Ice, (2 + 2 * Ice) / 12, z, V, Y, ExpCat, StrCl, Topo, IncludeIce);
              EndLugs = 2 * (5.25 + 2 * Ice) * (0.5 + 2 * Ice) * AppurtDrag(WSpec, 5.25 + 2 * Ice, (0.5 + 2 * Ice) / 12, z, V, Y, ExpCat, StrCl, Topo, IncludeIce);
              TL = ((9 + 60) * (3.125 + 2 * Ice) + 4 * (0.5 + 2 * Ice) * (5.19 + 2 * Ice)) * AppurtDrag(WSpec, 3.125 + 2 * Ice, (60 + 9) / 12, z, V, Y, ExpCat, StrCl, Topo, IncludeIce);
              Feedline = (300) * (3.01 + 2 * Ice) * AppurtDrag(WSpec, 3.01 + 2 * Ice, 300 / 12, z, V, Y, ExpCat, StrCl, Topo, IncludeIce);
              Adir1 = Round(((NumberSections * (FinnedArea + SlotCovers + EndFlanges + EndLugs + TL) + Feedline * numberFeedLines + PowerDividerArea)) / 144, 2);
              Amax = ((NumberSections * (FinnedArea + SlotCovers + EndFlanges + EndLugs + TL) + Feedline * numberFeedLines + PowerDividerArea)) / 144;
              RDir1 = Round(G * Amax * Q, 0);

              // 'Area Wind Direction2
              SlotCovers = (6.375 + 2 * Ice) * (L - 2.25 - 2 * Ice) * FlatAppurtDrag(WSpec, 6.375 + 2 * Ice, (L - 2.25 - 2 * Ice) / 12, z, V, ExpCat, StrCl, Topo);
              EndFlanges = (2 + 2 * Ice) * (5.25 + 2 * Ice) * AppurtDrag(WSpec, 5.25 + 2 * Ice, (2 + 2 * Ice) / 12, z, V, Y, ExpCat, StrCl, Topo, IncludeIce);
              Tee = (3.84 - Ice) * (3.5 + 2 * Ice) * AppurtDrag(WSpec, 3.5 + 2 * Ice, (3.84 - Ice) / 12, z, V, Y, ExpCat, StrCl, Topo, IncludeIce);
              Lug = (((3.75 + Ice) * (4.1 + Ice) - 0.5 * Math.pow((2.34 + Ice), 2))) * FlatAppurtDrag(WSpec, 3.75 + Ice, (4.1 + Ice) / 12, z, V, ExpCat, StrCl, Topo);
              EndLugs = 2 * (10.125 + 2 * Ice) * (0.5 + 2 * Ice) * FlatAppurtDrag(WSpec, 10.125 + 2 * Ice, (0.5 + 2 * Ice) / 12, z, V, ExpCat, StrCl, Topo);
              GasStop = (5.19 + 2 * Ice) * (2 * 0.5 + 1.375 + Ice) * AppurtDrag(WSpec, 5.19 + 2 * Ice, (2 * 0.5 + 1.375 + Ice) / 12, z, V, Y, ExpCat, StrCl, Topo, IncludeIce);
              TL = ((22 + 15 + 60) * (3.125 + 2 * Ice) + 6 * (0.5 + 2 * Ice) * (5.19 + 2 * Ice)) * AppurtDrag(WSpec, 3.125 + 2 * Ice, (22 + 15 + 60) / 12, z, V, Y, ExpCat, StrCl, Topo, IncludeIce);
              Feedline = (300) * (3.01 + 2 * Ice) * AppurtDrag(WSpec, 3.01 + 2 * Ice, 300 / 12, z, V, Y, ExpCat, StrCl, Topo, IncludeIce);
              Adir2 = Round(((NumberSections * (SlotCovers + BarePole + EndFlanges + EndLugs + TL + Tee + GasStop) + Feedline * numberFeedLines + PowerDividerArea)) / 144, 2);
              A = ((NumberSections * (SlotCovers + BarePole + EndFlanges + EndLugs + TL + Tee + GasStop) + Feedline * numberFeedLines + PowerDividerArea)) / 144;
              // 'Calculate the shear force
              RDir2 = Round(G * A * Q, 0);
              if ((A > Amax)) {
                Amax = A;
              }
              RMax = Round(G * Amax * Q, 0);
              Amax = Round(Amax, 2);

              // 'Weight
              SlotCoverWeight = (L - 7) * (0.02);
              PoleWeight = Pi / 4 * 0.1 * (Math.pow(Dpipe, 2) - Math.pow((Dpipe - 2 * twall), 2)) * ((NLayers + 1) * lambda - 0.5);
              FinWeight = (2 * NLayers) * (0.1) * (0.4 * lambda) * Lfin * 0.125;
              PatternShaperWeight = (0.5 * 1 + lambda / 2 * 0.125) * (Lfin) * NLayers * 0.1;
              PN48959 = 2 * 0.65; //End Flange
              PN75553 = 1.85; //Spacer Flange
              PN75554 = 0.62; // Tee Flange
              PN75730 = 0.6; //Vertical Lug
              PN75483 = 2 * 3.66; //End Short
              PN75539 = 2 * 1.75; //Bolt On Lug
              Inner = Pi / 4 * (Math.pow(1.315, 2) - Math.pow(1.23, 2)) * L * 0.323 + 5;
              Misc = 5;
              GasStopWeight = 10;
              TLWeight = 0.23 * (22 + 60);
              Elbow = 12; //Catalog Value
              FeedlineWeight = 300 * 0.148; //Andrew Catalog
              Weight = (SlotCoverWeight + PoleWeight + FinWeight + PatternShaperWeight + PN48959 + PN75553 + PN75554 + PN75730 + PN75539 + PN75483 + Inner + Misc + GasStopWeight + TLWeight + Elbow) * NumberSections + FeedlineWeight * numberFeedLines + PowerDividerWeight;
              Weight = Round(Weight, 0);

              //Ice Weight
              FinnedWeight = (2 * (0.4 * lambda + Ice) * 2 * Ice + (0.5 * (3.04) * (Math.pow((1.35 + Ice), 2) - (Math.pow(1.35, 2))) + 0.5 * (4.66) * (Math.pow((1.75 + Ice), 2) - Math.pow((1.75), 2)))) * NLayers * (Lfin + 2 * Ice) * 0.032;
              SlotCoverIce = (0.5 * (3.04) * (Math.pow((1.35 + Ice), 2) - (Math.pow(1.35, 2))) + 0.5 * (4.66) * (Math.pow((1.75 + Ice), 2) - Math.pow((1.75), 2))) * (L - 8 * (Lfin + 2 * Ice) - 2 - 2 * Ice) * (0.032);
              BarePoleIce = Pi / 4 * (0.032) * (Math.pow((Dpipe + 2 * Ice), 2) - Math.pow(Dpipe, 2)) * (L - 6.4 * lambda - 11 - 18 * Ice);
              EndFlangeIce = Pi / 4 * (0.032) * (Math.pow((5.25 + 2 * Ice), 2) - Math.pow((5.25), 2)) * (2 + 2 * Ice);
              TeeIce = Pi / 4 * (0.032) * (Math.pow((3.5 + 2 * Ice), 2) - Math.pow((3.5), 2)) * (3.84 - Ice);
              LugIce = ((3.75 + Ice) * (4.1 + Ice) - 0.5 * Math.pow((2.34 + Ice), 2)) * 2 * Ice * (0.032);
              EndLugIce = (Pi / 16 * (Math.pow((5.25 + 2 * Ice), 2) - Math.pow((5.25), 2)) + (5.25 + 2 * Ice) * (7.5 + Ice) - (1.375 + Ice) * (7.5 + Ice)) * 2 * Ice * (0.032);
              GasStopIce = Pi / 4 * (0.032) * (Math.pow((5.19 + 2 * Ice), 2) - Math.pow((5.19), 2)) * (2 * 0.5 + 1.375 + 2 * Ice);
              TLIce = Pi / 4 * (0.032) * (Math.pow((3.125 + 2 * Ice), 2) - Math.pow((3.125), 2)) * (22 + 15 + 60);
              FeedLineIce = Pi / 4 * (0.032) * (Math.pow((3.01 + 2 * Ice), 2) - Math.pow((3.01), 2)) * 300;
              IceWeight = (SlotCoverIce + FinnedWeight + BarePoleIce + EndFlangeIce + TeeIce + LugIce + EndLugIce + GasStopIce + TLIce + FeedLineIce) * NumberSections + DSBPowerDividerIceWeight;
              Wice = Round(IceWeight, 0);

              //Total Weight
              TotalWeight = Weight + IceWeight;
              TotalWeight = Round(TotalWeight, 0);
            } else if ((optRadome == true)) {
              //Area Direction 1
              Dradome = RadDia;
              Lradome = (L - S - 7);
              Lfin = 0.8 * lambda + 1;
              Radome = (Lradome + 2 * Ice) * (Dradome + 2 * Ice) * AppurtDrag(WSpec, (Dradome + 2 * Ice), (Lradome + 2 * Ice) / 12, z, V, Y, ExpCat, StrCl, Topo, IncludeIce);
              BarePole = (L - Lradome - 4 * Ice) * (Dpipe + 2 * Ice) * AppurtDrag(WSpec, (Dpipe + 2 * Ice), (L - Lradome - 4 * Ice) / 12, z, V, Y, ExpCat, StrCl, Topo, IncludeIce);
              EndFlanges = (2 + 2 * Ice) * (5.25 + 2 * Ice) * AppurtDrag(WSpec, 5.25 + 2 * Ice, (2 + 2 * Ice) / 12, z, V, Y, ExpCat, StrCl, Topo, IncludeIce);
              EndLugs = 2 * (5.25 + 2 * Ice) * (0.5 + 2 * Ice) * AppurtDrag(WSpec, 5.25 + 2 * Ice, (0.5 + 2 * Ice) / 12, z, V, Y, ExpCat, StrCl, Topo, IncludeIce);
              TL = ((60 + 9) * (3.125 + 2 * Ice) + 4 * (0.5 + 2 * Ice) * (5.19 + 2 * Ice)) * AppurtDrag(WSpec, 3.125 + 2 * Ice, (60 + 9) / 12, z, V, Y, ExpCat, StrCl, Topo, IncludeIce);
              Feedline = (300) * (3.01 + 2 * Ice) * AppurtDrag(WSpec, 3.01 + 2 * Ice, 300 / 12, z, V, Y, ExpCat, StrCl, Topo, IncludeIce);
              Adir1 = Round(((NumberSections * (Radome + BarePole + EndFlanges + EndLugs + TL) + Feedline * numberFeedLines + PowerDividerArea)) / 144, 2);
              Amax = ((NumberSections * (Radome + BarePole + EndFlanges + EndLugs + TL) + Feedline * numberFeedLines + PowerDividerArea)) / 144;
              RDir1 = Round(G * Amax * Q, 0);

              //Area Direction 2
              Radome = (Lradome + 2 * Ice) * (Dradome + 2 * Ice) * AppurtDrag(WSpec, (Dradome + 2 * Ice), (Lradome + 2 * Ice) / 12, z, V, Y, ExpCat, StrCl, Topo, IncludeIce);
              BarePole = (L - Lradome - 4 * Ice) * (Dpipe + 2 * Ice) * AppurtDrag(WSpec, (Dpipe + 2 * Ice), (L - Lradome - 4 * Ice) / 12, z, V, Y, ExpCat, StrCl, Topo, IncludeIce);
              EndFlanges = (2 + 2 * Ice) * (5.25 + 2 * Ice) * AppurtDrag(WSpec, 5.25 + 2 * Ice, (2 + 2 * Ice) / 12, z, V, Y, ExpCat, StrCl, Topo, IncludeIce);
              Tee = (3.84 - Ice) * (3.5 + 2 * Ice) * AppurtDrag(WSpec, 3.5 + 2 * Ice, (3.84 - Ice) / 12, z, V, Y, ExpCat, StrCl, Topo, IncludeIce);
              Lug = (((3.75 + Ice) * (4.1 + Ice)) - 0.5 * Math.pow((2.35 + Ice), 2)) * FlatAppurtDrag(WSpec, 3.75 + Ice, (4.1 + Ice) / 12, z, V, ExpCat, StrCl, Topo);
              EndLugs = 2 * (10.125 + 2 * Ice) * (0.5 + 2 * Ice) * FlatAppurtDrag(WSpec, 10.125 + 2 * Ice, (0.5 + 2 * Ice) / 12, z, V, ExpCat, StrCl, Topo);
              GasStop = (5.19 + 2 * Ice) * (2 * 0.5 + 1.375 + Ice) * AppurtDrag(WSpec, 5.19 + 2 * Ice, (2 * 0.5 + 1.375 + Ice) / 12, z, V, Y, ExpCat, StrCl, Topo, IncludeIce);
              TL = ((22 + 15 + 60) * (3.125 + 2 * Ice) + 6 * (0.5 + 2 * Ice) * (5.19 + 2 * Ice)) * AppurtDrag(WSpec, 3.125 + 2 * Ice, (22 + 15 + 60) / 12, z, V, Y, ExpCat, StrCl, Topo, IncludeIce);
              Feedline = (300) * (3.01 + 2 * Ice) * AppurtDrag(WSpec, 3.01 + 2 * Ice, 300 / 12, z, V, Y, ExpCat, StrCl, Topo, IncludeIce);
              Adir2 = Round(((NumberSections * (Radome + BarePole + EndFlanges + EndLugs + TL + Tee + GasStop) + Feedline * numberFeedLines + PowerDividerArea)) / 144, 2);
              A = ((NumberSections * (Radome + BarePole + EndFlanges + EndLugs + TL + Tee + GasStop) + Feedline * numberFeedLines + PowerDividerArea)) / 144;
              //Calculate the shear force
              RDir2 = Round(G * A * Q, 0);
              if ((A > Amax)) {
                Amax = A;
              }
              RMax = Round(G * Amax * Q, 0);
              Amax = Round(Amax, 2);

              //Weight
              EndSeals = Pi * (Math.pow(Dradome, 2) - Math.pow(Dpipe, 2)) * 1 * 0.034;
              RadomeWeight = 0.1544 * Dradome * Lradome / 12;
              PoleWeight = Pi / 4 * 0.1 * (Math.pow(Dpipe, 2) - Math.pow((Dpipe - 2 * twall), 2)) * ((NLayers + 1) * lambda - 0.5);
              FinWeight = (2 * NLayers) * (0.1) * (0.4 * lambda) * (0.8 * lambda + 1) * 0.125;
              PatternShaperWeight = (0.5 * 1 + lambda / 2 * 0.125) * (0.8 * lambda + 1) * NLayers * 0.1;
              PN48959 = 2 * 0.65; //End Flange
              PN75553 = 1.85; //Spacer Flange
              PN75554 = 0.62; // Tee Flange
              PN75730 = 0.6; //Vertical Lug
              PN75483 = 2 * 3.66; //End Short
              PN75539 = 2 * 1.75; //Bolt On Lug
              Inner = Pi / 4 * (Math.pow(1.315, 2) - Math.pow(1.23, 2)) * L * 0.323 + 5;
              Misc = 5;
              GasStopWeight = 10;
              TLWeight = 0.23 * (22 + 60);
              Elbow = 12; //Catalog Value
              FeedlineWeight = 300 * 0.148; //Andrew Catalog
              Weight = (RadomeWeight + EndSeals + PoleWeight + FinWeight + PatternShaperWeight + PN48959 + PN75553 + PN75554 + PN75730 + PN75539 + PN75483 + Inner + Misc + GasStopWeight + TLWeight + Elbow) * NumberSections + FeedlineWeight * numberFeedLines + PowerDividerWeight;
              Weight = Round(Weight, 0);


              //Ice Weight
              RadomeIce = Pi / 4 * (0.032) * (Math.pow((Dradome + 2 * Ice), 2) - Math.pow(Dradome, 2)) * (Lradome - 2 * Ice);
              BarePoleIce = Pi / 4 * (0.032) * (Math.pow((Dpipe + 2 * Ice), 2) - Math.pow(Dpipe, 2)) * (L - Lradome - 4 * Ice);
              EndFlangeIce = Pi / 4 * (0.032) * (Math.pow((5.25 + 2 * Ice), 2) - Math.pow((5.25), 2)) * (2 + 2 * Ice);
              TeeIce = Pi / 4 * (0.032) * (Math.pow((3.5 + 2 * Ice), 2) - Math.pow((3.5), 2)) * (3.84 - Ice);
              LugIce = ((3.75 + Ice) * (4.1 + Ice) - 0.5 * Math.pow((2.34 + Ice), 2)) * 2 * Ice * (0.032);
              EndLugIce = (Pi / 16 * (Math.pow((5.25 + 2 * Ice), 2) - Math.pow((5.25), 2)) + (5.25 + 2 * Ice) * (7.5 + Ice) - (1.375 + Ice) * (7.5 + Ice)) * 2 * Ice * (0.032);
              GasStopIce = Pi / 4 * (0.032) * (Math.pow((5.19 + 2 * Ice), 2) - Math.pow((5.19), 2)) * (2 * 0.5 + 1.375 + 2 * Ice);
              TLIce = Pi / 4 * (0.032) * (Math.pow((3.125 + 2 * Ice), 2) - Math.pow((3.125), 2)) * (22 + 15 + 60);
              FeedLineIce = Pi / 4 * (0.032) * (Math.pow((3.01 + 2 * Ice), 2) - Math.pow((3.01), 2)) * 300;
              IceWeight = (RadomeIce + BarePoleIce + EndFlangeIce + TeeIce + LugIce + EndLugIce + GasStopIce + TLIce + FeedLineIce) * NumberSections + PowerDividerIceWeight;
              Wice = Round(IceWeight, 0);

              //Total Weight
              TotalWeight = Weight + IceWeight;
              TotalWeight = Round(TotalWeight, 0);
            }
      },
      patternG: function() {
            //constants
            Pi = Math.PI;
            if ((optSlotCover == true)) {
              // 'Area Wind Direction1
              Lfin = 0.8 * lambda + 1;
              FinnedArea = (lambda / 2 + 2 * Ice) * NLayers * (Lfin + 2 * Ice) * FlatAppurtDrag(WSpec, lambda / 2 + 2 * Ice, NLayers * (Lfin + 2 * Ice) / 12, z, V, ExpCat, StrCl, Topo);
              SlotCovers = (Dpipe + 2 * Ice) * (L - NLayers * (Lfin + 2 * Ice)) * FlatAppurtDrag(WSpec, Dpipe + 2 * Ice, (L - NLayers * (Lfin + 2 * Ice)) / 12, z, V, ExpCat, StrCl, Topo);
              EndFlanges = (2 + 2 * Ice) * (5.25 + 2 * Ice) * AppurtDrag(WSpec, 5.25 + 2 * Ice, (2 + 2 * Ice) / 12, z, V, Y, ExpCat, StrCl, Topo, IncludeIce);
              EndLugs = 2 * (5.25 + 2 * Ice) * (0.5 + 2 * Ice) * AppurtDrag(WSpec, 5.25 + 2 * Ice, (0.5 + 2 * Ice) / 12, z, V, Y, ExpCat, StrCl, Topo, IncludeIce);
              TL = ((9 + 60) * (3.125 + 2 * Ice) + 4 * (0.5 + 2 * Ice) * (5.19 + 2 * Ice)) * AppurtDrag(WSpec, 3.125 + 2 * Ice, (60 + 9) / 12, z, V, Y, ExpCat, StrCl, Topo, IncludeIce);
              FeedLineArea = (300) * (3.01 + 2 * Ice) * AppurtDrag(WSpec, 3.01 + 2 * Ice, 300 / 12, z, V, Y, ExpCat, StrCl, Topo, IncludeIce);
              Adir1 = Round(((NumberSections * (SlotCovers + FinnedArea + EndFlanges + EndLugs + TL) + FeedLineArea * numberFeedLines + PowerDividerArea)) / 144, 2);
              Amax = ((NumberSections * (SlotCovers + FinnedArea + EndFlanges + EndLugs + TL) + FeedLineArea * numberFeedLines + PowerDividerArea)) / 144;
              RDir1 = Round(G * Amax * Q, 0);

              // 'Area Wind Direction2
              FinnedArea = (5.375 + 1.125 + 2 * Ice) * NLayers * (Lfin + 2 * Ice) * FlatAppurtDrag(WSpec, 5.375 + 1.125 + 2 * Ice, NLayers * (Lfin + 2 * Ice) / 12, z, V, ExpCat, StrCl, Topo);
              SlotCovers = (5.375 + 2 * Ice) * (L - NLayers * (Lfin + 2 * Ice)) * FlatAppurtDrag(WSpec, 5.375 + 2 * Ice, (L - NLayers * (Lfin + 2 * Ice)) / 12, z, V, ExpCat, StrCl, Topo);
              EndFlanges = (2 + 2 * Ice) * (5.25 + 2 * Ice) * AppurtDrag(WSpec, 5.25 + 2 * Ice, (2 + 2 * Ice) / 12, z, V, Y, ExpCat, StrCl, Topo, IncludeIce);
              Tee = (3.84 - Ice) * (3.5 + 2 * Ice) * AppurtDrag(WSpec, 3.5 + 2 * Ice, (3.84 - Ice) / 12, z, V, Y, ExpCat, StrCl, Topo, IncludeIce);
              Lug = (((3.75 + Ice) * (4.1 + Ice) - 0.5 * Math.pow((2.34 + Ice), 2))) * FlatAppurtDrag(WSpec, 3.75 + Ice, (4.1 + Ice) / 12, z, V, ExpCat, StrCl, Topo);
              EndLugs = 2 * (10.125 + 2 * Ice) * (0.5 + 2 * Ice) * FlatAppurtDrag(WSpec, 10.125 + 2 * Ice, (0.5 + 2 * Ice) / 12, z, V, ExpCat, StrCl, Topo);
              GasStop = (5.19 + 2 * Ice) * (2 * 0.5 + 1.375 + Ice) * AppurtDrag(WSpec, 5.19 + 2 * Ice, (2 * 0.5 + 1.375 + Ice) / 12, z, V, Y, ExpCat, StrCl, Topo, IncludeIce);
              TL = ((22 + 15 + 60) * (3.125 + 2 * Ice) + 6 * (0.5 + 2 * Ice) * (5.19 + 2 * Ice)) * AppurtDrag(WSpec, 3.125 + 2 * Ice, (22 + 15 + 60) / 12, z, V, Y, ExpCat, StrCl, Topo, IncludeIce);
              FeedLineArea = (300) * (3.01 + 2 * Ice) * AppurtDrag(WSpec, 3.01 + 2 * Ice, 300 / 12, z, V, Y, ExpCat, StrCl, Topo, IncludeIce);
              Adir2 = Round(((NumberSections * (FinnedArea + SlotCovers + EndFlanges + EndLugs + TL + Tee + GasStop) + FeedLineArea * numberFeedLines + PowerDividerArea)) / 144, 2);
              A = ((NumberSections * (FinnedArea + SlotCovers + EndFlanges + EndLugs + TL + Tee + GasStop) + FeedLineArea * numberFeedLines + PowerDividerArea)) / 144;
              // 'Calculate the shear force
              RDir2 = Round(G * A * Q, 0);
              if ((A > Amax)) {
                Amax = A;
              }
              RMax = Round(G * Amax * Q, 0);
              Amax = Round(Amax, 2);

              // 'Weight
              PatternShaperWeight = (0.5 * 1 + lambda / 2 * 0.125) * Lfin * NLayers * 0.1;
              SlotCoverWeight = (L - 7) * (0.02);
              PoleWeight = Pi / 4 * 0.1 * (Math.pow(Dpipe, 2) - Math.pow((Dpipe - 2 * twall), 2)) * ((NLayers + 1) * lambda - 0.5);
              PN48959 = 2 * 0.65; //End Flange
              PN75553 = 1.85; //Spacer Flange
              PN75554 = 0.62; // Tee Flange
              PN75730 = 0.6; //Vertical Lug
              PN75483 = 2 * 3.66; //End Short
              PN75539 = 2 * 1.75; //Bolt On Lug
              Inner = Pi / 4 * (Math.pow(1.315, 2) - Math.pow(1.23, 2)) * L * 0.323 + 5;
              Misc = 5;
              GasStopWeight = 10;
              TLWeight = 0.23 * (22 + 60);
              Elbow = 12; //Catalog Value
              FeedlineWeight = 300 * 0.148; //Andrew Catalog
              Weight = (SlotCoverWeight + PoleWeight + PatternShaperWeight + PN48959 + PN75553 + PN75554 + PN75730 + PN75539 + PN75483 + Inner + Misc + GasStopWeight + TLWeight + Elbow) * NumberSections + FeedlineWeight * numberFeedLines + PowerDividerWeight;
              Weight = Round(Weight, 0);

              // 'Ice Weight
              SlotCoverIce = (2.75 * Ice + Pi / 2 * Math.pow(Ice, 2) + 2.234 * (Math.pow((1.75 + Ice), 2) - Math.pow((1.75), 2))) * (L - NLayers * (Lfin + 2 * Ice)) * 0.032;
              PatternShaperIce = (2 * 0.5 * lambda + 2 * Ice) * NLayers * (Lfin + 2 * Ice) * 0.032;
              BarePoleIce = Pi / 4 * (0.032) * (Math.pow((Dpipe + 2 * Ice), 2) - Math.pow(Dpipe, 2)) * (L - 6.4 * lambda - 11 - 18 * Ice);
              EndFlangeIce = Pi / 4 * (0.032) * (Math.pow((5.25 + 2 * Ice), 2) - Math.pow((5.25), 2)) * (2 + 2 * Ice);
              TeeIce = Pi / 4 * (0.032) * (Math.pow((3.5 + 2 * Ice), 2) - Math.pow((3.5), 2)) * (3.84 - Ice);
              LugIce = ((3.75 + Ice) * (4.1 + Ice) - 0.5 * Math.pow((2.34 + Ice), 2)) * 2 * Ice * (0.032);
              EndLugIce = (Pi / 16 * (Math.pow((5.25 + 2 * Ice), 2) - Math.pow((5.25), 2)) + (5.25 + 2 * Ice) * (7.5 + Ice) - (1.375 + Ice) * (7.5 + Ice)) * 2 * Ice * (0.032);
              GasStopIce = Pi / 4 * (0.032) * (Math.pow((5.19 + 2 * Ice), 2) - Math.pow((5.19), 2)) * (2 * 0.5 + 1.375 + 2 * Ice);
              TLIce = Pi / 4 * (0.032) * (Math.pow((3.125 + 2 * Ice), 2) - Math.pow((3.125), 2)) * (22 + 15 + 60);
              FeedLineIce = Pi / 4 * (0.032) * (Math.pow((3.01 + 2 * Ice), 2) - Math.pow((3.01), 2)) * 300;
              IceWeight = (SlotCoverIce + BarePoleIce + PatternShaperIce + EndFlangeIce + TeeIce + LugIce + EndLugIce + GasStopIce + TLIce + FeedLineIce) * NumberSections + PowerDividerIceWeight;
              Wice = Round(IceWeight, 0);

              // 'Total Weight
              TotalWeight = Weight + IceWeight;
              //TotalWeight = Round(TotalWeight, 0)

            } else if ((optRadome == true)) {
              // 'Area Direction 1
              Dradome = RadDia;
              Lradome = (L - S - 7);
              Lfin = 0.8 * lambda + 1;
              Radome = (Lradome + 2 * Ice) * (Dradome + 2 * Ice) * AppurtDrag(WSpec, (Dradome + 2 * Ice), (Lradome + 2 * Ice) / 12, z, V, Y, ExpCat, StrCl, Topo, IncludeIce);
              BarePole = (L - Lradome - 4 * Ice) * (Dpipe + 2 * Ice) * AppurtDrag(WSpec, (Dpipe + 2 * Ice), (L - Lradome - 4 * Ice) / 12, z, V, Y, ExpCat, StrCl, Topo, IncludeIce);
              EndFlanges = (2 + 2 * Ice) * (5.25 + 2 * Ice) * AppurtDrag(WSpec, 5.25 + 2 * Ice, (2 + 2 * Ice) / 12, z, V, Y, ExpCat, StrCl, Topo, IncludeIce);
              EndLugs = 2 * (5.25 + 2 * Ice) * (0.5 + 2 * Ice) * AppurtDrag(WSpec, 5.25 + 2 * Ice, (0.5 + 2 * Ice) / 12, z, V, Y, ExpCat, StrCl, Topo, IncludeIce);
              TL = ((60 + 9) * (3.125 + 2 * Ice) + 4 * (0.5 + 2 * Ice) * (5.19 + 2 * Ice)) * AppurtDrag(WSpec, 3.125 + 2 * Ice, (60 + 9) / 12, z, V, Y, ExpCat, StrCl, Topo, IncludeIce);
              Feedline = (300) * (3.01 + 2 * Ice) * AppurtDrag(WSpec, 3.01 + 2 * Ice, 300 / 12, z, V, Y, ExpCat, StrCl, Topo, IncludeIce);
              Adir1 = Round(((NumberSections * (Radome + BarePole + EndFlanges + EndLugs + TL) + Feedline * numberFeedLines + PowerDividerArea)) / 144, 2);
              Amax = ((NumberSections * (Radome + BarePole + EndFlanges + EndLugs + TL) + Feedline * numberFeedLines + PowerDividerArea)) / 144;
              RDir1 = Round(G * Amax * Q, 0);

              // 'Area Direction 2
              Radome = (Lradome + 2 * Ice) * (Dradome + 2 * Ice) * AppurtDrag(WSpec, (Dradome + 2 * Ice), (Lradome + 2 * Ice) / 12, z, V, Y, ExpCat, StrCl, Topo, IncludeIce);
              BarePole = (L - Lradome - 4 * Ice) * (Dpipe + 2 * Ice) * AppurtDrag(WSpec, (Dpipe + 2 * Ice), (L - Lradome - 4 * Ice) / 12, z, V, Y, ExpCat, StrCl, Topo, IncludeIce);
              EndFlanges = (2 + 2 * Ice) * (5.25 + 2 * Ice) * AppurtDrag(WSpec, 5.25 + 2 * Ice, (2 + 2 * Ice) / 12, z, V, Y, ExpCat, StrCl, Topo, IncludeIce);
              Tee = (3.84 - Ice) * (3.5 + 2 * Ice) * AppurtDrag(WSpec, 3.5 + 2 * Ice, (3.84 - Ice) / 12, z, V, Y, ExpCat, StrCl, Topo, IncludeIce);
              Lug = (((3.75 + Ice) * (4.1 + Ice)) - 0.5 * Math.pow((2.35 + Ice), 2)) * FlatAppurtDrag(WSpec, 3.75 + Ice, (4.1 + Ice) / 12, z, V, ExpCat, StrCl, Topo);
              EndLugs = 2 * (10.125 + 2 * Ice) * (0.5 + 2 * Ice) * FlatAppurtDrag(WSpec, 10.125 + 2 * Ice, (0.5 + 2 * Ice) / 12, z, V, ExpCat, StrCl, Topo);
              GasStop = (5.19 + 2 * Ice) * (2 * 0.5 + 1.375 + Ice) * AppurtDrag(WSpec, 5.19 + 2 * Ice, (2 * 0.5 + 1.375 + Ice) / 12, z, V, Y, ExpCat, StrCl, Topo, IncludeIce);
              TL = ((22 + 15 + 60) * (3.125 + 2 * Ice) + 6 * (0.5 + 2 * Ice) * (5.19 + 2 * Ice)) * AppurtDrag(WSpec, 3.125 + 2 * Ice, (22 + 15 + 60) / 12, z, V, Y, ExpCat, StrCl, Topo, IncludeIce);
              Feedline = (300) * (3.01 + 2 * Ice) * AppurtDrag(WSpec, 3.01 + 2 * Ice, 300 / 12, z, V, Y, ExpCat, StrCl, Topo, IncludeIce);
              Adir2 = Round(((NumberSections * (Radome + BarePole + EndFlanges + EndLugs + TL + Tee + GasStop) + Feedline * numberFeedLines + PowerDividerArea)) / 144, 2);
              A = ((NumberSections * (Radome + BarePole + EndFlanges + EndLugs + TL + Tee + GasStop) + Feedline * numberFeedLines + PowerDividerArea)) / 144;
              // 'Calculate the shear force
              RDir2 = Round(G * A * Q, 0);
              if ((A > Amax)) {
                Amax = A;
              }
              RMax = Round(G * Amax * Q, 0);
              Amax = Round(Amax, 2);

              // 'Weight
              EndSeals = Pi * (Math.pow(Dradome, 2) - Math.pow(Dpipe, 2)) * 1 * 0.034;
              RadomeWeight = 0.1544 * Dradome * Lradome / 12;
              PoleWeight = Pi / 4 * 0.1 * (Math.pow(Dpipe, 2) - Math.pow((Dpipe - 2 * twall), 2)) * ((NLayers + 1) * lambda - 0.5);
              PatternShaperWeight = (0.5 * 1 + lambda / 2 * 0.125) * Lfin * NLayers * 0.1;
              PN48959 = 2 * 0.65; //End Flange
              PN75553 = 1.85; //Spacer Flange
              PN75554 = 0.62; // Tee Flange
              PN75730 = 0.6; //Vertical Lug
              PN75483 = 2 * 3.66; //End Short
              PN75539 = 2 * 1.75; //Bolt On Lug
              Inner = Pi / 4 * (Math.pow(1.315, 2) - Math.pow(1.23, 2)) * L * 0.323 + 5;
              Misc = 5;
              GasStopWeight = 10;
              TLWeight = 0.23 * (22 + 60);
              Elbow = 12; //Catalog Value
              FeedlineWeight = 300 * 0.148; //Andrew Catalog
              Weight = (EndSeals + RadomeWeight + PoleWeight + PatternShaperWeight + PN48959 + PN75553 + PN75554 + PN75730 + PN75539 + PN75483 + Inner + Misc + GasStopWeight + TLWeight + Elbow) * NumberSections + FeedlineWeight * numberFeedLines + PowerDividerWeight;
              //Weight = Round(Weight, 0)


              // 'Ice Weight
              RadomeIce = Pi / 4 * (0.032) * (Math.pow((Dradome + 2 * Ice), 2) - Math.pow(Dradome, 2)) * (Lradome - 2 * Ice)
              BarePoleIce = Pi / 4 * (0.032) * (Math.pow((Dpipe + 2 * Ice), 2) - Math.pow(Dpipe, 2)) * (L - Lradome - 4 * Ice)
              EndFlangeIce = Pi / 4 * (0.032) * (Math.pow((5.25 + 2 * Ice), 2) - Math.pow((5.25), 2)) * (2 + 2 * Ice)
              TeeIce = Pi / 4 * (0.032) * (Math.pow((3.5 + 2 * Ice), 2) - Math.pow((3.5), 2)) * (3.84 - Ice)
              LugIce = ((3.75 + Ice) * (4.1 + Ice) - 0.5 * Math.pow((2.34 + Ice), 2)) * 2 * Ice * (0.032)
              EndLugIce = (Pi / 16 * (Math.pow((5.25 + 2 * Ice), 2) - Math.pow((5.25), 2)) + (5.25 + 2 * Ice) * (7.5 + Ice) - (1.375 + Ice) * (7.5 + Ice)) * 2 * Ice * (0.032)
              GasStopIce = Pi / 4 * (0.032) * (Math.pow((5.19 + 2 * Ice), 2) - Math.pow((5.19), 2)) * (2 * 0.5 + 1.375 + 2 * Ice)
              TLIce = Pi / 4 * (0.032) * (Math.pow((3.125 + 2 * Ice), 2) - Math.pow((3.125), 2)) * (22 + 15 + 60)
              FeedLineIce = Pi / 4 * (0.032) * (Math.pow((3.01 + 2 * Ice), 2) - Math.pow((3.01), 2)) * 300
              IceWeight = (RadomeIce + BarePoleIce + EndFlangeIce + TeeIce + LugIce + EndLugIce + GasStopIce + TLIce + FeedLineIce) * NumberSections + DSBPowerDividerIceWeight
              Wice = Round(IceWeight, 0)

              // 'Total Weight
              TotalWeight = Weight + IceWeight
              TotalWeight = Round(TotalWeight, 0)
            }
          },
                patternH: function() {
            //constants
            Pi = Math.PI;
            if (optSlotCover == true) {
              // 'Area Wind Direction1
              Lfin = 0.8 * lambda + 1;
              FinWidth = 2 * (0.4 * lambda + Dpipe / 2) * Math.cos(70 * Pi / 180);
              FinnedArea = (FinWidth + 2 * Ice) * NLayers * (Lfin + 2 * Ice) * FlatAppurtDrag(WSpec, FinWidth + 2 * Ice, NLayers * (Lfin + 2 * Ice) / 12, z, V, ExpCat, StrCl, Topo);
              SlotCovers = (Dpipe + 2 * Ice) * (L - NLayers * (Lfin + 2 * Ice)) * FlatAppurtDrag(WSpec, Dpipe + 2 * Ice, (L - NLayers * (Lfin + 2 * Ice)) / 12, z, V, ExpCat, StrCl, Topo);
              EndFlanges = (2 + 2 * Ice) * (5.25 + 2 * Ice) * AppurtDrag(WSpec, 5.25 + 2 * Ice, (2 + 2 * Ice) / 12, z, V, Y, ExpCat, StrCl, Topo, IncludeIce);
              EndLugs = 2 * (5.25 + 2 * Ice) * (0.5 + 2 * Ice) * AppurtDrag(WSpec, 5.25 + 2 * Ice, (0.5 + 2 * Ice) / 12, z, V, Y, ExpCat, StrCl, Topo, IncludeIce);
              TL = ((9 + 60) * (3.125 + 2 * Ice) + 4 * (0.5 + 2 * Ice) * (5.19 + 2 * Ice)) * AppurtDrag(WSpec, 3.125 + 2 * Ice, (60 + 9) / 12, z, V, Y, ExpCat, StrCl, Topo, IncludeIce);
              Feedline = (300) * (3.01 + 2 * Ice) * AppurtDrag(WSpec, 3.01 + 2 * Ice, 300 / 12, z, V, Y, ExpCat, StrCl, Topo, IncludeIce);
              Adir1 = Round(((NumberSections * (SlotCovers + FinnedArea + EndFlanges + EndLugs + TL) + Feedline * numberFeedLines + PowerDividerArea)) / 144, 2);
              Amax = ((NumberSections * (SlotCovers + FinnedArea + EndFlanges + EndLugs + TL) + Feedline * numberFeedLines + PowerDividerArea)) / 144;
              RDir1 = Round(G * Amax * Q, 0);

              // 'Area Wind Direction2
              FinHeight = 3.625 + (0.4 * lambda + Dpipe / 2) * Math.sin(70 * Pi / 180);
              FinnedArea = (FinHeight + 2 * Ice) * NLayers * (Lfin + 2 * Ice) * FlatAppurtDrag(WSpec, FinHeight + 2 * Ice, NLayers * (Lfin + 2 * Ice) / 12, z, V, ExpCat, StrCl, Topo);
              SlotCovers = (5.375 + 2 * Ice) * (L - NLayers * (Lfin + 2 * Ice)) * FlatAppurtDrag(WSpec, 5.375 + 2 * Ice, (L - NLayers * (Lfin + 2 * Ice)) / 12, z, V, ExpCat, StrCl, Topo);
              EndFlanges = (2 + 2 * Ice) * (5.25 + 2 * Ice) * AppurtDrag(WSpec, 5.25 + 2 * Ice, (2 + 2 * Ice) / 12, z, V, Y, ExpCat, StrCl, Topo, IncludeIce);
              Tee = (3.84 - Ice) * (3.5 + 2 * Ice) * AppurtDrag(WSpec, 3.5 + 2 * Ice, (3.84 - Ice) / 12, z, V, Y, ExpCat, StrCl, Topo, IncludeIce);
              Lug = (((3.75 + Ice) * (4.1 + Ice) - 0.5 * Math.pow((2.34 + Ice), 2))) * FlatAppurtDrag(WSpec, 3.75 + Ice, (4.1 + Ice) / 12, z, V, ExpCat, StrCl, Topo);
              EndLugs = 2 * (10.125 + 2 * Ice) * (0.5 + 2 * Ice) * FlatAppurtDrag(WSpec, 10.125 + 2 * Ice, (0.5 + 2 * Ice) / 12, z, V, ExpCat, StrCl, Topo);
              GasStop = (5.19 + 2 * Ice) * (2 * 0.5 + 1.375 + Ice) * AppurtDrag(WSpec, 5.19 + 2 * Ice, (2 * 0.5 + 1.375 + Ice) / 12, z, V, Y, ExpCat, StrCl, Topo, IncludeIce);
              TL = ((22 + 15 + 60) * (3.125 + 2 * Ice) + 6 * (0.5 + 2 * Ice) * (5.19 + 2 * Ice)) * AppurtDrag(WSpec, 3.125 + 2 * Ice, (22 + 15 + 60) / 12, z, V, Y, ExpCat, StrCl, Topo, IncludeIce);
              Feedline = (300) * (3.01 + 2 * Ice) * AppurtDrag(WSpec, 3.01 + 2 * Ice, 300 / 12, z, V, Y, ExpCat, StrCl, Topo, IncludeIce);
              Adir2 = Round(((NumberSections * (FinnedArea + SlotCovers + EndFlanges + EndLugs + TL + Tee + GasStop) + Feedline * numberFeedLines + PowerDividerArea)) / 144, 2);
              A = ((NumberSections * (FinnedArea + SlotCovers + EndFlanges + EndLugs + TL + Tee + GasStop) + Feedline * numberFeedLines + PowerDividerArea)) / 144;
              // 'Calculate the shear force
              RDir2 = Round(G * A * Q, 0);
              if ((A > Amax)) {
                Amax = A;
              }
              RMax = Round(G * Amax * Q, 0);
              Amax = Round(Amax, 2);

              // 'Weight
              FinWeight = (2 * 0.4 * lambda * Lfin * 0.125) * 0.1 * NLayers;
              SlotCoverWeight = (L - 7) * (0.02);
              PoleWeight = Pi / 4 * 0.1 * (Math.pow(Dpipe, 2) - Math.pow((Dpipe - 2 * twall), 2)) * ((NLayers + 1) * lambda - 0.5);
              PN48959 = 2 * 0.65; //End Flange
              PN75553 = 1.85; //Spacer Flange
              PN75554 = 0.62; // Tee Flange
              PN75730 = 0.6; //Vertical Lug
              PN75483 = 2 * 3.66; //End Short
              PN75539 = 2 * 1.75; //Bolt On Lug
              Inner = Pi / 4 * (Math.pow(1.315, 2) - Math.pow(1.23, 2)) * L * 0.323 + 5;
              Misc = 5;
              GasStopWeight = 10;
              TLWeight = 0.23 * (22 + 60);
              Elbow = 12; //Catalog Value
              FeedlineWeight = 300 * 0.148; //Andrew Catalog
              Weight = (SlotCoverWeight + PoleWeight + FinWeight + PN48959 + PN75553 + PN75554 + PN75730 + PN75539 + PN75483 + Inner + Misc + GasStopWeight + TLWeight + Elbow) * NumberSections + FeedlineWeight * numberFeedLines + PowerDividerWeight;
              Weight = Round(Weight, 0);

              // 'Ice Weight
              FinIce = (2.75 * Ice + Pi / 2 * Math.pow(Ice, 2) + 2.234 * (Math.pow((1.75 + Ice), 2) - Math.pow((1.75), 2)) + 0.8 * lambda * Ice) * NLayers * (Lfin + 2 * Ice) * 0.032;
              SlotCoverIce = (2.75 * Ice + Pi / 2 * Math.pow(Ice, 2) + 2.234 * (Math.pow((1.75 + Ice), 2) - Math.pow((1.75), 2))) * (L - NLayers * (Lfin + 2 * Ice)) * 0.032;
              BarePoleIce = Pi / 4 * (0.032) * (Math.pow((Dpipe + 2 * Ice), 2) - Math.pow(Dpipe, 2)) * (L - 6.4 * lambda - 11 - 18 * Ice);
              EndFlangeIce = Pi / 4 * (0.032) * (Math.pow((5.25 + 2 * Ice), 2) - Math.pow((5.25), 2)) * (2 + 2 * Ice);
              TeeIce = Pi / 4 * (0.032) * (Math.pow((3.5 + 2 * Ice), 2) - Math.pow((3.5), 2)) * (3.84 - Ice);
              LugIce = ((3.75 + Ice) * (4.1 + Ice) - 0.5 * Math.pow((2.34 + Ice), 2)) * 2 * Ice * (0.032);
              EndLugIce = (Pi / 16 * (Math.pow((5.25 + 2 * Ice), 2) - Math.pow((5.25), 2)) + (5.25 + 2 * Ice) * (7.5 + Ice) - (1.375 + Ice) * (7.5 + Ice)) * 2 * Ice * (0.032);
              GasStopIce = Pi / 4 * (0.032) * (Math.pow((5.19 + 2 * Ice), 2) - Math.pow((5.19), 2)) * (2 * 0.5 + 1.375 + 2 * Ice);
              TLIce = Pi / 4 * (0.032) * (Math.pow((3.125 + 2 * Ice), 2) - Math.pow((3.125), 2)) * (22 + 15 + 60);
              FeedLineIce = Pi / 4 * (0.032) * (Math.pow((3.01 + 2 * Ice), 2) - Math.pow((3.01), 2)) * 300;
              IceWeight = (SlotCoverIce + FinIce + BarePoleIce + EndFlangeIce + TeeIce + LugIce + EndLugIce + GasStopIce + TLIce + FeedLineIce) * NumberSections + PowerDividerIceWeight;
              Wice = Round(IceWeight, 0);

              // 'Total Weight
              TotalWeight = Weight + IceWeight;
              TotalWeight = Round(TotalWeight, 0);

            } else if ((optRadome == true)) {
              // 'Area Direction 1
              Dradome = RadDia;
              Lradome = (L - S - 7);
              Lfin = 0.8 * lambda + 1;
              Radome = (Lradome + 2 * Ice) * (Dradome + 2 * Ice) * AppurtDrag(WSpec, (Dradome + 2 * Ice), (Lradome + 2 * Ice) / 12, z, V, Y, ExpCat, StrCl, Topo, IncludeIce);
              BarePole = (L - Lradome - 4 * Ice) * (Dpipe + 2 * Ice) * AppurtDrag(WSpec, (Dpipe + 2 * Ice), (L - Lradome - 4 * Ice) / 12, z, V, Y, ExpCat, StrCl, Topo, IncludeIce);
              EndFlanges = (2 + 2 * Ice) * (5.25 + 2 * Ice) * AppurtDrag(WSpec, 5.25 + 2 * Ice, (2 + 2 * Ice) / 12, z, V, Y, ExpCat, StrCl, Topo, IncludeIce);
              EndLugs = 2 * (5.25 + 2 * Ice) * (0.5 + 2 * Ice) * AppurtDrag(WSpec, 5.25 + 2 * Ice, (0.5 + 2 * Ice) / 12, z, V, Y, ExpCat, StrCl, Topo, IncludeIce);
              TL = ((60 + 9) * (3.125 + 2 * Ice) + 4 * (0.5 + 2 * Ice) * (5.19 + 2 * Ice)) * AppurtDrag(WSpec, 3.125 + 2 * Ice, (60 + 9) / 12, z, V, Y, ExpCat, StrCl, Topo, IncludeIce);
              Feedline = (300) * (3.01 + 2 * Ice) * AppurtDrag(WSpec, 3.01 + 2 * Ice, 300 / 12, z, V, Y, ExpCat, StrCl, Topo, IncludeIce);
              Adir1 = Round(((NumberSections * (Radome + BarePole + EndFlanges + EndLugs + TL) + Feedline * numberFeedLines + PowerDividerArea)) / 144, 2);
              Amax = ((NumberSections * (Radome + BarePole + EndFlanges + EndLugs + TL) + Feedline * numberFeedLines + PowerDividerArea)) / 144;
              RDir1 = Round(G * Amax * Q, 0);

              //Area Direction 2
              Radome = (Lradome + 2 * Ice) * (Dradome + 2 * Ice) * AppurtDrag(WSpec, (Dradome + 2 * Ice), (Lradome + 2 * Ice) / 12, z, V, Y, ExpCat, StrCl, Topo, IncludeIce);
              BarePole = (L - Lradome - 4 * Ice) * (Dpipe + 2 * Ice) * AppurtDrag(WSpec, (Dpipe + 2 * Ice), (L - Lradome - 4 * Ice) / 12, z, V, Y, ExpCat, StrCl, Topo, IncludeIce);
              EndFlanges = (2 + 2 * Ice) * (5.25 + 2 * Ice) * AppurtDrag(WSpec, 5.25 + 2 * Ice, (2 + 2 * Ice) / 12, z, V, Y, ExpCat, StrCl, Topo, IncludeIce);
              Tee = (3.84 - Ice) * (3.5 + 2 * Ice) * AppurtDrag(WSpec, 3.5 + 2 * Ice, (3.84 - Ice) / 12, z, V, Y, ExpCat, StrCl, Topo, IncludeIce);
              Lug = (((3.75 + Ice) * (4.1 + Ice)) - 0.5 * Math.pow((2.35 + Ice), 2)) * FlatAppurtDrag(WSpec, 3.75 + Ice, (4.1 + Ice) / 12, z, V, ExpCat, StrCl, Topo);
              EndLugs = 2 * (10.125 + 2 * Ice) * (0.5 + 2 * Ice) * FlatAppurtDrag(WSpec, 10.125 + 2 * Ice, (0.5 + 2 * Ice) / 12, z, V, ExpCat, StrCl, Topo);
              GasStop = (5.19 + 2 * Ice) * (2 * 0.5 + 1.375 + Ice) * AppurtDrag(WSpec, 5.19 + 2 * Ice, (2 * 0.5 + 1.375 + Ice) / 12, z, V, Y, ExpCat, StrCl, Topo, IncludeIce);
              TL = ((22 + 15 + 60) * (3.125 + 2 * Ice) + 6 * (0.5 + 2 * Ice) * (5.19 + 2 * Ice)) * AppurtDrag(WSpec, 3.125 + 2 * Ice, (22 + 15 + 60) / 12, z, V, Y, ExpCat, StrCl, Topo, IncludeIce);
              Feedline = (300) * (3.01 + 2 * Ice) * AppurtDrag(WSpec, 3.01 + 2 * Ice, 300 / 12, z, V, Y, ExpCat, StrCl, Topo, IncludeIce);
              Adir2 = Round(((NumberSections * (Radome + BarePole + EndFlanges + EndLugs + TL + Tee + GasStop) + Feedline * numberFeedLines + PowerDividerArea)) / 144, 2);
              A = ((NumberSections * (Radome + BarePole + EndFlanges + EndLugs + TL + Tee + GasStop) + Feedline * numberFeedLines + PowerDividerArea)) / 144;
              //Calculate the shear force
              RDir2 = Round(G * A * Q, 0);
              if ((A > Amax)) {
                Amax = A;
              }
              RMax = Round(G * Amax * Q, 0);
              Amax = Round(Amax, 2);

              // 'Weight
              EndSeals = Pi * (Math.pow(Dradome, 2) - Math.pow(Dpipe, 2)) * 1 * 0.034;
              RadomeWeight = 0.1544 * Dradome * Lradome / 12;
              PoleWeight = Pi / 4 * 0.1 * (Math.pow(Dpipe, 2) - Math.pow((Dpipe - 2 * twall), 2)) * ((NLayers + 1) * lambda - 0.5);
              FinWeight = (2 * 0.4 * lambda * (0.8 * lambda + 1) * 0.125) * 0.1 * NLayers;
              PatternShaperWeight = (0.5 * 1 + lambda / 2 * 0.125) * (0.8 * lambda + 1) * NLayers * 0.1;
              PN48959 = 2 * 0.65; //End Flange
              PN75553 = 1.85; //Spacer Flange
              PN75554 = 0.62; // Tee Flange
              PN75730 = 0.6; //Vertical Lug
              PN75483 = 2 * 3.66; //End Short
              PN75539 = 2 * 1.75; //Bolt On Lug
              Inner = Pi / 4 * (Math.pow(1.315, 2) - Math.pow(1.23, 2)) * L * 0.323 + 5;
              Misc = 5;
              GasStopWeight = 10;
              TLWeight = 0.23 * (22 + 60);
              Elbow = 12; //Catalog Value
              FeedlineWeight = 300 * 0.148; //Andrew Catalog
              Weight = (RadomeWeight + EndSeals + PoleWeight + FinWeight + PatternShaperWeight + PN48959 + PN75553 + PN75554 + PN75730 + PN75539 + PN75483 + Inner + Misc + GasStopWeight + TLWeight + Elbow) * NumberSections + FeedlineWeight * numberFeedLines + PowerDividerWeight;
              Weight = Round(Weight, 0);


              // 'Ice Weight
              RadomeIce = Pi / 4 * (0.032) * (Math.pow((Dradome + 2 * Ice), 2) - Math.pow(Dradome, 2)) * (Lradome - 2 * Ice);
              BarePoleIce = Pi / 4 * (0.032) * (Math.pow((Dpipe + 2 * Ice), 2) - Math.pow(Dpipe, 2)) * (L - Lradome - 4 * Ice);
              EndFlangeIce = Pi / 4 * (0.032) * (Math.pow((5.25 + 2 * Ice), 2) - Math.pow((5.25), 2)) * (2 + 2 * Ice);
              TeeIce = Pi / 4 * (0.032) * (Math.pow((3.5 + 2 * Ice), 2) - Math.pow((3.5), 2)) * (3.84 - Ice);
              LugIce = ((3.75 + Ice) * (4.1 + Ice) - 0.5 * Math.pow((2.34 + Ice), 2)) * 2 * Ice * (0.032);
              EndLugIce = (Pi / 16 * (Math.pow((5.25 + 2 * Ice), 2) - Math.pow((5.25), 2)) + (5.25 + 2 * Ice) * (7.5 + Ice) - (1.375 + Ice) * (7.5 + Ice)) * 2 * Ice * (0.032);
              GasStopIce = Pi / 4 * (0.032) * (Math.pow((5.19 + 2 * Ice), 2) - Math.pow((5.19), 2)) * (2 * 0.5 + 1.375 + 2 * Ice);
              TLIce = Pi / 4 * (0.032) * (Math.pow((3.125 + 2 * Ice), 2) - Math.pow((3.125), 2)) * (22 + 15 + 60);
              FeedLineIce = Pi / 4 * (0.032) * (Math.pow((3.01 + 2 * Ice), 2) - Math.pow((3.01), 2)) * 300;
              IceWeight = (RadomeIce + BarePoleIce + EndFlangeIce + TeeIce + LugIce + EndLugIce + GasStopIce + TLIce + FeedLineIce) * NumberSections + DSBPowerDividerIceWeight;
              Wice = Round(IceWeight, 0);

              // 'Total Weight
              TotalWeight = Weight + IceWeight;
              TotalWeight = Round(TotalWeight, 0);
            }
      },
        patternI: function() {
            //constants
            Pi = Math.PI;
            if ((optSlotCover == true)) {
              // 'Area Wind Direction1
              Lfin = 0.8 * lambda + 1;
              FinWidth = 2 * (0.4 * lambda + Dpipe / 2) * Math.cos(50 * Pi / 180);
              FinnedArea = (FinWidth + 2 * Ice) * NLayers * (Lfin + 2 * Ice) * FlatAppurtDrag(WSpec, FinWidth + 2 * Ice, NLayers * (Lfin + 2 * Ice) / 12, z, V, ExpCat, StrCl, Topo);
              SlotCovers = (Dpipe + 2 * Ice) * (L - NLayers * (Lfin + 2 * Ice)) * FlatAppurtDrag(WSpec, Dpipe + 2 * Ice, (L - NLayers * (Lfin + 2 * Ice)) / 12, z, V, ExpCat, StrCl, Topo);
              EndFlanges = (2 + 2 * Ice) * (5.25 + 2 * Ice) * AppurtDrag(WSpec, 5.25 + 2 * Ice, (2 + 2 * Ice) / 12, z, V, Y, ExpCat, StrCl, Topo, IncludeIce);
              EndLugs = 2 * (5.25 + 2 * Ice) * (0.5 + 2 * Ice) * AppurtDrag(WSpec, 5.25 + 2 * Ice, (0.5 + 2 * Ice) / 12, z, V, Y, ExpCat, StrCl, Topo, IncludeIce);
              TL = ((9 + 60) * (3.125 + 2 * Ice) + 4 * (0.5 + 2 * Ice) * (5.19 + 2 * Ice)) * AppurtDrag(WSpec, 3.125 + 2 * Ice, (60 + 9) / 12, z, V, Y, ExpCat, StrCl, Topo, IncludeIce);
              Feedline = (300) * (3.01 + 2 * Ice) * AppurtDrag(WSpec, 3.01 + 2 * Ice, 300 / 12, z, V, Y, ExpCat, StrCl, Topo, IncludeIce);
              Adir1 = Round(((NumberSections * (SlotCovers + FinnedArea + EndFlanges + EndLugs + TL) + Feedline * numberFeedLines + PowerDividerArea)) / 144, 2);
              Amax = ((NumberSections * (SlotCovers + FinnedArea + EndFlanges + EndLugs + TL) + Feedline * numberFeedLines + PowerDividerArea)) / 144;
              RDir1 = Round(G * Amax * Q, 0);

              // 'Area Wind Direction2
              FinHeight = 3.625 + (0.4 * lambda + Dpipe / 2) * Math.sin(50 * Pi / 180);
              FinnedArea = (FinHeight + 2 * Ice) * NLayers * (Lfin + 2 * Ice) * FlatAppurtDrag(WSpec, FinHeight + 2 * Ice, NLayers * (Lfin + 2 * Ice) / 12, z, V, ExpCat, StrCl, Topo);
              SlotCovers = (5.375 + 2 * Ice) * (L - NLayers * (Lfin + 2 * Ice)) * FlatAppurtDrag(WSpec, 5.375 + 2 * Ice, (L - NLayers * (Lfin + 2 * Ice)) / 12, z, V, ExpCat, StrCl, Topo);
              EndFlanges = (2 + 2 * Ice) * (5.25 + 2 * Ice) * AppurtDrag(WSpec, 5.25 + 2 * Ice, (2 + 2 * Ice) / 12, z, V, Y, ExpCat, StrCl, Topo, IncludeIce);
              Tee = (3.84 - Ice) * (3.5 + 2 * Ice) * AppurtDrag(WSpec, 3.5 + 2 * Ice, (3.84 - Ice) / 12, z, V, Y, ExpCat, StrCl, Topo, IncludeIce);
              Lug = (((3.75 + Ice) * (4.1 + Ice) - 0.5 * Math.pow((2.34 + Ice), 2))) * FlatAppurtDrag(WSpec, 3.75 + Ice, (4.1 + Ice) / 12, z, V, ExpCat, StrCl, Topo);
              EndLugs = 2 * (10.125 + 2 * Ice) * (0.5 + 2 * Ice) * FlatAppurtDrag(WSpec, 10.125 + 2 * Ice, (0.5 + 2 * Ice) / 12, z, V, ExpCat, StrCl, Topo);
              GasStop = (5.19 + 2 * Ice) * (2 * 0.5 + 1.375 + Ice) * AppurtDrag(WSpec, 5.19 + 2 * Ice, (2 * 0.5 + 1.375 + Ice) / 12, z, V, Y, ExpCat, StrCl, Topo, IncludeIce);
              TL = ((22 + 15 + 60) * (3.125 + 2 * Ice) + 6 * (0.5 + 2 * Ice) * (5.19 + 2 * Ice)) * AppurtDrag(WSpec, 3.125 + 2 * Ice, (22 + 15 + 60) / 12, z, V, Y, ExpCat, StrCl, Topo, IncludeIce);
              Feedline = (300) * (3.01 + 2 * Ice) * AppurtDrag(WSpec, 3.01 + 2 * Ice, 300 / 12, z, V, Y, ExpCat, StrCl, Topo, IncludeIce);
              Adir2 = Round(((NumberSections * (FinnedArea + SlotCovers + EndFlanges + EndLugs + TL + Tee + GasStop) + Feedline * numberFeedLines + PowerDividerArea)) / 144, 2);
              A = ((NumberSections * (FinnedArea + SlotCovers + EndFlanges + EndLugs + TL + Tee + GasStop) + Feedline * numberFeedLines + PowerDividerArea)) / 144;
              // 'Calculate the shear force
              RDir2 = Round(G * A * Q, 0);
              if ((A > Amax)) {
                Amax = A;
              }
              RMax = Round(G * Amax * Q, 0);
              Amax = Round(Amax, 2);

              // 'Weight
              FinWeight = (2 * 0.4 * lambda * Lfin * 0.125) * 0.1 * NLayers;
              SlotCoverWeight = (L - 7) * (0.02);
              PoleWeight = Pi / 4 * 0.1 * (Math.pow(Dpipe, 2) - Math.pow((Dpipe - 2 * twall), 2)) * ((NLayers + 1) * lambda - 0.5);
              PN48959 = 2 * 0.65; //End Flange
              PN75553 = 1.85; //Spacer Flange
              PN75554 = 0.62; // Tee Flange
              PN75730 = 0.6; //Vertical Lug
              PN75483 = 2 * 3.66; //End Short
              PN75539 = 2 * 1.75; //Bolt On Lug
              Inner = Pi / 4 * (Math.pow(1.315, 2) - Math.pow(1.23, 2)) * L * 0.323 + 5;
              Misc = 5;
              GasStopWeight = 10;
              TLWeight = 0.23 * (22 + 60);
              Elbow = 12; //Catalog Value
              FeedlineWeight = 300 * 0.148; //Andrew Catalog
              Weight = (SlotCoverWeight + PoleWeight + FinWeight + PN48959 + PN75553 + PN75554 + PN75730 + PN75539 + PN75483 + Inner + Misc + GasStopWeight + TLWeight + Elbow) * NumberSections + FeedlineWeight * numberFeedLines + PowerDividerWeight;
              Weight = Round(Weight, 0);

              // 'Ice Weight
              FinIce = (2.75 * Ice + Pi / 2 * Math.pow(Ice, 2) + 2.234 * (Math.pow((1.75 + Ice), 2) - Math.pow((1.75), 2)) + 0.8 * lambda * Ice) * NLayers * (Lfin + 2 * Ice) * 0.032;
              SlotCoverIce = (2.75 * Ice + Pi / 2 * Math.pow(Ice, 2) + 2.234 * (Math.pow((1.75 + Ice), 2) - Math.pow((1.75), 2))) * (L - NLayers * (Lfin + 2 * Ice)) * 0.032;
              BarePoleIce = Pi / 4 * (0.032) * (Math.pow((Dpipe + 2 * Ice), 2) - Math.pow(Dpipe, 2)) * (L - 6.4 * lambda - 11 - 18 * Ice);
              EndFlangeIce = Pi / 4 * (0.032) * (Math.pow((5.25 + 2 * Ice), 2) - Math.pow((5.25), 2)) * (2 + 2 * Ice);
              TeeIce = Pi / 4 * (0.032) * (Math.pow((3.5 + 2 * Ice), 2) - Math.pow((3.5), 2)) * (3.84 - Ice);
              LugIce = ((3.75 + Ice) * (4.1 + Ice) - 0.5 * Math.pow((2.34 + Ice), 2)) * 2 * Ice * (0.032);
              EndLugIce = (Pi / 16 * (Math.pow((5.25 + 2 * Ice), 2) - Math.pow((5.25), 2)) + (5.25 + 2 * Ice) * (7.5 + Ice) - (1.375 + Ice) * (7.5 + Ice)) * 2 * Ice * (0.032);
              GasStopIce = Pi / 4 * (0.032) * (Math.pow((5.19 + 2 * Ice), 2) - Math.pow((5.19), 2)) * (2 * 0.5 + 1.375 + 2 * Ice);
              TLIce = Pi / 4 * (0.032) * (Math.pow((3.125 + 2 * Ice), 2) - Math.pow((3.125), 2)) * (22 + 15 + 60);
              FeedLineIce = Pi / 4 * (0.032) * (Math.pow((3.01 + 2 * Ice), 2) - Math.pow((3.01), 2)) * 300;
              IceWeight = (SlotCoverIce + FinIce + BarePoleIce + EndFlangeIce + TeeIce + LugIce + EndLugIce + GasStopIce + TLIce + FeedLineIce) * NumberSections + PowerDividerIceWeight;
              Wice = Round(IceWeight, 0);

              // 'Total Weight
              TotalWeight = Weight + IceWeight;
              TotalWeight = Round(TotalWeight, 0);

            } else if ((optRadome == true)) {
              // 'Area Direction 1
              Dradome = RadDia;
              Lradome = (L - S - 7);
              Lfin = 0.8 * lambda + 1;
              Radome = (Lradome + 2 * Ice) * (Dradome + 2 * Ice) * AppurtDrag(WSpec, (Dradome + 2 * Ice), (Lradome + 2 * Ice) / 12, z, V, Y, ExpCat, StrCl, Topo, IncludeIce);
              BarePole = (L - Lradome - 4 * Ice) * (Dpipe + 2 * Ice) * AppurtDrag(WSpec, (Dpipe + 2 * Ice), (L - Lradome - 4 * Ice) / 12, z, V, Y, ExpCat, StrCl, Topo, IncludeIce);
              EndFlanges = (2 + 2 * Ice) * (5.25 + 2 * Ice) * AppurtDrag(WSpec, 5.25 + 2 * Ice, (2 + 2 * Ice) / 12, z, V, Y, ExpCat, StrCl, Topo, IncludeIce);
              EndLugs = 2 * (5.25 + 2 * Ice) * (0.5 + 2 * Ice) * AppurtDrag(WSpec, 5.25 + 2 * Ice, (0.5 + 2 * Ice) / 12, z, V, Y, ExpCat, StrCl, Topo, IncludeIce);
              TL = ((60 + 9) * (3.125 + 2 * Ice) + 4 * (0.5 + 2 * Ice) * (5.19 + 2 * Ice)) * AppurtDrag(WSpec, 3.125 + 2 * Ice, (60 + 9) / 12, z, V, Y, ExpCat, StrCl, Topo, IncludeIce);
              Feedline = (300) * (3.01 + 2 * Ice) * AppurtDrag(WSpec, 3.01 + 2 * Ice, 300 / 12, z, V, Y, ExpCat, StrCl, Topo, IncludeIce);
              Adir1 = Round(((NumberSections * (Radome + BarePole + EndFlanges + EndLugs + TL) + Feedline * numberFeedLines + PowerDividerArea)) / 144, 2);
              Amax = ((NumberSections * (Radome + BarePole + EndFlanges + EndLugs + TL) + Feedline * numberFeedLines + PowerDividerArea)) / 144;
              RDir1 = Round(G * Amax * Q, 0);

              // 'Area Direction 2
              Radome = (Lradome + 2 * Ice) * (Dradome + 2 * Ice) * AppurtDrag(WSpec, (Dradome + 2 * Ice), (Lradome + 2 * Ice) / 12, z, V, Y, ExpCat, StrCl, Topo, IncludeIce);
              BarePole = (L - Lradome - 4 * Ice) * (Dpipe + 2 * Ice) * AppurtDrag(WSpec, (Dpipe + 2 * Ice), (L - Lradome - 4 * Ice) / 12, z, V, Y, ExpCat, StrCl, Topo, IncludeIce);
              EndFlanges = (2 + 2 * Ice) * (5.25 + 2 * Ice) * AppurtDrag(WSpec, 5.25 + 2 * Ice, (2 + 2 * Ice) / 12, z, V, Y, ExpCat, StrCl, Topo, IncludeIce);
              Tee = (3.84 - Ice) * (3.5 + 2 * Ice) * AppurtDrag(WSpec, 3.5 + 2 * Ice, (3.84 - Ice) / 12, z, V, Y, ExpCat, StrCl, Topo, IncludeIce);
              Lug = (((3.75 + Ice) * (4.1 + Ice)) - 0.5 * Math.pow((2.35 + Ice), 2)) * FlatAppurtDrag(WSpec, 3.75 + Ice, (4.1 + Ice) / 12, z, V, ExpCat, StrCl, Topo);
              EndLugs = 2 * (10.125 + 2 * Ice) * (0.5 + 2 * Ice) * FlatAppurtDrag(WSpec, 10.125 + 2 * Ice, (0.5 + 2 * Ice) / 12, z, V, ExpCat, StrCl, Topo);
              GasStop = (5.19 + 2 * Ice) * (2 * 0.5 + 1.375 + Ice) * AppurtDrag(WSpec, 5.19 + 2 * Ice, (2 * 0.5 + 1.375 + Ice) / 12, z, V, Y, ExpCat, StrCl, Topo, IncludeIce);
              TL = ((22 + 15 + 60) * (3.125 + 2 * Ice) + 6 * (0.5 + 2 * Ice) * (5.19 + 2 * Ice)) * AppurtDrag(WSpec, 3.125 + 2 * Ice, (22 + 15 + 60) / 12, z, V, Y, ExpCat, StrCl, Topo, IncludeIce);
              Feedline = (300) * (3.01 + 2 * Ice) * AppurtDrag(WSpec, 3.01 + 2 * Ice, 300 / 12, z, V, Y, ExpCat, StrCl, Topo, IncludeIce);
              Adir2 = Round(((NumberSections * (Radome + BarePole + EndFlanges + EndLugs + TL + Tee + GasStop) + Feedline * numberFeedLines + PowerDividerArea)) / 144, 2);
              A = ((NumberSections * (Radome + BarePole + EndFlanges + EndLugs + TL + Tee + GasStop) + Feedline * numberFeedLines + PowerDividerArea)) / 144;
              // 'Calculate the shear force
              RDir2 = Round(G * A * Q, 0);
              if ((A > Amax)) {
                Amax = A;
              }
              RMax = Round(G * Amax * Q, 0);
              Amax = Round(Amax, 2);

              // 'Weight
              EndSeals = Pi * (Math.pow(Dradome, 2) - Math.pow(Dpipe, 2)) * 1 * 0.034;
              RadomeWeight = 0.1544 * Dradome * Lradome / 12;
              PoleWeight = Pi / 4 * 0.1 * (Math.pow(Dpipe, 2) - Math.pow((Dpipe - 2 * twall), 2)) * ((NLayers + 1) * lambda - 0.5);
              FinWeight = (2 * 0.4 * lambda * (0.8 * lambda + 1) * 0.125) * 0.1 * NLayers;
              PatternShaperWeight = (0.5 * 1 + lambda / 2 * 0.125) * (0.8 * lambda + 1) * NLayers * 0.1;
              PN48959 = 2 * 0.65; //End Flange
              PN75553 = 1.85; //Spacer Flange
              PN75554 = 0.62; // Tee Flange
              PN75730 = 0.6; //Vertical Lug
              PN75483 = 2 * 3.66; //End Short
              PN75539 = 2 * 1.75; //Bolt On Lug
              Inner = Pi / 4 * (Math.pow(1.315, 2) - Math.pow(1.23, 2)) * L * 0.323 + 5;
              Misc = 5;
              GasStopWeight = 10;
              TLWeight = 0.23 * (22 + 60);
              Elbow = 12; //Catalog Value
              FeedlineWeight = 300 * 0.148; //Andrew Catalog
              Weight = (RadomeWeight + EndSeals + PoleWeight + FinWeight + PatternShaperWeight + PN48959 + PN75553 + PN75554 + PN75730 + PN75539 + PN75483 + Inner + Misc + GasStopWeight + TLWeight + Elbow) * NumberSections + FeedlineWeight * numberFeedLines + PowerDividerWeight;
              Weight = Round(Weight, 0);


              // 'Ice Weight
              RadomeIce = Pi / 4 * (0.032) * (Math.pow((Dradome + 2 * Ice), 2) - Math.pow(Dradome, 2)) * (Lradome - 2 * Ice);
              BarePoleIce = Pi / 4 * (0.032) * (Math.pow((Dpipe + 2 * Ice), 2) - Math.pow(Dpipe, 2)) * (L - Lradome - 4 * Ice);
              EndFlangeIce = Pi / 4 * (0.032) * (Math.pow((5.25 + 2 * Ice), 2) - Math.pow((5.25), 2)) * (2 + 2 * Ice);
              TeeIce = Pi / 4 * (0.032) * (Math.pow((3.5 + 2 * Ice), 2) - Math.pow((3.5), 2)) * (3.84 - Ice);
              LugIce = ((3.75 + Ice) * (4.1 + Ice) - 0.5 * Math.pow((2.34 + Ice), 2)) * 2 * Ice * (0.032);
              EndLugIce = (Pi / 16 * (Math.pow((5.25 + 2 * Ice), 2) - Math.pow((5.25), 2)) + (5.25 + 2 * Ice) * (7.5 + Ice) - (1.375 + Ice) * (7.5 + Ice)) * 2 * Ice * (0.032);
              GasStopIce = Pi / 4 * (0.032) * (Math.pow((5.19 + 2 * Ice), 2) - Math.pow((5.19), 2)) * (2 * 0.5 + 1.375 + 2 * Ice);
              TLIce = Pi / 4 * (0.032) * (Math.pow((3.125 + 2 * Ice), 2) - Math.pow((3.125), 2)) * (22 + 15 + 60);
              FeedLineIce = Pi / 4 * (0.032) * (Math.pow((3.01 + 2 * Ice), 2) - Math.pow((3.01), 2)) * 300;
              IceWeight = (RadomeIce + BarePoleIce + EndFlangeIce + TeeIce + LugIce + EndLugIce + GasStopIce + TLIce + FeedLineIce) * NumberSections + PowerDividerIceWeight;
              Wice = Round(IceWeight, 0);

              // 'Total Weight
              TotalWeight = Weight + IceWeight;
              TotalWeight = Round(TotalWeight, 0);
            }
    },
    patternJ: function() {
            FinIce = (2.75 * Ice + Pi / 2 *  Math.pow(Ice, 2) + 2.234 * ( Math.pow(1.75 + Ice, 2) -  Math.pow(1.75, 2)) + 0.74 * lambda * Ice) * NLayers * (0.8 * lambda + 1 + 2 * Ice) * 0.032;
            //constants
            Pi = Math.PI;
            if ((optSlotCover == true)) {
              // 'Area Wind Direction1
              Lfin = 0.8 * lambda + 1;
              FinWidth = 2 * (0.4 * lambda + Dpipe / 2) * Math.cos(32.5 * Pi / 180);
              FinnedArea = (FinWidth + 2 * Ice) * NLayers * (Lfin + 2 * Ice) * FlatAppurtDrag(WSpec, FinWidth + 2 * Ice, NLayers * (Lfin + 2 * Ice) / 12, z, V, ExpCat, StrCl, Topo);
              SlotCovers = (Dpipe + 2 * Ice) * (L - NLayers * (Lfin + 2 * Ice)) * FlatAppurtDrag(WSpec, Dpipe + 2 * Ice, (L - NLayers * (Lfin + 2 * Ice)) / 12, z, V, ExpCat, StrCl, Topo);
              EndFlanges = (2 + 2 * Ice) * (5.25 + 2 * Ice) * AppurtDrag(WSpec, 5.25 + 2 * Ice, (2 + 2 * Ice) / 12, z, V, Y, ExpCat, StrCl, Topo, IncludeIce);
              EndLugs = 2 * (5.25 + 2 * Ice) * (0.5 + 2 * Ice) * AppurtDrag(WSpec, 5.25 + 2 * Ice, (0.5 + 2 * Ice) / 12, z, V, Y, ExpCat, StrCl, Topo, IncludeIce);
              TL = ((9 + 60) * (3.125 + 2 * Ice) + 4 * (0.5 + 2 * Ice) * (5.19 + 2 * Ice)) * AppurtDrag(WSpec, 3.125 + 2 * Ice, (60 + 9) / 12, z, V, Y, ExpCat, StrCl, Topo, IncludeIce);
              Feedline = (300) * (3.01 + 2 * Ice) * AppurtDrag(WSpec, 3.01 + 2 * Ice, 300 / 12, z, V, Y, ExpCat, StrCl, Topo, IncludeIce);
              Adir1 = Round(((NumberSections * (SlotCovers + FinnedArea + EndFlanges + EndLugs + TL) + Feedline * numberFeedLines + PowerDividerArea)) / 144, 2);
              Amax = ((NumberSections * (SlotCovers + FinnedArea + EndFlanges + EndLugs + TL) + Feedline * numberFeedLines + PowerDividerArea)) / 144;
              RDir1 = Round(G * Amax * Q, 0);

              // 'Area Wind Direction2
              FinHeight = 3.625 + (0.4 * lambda + Dpipe / 2) * Math.sin(32.5 * Pi / 180);
              FinnedArea = (FinHeight + 2 * Ice) * NLayers * (Lfin + 2 * Ice) * FlatAppurtDrag(WSpec, FinHeight + 2 * Ice, NLayers * (Lfin + 2 * Ice) / 12, z, V, ExpCat, StrCl, Topo);
              SlotCovers = (5.375 + 2 * Ice) * (L - NLayers * (Lfin + 2 * Ice)) * FlatAppurtDrag(WSpec, 5.375 + 2 * Ice, (L - NLayers * (Lfin + 2 * Ice)) / 12, z, V, ExpCat, StrCl, Topo);
              EndFlanges = (2 + 2 * Ice) * (5.25 + 2 * Ice) * AppurtDrag(WSpec, 5.25 + 2 * Ice, (2 + 2 * Ice) / 12, z, V, Y, ExpCat, StrCl, Topo, IncludeIce);
              Tee = (3.84 - Ice) * (3.5 + 2 * Ice) * AppurtDrag(WSpec, 3.5 + 2 * Ice, (3.84 - Ice) / 12, z, V, Y, ExpCat, StrCl, Topo, IncludeIce);
              Lug = (((3.75 + Ice) * (4.1 + Ice) - 0.5 * Math.pow((2.34 + Ice), 2))) * FlatAppurtDrag(WSpec, 3.75 + Ice, (4.1 + Ice) / 12, z, V, ExpCat, StrCl, Topo);
              EndLugs = 2 * (10.125 + 2 * Ice) * (0.5 + 2 * Ice) * FlatAppurtDrag(WSpec, 10.125 + 2 * Ice, (0.5 + 2 * Ice) / 12, z, V, ExpCat, StrCl, Topo);
              GasStop = (5.19 + 2 * Ice) * (2 * 0.5 + 1.375 + Ice) * AppurtDrag(WSpec, 5.19 + 2 * Ice, (2 * 0.5 + 1.375 + Ice) / 12, z, V, Y, ExpCat, StrCl, Topo, IncludeIce);
              TL = ((22 + 15 + 60) * (3.125 + 2 * Ice) + 6 * (0.5 + 2 * Ice) * (5.19 + 2 * Ice)) * AppurtDrag(WSpec, 3.125 + 2 * Ice, (22 + 15 + 60) / 12, z, V, Y, ExpCat, StrCl, Topo, IncludeIce);
              Feedline = (300) * (3.01 + 2 * Ice) * AppurtDrag(WSpec, 3.01 + 2 * Ice, 300 / 12, z, V, Y, ExpCat, StrCl, Topo, IncludeIce);
              Adir2 = Round(((NumberSections * (FinnedArea + SlotCovers + EndFlanges + EndLugs + TL + Tee + GasStop) + Feedline * numberFeedLines + PowerDividerArea)) / 144, 2);
              A = ((NumberSections * (FinnedArea + SlotCovers + EndFlanges + EndLugs + TL + Tee + GasStop) + Feedline * numberFeedLines + PowerDividerArea)) / 144;
              //Calculate the shear force
            RDir2 = Round(G * A * Q, 0);
            if (A > Amax) {
                Amax = A;
            }
            RMax = Round(G * Amax * Q, 0);
            Amax = Round(Amax, 2);
            
            // 'Weight
            FinWeight = (2 * 0.4 * lambda * Lfin * 0.125) * 0.1 * NLayers;
            SlotCoverWeight = (L - 7) * (0.02);
            PoleWeight = Pi / 4 * 0.1 * (Math.pow(Dpipe, 2) - Math.pow((Dpipe - 2 * twall), 2)) * ((NLayers + 1) * lambda - 0.5);
            PN48959 = 2 * 0.65; // 'End Flange
            PN75553 = 1.85; // 'Spacer Flange
            PN75554 = 0.62; // ' Tee Flange
            PN75730 = 0.6; // 'Vertical Lug
            PN75483 = 2 * 3.66; // 'End Short
            PN75539 = 2 * 1.75; // 'Bolt On Lug
            Inner = Pi / 4 * ( Math.pow(1.315, 2) -  Math.pow(1.23, 2)) * L * 0.323 + 5;
            Misc = 5;
            GasStopWeight = 10;
            TLWeight = 0.23 * (22 + 60);
            Elbow = 12; // 'Catalog Value
            FeedlineWeight = 300 * 0.148; // 'Andrew Catalog
            Weight = (SlotCoverWeight + PoleWeight + FinWeight + PN48959 + PN75553 + PN75554 + PN75730 + PN75539 + PN75483 + Inner + Misc + GasStopWeight + TLWeight + Elbow) * NumberSections + FeedlineWeight * numberFeedLines + PowerDividerWeight;
            Weight = Round(Weight, 3);
            
            // 'Ice Weight
            FinIce = (2.75 * Ice + Pi / 2 * Math.pow(Ice, 2) + 2.234 * (Math.pow((1.75 + Ice), 2) - Math.pow((1.75), 2)) + 0.8 * lambda * Ice) * NLayers * (Lfin + 2 * Ice) * 0.032;
            SlotCoverIce = (2.75 * Ice + Pi / 2 * Math.pow(Ice, 2) + 2.234 * (Math.pow((1.75 + Ice), 2) - Math.pow((1.75), 2))) * (L - NLayers * (Lfin + 2 * Ice)) * 0.032;
            BarePoleIce = Pi / 4 * (0.032) * (Math.pow((Dpipe + 2 * Ice), 2) - Math.pow(Dpipe, 2)) * (L - 6.4 * lambda - 11 - 18 * Ice);
            EndFlangeIce = Pi / 4 * (0.032) * (Math.pow((5.25 + 2 * Ice), 2) - Math.pow((5.25), 2)) * (2 + 2 * Ice);
            TeeIce = Pi / 4 * (0.032) * (Math.pow((3.5 + 2 * Ice), 2) - Math.pow((3.5), 2)) * (3.84 - Ice);
            LugIce = ((3.75 + Ice) * (4.1 + Ice) - 0.5 * Math.pow((2.34 + Ice), 2)) * 2 * Ice * (0.032);
            EndLugIce = (Pi / 16 * (Math.pow((5.25 + 2 * Ice), 2) - Math.pow((5.25), 2)) + (5.25 + 2 * Ice) * (7.5 + Ice) - (1.375 + Ice) * (7.5 + Ice)) * 2 * Ice * (0.032);
            GasStopIce = Pi / 4 * (0.032) * (Math.pow((5.19 + 2 * Ice), 2) - Math.pow((5.19), 2)) * (2 * 0.5 + 1.375 + 2 * Ice);
            TLIce = Pi / 4 * (0.032) * (Math.pow((3.125 + 2 * Ice), 2) - Math.pow((3.125), 2)) * (22 + 15 + 60);
            FeedLineIce = Pi / 4 * (0.032) * (Math.pow((3.01 + 2 * Ice), 2) - Math.pow((3.01), 2)) * 300;
            IceWeight = (SlotCoverIce + FinIce + BarePoleIce + EndFlangeIce + TeeIce + LugIce + EndLugIce + GasStopIce + TLIce + FeedLineIce) * NumberSections + PowerDividerIceWeight;
            Wice = Round(IceWeight, 3);
            
            // 'Total Weight
            TotalWeight = Weight + IceWeight;
            TotalWeight = Round(TotalWeight, 3);
            
    } else if (optRadome == true) {
            // 'Area Direction 1
            Dradome = RadDia;
            Lradome = (L - S - 7);
            Lfin = 0.8 * lambda + 1;
            Radome = (L - S - 7 + 2 * Ice) * (RadDia + 2 * Ice) * AppurtDrag(WSpec, (RadDia + 2 * Ice), SingleSectionH2, z, V, Y, ExpCat, StrCl, Topo, IncludeIce);
            BarePole = (2 - 2 * Ice) * (3.5 + 2 * Ice) * AppurtDrag(WSpec, (3.5 + 2 * Ice), SingleSectionH2, z, V, Y, ExpCat, StrCl, Topo, IncludeIce);
            EndFlanges = (2 + 2 * Ice) * (5.25 + 2 * Ice) * AppurtDrag(WSpec, 5.25 + 2 * Ice, (2 + 2 * Ice) / 12, z, V, Y, ExpCat, StrCl, Topo, IncludeIce);
            EndLugs = 2 * (5.25 + 2 * Ice) * (0.5 + 2 * Ice) * AppurtDrag(WSpec, 5.25 + 2 * Ice, (0.5 + 2 * Ice) / 12, z, V, Y, ExpCat, StrCl, Topo, IncludeIce);
            TL = ((60 + 9) * (3.125 + 2 * Ice) + 4 * (0.5 + 2 * Ice) * (5.19 + 2 * Ice)) * AppurtDrag(WSpec, 3.125 + 2 * Ice, (60 + 9) / 12, z, V, Y, ExpCat, StrCl, Topo, IncludeIce);
            Feedline = (300) * (3.01 + 2 * Ice) * AppurtDrag(WSpec, 3.01 + 2 * Ice, 300 / 12, z, V, Y, ExpCat, StrCl, Topo, IncludeIce);
            Adir1 = Round(((NumberSections * (Radome + BarePole + EndFlanges + EndLugs + TL) + Feedline * numberFeedLines + PowerDividerArea)) / 144, 2);
            Amax = ((NumberSections * (Radome + BarePole + EndFlanges + EndLugs + TL) + Feedline * numberFeedLines + PowerDividerArea)) / 144;
            RDir1 = Round(G * Amax * Q, 0);
            
            // 'Area Direction 2
            Radome = (Lradome + 2 * Ice) * (Dradome + 2 * Ice) * AppurtDrag(WSpec, (Dradome + 2 * Ice), (Lradome + 2 * Ice) / 12, z, V, Y, ExpCat, StrCl, Topo, IncludeIce);
            BarePole = (L - Lradome - 4 * Ice) * (Dpipe + 2 * Ice) * AppurtDrag(WSpec, (Dpipe + 2 * Ice), (L - Lradome - 4 * Ice) / 12, z, V, Y, ExpCat, StrCl, Topo, IncludeIce);
            EndFlanges = (2 + 2 * Ice) * (5.25 + 2 * Ice) * AppurtDrag(WSpec, 5.25 + 2 * Ice, (2 + 2 * Ice) / 12, z, V, Y, ExpCat, StrCl, Topo, IncludeIce);
            Tee = (3.84 - Ice) * (3.5 + 2 * Ice) * AppurtDrag(WSpec, 3.5 + 2 * Ice, (3.84 - Ice) / 12, z, V, Y, ExpCat, StrCl, Topo, IncludeIce);
            Lug = (((3.75 + Ice) * (4.1 + Ice)) - 0.5 * Math.pow((2.35 + Ice), 2)) * FlatAppurtDrag(WSpec, 3.75 + Ice, (4.1 + Ice) / 12, z, V, ExpCat, StrCl, Topo);
            EndLugs = 2 * (10.125 + 2 * Ice) * (0.5 + 2 * Ice) * FlatAppurtDrag(WSpec, 10.125 + 2 * Ice, (0.5 + 2 * Ice) / 12, z, V, ExpCat, StrCl, Topo);
            GasStop = (5.19 + 2 * Ice) * (2 * 0.5 + 1.375 + Ice) * AppurtDrag(WSpec, 5.19 + 2 * Ice, (2 * 0.5 + 1.375 + Ice) / 12, z, V, Y, ExpCat, StrCl, Topo, IncludeIce);
            TL = ((22 + 15 + 60) * (3.125 + 2 * Ice) + 6 * (0.5 + 2 * Ice) * (5.19 + 2 * Ice)) * AppurtDrag(WSpec, 3.125 + 2 * Ice, (22 + 15 + 60) / 12, z, V, Y, ExpCat, StrCl, Topo, IncludeIce);
            Feedline = (300) * (3.01 + 2 * Ice) * AppurtDrag(WSpec, 3.01 + 2 * Ice, 300 / 12, z, V, Y, ExpCat, StrCl, Topo, IncludeIce);
            Adir2 = Round(((NumberSections * (Radome + BarePole + EndFlanges + EndLugs + TL + Tee + GasStop) + Feedline * numberFeedLines + PowerDividerArea)) / 144, 2);
            A = ((NumberSections * (Radome + BarePole + EndFlanges + EndLugs + TL + Tee + GasStop) + Feedline * numberFeedLines + PowerDividerArea)) / 144;
            // 'Calculate the shear force
            RDir2 = Round(G * A * Q, 0);
            if (A > Amax) {
                Amax = A;
            }
            RMax = Round(G * Amax * Q, 0);
            Amax = Round(Amax, 2);
            
            // 'Weight
            EndSeals = Pi * (Math.pow(Dradome, 2) - Math.pow(Dpipe, 2)) * 1 * 0.034;
            RadomeWeight = 0.1544 * Dradome * Lradome / 12;
            PoleWeight = Pi / 4 * 0.1 * (Math.pow(Dpipe, 2) - Math.pow((Dpipe - 2 * twall), 2)) * ((NLayers + 1) * lambda - 0.5);
            FinWeight = (2 * 0.37 * lambda * (0.8 * lambda + 1) * 0.125) * 0.1 * NLayers;
            PatternShaperWeight = (0.5 * 1 + lambda / 2 * 0.125) * (0.8 * lambda + 1) * NLayers * 0.1;
            PN48959 = 2 * 0.65; // 'End Flange
            PN75553 = 1.85; // 'Spacer Flange
            PN75554 = 0.62; // ' Tee Flange
            PN75730 = 0.6; // 'Vertical Lug
            PN75483 = 2 * 3.66; // 'End Short
            PN75539 = 2 * 1.75; // 'Bolt On Lug
            Inner = Pi / 4 * ( Math.pow(1.315, 2) -  Math.pow(1.23, 2)) * L * 0.323 + 5;
            Misc = 5;
            GasStopWeight = 10;
            TLWeight = 0.23 * (22 + 60);
            Elbow = 12; // 'Catalog Value
            FeedlineWeight = 300 * 0.148; // 'Andrew Catalog
            Weight = (RadomeWeight + EndSeals + PoleWeight + FinWeight + PatternShaperWeight + PN48959 + PN75553 + PN75554 + PN75730 + PN75539 + PN75483 + Inner + Misc + GasStopWeight + TLWeight + Elbow) * NumberSections + FeedlineWeight * numberFeedLines + PowerDividerWeight;
            Weight = Round(Weight, 3);
    
            
            // 'Ice Weight
            RadomeIce = Pi / 4 * (0.032) * (Math.pow((Dradome + 2 * Ice), 2) - Math.pow(Dradome, 2)) * (Lradome - 2 * Ice);
            BarePoleIce = Pi / 4 * (0.032) * (Math.pow((Dpipe + 2 * Ice), 2) - Math.pow(Dpipe, 2)) * (L - Lradome - 4 * Ice);
            EndFlangeIce = Pi / 4 * (0.032) * (Math.pow((5.25 + 2 * Ice), 2) - Math.pow((5.25), 2)) * (2 + 2 * Ice);
            TeeIce = Pi / 4 * (0.032) * (Math.pow((3.5 + 2 * Ice), 2) - Math.pow((3.5), 2)) * (3.84 - Ice);
            LugIce = ((3.75 + Ice) * (4.1 + Ice) - 0.5 * Math.pow((2.34 + Ice), 2)) * 2 * Ice * (0.032);
            EndLugIce = (Pi / 16 * (Math.pow((5.25 + 2 * Ice), 2) - Math.pow((5.25), 2)) + (5.25 + 2 * Ice) * (7.5 + Ice) - (1.375 + Ice) * (7.5 + Ice)) * 2 * Ice * (0.032);
            GasStopIce = Pi / 4 * (0.032) * (Math.pow((5.19 + 2 * Ice), 2) - Math.pow((5.19), 2)) * (2 * 0.5 + 1.375 + 2 * Ice);
            TLIce = Pi / 4 * (0.032) * (Math.pow((3.125 + 2 * Ice), 2) - Math.pow((3.125), 2)) * (22 + 15 + 60);
            FeedLineIce = Pi / 4 * (0.032) * (Math.pow((3.01 + 2 * Ice), 2) - Math.pow((3.01), 2)) * 300;
            IceWeight = (RadomeIce + BarePoleIce + EndFlangeIce + TeeIce + LugIce + EndLugIce + GasStopIce + TLIce + FeedLineIce) * NumberSections + PowerDividerIceWeight;
            Wice = Round(IceWeight, 3);
    
            // 'Total Weight
            TotalWeight = Weight + IceWeight;
            TotalWeight = Round(TotalWeight, 3);
        }
      },

      patternM: function() {
                //constants
                Pi = Math.PI;
                if ((optSlotCover == true)) {
                  // 'Area Wind Direction1
                  Lfin = 0.8 * lambda + 1;
                  FinWidth = 2 * (0.4 * lambda + Dpipe / 2) * Math.cos(15 * Pi / 180);
                  FinnedArea = (FinWidth + 2 * Ice) * NLayers * (Lfin + 2 * Ice) * FlatAppurtDrag(WSpec, FinWidth + 2 * Ice, NLayers * (Lfin + 2 * Ice) / 12, z, V, ExpCat, StrCl, Topo);
                  SlotCovers = (Dpipe + 2 * Ice) * (L - NLayers * (Lfin + 2 * Ice)) * FlatAppurtDrag(WSpec, Dpipe + 2 * Ice, (L - NLayers * (Lfin + 2 * Ice)) / 12, z, V, ExpCat, StrCl, Topo);
                  EndFlanges = (2 + 2 * Ice) * (5.25 + 2 * Ice) * AppurtDrag(WSpec, 5.25 + 2 * Ice, (2 + 2 * Ice) / 12, z, V, Y, ExpCat, StrCl, Topo, IncludeIce);
                  EndLugs = 2 * (5.25 + 2 * Ice) * (0.5 + 2 * Ice) * AppurtDrag(WSpec, 5.25 + 2 * Ice, (0.5 + 2 * Ice) / 12, z, V, Y, ExpCat, StrCl, Topo, IncludeIce);
                  TL = ((9 + 60) * (3.125 + 2 * Ice) + 4 * (0.5 + 2 * Ice) * (5.19 + 2 * Ice)) * AppurtDrag(WSpec, 3.125 + 2 * Ice, (60 + 9) / 12, z, V, Y, ExpCat, StrCl, Topo, IncludeIce);
                  Feedline = (300) * (3.01 + 2 * Ice) * AppurtDrag(WSpec, 3.01 + 2 * Ice, 300 / 12, z, V, Y, ExpCat, StrCl, Topo, IncludeIce);
                  Adir1 = Round(((NumberSections * (SlotCovers + FinnedArea + EndFlanges + EndLugs + TL) + Feedline * numberFeedLines + PowerDividerArea)) / 144, 2);
                  Amax = ((NumberSections * (SlotCovers + FinnedArea + EndFlanges + EndLugs + TL) + Feedline * numberFeedLines + PowerDividerArea)) / 144;
                  RDir1 = Round(G * Amax * Q, 0);

                  // 'Area Wind Direction2
                  FinHeight = 3.625 + (0.4 * lambda + Dpipe / 2) * Math.sin(15 * Pi / 180);
                  FinnedArea = (FinHeight + 2 * Ice) * NLayers * (Lfin + 2 * Ice) * FlatAppurtDrag(WSpec, FinHeight + 2 * Ice, NLayers * (Lfin + 2 * Ice) / 12, z, V, ExpCat, StrCl, Topo);
                  SlotCovers = (5.375 + 2 * Ice) * (L - NLayers * (Lfin + 2 * Ice)) * FlatAppurtDrag(WSpec, 5.375 + 2 * Ice, (L - NLayers * (Lfin + 2 * Ice)) / 12, z, V, ExpCat, StrCl, Topo);
                  EndFlanges = (2 + 2 * Ice) * (5.25 + 2 * Ice) * AppurtDrag(WSpec, 5.25 + 2 * Ice, (2 + 2 * Ice) / 12, z, V, Y, ExpCat, StrCl, Topo, IncludeIce);
                  Tee = (3.84 - Ice) * (3.5 + 2 * Ice) * AppurtDrag(WSpec, 3.5 + 2 * Ice, (3.84 - Ice) / 12, z, V, Y, ExpCat, StrCl, Topo, IncludeIce);
                  Lug = (((3.75 + Ice) * (4.1 + Ice) - 0.5 * Math.pow((2.34 + Ice), 2))) * FlatAppurtDrag(WSpec, 3.75 + Ice, (4.1 + Ice) / 12, z, V, ExpCat, StrCl, Topo);
                  EndLugs = 2 * (10.125 + 2 * Ice) * (0.5 + 2 * Ice) * FlatAppurtDrag(WSpec, 10.125 + 2 * Ice, (0.5 + 2 * Ice) / 12, z, V, ExpCat, StrCl, Topo);
                  GasStop = (5.19 + 2 * Ice) * (2 * 0.5 + 1.375 + Ice) * AppurtDrag(WSpec, 5.19 + 2 * Ice, (2 * 0.5 + 1.375 + Ice) / 12, z, V, Y, ExpCat, StrCl, Topo, IncludeIce);
                  TL = ((22 + 15 + 60) * (3.125 + 2 * Ice) + 6 * (0.5 + 2 * Ice) * (5.19 + 2 * Ice)) * AppurtDrag(WSpec, 3.125 + 2 * Ice, (22 + 15 + 60) / 12, z, V, Y, ExpCat, StrCl, Topo, IncludeIce);
                  Feedline = (300) * (3.01 + 2 * Ice) * AppurtDrag(WSpec, 3.01 + 2 * Ice, 300 / 12, z, V, Y, ExpCat, StrCl, Topo, IncludeIce);
                  Adir2 = Round(((NumberSections * (FinnedArea + SlotCovers + EndFlanges + EndLugs + TL + Tee + GasStop) + Feedline * numberFeedLines + PowerDividerArea)) / 144, 2);
                  A = ((NumberSections * (FinnedArea + SlotCovers + EndFlanges + EndLugs + TL + Tee + GasStop) + Feedline * numberFeedLines + PowerDividerArea)) / 144;
                  // 'Calculate the shear force
                  RDir2 = Round(G * A * Q, 0);
                  if (A > Amax) {
                      Amax = A;
                  }
                  RMax = Round(G * Amax * Q, 0);
                  Amax = Round(Amax, 2);
                  
                  // 'Weight
                  FinWeight = (2 * 0.4 * lambda * Lfin * 0.125) * 0.1 * NLayers;
                  SlotCoverWeight = (L - 7) * (0.02);
                  PoleWeight = Pi / 4 * 0.1 * (Math.pow(Dpipe, 2) - Math.pow((Dpipe - 2 * twall), 2)) * ((NLayers + 1) * lambda - 0.5);
                  PN48959 = 2 * 0.65; // 'End Flange
                  PN75553 = 1.85; // 'Spacer Flange
                  PN75554 = 0.62; // ' Tee Flange
                  PN75730 = 0.6; // 'Vertical Lug
                  PN75483 = 2 * 3.66; // 'End Short
                  PN75539 = 2 * 1.75; // 'Bolt On Lug
                  Inner = Pi / 4 * ( Math.pow(1.315, 2) -  Math.pow(1.23, 2)) * L * 0.323 + 5;
                  Misc = 5;
                  GasStopWeight = 10;
                  TLWeight = 0.23 * (22 + 60);
                  Elbow = 12; // 'Catalog Value
                  FeedlineWeight = 300 * 0.148; // 'Andrew Catalog
                  Weight = (SlotCoverWeight + PoleWeight + FinWeight + PN48959 + PN75553 + PN75554 + PN75730 + PN75539 + PN75483 + Inner + Misc + GasStopWeight + TLWeight + Elbow) * NumberSections + FeedlineWeight * numberFeedLines + PowerDividerWeight;
                  Weight = Round(Weight, 3);
                  
                  // 'Ice Weight
                  FinIce = (2.75 * Ice + Pi / 2 * Math.pow(Ice, 2) + 2.234 * (Math.pow((1.75 + Ice), 2) - Math.pow((1.75), 2)) + 0.8 * lambda * Ice) * NLayers * (Lfin + 2 * Ice) * 0.032;
                  SlotCoverIce = (2.75 * Ice + Pi / 2 * Math.pow(Ice, 2) + 2.234 * (Math.pow((1.75 + Ice), 2) - Math.pow((1.75), 2))) * (L - NLayers * (Lfin + 2 * Ice)) * 0.032;
                  BarePoleIce = Pi / 4 * (0.032) * (Math.pow((Dpipe + 2 * Ice), 2) - Math.pow(Dpipe, 2)) * (L - 6.4 * lambda - 11 - 18 * Ice);
                  EndFlangeIce = Pi / 4 * (0.032) * (Math.pow((5.25 + 2 * Ice), 2) - Math.pow((5.25), 2)) * (2 + 2 * Ice);
                  TeeIce = Pi / 4 * (0.032) * (Math.pow((3.5 + 2 * Ice), 2) - Math.pow((3.5), 2)) * (3.84 - Ice);
                  LugIce = ((3.75 + Ice) * (4.1 + Ice) - 0.5 * Math.pow((2.34 + Ice), 2)) * 2 * Ice * (0.032);
                  EndLugIce = (Pi / 16 * (Math.pow((5.25 + 2 * Ice), 2) - Math.pow((5.25), 2)) + (5.25 + 2 * Ice) * (7.5 + Ice) - (1.375 + Ice) * (7.5 + Ice)) * 2 * Ice * (0.032);
                  GasStopIce = Pi / 4 * (0.032) * (Math.pow((5.19 + 2 * Ice), 2) - Math.pow((5.19), 2)) * (2 * 0.5 + 1.375 + 2 * Ice);
                  TLIce = Pi / 4 * (0.032) * (Math.pow((3.125 + 2 * Ice), 2) - Math.pow((3.125), 2)) * (22 + 15 + 60);
                  FeedLineIce = Pi / 4 * (0.032) * (Math.pow((3.01 + 2 * Ice), 2) - Math.pow((3.01), 2)) * 300;
                  IceWeight = (SlotCoverIce + FinIce + BarePoleIce + EndFlangeIce + TeeIce + LugIce + EndLugIce + GasStopIce + TLIce + FeedLineIce) * NumberSections + PowerDividerIceWeight;
                  Wice = Round(IceWeight, 3);
                  
                  // 'Total Weight
                  TotalWeight = Weight + IceWeight;
                  TotalWeight = Round(TotalWeight, 3);
                  
            } else if (optRadome == true) {
                  // 'Area Direction 1
                  Dradome = RadDia;
                  Lradome = (L - S - 7);
                  Lfin = 0.8 * lambda + 1;
                  Radome = (Lradome + 2 * Ice) * (Dradome + 2 * Ice) * AppurtDrag(WSpec, (Dradome + 2 * Ice), (Lradome + 2 * Ice) / 12, z, V, Y, ExpCat, StrCl, Topo, IncludeIce);
                  BarePole = (L - Lradome - 4 * Ice) * (Dpipe + 2 * Ice) * AppurtDrag(WSpec, (Dpipe + 2 * Ice), (L - Lradome - 4 * Ice) / 12, z, V, Y, ExpCat, StrCl, Topo, IncludeIce);
                  EndFlanges = (2 + 2 * Ice) * (5.25 + 2 * Ice) * AppurtDrag(WSpec, 5.25 + 2 * Ice, (2 + 2 * Ice) / 12, z, V, Y, ExpCat, StrCl, Topo, IncludeIce);
                  EndLugs = 2 * (5.25 + 2 * Ice) * (0.5 + 2 * Ice) * AppurtDrag(WSpec, 5.25 + 2 * Ice, (0.5 + 2 * Ice) / 12, z, V, Y, ExpCat, StrCl, Topo, IncludeIce);
                  TL = ((60 + 9) * (3.125 + 2 * Ice) + 4 * (0.5 + 2 * Ice) * (5.19 + 2 * Ice)) * AppurtDrag(WSpec, 3.125 + 2 * Ice, (60 + 9) / 12, z, V, Y, ExpCat, StrCl, Topo, IncludeIce);
                  Feedline = (300) * (3.01 + 2 * Ice) * AppurtDrag(WSpec, 3.01 + 2 * Ice, 300 / 12, z, V, Y, ExpCat, StrCl, Topo, IncludeIce);
                  Adir1 = Round(((NumberSections * (Radome + BarePole + EndFlanges + EndLugs + TL) + Feedline * numberFeedLines + PowerDividerArea)) / 144, 2);
                  Amax = ((NumberSections * (Radome + BarePole + EndFlanges + EndLugs + TL) + Feedline * numberFeedLines + PowerDividerArea)) / 144;
                  RDir1 = Round(G * Amax * Q, 0);
                  
                  // 'Area Direction 2
                  Radome = (Lradome + 2 * Ice) * (Dradome + 2 * Ice) * AppurtDrag(WSpec, (Dradome + 2 * Ice), (Lradome + 2 * Ice) / 12, z, V, Y, ExpCat, StrCl, Topo, IncludeIce);
                  BarePole = (L - Lradome - 4 * Ice) * (Dpipe + 2 * Ice) * AppurtDrag(WSpec, (Dpipe + 2 * Ice), (L - Lradome - 4 * Ice) / 12, z, V, Y, ExpCat, StrCl, Topo, IncludeIce);
                  EndFlanges = (2 + 2 * Ice) * (5.25 + 2 * Ice) * AppurtDrag(WSpec, 5.25 + 2 * Ice, (2 + 2 * Ice) / 12, z, V, Y, ExpCat, StrCl, Topo, IncludeIce);
                  Tee = (3.84 - Ice) * (3.5 + 2 * Ice) * AppurtDrag(WSpec, 3.5 + 2 * Ice, (3.84 - Ice) / 12, z, V, Y, ExpCat, StrCl, Topo, IncludeIce);
                  Lug = (((3.75 + Ice) * (4.1 + Ice)) - 0.5 *  Math.pow(2.35 + Ice, 2)) * FlatAppurtDrag(WSpec, 3.75 + Ice, (4.1 + Ice) / 12, z, V, ExpCat, StrCl, Topo);
                  EndLugs = 2 * (10.125 + 2 * Ice) * (0.5 + 2 * Ice) * FlatAppurtDrag(WSpec, 10.125 + 2 * Ice, (0.5 + 2 * Ice) / 12, z, V, ExpCat, StrCl, Topo);
                  GasStop = (5.19 + 2 * Ice) * (2 * 0.5 + 1.375 + Ice) * AppurtDrag(WSpec, 5.19 + 2 * Ice, (2 * 0.5 + 1.375 + Ice) / 12, z, V, Y, ExpCat, StrCl, Topo, IncludeIce);
                  TL = ((22 + 15 + 60) * (3.125 + 2 * Ice) + 6 * (0.5 + 2 * Ice) * (5.19 + 2 * Ice)) * AppurtDrag(WSpec, 3.125 + 2 * Ice, (22 + 15 + 60) / 12, z, V, Y, ExpCat, StrCl, Topo, IncludeIce);
                  Feedline = (300) * (3.01 + 2 * Ice) * AppurtDrag(WSpec, 3.01 + 2 * Ice, 300 / 12, z, V, Y, ExpCat, StrCl, Topo, IncludeIce);
                  Adir2 = Round(((NumberSections * (Radome + BarePole + EndFlanges + EndLugs + TL + Tee + GasStop) + Feedline * numberFeedLines + PowerDividerArea)) / 144, 2);
                  A = ((NumberSections * (Radome + BarePole + EndFlanges + EndLugs + TL + Tee + GasStop) + Feedline * numberFeedLines + PowerDividerArea)) / 144;
                  // 'Calculate the shear force
                  RDir2 = Round(G * A * Q, 0);
                  if (A > Amax) {
                      Amax = A;
                  }
                  RMax = Round(G * Amax * Q, 0);
                  Amax = Round(Amax, 2);
                  
                  // 'Weight
                  EndSeals = Pi * (Math.pow(Dradome, 2) - Math.pow(Dpipe, 2)) * 1 * 0.034;
                  RadomeWeight = 0.1544 * Dradome * Lradome / 12;
                  PoleWeight = Pi / 4 * 0.1 * (Math.pow(Dpipe, 2) - Math.pow((Dpipe - 2 * twall), 2)) * ((NLayers + 1) * lambda - 0.5);
                  FinWeight = (2 * 0.4 * lambda * (0.8 * lambda + 1) * 0.125) * 0.1 * NLayers;
                  PN48959 = 2 * 0.65; // 'End Flange
                  PN75553 = 1.85; // 'Spacer Flange
                  PN75554 = 0.62; // ' Tee Flange
                  PN75730 = 0.6; // 'Vertical Lug
                  PN75483 = 2 * 3.66; // 'End Short
                  PN75539 = 2 * 1.75; // 'Bolt On Lug
                  Inner = Pi / 4 * ( Math.pow(1.315, 2) -  Math.pow(1.23, 2)) * L * 0.323 + 5;
                  Misc = 5;
                  GasStopWeight = 10;
                  TLWeight = 0.23 * (22 + 60);
                  Elbow = 12; // 'Catalog Value
                  FeedlineWeight = 300 * 0.148; // 'Andrew Catalog
                  Weight = (RadomeWeight + EndSeals + PoleWeight + FinWeight + PN48959 + PN75553 + PN75554 + PN75730 + PN75539 + PN75483 + Inner + Misc + GasStopWeight + TLWeight + Elbow) * NumberSections + FeedlineWeight * numberFeedLines + PowerDividerWeight;
                  Weight = Round(Weight, 3);
          
                  
                  // 'Ice Weight
                  RadomeIce = Pi / 4 * (0.032) * (Math.pow((Dradome + 2 * Ice), 2) - Math.pow(Dradome, 2)) * (Lradome - 2 * Ice);
                  BarePoleIce = Pi / 4 * (0.032) * (Math.pow((Dpipe + 2 * Ice), 2) - Math.pow(Dpipe, 2)) * (L - Lradome - 4 * Ice);
                  EndFlangeIce = Pi / 4 * (0.032) * (Math.pow((5.25 + 2 * Ice), 2) - Math.pow((5.25), 2)) * (2 + 2 * Ice);
                  TeeIce = Pi / 4 * (0.032) * (Math.pow((3.5 + 2 * Ice), 2) - Math.pow((3.5), 2)) * (3.84 - Ice);
                  LugIce = ((3.75 + Ice) * (4.1 + Ice) - 0.5 * Math.pow((2.34 + Ice), 2)) * 2 * Ice * (0.032);
                  EndLugIce = (Pi / 16 * (Math.pow((5.25 + 2 * Ice), 2) - Math.pow((5.25), 2)) + (5.25 + 2 * Ice) * (7.5 + Ice) - (1.375 + Ice) * (7.5 + Ice)) * 2 * Ice * (0.032);
                  GasStopIce = Pi / 4 * (0.032) * (Math.pow((5.19 + 2 * Ice), 2) - Math.pow((5.19), 2)) * (2 * 0.5 + 1.375 + 2 * Ice);
                  TLIce = Pi / 4 * (0.032) * (Math.pow((3.125 + 2 * Ice), 2) - Math.pow((3.125), 2)) * (22 + 15 + 60);
                  FeedLineIce = Pi / 4 * (0.032) * (Math.pow((3.01 + 2 * Ice), 2) - Math.pow((3.01), 2)) * 300;
                  IceWeight = (RadomeIce + BarePoleIce + EndFlangeIce + TeeIce + LugIce + EndLugIce + GasStopIce + TLIce + FeedLineIce) * NumberSections + PowerDividerIceWeight;
                  Wice = Round(IceWeight, 3);
          
                  // 'Total Weight
                  TotalWeight = Weight + IceWeight;
                  TotalWeight = Round(TotalWeight, 3);
            }

      },
      patternN: function() {
            //constants
            Pi = Math.PI;
            if ((optSlotCover == true)) {
              // 'Area Wind Direction1
              Lfin = 0.8 * lambda + 1;
              FinnedArea = (lambda / 2 + 2 * Ice) * NLayers * (Lfin + 2 * Ice) * FlatAppurtDrag(WSpec, lambda / 2 + 2 * Ice, NLayers * (Lfin + 2 * Ice) / 12, z, V, ExpCat, StrCl, Topo);
              SlotCovers = (Dpipe + 2 * Ice) * (L - NLayers * (Lfin + 2 * Ice)) * FlatAppurtDrag(WSpec, Dpipe + 2 * Ice, (L - NLayers * (Lfin + 2 * Ice)) / 12, z, V, ExpCat, StrCl, Topo);
              EndFlanges = (2 + 2 * Ice) * (5.25 + 2 * Ice) * AppurtDrag(WSpec, 5.25 + 2 * Ice, (2 + 2 * Ice) / 12, z, V, Y, ExpCat, StrCl, Topo, IncludeIce);
              EndLugs = 2 * (5.25 + 2 * Ice) * (0.5 + 2 * Ice) * AppurtDrag(WSpec, 5.25 + 2 * Ice, (0.5 + 2 * Ice) / 12, z, V, Y, ExpCat, StrCl, Topo, IncludeIce);
              TL = ((9 + 60) * (3.125 + 2 * Ice) + 4 * (0.5 + 2 * Ice) * (5.19 + 2 * Ice)) * AppurtDrag(WSpec, 3.125 + 2 * Ice, (60 + 9) / 12, z, V, Y, ExpCat, StrCl, Topo, IncludeIce);
              FeedLineArea = (300) * (3.01 + 2 * Ice) * AppurtDrag(WSpec, 3.01 + 2 * Ice, 300 / 12, z, V, Y, ExpCat, StrCl, Topo, IncludeIce);
              Adir1 = Round(((NumberSections * (SlotCovers + FinnedArea + EndFlanges + EndLugs + TL) + FeedLineArea * numberFeedLines + PowerDividerArea)) / 144, 2);
              Amax = ((NumberSections * (SlotCovers + FinnedArea + EndFlanges + EndLugs + TL) + FeedLineArea * numberFeedLines + PowerDividerArea)) / 144;
              RDir1 = Round(G * Amax * Q, 0);
              
                  // 'Area Wind Direction2
                  FinnedArea = (5.375 + 0.125 + 2 * Ice) * NLayers * (Lfin + 2 * Ice) * FlatAppurtDrag(WSpec, 5.375 + 0.125 + 2 * Ice, NLayers * (Lfin + 2 * Ice) / 12, z, V, ExpCat, StrCl, Topo);
                  SlotCovers = (5.375 + 2 * Ice) * (L - NLayers * (Lfin + 2 * Ice)) * FlatAppurtDrag(WSpec, 5.375 + 2 * Ice, (L - NLayers * (Lfin + 2 * Ice)) / 12, z, V, ExpCat, StrCl, Topo);
                  EndFlanges = (2 + 2 * Ice) * (5.25 + 2 * Ice) * AppurtDrag(WSpec, 5.25 + 2 * Ice, (2 + 2 * Ice) / 12, z, V, Y, ExpCat, StrCl, Topo, IncludeIce);
                  Tee = (3.84 - Ice) * (3.5 + 2 * Ice) * AppurtDrag(WSpec, 3.5 + 2 * Ice, (3.84 - Ice) / 12, z, V, Y, ExpCat, StrCl, Topo, IncludeIce);
                  Lug = (((3.75 + Ice) * (4.1 + Ice) - 0.5 * Math.pow((2.34 + Ice), 2))) * FlatAppurtDrag(WSpec, 3.75 + Ice, (4.1 + Ice) / 12, z, V, ExpCat, StrCl, Topo);
                  EndLugs = 2 * (10.125 + 2 * Ice) * (0.5 + 2 * Ice) * FlatAppurtDrag(WSpec, 10.125 + 2 * Ice, (0.5 + 2 * Ice) / 12, z, V, ExpCat, StrCl, Topo);
                  GasStop = (5.19 + 2 * Ice) * (2 * 0.5 + 1.375 + Ice) * AppurtDrag(WSpec, 5.19 + 2 * Ice, (2 * 0.5 + 1.375 + Ice) / 12, z, V, Y, ExpCat, StrCl, Topo, IncludeIce);
                  TL = ((22 + 15 + 60) * (3.125 + 2 * Ice) + 6 * (0.5 + 2 * Ice) * (5.19 + 2 * Ice)) * AppurtDrag(WSpec, 3.125 + 2 * Ice, (22 + 15 + 60) / 12, z, V, Y, ExpCat, StrCl, Topo, IncludeIce);
                  FeedLineArea = (300) * (3.01 + 2 * Ice) * AppurtDrag(WSpec, 3.01 + 2 * Ice, 300 / 12, z, V, Y, ExpCat, StrCl, Topo, IncludeIce);
                  Adir2 = Round(((NumberSections * (FinnedArea + SlotCovers + EndFlanges + EndLugs + TL + Tee + GasStop) + FeedLineArea * numberFeedLines + PowerDividerArea)) / 144, 2);
                  A = ((NumberSections * (FinnedArea + SlotCovers + EndFlanges + EndLugs + TL + Tee + GasStop) + FeedLineArea * numberFeedLines + PowerDividerArea)) / 144;
                  // 'Calculate the shear force
                  RDir2 = Round(G * A * Q, 0);
                  if (A > Amax) {
                      Amax = A;
                  }
                  RMax = Round(G * Amax * Q, 0);
                  Amax = Round(Amax, 2);
                  
                  // 'Weight
                  PatternShaperWeight = lambda / 2 * 0.125 * Lfin * NLayers * 0.1;
                  SlotCoverWeight = (L - 7) * (0.02);
                  PoleWeight = Pi / 4 * 0.1 * (Math.pow(Dpipe, 2) - Math.pow((Dpipe - 2 * twall), 2)) * ((NLayers + 1) * lambda - 0.5);
                  PN48959 = 2 * 0.65; // 'End Flange
                  PN75553 = 1.85; // 'Spacer Flange
                  PN75554 = 0.62; // ' Tee Flange
                  PN75730 = 0.6; // 'Vertical Lug
                  PN75483 = 2 * 3.66; // 'End Short
                  PN75539 = 2 * 1.75; // 'Bolt On Lug
                  Inner = Pi / 4 * ( Math.pow(1.315, 2) -  Math.pow(1.23, 2)) * L * 0.323 + 5;
                  Misc = 5;
                  GasStopWeight = 10;
                  TLWeight = 0.23 * (22 + 60);
                  Elbow = 12; // 'Catalog Value
                  FeedlineWeight = 300 * 0.148; // 'Andrew Catalog
                  Weight = (SlotCoverWeight + PoleWeight + PatternShaperWeight + PN48959 + PN75553 + PN75554 + PN75730 + PN75539 + PN75483 + Inner + Misc + GasStopWeight + TLWeight + Elbow) * NumberSections + FeedlineWeight * numberFeedLines + PowerDividerWeight;
                  Weight = Round(Weight, 3);
                  
                  // 'Ice Weight
                  SlotCoverIce = (2.75 * Ice + Pi / 2 * Math.pow(Ice, 2) + 2.234 * (Math.pow((1.75 + Ice), 2) - Math.pow((1.75), 2))) * (L - NLayers * (Lfin + 2 * Ice)) * 0.032;
                  PatternShaperIce = (2 * 0.5 * lambda + 2 * Ice) * NLayers * (Lfin + 2 * Ice) * 0.032;
                  BarePoleIce = Pi / 4 * (0.032) * (Math.pow((Dpipe + 2 * Ice), 2) - Math.pow(Dpipe, 2)) * (L - 6.4 * lambda - 11 - 18 * Ice);
                  EndFlangeIce = Pi / 4 * (0.032) * (Math.pow((5.25 + 2 * Ice), 2) - Math.pow((5.25), 2)) * (2 + 2 * Ice);
                  TeeIce = Pi / 4 * (0.032) * (Math.pow((3.5 + 2 * Ice), 2) - Math.pow((3.5), 2)) * (3.84 - Ice);
                  LugIce = ((3.75 + Ice) * (4.1 + Ice) - 0.5 * Math.pow((2.34 + Ice), 2)) * 2 * Ice * (0.032);
                  EndLugIce = (Pi / 16 * (Math.pow((5.25 + 2 * Ice), 2) - Math.pow((5.25), 2)) + (5.25 + 2 * Ice) * (7.5 + Ice) - (1.375 + Ice) * (7.5 + Ice)) * 2 * Ice * (0.032);
                  GasStopIce = Pi / 4 * (0.032) * (Math.pow((5.19 + 2 * Ice), 2) - Math.pow((5.19), 2)) * (2 * 0.5 + 1.375 + 2 * Ice);
                  TLIce = Pi / 4 * (0.032) * (Math.pow((3.125 + 2 * Ice), 2) - Math.pow((3.125), 2)) * (22 + 15 + 60);
                  FeedLineIce = Pi / 4 * (0.032) * (Math.pow((3.01 + 2 * Ice), 2) - Math.pow((3.01), 2)) * 300;
                  IceWeight = (SlotCoverIce + BarePoleIce + PatternShaperIce + EndFlangeIce + TeeIce + LugIce + EndLugIce + GasStopIce + TLIce + FeedLineIce) * NumberSections + PowerDividerIceWeight;
                  Wice = Round(IceWeight, 3);
                  
                  // 'Total Weight
                  TotalWeight = Weight + IceWeight;
                  TotalWeight = Round(TotalWeight, 3);
                  
            } else if (optRadome == true) {
                  // 'Area Direction 1
                  Dradome = RadDia;
                  Lradome = (L - S - 7);
                  Lfin = 0.8 * lambda + 1;
                  Radome = (Lradome + 2 * Ice) * (Dradome + 2 * Ice) * AppurtDrag(WSpec, (Dradome + 2 * Ice), (Lradome + 2 * Ice) / 12, z, V, Y, ExpCat, StrCl, Topo, IncludeIce);
                  BarePole = (L - Lradome - 4 * Ice) * (Dpipe + 2 * Ice) * AppurtDrag(WSpec, (Dpipe + 2 * Ice), (L - Lradome - 4 * Ice) / 12, z, V, Y, ExpCat, StrCl, Topo, IncludeIce);
                  EndFlanges = (2 + 2 * Ice) * (5.25 + 2 * Ice) * AppurtDrag(WSpec, 5.25 + 2 * Ice, (2 + 2 * Ice) / 12, z, V, Y, ExpCat, StrCl, Topo, IncludeIce);
                  EndLugs = 2 * (5.25 + 2 * Ice) * (0.5 + 2 * Ice) * AppurtDrag(WSpec, 5.25 + 2 * Ice, (0.5 + 2 * Ice) / 12, z, V, Y, ExpCat, StrCl, Topo, IncludeIce);
                  TL = ((60 + 9) * (3.125 + 2 * Ice) + 4 * (0.5 + 2 * Ice) * (5.19 + 2 * Ice)) * AppurtDrag(WSpec, 3.125 + 2 * Ice, (60 + 9) / 12, z, V, Y, ExpCat, StrCl, Topo, IncludeIce);
                  Feedline = (300) * (3.01 + 2 * Ice) * AppurtDrag(WSpec, 3.01 + 2 * Ice, 300 / 12, z, V, Y, ExpCat, StrCl, Topo, IncludeIce);
                  Adir1 = Round(((NumberSections * (Radome + BarePole + EndFlanges + EndLugs + TL) + Feedline * numberFeedLines + PowerDividerArea)) / 144, 2);
                  Amax = ((NumberSections * (Radome + BarePole + EndFlanges + EndLugs + TL) + Feedline * numberFeedLines + PowerDividerArea)) / 144;
                  RDir1 = Round(G * Amax * Q, 0);
                  
                  // 'Area Direction 2
                  Radome = (Lradome + 2 * Ice) * (Dradome + 2 * Ice) * AppurtDrag(WSpec, (Dradome + 2 * Ice), (Lradome + 2 * Ice) / 12, z, V, Y, ExpCat, StrCl, Topo, IncludeIce);
                  BarePole = (L - Lradome - 4 * Ice) * (Dpipe + 2 * Ice) * AppurtDrag(WSpec, (Dpipe + 2 * Ice), (L - Lradome - 4 * Ice) / 12, z, V, Y, ExpCat, StrCl, Topo, IncludeIce);
                  EndFlanges = (2 + 2 * Ice) * (5.25 + 2 * Ice) * AppurtDrag(WSpec, 5.25 + 2 * Ice, (2 + 2 * Ice) / 12, z, V, Y, ExpCat, StrCl, Topo, IncludeIce);
                  Tee = (3.84 - Ice) * (3.5 + 2 * Ice) * AppurtDrag(WSpec, 3.5 + 2 * Ice, (3.84 - Ice) / 12, z, V, Y, ExpCat, StrCl, Topo, IncludeIce);
                  Lug = (((3.75 + Ice) * (4.1 + Ice)) - 0.5 *  Math.pow((2.35 + Ice), 2)) * FlatAppurtDrag(WSpec, 3.75 + Ice, (4.1 + Ice) / 12, z, V, ExpCat, StrCl, Topo);
                  EndLugs = 2 * (10.125 + 2 * Ice) * (0.5 + 2 * Ice) * FlatAppurtDrag(WSpec, 10.125 + 2 * Ice, (0.5 + 2 * Ice) / 12, z, V, ExpCat, StrCl, Topo);
                  GasStop = (5.19 + 2 * Ice) * (2 * 0.5 + 1.375 + Ice) * AppurtDrag(WSpec, 5.19 + 2 * Ice, (2 * 0.5 + 1.375 + Ice) / 12, z, V, Y, ExpCat, StrCl, Topo, IncludeIce);
                  TL = ((22 + 15 + 60) * (3.125 + 2 * Ice) + 6 * (0.5 + 2 * Ice) * (5.19 + 2 * Ice)) * AppurtDrag(WSpec, 3.125 + 2 * Ice, (22 + 15 + 60) / 12, z, V, Y, ExpCat, StrCl, Topo, IncludeIce);
                  Feedline = (300) * (3.01 + 2 * Ice) * AppurtDrag(WSpec, 3.01 + 2 * Ice, 300 / 12, z, V, Y, ExpCat, StrCl, Topo, IncludeIce);
                  Adir2 = Round(((NumberSections * (Radome + BarePole + EndFlanges + EndLugs + TL + Tee + GasStop) + Feedline * numberFeedLines + PowerDividerArea)) / 144, 2);
                  A = ((NumberSections * (Radome + BarePole + EndFlanges + EndLugs + TL + Tee + GasStop) + Feedline * numberFeedLines + PowerDividerArea)) / 144;
                  // 'Calculate the shear force
                  RDir2 = Round(G * A * Q, 0);
                  if (A > Amax) {
                      Amax = A;
                  }
                  RMax = Round(G * Amax * Q, 0);
                  Amax = Round(Amax, 2);
                  
                  // 'Weight
                  EndSeals = Pi * (Math.pow(Dradome, 2) - Math.pow(Dpipe, 2)) * 1 * 0.034;
                  RadomeWeight = 0.1544 * Dradome * Lradome / 12;
                  PoleWeight = Pi / 4 * 0.1 * (Math.pow(Dpipe, 2) - Math.pow((Dpipe - 2 * twall), 2)) * ((NLayers + 1) * lambda - 0.5);
                  FinWeight = (2 * NLayers) * (0.1) * (0.4 * lambda) * (0.8 * lambda + 1) * 0.125;
                  PatternShaperWeight = (0.5 * 1 + lambda / 2 * 0.125) * (0.8 * lambda + 1) * NLayers * 0.1;
                  PN48959 = 2 * 0.65; // 'End Flange
                  PN75553 = 1.85; // 'Spacer Flange
                  PN75554 = 0.62; // ' Tee Flange
                  PN75730 = 0.6; // 'Vertical Lug
                  PN75483 = 2 * 3.66; // 'End Short
                  PN75539 = 2 * 1.75; // 'Bolt On Lug
                  Inner = Pi / 4 * ( Math.pow(1.315, 2) -  Math.pow(1.23, 2)) * L * 0.323 + 5;
                  Misc = 5;
                  GasStopWeight = 10;
                  TLWeight = 0.23 * (22 + 60);
                  Elbow = 12; // 'Catalog Value
                  FeedlineWeight = 300 * 0.148; // 'Andrew Catalog
                  Weight = (RadomeWeight + EndSeals + PoleWeight + FinWeight + PatternShaperWeight + PN48959 + PN75553 + PN75554 + PN75730 + PN75539 + PN75483 + Inner + Misc + GasStopWeight + TLWeight + Elbow) * NumberSections + FeedlineWeight * numberFeedLines + PowerDividerWeight;
                  Weight = Round(Weight, 3);
          
                  
                  // 'Ice Weight
                  RadomeIce = Pi / 4 * (0.032) * (Math.pow((Dradome + 2 * Ice), 2) - Math.pow(Dradome, 2)) * (Lradome - 2 * Ice);
                  BarePoleIce = Pi / 4 * (0.032) * (Math.pow((Dpipe + 2 * Ice), 2) - Math.pow(Dpipe, 2)) * (L - Lradome - 4 * Ice);
                  EndFlangeIce = Pi / 4 * (0.032) * ( Math.pow(5.25 + 2 * Ice, 2) -  Math.pow(5.25, 2)) * (2 + 2 * Ice);
                  TeeIce = Pi / 4 * (0.032) * ( Math.pow(3.5 + 2 * Ice, 2) -  Math.pow(3.5, 2)) * (3.84 - Ice);
                  LugIce = ((3.75 + Ice) * (4.1 + Ice) - 0.5 *  Math.pow(2.34 + Ice, 2)) * 2 * Ice * (0.032);
                  EndLugIce = (Pi / 16 * ( Math.pow(5.25 + 2 * Ice, 2) -  Math.pow(5.25, 2)) + (5.25 + 2 * Ice) * (7.5 + Ice) - (1.375 + Ice) * (7.5 + Ice)) * 2 * Ice * (0.032);
                  GasStopIce = Pi / 4 * (0.032) * ( Math.pow(5.19 + 2 * Ice, 2) -  Math.pow(5.19, 2)) * (2 * 0.5 + 1.375 + 2 * Ice);
                  TLIce = Pi / 4 * (0.032) * ( Math.pow(3.125 + 2 * Ice, 2) -  Math.pow(3.125, 2)) * (22 + 15 + 60);
                  FeedLineIce = Pi / 4 * (0.032) * ( Math.pow(3.01 + 2 * Ice, 2) -  Math.pow(3.01, 2)) * 300;
                  IceWeight = (RadomeIce + BarePoleIce + EndFlangeIce + TeeIce + LugIce + EndLugIce + GasStopIce + TLIce + FeedLineIce) * NumberSections + PowerDividerIceWeight;
                  Wice = Round(IceWeight, 3);
          
                  // 'Total Weight
                  TotalWeight = Weight + IceWeight;
                  TotalWeight = Round(TotalWeight, 3);
            }
      },
      patternO: function() {
            FinIce = (2.75 * Ice + Pi / 2 *  Math.pow(Ice, 2) + 2.234 * ( Math.pow(1.75 + Ice, 2) -  Math.pow(1.75, 2)) + 0.8 * lambda * Ice) * NLayers * (0.8 * lambda + 1 + 2 * Ice) * 0.032;
            //constants
            Pi = Math.PI;
            if ((optSlotCover == true)) {
              // 'Area Wind Direction1
              Lfin = 0.8 * lambda + 1
              FinWidth = 2 * 0.25 * lambda * Math.cos(55 * Pi / 180)
              FinnedArea = (FinWidth + 2 * Ice) * NLayers * (Lfin + 2 * Ice) * FlatAppurtDrag(WSpec, FinWidth + 2 * Ice, NLayers * (Lfin + 2 * Ice) / 12, z, V, ExpCat, StrCl, Topo);
              SlotCovers = (Dpipe + 2 * Ice) * (L - NLayers * (Lfin + 2 * Ice)) * FlatAppurtDrag(WSpec, Dpipe + 2 * Ice, (L - NLayers * (Lfin + 2 * Ice)) / 12, z, V, ExpCat, StrCl, Topo);
              EndFlanges = (2 + 2 * Ice) * (5.25 + 2 * Ice) * AppurtDrag(WSpec, 5.25 + 2 * Ice, (2 + 2 * Ice) / 12, z, V, Y, ExpCat, StrCl, Topo, IncludeIce);
              EndLugs = 2 * (5.25 + 2 * Ice) * (0.5 + 2 * Ice) * AppurtDrag(WSpec, 5.25 + 2 * Ice, (0.5 + 2 * Ice) / 12, z, V, Y, ExpCat, StrCl, Topo, IncludeIce);
              TL = ((9 + 60) * (3.125 + 2 * Ice) + 4 * (0.5 + 2 * Ice) * (5.19 + 2 * Ice)) * AppurtDrag(WSpec, 3.125 + 2 * Ice, (60 + 9) / 12, z, V, Y, ExpCat, StrCl, Topo, IncludeIce);
              Feedline = (300) * (3.01 + 2 * Ice) * AppurtDrag(WSpec, 3.01 + 2 * Ice, 300 / 12, z, V, Y, ExpCat, StrCl, Topo, IncludeIce);
              Adir1 = Round(((NumberSections * (SlotCovers + FinnedArea + EndFlanges + EndLugs + TL) + Feedline * numberFeedLines + PowerDividerArea)) / 144, 2);
              Amax = ((NumberSections * (SlotCovers + FinnedArea + EndFlanges + EndLugs + TL) + Feedline * numberFeedLines + PowerDividerArea)) / 144;
              RDir1 = Round(G * Amax * Q, 0);
                  
                  // 'Area Wind Direction2
                  FinHeight = 0.25 * lambda * Math.sin(55 * Pi / 180) + 1 + 5.375
                  FinnedArea = (FinHeight + 2 * Ice) * NLayers * (Lfin + 2 * Ice) * FlatAppurtDrag(WSpec, FinHeight + 2 * Ice, NLayers * (Lfin + 2 * Ice) / 12, z, V, ExpCat, StrCl, Topo);
                  SlotCovers = (5.375 + 2 * Ice) * (L - NLayers * (Lfin + 2 * Ice)) * FlatAppurtDrag(WSpec, 5.375 + 2 * Ice, (L - NLayers * (Lfin + 2 * Ice)) / 12, z, V, ExpCat, StrCl, Topo);
                  EndFlanges = (2 + 2 * Ice) * (5.25 + 2 * Ice) * AppurtDrag(WSpec, 5.25 + 2 * Ice, (2 + 2 * Ice) / 12, z, V, Y, ExpCat, StrCl, Topo, IncludeIce);
                  Tee = (3.84 - Ice) * (3.5 + 2 * Ice) * AppurtDrag(WSpec, 3.5 + 2 * Ice, (3.84 - Ice) / 12, z, V, Y, ExpCat, StrCl, Topo, IncludeIce);
                  Lug = (((3.75 + Ice) * (4.1 + Ice) - 0.5 *  Math.pow(2.34 + Ice, 2))) * FlatAppurtDrag(WSpec, 3.75 + Ice, (4.1 + Ice) / 12, z, V, ExpCat, StrCl, Topo);
                  EndLugs = 2 * (10.125 + 2 * Ice) * (0.5 + 2 * Ice) * FlatAppurtDrag(WSpec, 10.125 + 2 * Ice, (0.5 + 2 * Ice) / 12, z, V, ExpCat, StrCl, Topo);
                  GasStop = (5.19 + 2 * Ice) * (2 * 0.5 + 1.375 + Ice) * AppurtDrag(WSpec, 5.19 + 2 * Ice, (2 * 0.5 + 1.375 + Ice) / 12, z, V, Y, ExpCat, StrCl, Topo, IncludeIce);
                  TL = ((22 + 15 + 60) * (3.125 + 2 * Ice) + 6 * (0.5 + 2 * Ice) * (5.19 + 2 * Ice)) * AppurtDrag(WSpec, 3.125 + 2 * Ice, (22 + 15 + 60) / 12, z, V, Y, ExpCat, StrCl, Topo, IncludeIce);
                  Feedline = (300) * (3.01 + 2 * Ice) * AppurtDrag(WSpec, 3.01 + 2 * Ice, 300 / 12, z, V, Y, ExpCat, StrCl, Topo, IncludeIce);
                  Adir2 = Round(((NumberSections * (FinnedArea + SlotCovers + EndFlanges + EndLugs + TL + Tee + GasStop) + Feedline * numberFeedLines + PowerDividerArea)) / 144, 2);
                  A = ((NumberSections * (FinnedArea + SlotCovers + EndFlanges + EndLugs + TL + Tee + GasStop) + Feedline * numberFeedLines + PowerDividerArea)) / 144;
                  // 'Calculate the shear force
                  RDir2 = Round(G * A * Q, 0);
                  if (A > Amax) {
                      Amax = A;
                  }
                  RMax = Round(G * Amax * Q, 0);
                  Amax = Round(Amax, 2);
                  
                  // 'Weight
                  FinWeight = ((2 * 0.3 * lambda * 0.125 + 1 * 0.5) * Lfin) * 0.1 * NLayers;
                  SlotCoverWeight = (L - 7) * (0.02);
                  PoleWeight = Pi / 4 * 0.1 * (Math.pow(Dpipe, 2) - Math.pow((Dpipe - 2 * twall), 2)) * ((NLayers + 1) * lambda - 0.5);
                  PN48959 = 2 * 0.65; // 'End Flange
                  PN75553 = 1.85; // 'Spacer Flange
                  PN75554 = 0.62; // ' Tee Flange
                  PN75730 = 0.6; // 'Vertical Lug
                  PN75483 = 2 * 3.66; // 'End Short
                  PN75539 = 2 * 1.75; // 'Bolt On Lug
                  Inner = Pi / 4 * ( Math.pow(1.315, 2) -  Math.pow(1.23, 2)) * L * 0.323 + 5;
                  Misc = 5;
                  GasStopWeight = 10;
                  TLWeight = 0.23 * (22 + 60);
                  Elbow = 12; // 'Catalog Value
                  FeedlineWeight = 300 * 0.148; // 'Andrew Catalog
                  Weight = (SlotCoverWeight + PoleWeight + FinWeight + PN48959 + PN75553 + PN75554 + PN75730 + PN75539 + PN75483 + Inner + Misc + GasStopWeight + TLWeight + Elbow) * NumberSections + FeedlineWeight * numberFeedLines + PowerDividerWeight;
                  Weight = Round(Weight, 3);
                  
                  // 'Ice Weight
                  FinIce = 2 * (2 * (0.3 * lambda + Ice) + 1) * NLayers * (Lfin + 2 * Ice) * 0.032;
                  SlotCoverIce = (2.75 * Ice + Pi / 2 * Math.pow(Ice, 2) + 2.234 * (Math.pow((1.75 + Ice), 2) - Math.pow((1.75), 2))) * NLayers * (Lfin + 2 * Ice) * 0.032;
                  BarePoleIce = Pi / 4 * (0.032) * (Math.pow((Dpipe + 2 * Ice), 2) - Math.pow(Dpipe, 2)) * (L - 6.4 * lambda - 11 - 18 * Ice);
                  EndFlangeIce = Pi / 4 * (0.032) * ( Math.pow(5.25 + 2 * Ice, 2) -  Math.pow(5.25, 2)) * (2 + 2 * Ice);
                  TeeIce = Pi / 4 * (0.032) * ( Math.pow(3.5 + 2 * Ice, 2) -  Math.pow(3.5, 2)) * (3.84 - Ice);
                  LugIce = ((3.75 + Ice) * (4.1 + Ice) - 0.5 *  Math.pow(2.34 + Ice, 2)) * 2 * Ice * (0.032);
                  EndLugIce = (Pi / 16 * ( Math.pow(5.25 + 2 * Ice, 2) -  Math.pow(5.25, 2)) + (5.25 + 2 * Ice) * (7.5 + Ice) - (1.375 + Ice) * (7.5 + Ice)) * 2 * Ice * (0.032);
                  GasStopIce = Pi / 4 * (0.032) * ( Math.pow(5.19 + 2 * Ice, 2) -  Math.pow(5.19, 2)) * (2 * 0.5 + 1.375 + 2 * Ice);
                  TLIce = Pi / 4 * (0.032) * ( Math.pow(3.125 + 2 * Ice, 2) -  Math.pow(3.125, 2)) * (22 + 15 + 60);
                  FeedLineIce = Pi / 4 * (0.032) * ( Math.pow(3.01 + 2 * Ice, 2) -  Math.pow(3.01, 2)) * 300;
                  IceWeight = (SlotCoverIce + FinIce + BarePoleIce + EndFlangeIce + TeeIce + LugIce + EndLugIce + GasStopIce + TLIce + FeedLineIce) * NumberSections + PowerDividerIceWeight;
                  Wice = Round(IceWeight, 3);
                  
                  // 'Total Weight
                  TotalWeight = Weight + IceWeight;
                  TotalWeight = Round(TotalWeight, 3);
                  
          } else if (optRadome == true) {
                  // 'Area Direction 1
                  Dradome = RadDia;
                  Lradome = (L - S - 7);
                  Lfin = 0.8 * lambda + 1;
                  Radome = (Lradome + 2 * Ice) * (Dradome + 2 * Ice) * AppurtDrag(WSpec, (Dradome + 2 * Ice), (Lradome + 2 * Ice) / 12, z, V, Y, ExpCat, StrCl, Topo, IncludeIce);
                  BarePole = (L - Lradome - 4 * Ice) * (Dpipe + 2 * Ice) * AppurtDrag(WSpec, (Dpipe + 2 * Ice), (L - Lradome - 4 * Ice) / 12, z, V, Y, ExpCat, StrCl, Topo, IncludeIce);
                  EndFlanges = (2 + 2 * Ice) * (5.25 + 2 * Ice) * AppurtDrag(WSpec, 5.25 + 2 * Ice, (2 + 2 * Ice) / 12, z, V, Y, ExpCat, StrCl, Topo, IncludeIce);
                  EndLugs = 2 * (5.25 + 2 * Ice) * (0.5 + 2 * Ice) * AppurtDrag(WSpec, 5.25 + 2 * Ice, (0.5 + 2 * Ice) / 12, z, V, Y, ExpCat, StrCl, Topo, IncludeIce);
                  TL = ((60 + 9) * (3.125 + 2 * Ice) + 4 * (0.5 + 2 * Ice) * (5.19 + 2 * Ice)) * AppurtDrag(WSpec, 3.125 + 2 * Ice, (60 + 9) / 12, z, V, Y, ExpCat, StrCl, Topo, IncludeIce);
                  Feedline = (300) * (3.01 + 2 * Ice) * AppurtDrag(WSpec, 3.01 + 2 * Ice, 300 / 12, z, V, Y, ExpCat, StrCl, Topo, IncludeIce);
                  Adir1 = Round(((NumberSections * (Radome + BarePole + EndFlanges + EndLugs + TL) + Feedline * numberFeedLines + PowerDividerArea)) / 144, 2);
                  Amax = ((NumberSections * (Radome + BarePole + EndFlanges + EndLugs + TL) + Feedline * numberFeedLines + PowerDividerArea)) / 144;
                  RDir1 = Round(G * Amax * Q, 0);
                  
                  // 'Area Direction 2
                  Radome = (Lradome + 2 * Ice) * (Dradome + 2 * Ice) * AppurtDrag(WSpec, (Dradome + 2 * Ice), (Lradome + 2 * Ice) / 12, z, V, Y, ExpCat, StrCl, Topo, IncludeIce);
                  BarePole = (L - Lradome - 4 * Ice) * (Dpipe + 2 * Ice) * AppurtDrag(WSpec, (Dpipe + 2 * Ice), (L - Lradome - 4 * Ice) / 12, z, V, Y, ExpCat, StrCl, Topo, IncludeIce);
                  EndFlanges = (2 + 2 * Ice) * (5.25 + 2 * Ice) * AppurtDrag(WSpec, 5.25 + 2 * Ice, (2 + 2 * Ice) / 12, z, V, Y, ExpCat, StrCl, Topo, IncludeIce);
                  Tee = (3.84 - Ice) * (3.5 + 2 * Ice) * AppurtDrag(WSpec, 3.5 + 2 * Ice, (3.84 - Ice) / 12, z, V, Y, ExpCat, StrCl, Topo, IncludeIce);
                  Lug = (((3.75 + Ice) * (4.1 + Ice)) - 0.5 * Math.pow((2.35 + Ice), 2)) * FlatAppurtDrag(WSpec, 3.75 + Ice, (4.1 + Ice) / 12, z, V, ExpCat, StrCl, Topo);
                  EndLugs = 2 * (10.125 + 2 * Ice) * (0.5 + 2 * Ice) * FlatAppurtDrag(WSpec, 10.125 + 2 * Ice, (0.5 + 2 * Ice) / 12, z, V, ExpCat, StrCl, Topo);
                  GasStop = (5.19 + 2 * Ice) * (2 * 0.5 + 1.375 + Ice) * AppurtDrag(WSpec, 5.19 + 2 * Ice, (2 * 0.5 + 1.375 + Ice) / 12, z, V, Y, ExpCat, StrCl, Topo, IncludeIce);
                  TL = ((22 + 15 + 60) * (3.125 + 2 * Ice) + 6 * (0.5 + 2 * Ice) * (5.19 + 2 * Ice)) * AppurtDrag(WSpec, 3.125 + 2 * Ice, (22 + 15 + 60) / 12, z, V, Y, ExpCat, StrCl, Topo, IncludeIce);
                  Feedline = (300) * (3.01 + 2 * Ice) * AppurtDrag(WSpec, 3.01 + 2 * Ice, 300 / 12, z, V, Y, ExpCat, StrCl, Topo, IncludeIce);
                  Adir2 = Round(((NumberSections * (Radome + BarePole + EndFlanges + EndLugs + TL + Tee + GasStop) + Feedline * numberFeedLines + PowerDividerArea)) / 144, 2);
                  A = ((NumberSections * (Radome + BarePole + EndFlanges + EndLugs + TL + Tee + GasStop) + Feedline * numberFeedLines + PowerDividerArea)) / 144;
                  // 'Calculate the shear force
                  RDir2 = Round(G * A * Q, 0);
                  if (A > Amax) {
                      Amax = A;
                  }
                  RMax = Round(G * Amax * Q, 0);
                  Amax = Round(Amax, 2);
                  
                  // 'Weight
                  EndSeals = Pi * (Math.pow(Dradome, 2) - Math.pow(Dpipe, 2)) * 1 * 0.034;
                  RadomeWeight = 0.1544 * Dradome * Lradome / 12;
                  PoleWeight = Pi / 4 * 0.1 * (Math.pow(Dpipe, 2) - Math.pow((Dpipe - 2 * twall), 2)) * ((NLayers + 1) * lambda - 0.5);
                  FinWeight = ((2 * 0.3 * lambda * 0.125 + 1 * 0.5) * (0.8 * lambda + 1)) * 0.1 * NLayers;
                  PN48959 = 2 * 0.65; // 'End Flange
                  PN75553 = 1.85; // 'Spacer Flange
                  PN75554 = 0.62; // ' Tee Flange
                  PN75730 = 0.6; // 'Vertical Lug
                  PN75483 = 2 * 3.66; // 'End Short
                  PN75539 = 2 * 1.75; // 'Bolt On Lug
                  Inner = Pi / 4 * ( Math.pow(1.315, 2) -  Math.pow(1.23, 2)) * L * 0.323 + 5;
                  Misc = 5;
                  GasStopWeight = 10;
                  TLWeight = 0.23 * (22 + 60);
                  Elbow = 12; // 'Catalog Value
                  FeedlineWeight = 300 * 0.148; // 'Andrew Catalog
                  Weight = (RadomeWeight + EndSeals + PoleWeight + FinWeight + PN48959 + PN75553 + PN75554 + PN75730 + PN75539 + PN75483 + Inner + Misc + GasStopWeight + TLWeight + Elbow) * NumberSections + FeedlineWeight * numberFeedLines + PowerDividerWeight;
                  Weight = Round(Weight, 3);
          
                  
                  // 'Ice Weight
                  RadomeIce = Pi / 4 * (0.032) * (Math.pow((Dradome + 2 * Ice), 2) - Math.pow(Dradome, 2)) * (Lradome - 2 * Ice);
                  BarePoleIce = Pi / 4 * (0.032) * (Math.pow((Dpipe + 2 * Ice), 2) - Math.pow(Dpipe, 2)) * (L - Lradome - 4 * Ice);
                  EndFlangeIce = Pi / 4 * (0.032) * ( Math.pow(5.25 + 2 * Ice, 2) -  Math.pow(5.25, 2)) * (2 + 2 * Ice);
                  TeeIce = Pi / 4 * (0.032) * ( Math.pow(3.5 + 2 * Ice, 2) -  Math.pow(3.5, 2)) * (3.84 - Ice);
                  LugIce = ((3.75 + Ice) * (4.1 + Ice) - 0.5 *  Math.pow(2.34 + Ice, 2)) * 2 * Ice * (0.032);
                  EndLugIce = (Pi / 16 * ( Math.pow(5.25 + 2 * Ice, 2) -  Math.pow(5.25, 2)) + (5.25 + 2 * Ice) * (7.5 + Ice) - (1.375 + Ice) * (7.5 + Ice)) * 2 * Ice * (0.032);
                  GasStopIce = Pi / 4 * (0.032) * ( Math.pow(5.19 + 2 * Ice, 2) -  Math.pow(5.19, 2)) * (2 * 0.5 + 1.375 + 2 * Ice);
                  TLIce = Pi / 4 * (0.032) * ( Math.pow(3.125 + 2 * Ice, 2) -  Math.pow(3.125, 2)) * (22 + 15 + 60);
                  FeedLineIce = Pi / 4 * (0.032) * ( Math.pow(3.01 + 2 * Ice, 2) -  Math.pow(3.01, 2)) * 300;
                  IceWeight = (RadomeIce + BarePoleIce + EndFlangeIce + TeeIce + LugIce + EndLugIce + GasStopIce + TLIce + FeedLineIce) * NumberSections + PowerDividerIceWeight;
                  Wice = Round(IceWeight, 3);
          
                  // 'Total Weight
                  TotalWeight = Weight + IceWeight;
                  TotalWeight = Round(TotalWeight, 3);
            }
          }
            
        },
        TLPc: { // this is for the low power custom (in the high power option)
            get: function(_channel, azimuthpattern, elevationpattern, params) {
              if (defined(params))
                return self.calc.TLP.get(_channel, azimuthpattern, elevationpattern, params);
            }
        },
        TLP: {
            get: function(_channel, azimuthpattern, elevationpattern, params) {            
            if (defined(params))
              return this.calculate.apply(this, params);
            },
            BarePoleArea: function(/*Ice, WSpec, RadDia, z, V, Y, ExpCat, StrCl, Topo, IncludeIce*/) {
              WSpec = windSpec;
              if (!ExposeCat) var ExposeCat = "A";
              if (!StructClass) var StructClass = "I";
              if (!TopoCat) var TopoCat = 1;
              if (!IncludeIce) var IncludeIce = false;

                // define a constant for use in the BarePoleArea calculation
              var BPConst = (optRadome == true || pattern == 'A') ? 5 : 3.5; // not sure if it was intentional for pattern A to have the same for Radome and Slot Covers or if it's a typo.  Just take out the 2nd condition if A was in error.
              BarePoleArea = (2 + S - 0.5 - 4 * Ice) * (BPConst + 2 * Ice) * AppurtDrag(WSpec, (BPConst + 2 * RadDia + 0.1875 + 2 * Ice), SingleSectionH2, z, V, Y, ExposeCat, StructClass, TopoCat, IncludeIce); // 5 for optRadome, 3.5 for !;optRadome in (5 + 2 * Ice);
            },
            setupPatterns: function() {
              Pi = Math.PI;

              Channel = channel; // CInt(Channel)
              NumberSections = numberOfSections; // cmbNSections.Value
              NLayers = n; // cmbNLayers.Value
              GainNumber = gain; // cmbNLayers.Value * cmbNSections.Value
              Pattern = pattern; // cmbPattern.Value - currently it is 
              h = self.values.h;
              z = self.values.z;
              V = self.values.V;
              Y = h;
              Ice = 0;
              Qzg = self.helpers.Qzg;
              Qzf = self.helpers.Qzf;
              AppurtDrag = self.helpers.AppurtDrag;
              FlatAppurtDrag = self.helpers.FlatAppurtDrag;
              chkIce = self.values.chkIce;
              self.TLP.BarePoleArea();

              // 'get the wind spec related data
              WSpec = windSpec; //cmbWindSpec.Value
              G = self.helpers.Gust(WSpec, true, h);
              if (WSpec == "C") {} // for WindSpec 'C'.  Delete if we decide not to use
                  // z = txtElevation.Value
                  ZoneC = ["A","B","C"][ Math.round(2 *  Math.random())]; // cmbZoneC.Value // this is a user input from a dropdown, options A, B, C - until user input available choose at random.
                  Q = self.helpers.Qzc(ZoneC, z);
                  G = 1;
                  // Ice = 0;
                  if (chkIce == true) {
                      Ice = self.values.Ice// txtTiC.Value //typical Ice input
                      Q = 7; // txtQiC.Value // this is a user input (wind pressure with ice) 7 is dummy value
                  }
              if (WSpec == "F") {
                  Q = Qzf(z, h, V);      
                  if (chkIce == true) {
                      Ice = self.values.Ice;
                      Q = 0.75 * Q;
                  }
              }
              else if (WSpec == "G") {
                  StrCl = self.values.StrCl; // cmbStrCl.Value
                  ExpCat = self.values.ExpCat; // cmbExpCat.Value
                  Topo = self.values.Topo; // cmbTopo.Value
                  Q = Qzg(z, V, Y, ExpCat, StrCl, Topo);
                  IncludeIce = false;
                  if (chkIce == true) {
                      IncludeIce = true;
                      V = self.values.V_ice; // txtViG.Value
                      DesignIce = self.values.Ice;
                      Ice = self.helpers.tiz(DesignIce, z, Y, ExpCat, Topo, StrCl);  // done elsewhere
                      Q = Qzg(z, V, Y, ExpCat, StrCl, Topo);
                  }
              }
              FeedLineLength = 300;
              LugLength = 1.5;
              FinnedArea = 0;              
              FeedLineArea = FeedLineLength * (2 + 2 * Ice) * AppurtDrag(WSpec, 2 + 2 * Ice, FeedLineLength / 12, z, V, Y, ExpCat, StrCl, Topo, IncludeIce); // 52 matches!
              ElbowArea = (3 * (1.625 + (2 * Ice)) + (6 * (1.625 + (2 * Ice)))) * AppurtDrag(WSpec, 1.625 + (2 * Ice), 3 / 12, z, V, Y, ExpCat, StrCl, Topo, IncludeIce); // 52 matches!
              
              LugArea = LugLength * (6.5 + 3 * Ice) * FlatAppurtDrag(WSpec, 3.75 + Ice, (4.1 + Ice) / 12, z, V, ExpCat, StrCl, Topo); // 26 matches AD1, define AD2 in patternFn                
              HarnessWeight = (2 * 1.5) + ((0.243 * L) * 0.1) + ((0.075 * (L / 2)) * 0.322) + 0.165; //26 matches, universal;
              LugWeight = 7.5; //26 matches, universal;
              Misc = 5;
              Elbow = 3.5;
              FeedlineWeight = FeedLineLength * 1.04 / 12; //Andrew Catalog
              //if (pattern == 'A') {
              //    Inner = Pi / 4 * ( Math.pow(1.625, 2) -  Math.pow(1.527, 2)) * L * 0.323 + 5;
              //  } else
              //    Inner = Pi / 4 * ( Math.pow(1.315, 2) -  Math.pow(1.23, 2)) * L * 0.323 + 5;
              //if (!optRadome) {
              //    SlotCoverIce = (L - S - 7 - 2 * Ice) * (0.032) * (1.5 * (2.89) * ( Math.pow(1.59 + Ice, 2) -  Math.pow(1.59, 2)) + 1.5 * (0.71) * ( Math.pow(2.5 + Ice, 2) -  Math.pow(2.5, 2))); //13 !;optRadome
              //};
              Inner = 0;
              BarePoleIce = Pi / 4 * (0.032) * ( Math.pow(5 + 2 * Ice, 2) -  Math.pow(5, 2)) * (2 + S - 0.5 - 4 * Ice); //26;
              ElbowIce = (((Pi / 4) * ( Math.pow(1.625 + 2 * Ice, 2) -  Math.pow(1.625, 2)) * 5.6) + ((Pi / 4) * ( Math.pow(1.625 + 2 * Ice, 2) -  Math.pow(1.625, 2)) * 2.8)) * 0.032; //26;
              LugIce = 3 * 0.032 * (2 * (11.5 * 6.5 * Ice) + 2 * (11.5 * 0.5 * Ice) + 2 * (6.5 * 0.5 * Ice)); // 26;
              FeedLineIce = (Pi / 4) * ( Math.pow(1.98 + 2 * Ice, 2) -  Math.pow(1.98, 2)) * 0.032 * FeedLineLength; //26;
              if (optRadome) {
                Radome = (L - S - 3.875 + 2 * Ice) * (RadDia + 2 * Ice) * AppurtDrag(WSpec, (RadDia + 2 * Ice), SingleSectionH2 * 12, z, V, Y, ExpCat, StrCl, Topo, IncludeIce); // univeral optRadome;
                RadomeIce = Pi / 4 * (0.032) * ( Math.pow(RadDia + 2 * Ice, 2) -  Math.pow(RadDia, 2)) * (L - S - 3.875 + 2 * Ice);
              }
            },
            calculate: function(WindSpec, Ice_bool, t_ice, V_basic, V_basic_ice, NumberOfSections, TowerElevation, TowerHeight, TowerClearance, ExposeCat, TopoCat, StructClass, azimuthPattern, elevationPattern, Channel, Frequency, hasRadome, useVpolCheck) {            
                optRadome = hasRadome;
                chkVPol = useVpolCheck;
                // assign needed values
                self.values = {
                  WSpec: WindSpec,
                  chkIce: Ice_bool,
                  Ice: t_ice,
                  V: V_basic,
                  V_ice: V_basic_ice,
                  numSections: NumberOfSections,
                  z: TowerElevation,
                  h: TowerHeight,
                  baseClearance: TowerClearance, 
                  ExpCat: ExposeCat,
                  Topo: TopoCat,
                  StrCl: StructClass,
                  azimuthPattern: azimuthPattern,
                  elevationPattern: elevationPattern,
                  Channel: Channel
                };
                frequency = Frequency;
                channel = Channel;
                windSpec = WindSpec;  // WSpec in pattern function
                Ice = t_ice;                
                gain = parseInt(elevationPattern.substr(0, 2));
                //Determine number of slots per section;
                if (gain == 12) {
                    n = 12; //12 slots per section
                    numberSections = 1;
                } else {
                    n = 8; //8 slots per section
                    numberOfSections = gain / 8;
                };
                pattern = azimuthPattern.substr(azimuthPattern.lastIndexOf('-') + 1).toUpperCase();
                Ice = 0;

                // lambda = 11802.9 / (channel * 6 + 389);
                lambda = self.helpers.lambda(frequency);

                if (channel <= 30) {
                L = (lambda * (n + 1) + 1.125);
                } else {
                L = (lambda * (n + 0.5) + 1.125);
                }
                if (channel <= 30) {
                    Space = (2 * lambda) - (0.75 * lambda + 0.375) - (0.75 * lambda + 0.5);
                } else {
                    Space = (2 * lambda) - (0.5 * lambda + 0.375) - (0.5 * lambda + 0.5);
                }
                S = 0.7 * lambda - 2;
                SlotLength = 0.8 * lambda;
                SingleSectionH2 = L / 12;
                self.calc.TLP.powerDivider(Ice_bool);
                self.calc.TLP.setupPatterns();
                PowerDividerIceWeight = PowerDividerWeight;

                if (optRadome == true) {
                  AntennaDesignation = "TLP-" + gain + pattern + "-R (" + channel + ")";
                  AntType = AntennaDesignation;
                } else if (optSlotCover == true || !optRadome) {
                  AntennaDesignation = "TLP-" + gain + pattern + " (" + channel + ")";
                  AntType = AntennaDesignation;
                };                
                var patternFn = self.calc.TLP['pattern' + pattern];
                // if (defined(patternFn)) {
                //     patternFn();

                //     self._data = {
                //         weight: FinalWeight,
                //         areaFlats: ArevCmax,
                //         forceCoef: ArevFmax,
                //         momentArm: null
                //     };
                    
                //     return self._data;
                // }
                optStdRad = optRadome;


                if (defined(patternFn)) {
                    self.helpers.calcRadDia(pattern, optStdRad /*, optOptIce, optUserInp, OptBestFit*/);
                    patternFn();
                    if (!FinalWeight || typeof FinalWeight == 'undefined') FinalWeight = TotalWeight;                    
                    self._data = {
                        totalWeight: FinalWeight,
                        weight: (Ice_bool == true) ? TotalWeight : Weight,
                        iceWeight:(Ice_bool == true) ? IceWeight : 0,
                        areaFlats: (typeof ArevCMax != 'undefined') ? ArevCMax : null,
                        forceCoef: Amax,
                        momentArm: null
                    };
                    self._data.RadomeNote = RadomeNote;
                    self._data.AntennaDesignation = AntennaDesignation;
                    return self._data;
                }
                return angular.extend({}, self.dataDefaults);
            },            
            powerDivider: function(Ice_bool) {
              if (!Ice_bool || Ice_bool == false) Ice = 0;
                numberFeedLines = numberOfSections
                switch (numberOfSections) {
                case 1:
                    PowerDividerArea = 0;
                    PowerDividerWeight = 0;
                    numberFeedLines = 0;
                    break;
                case 2:
                //PN 56168;
                    PowerDividerArea = (0.5 + Ice) * (3.55 + 2 * Ice) + (10.71 - 2 * Ice) * (3.15 + 2 * Ice) + 2 * (1.18 - 2 * Ice) * (1.63 + 2 * Ice) + 2 * (0.44 + Ice) * (3.5 + 2 * Ice) + (3.55 + 2 * Ice) * (3.42 + 2 * Ice) + (2.4 + 2 * Ice) * (4.35 - Ice);
                    PowerDividerWeight = 22 + Math.PI/ 4 * (0.032) * (( Math.pow(3.55 + 2 * Ice, 2) -  Math.pow(3.55, 2)) * (0.5 + Ice) + ( Math.pow(3.15 + 2 * Ice, 2) -  Math.pow(3.15, 2)) * (10.71 - Ice) + 2 * ( Math.pow(1.63 + 2 * Ice, 2) -  Math.pow(1.63, 2)) * (1.18 - Ice) + 2 * ( Math.pow(3.5 + 2 * Ice, 2) -  Math.pow(3.5, 2)) * (0.44 + 2 * Ice)) + (0.032) * (( Math.pow(3.42 + 2 * Ice, 2) * (3.55 + 2 * Ice) -  Math.pow(3.42, 2) * (3.55)) + 2 * ((4.35 + Ice) *  Math.pow(2.4 + 2 * Ice, 2) - (4.35) *  Math.pow(2.4, 2))); //by measurement 1-21-2 JCB;
                    break;
                case 3:
                //PN 70214;
                    PowerDividerArea = (5 + 2 * Ice) * (0.47 + Ice) + (3.15 + 2 * Ice) * (3.88 + 2 * Ice) + (2.17 + 2 * Ice) * (4.19 + Ice) + (1.97 + 2 * Ice) * (13.13 + Ice) + (1.77 + 2 * Ice) * (4 + 13.75 + 2 * (2.35) + 7.69 + 7 * Ice) + 3 * (3.5 + 2 * Ice) * (0.44 + Ice);
                    PowerDividerWeight = 24 + Math.PI/ 4 * 0.032 * (( Math.pow(5 + 2 * Ice, 2) -  Math.pow(5, 2)) * (0.47 + Ice) + ( Math.pow(3.15 + 2 * Ice, 2) -  Math.pow(3.15, 2)) * (3.88 + 2 * Ice) + ( Math.pow(2.17 + 2 * Ice, 2) -  Math.pow(2.17, 2)) * (4.19 + Ice) + ( Math.pow(1.97 + 2 * Ice, 2) -  Math.pow(1.97, 2)) * (13.13 + Ice) + ( Math.pow(1.77 + 2 * Ice, 2) -  Math.pow(1.77, 2)) * (4 + 13.75 + 2 * (2.35) + 7.69 + 7 * Ice) + 3 * ( Math.pow(3.5 + 2 * Ice, 2) -  Math.pow(3.5, 2)) * (0.44 + Ice));
                    break;
                case 4:
                //PN 65841;
                    PowerDividerArea = (5 + 2 * Ice) * (0.47 + Ice) + (3.15 + 2 * Ice) * (9.75 + 10.69 - 4 * Ice) + (3.3 + 2 * Ice) * (3.54 + 2 * Ice) + 2 * (2.75 + 2 * Ice) * (4.23 + Ice) + 4 * (1.63 + 2 * Ice) * (6.06 - 2 * Ice) + 4 * (3.5 + 2 * Ice) * (0.44 + Ice);
                    PowerDividerWeight = 47 + Math.PI/ 4 * 0.032 * (( Math.pow(5 + 2 * Ice, 2) -  Math.pow(5, 2)) * (0.47 + Ice) + ( Math.pow(3.15 + 2 * Ice, 2) -  Math.pow(3.15, 2)) * (9.75 + 10.69 - 4 * Ice) + ((3.3 + 2 * Ice) *  Math.pow(3.54 + 2 * Ice, 2) - (3.3) *  Math.pow(3.54, 2)) + 2 * ( Math.pow(2.75 + 2 * Ice, 2) -  Math.pow(2.75, 2)) * (4.23 + Ice) + 4 * ( Math.pow(1.63 + 2 * Ice, 2) -  Math.pow(1.63, 2)) * (6.06 - 2 * Ice) + 4 * ( Math.pow(3.5 + 2 * Ice, 2) -  Math.pow(3.5, 2)) * (0.44 + Ice));
                    break;

                }
                numberFeedLines = numberOfSections;
                Dpipe = 3.5;
                twall = 0.25;

                if(pattern == 'A' || pattern == "A") {
                  Dpipe = 5;
                  twall = 0.25;
                  if (channel <= 20) {
                    Dpipe = 6;
                    twall = 0.5;
                  }
                }else if(pattern == 'W' || pattern == "W") {
                  Dpipe = 5;
                  twall = 0.25;
                }
                
            },
            patternA: function() {
                Dpipe = 5;
                twall = 0.25;
                if (channel <= 20) {
                  Dpipe = 6;
                  twall = 0.5;
                }

                if (!optRadome || optRadome != true) {
                  
                  FeedLineLength = 300;
                  LugLength = 1.5;
                  //powerDivider();


                  //Area Direction 1
                  SlotCovers = (L - 5 + 2 * Ice) * ((Dpipe + 3.55) + 1.5 + 2 * Ice) * FlatAppurtDrag(WSpec, (Dpipe + 3.55) + 2 * Ice, (L - 5 + 2 * Ice) / 12, z, V, ExpCat, StrCl, Topo);
                  BarePole = (5 - 2 * Ice) * (Dpipe + 2 * Ice) * AppurtDrag(WSpec, (Dpipe + 2 * Ice), (5 - 2 * Ice) / 12, z, V, Y, ExpCat, StrCl, Topo, IncludeIce);
                  LugArea = LugLength * (6.5 + 3 * Ice) * FlatAppurtDrag(WSpec, 6.5 + Ice, LugLength / 12, z, V, ExpCat, StrCl, Topo);
                  FeedLineArea = FeedLineLength * (2 + 2 * Ice) * AppurtDrag(WSpec, 2 + 2 * Ice, FeedLineLength / 12, z, V, Y, ExpCat, StrCl, Topo, IncludeIce);
                  ElbowArea = (3 * (1.625 + (2 * Ice)) + (6 * (1.625 + (2 * Ice)))) * AppurtDrag(WSpec, 1.625 + (2 * Ice), 3 / 12, z, V, Y, ExpCat, StrCl, Topo, IncludeIce);
                  Adir1 = Round(((NumberSections * (SlotCovers + BarePole + LugArea + ElbowArea) + FeedLineArea * numberFeedLines + PowerDividerArea)) / 144, 2);
                  Amax = ((NumberSections * (SlotCovers + BarePole + LugArea + ElbowArea) + FeedLineArea * numberFeedLines + PowerDividerArea)) / 144;
                  RDir1 = Round(G * Amax * Q, 0);

                  //Area Direction 2
                  SlotCovers = (L - 5 + 2 * Ice) * ((Dpipe + 2.56) + 2 * Ice) * FlatAppurtDrag(WSpec, (Dpipe + 2.56) + 2 * Ice, (L - 5 + 2 * Ice) / 12, z, V, ExpCat, StrCl, Topo);
                  BarePoleArea = (5 - 2 * Ice) * (Dpipe + 2 * Ice) * AppurtDrag(WSpec, Dpipe + 2 * Ice, (5 - 2 * Ice) / 12, z, V, Y, ExpCat, StrCl, Topo, IncludeIce);
                  LugArea = LugLength * (11.5 + 3 * Ice) * FlatAppurtDrag(WSpec, 3.75 + Ice, (11.5 + 3 * Ice) / 12, z, V, ExpCat, StrCl, Topo);
                  FeedLineArea = FeedLineLength * (2 + 2 * Ice) * AppurtDrag(WSpec, 2 + 2 * Ice, FeedLineLength / 12, z, V, Y, ExpCat, StrCl, Topo, IncludeIce);
                  ElbowArea = (3 * (1.625 + (2 * Ice)) + (6 * (1.625 + (2 * Ice)))) * AppurtDrag(WSpec, 1.625 + (2 * Ice), 3 / 12, z, V, Y, ExpCat, StrCl, Topo, IncludeIce);
                  Adir2 = Round(((NumberSections * (SlotCovers + BarePole + LugArea + ElbowArea) + FeedLineArea * numberFeedLines + PowerDividerArea)) / 144, 2);
                  A = ((NumberSections * (SlotCovers + BarePole + LugArea + ElbowArea) + FeedLineArea * numberFeedLines + PowerDividerArea)) / 144;
                  RDir2 = Round(G * A * Q, 0);
                  if (A > Amax) {
                        Amax = A;
                  }
                  RMax = Round(G * Amax * Q, 0);
                  Amax = Round(Amax, 2);

                  //Weight
                  PoleWeight = ((Pi / 4) * (Math.pow(Dpipe, 2) - Math.pow((Dpipe - 2 * twall), 2)) * L) * 0.1;
                  SlotCoverWeight = (L - 5) * 3 * (0.02);
                  HarnessWeight = (2 * 1.5) + ((0.243 * L) * 0.1) + ((0.075 * (L / 2)) * 0.322) + 0.165;
                  LugWeight = 7.5;
                  Misc = 5;
                  Elbow = 3.5;
                  FeedlineWeight = FeedLineLength * 1.04 / 12; //Andrew Catalog
                  Weight = (PoleWeight + SlotCoverWeight + Inner + Misc + Elbow) * NumberSections + (FeedlineWeight) * numberFeedLines + PowerDividerWeight;
                  Weight = Round(Weight, 3);
                  
                  //Ice Weight
                  SlotCoverIce = (L - 5 - 2 * Ice) * (0.032) * (1.5 * (2.89) * (Math.pow((1.59 + Ice), 2) - Math.pow((1.59), 2)) + 1.5 * (0.71) * (Math.pow((2.5 + Ice), 2) - Math.pow((2.5), 2)));
                  BarePoleIce = Pi / 4 * (0.032) * (Math.pow((Dpipe + 2 * Ice), 2) - Math.pow(Dpipe, 2)) * (5 - 2 * Ice);
                  ElbowIce = (((Pi / 4) * (Math.pow((1.625 + 2 * Ice), 2) - Math.pow((1.625), 2)) * 5.6) + ((Pi / 4) * (Math.pow((1.625 + 2 * Ice), 2) - Math.pow((1.625), 2)) * 2.8)) * 0.032;
                  LugIce = 3 * 0.032 * (2 * (11.5 * 6.5 * Ice) + 2 * (11.5 * 0.5 * Ice) + 2 * (6.5 * 0.5 * Ice));
                  FeedLineIce = (Pi / 4) * (Math.pow((1.98 + 2 * Ice), 2) - Math.pow((1.98), 2)) * 0.032 * FeedLineLength;
                  IceWeight = (SlotCoverIce + BarePoleIce + LugIce + FeedLineIce) * NumberSections + PowerDividerIceWeight;
                  Wice = Round(IceWeight, 3);

                  //Total Weight
                  TotalWeight =   Weight + IceWeight;
                  TotalWeight = Round(TotalWeight, 3);

            } else if (optRadome == true) {
                FeedLineLength = 300;
                LugLength = 1.5;
                //PowerDivider(Ice);
                //Area Direction 1
                Dradome = RadDia;
                Radome = (L - 5 + 2 * Ice) * (Dradome + 2 * Ice) * AppurtDrag(WSpec, Dradome + 2 * Ice, (L - 5 + 2 * Ice) / 12, z, V, Y, ExpCat, StrCl, Topo, IncludeIce);
                BarePole = (5 - 2 * Ice) * (Dpipe + 2 * Ice) * AppurtDrag(WSpec, Dpipe + 2 * Ice, (5 - 2 * Ice) / 12, z, V, Y, ExpCat, StrCl, Topo, IncludeIce);
                LugArea = LugLength * (6.5 + 3 * Ice) * FlatAppurtDrag(WSpec, 3.75 + Ice, (4.1 + Ice) / 12, z, V, ExpCat, StrCl, Topo);
                FeedLineArea = FeedLineLength * (2 + 2 * Ice) * AppurtDrag(WSpec, 2 + 2 * Ice, FeedLineLength / 12, z, V, Y, ExpCat, StrCl, Topo, IncludeIce);
                ElbowArea = (3 * (1.625 + (2 * Ice)) + (6 * (1.625 + (2 * Ice)))) * AppurtDrag(WSpec, 1.625 + (2 * Ice), 3 / 12, z, V, Y, ExpCat, StrCl, Topo, IncludeIce);
                Adir1 = Round(((NumberSections * (Radome + BarePole + LugArea + ElbowArea) + FeedLineArea * numberFeedLines + PowerDividerArea)) / 144, 2);
                Amax = ((NumberSections * (Radome + BarePole + LugArea + ElbowArea) + FeedLineArea * numberFeedLines + PowerDividerArea)) / 144;
                RDir1 = Round(G * Amax * Q, 0);

                //Area Direction 2
                Radome = (L - 5 + 2 * Ice) * (Dradome + 2 * Ice) * AppurtDrag(WSpec, Dradome + 2 * Ice, (L - 5 + 2 * Ice) / 12, z, V, Y, ExpCat, StrCl, Topo, IncludeIce);
                BarePoleArea = (5 - 2 * Ice) * (Dpipe + 2 * Ice) * AppurtDrag(WSpec, Dpipe + 2 * Ice, (5 - 2 * Ice) / 12, z, V, Y, ExpCat, StrCl, Topo, IncludeIce);
                LugArea = LugLength * (11.5 + 3 * Ice) * FlatAppurtDrag(WSpec, 3.75 + Ice, (11.5 + 3 * Ice) / 12, z, V, ExpCat, StrCl, Topo);
                FeedLineArea = FeedLineLength * (2 + 2 * Ice) * AppurtDrag(WSpec, 2 + 2 * Ice, FeedLineLength / 12, z, V, Y, ExpCat, StrCl, Topo, IncludeIce);
                ElbowArea = (3 * (1.625 + (2 * Ice)) + (6 * (1.625 + (2 * Ice)))) * AppurtDrag(WSpec, 1.625 + (2 * Ice), 3 / 12, z, V, Y, ExpCat, StrCl, Topo, IncludeIce);
                Adir2 = Round(((NumberSections * (Radome + BarePole + LugArea + ElbowArea) + FeedLineArea * numberFeedLines + PowerDividerArea)) / 144, 2);
                A = ((NumberSections * (Radome + BarePole + LugArea + ElbowArea) + FeedLineArea * numberFeedLines + PowerDividerArea)) / 144;
                RDir2 = Round(G * A * Q, 0);
                if (A > Amax) {
                      Amax = A;
                }
                RMax = Round(G * Amax * Q, 0);
                Amax = Round(Amax, 2);

                //Weight
                EndSeals = Pi * (Math.pow(Dradome, 2) - Math.pow(Dpipe, 2)) * 1 * 0.034;
                PoleWeight = ((Pi / 4) * (Math.pow(Dpipe, 2) - Math.pow((Dpipe - 2 * twall), 2)) * L) * 0.1;
                RadomeWeight = (0.1544 * Dradome + 2) * (L - 5) / 12;
                HarnessWeight = (2 * 1.5) + ((0.243 * L) * 0.1) + ((0.075 * (L / 2)) * 0.322) + 0.165;
                LugWeight = 7.5;
                Misc = 5;
                Elbow = 3.5;
                FeedlineWeight = FeedLineLength * 1.04 / 12; //Andrew Catalog
                Weight = (EndSeals + PoleWeight + RadomeWeight + Inner + Misc + Elbow) * NumberSections + (FeedlineWeight) * numberFeedLines + PowerDividerWeight;
                TotalWeight = Round(Weight, 3);

                //Ice Weight
                RadomeIce = Pi / 4 * (0.032) * (Math.pow((Dradome + 2 * Ice), 2) - Math.pow(Dradome, 2)) * (L - 5 + 2 * Ice);
                BarePoleIce = Pi / 4 * (0.032) * (Math.pow((Dpipe + 2 * Ice), 2) - Math.pow(Dpipe, 2)) * (5 - 2 * Ice);
                ElbowIce = (((Pi / 4) * (Math.pow((1.625 + 2 * Ice), 2) - Math.pow((1.625), 2)) * 5.6) + ((Pi / 4) * (Math.pow((1.625 + 2 * Ice), 2) - Math.pow((1.625), 2)) * 2.8)) * 0.032;
                LugIce = 3 * 0.032 * (2 * (11.5 * 6.5 * Ice) + 2 * (11.5 * 0.5 * Ice) + 2 * (6.5 * 0.5 * Ice));
                FeedLineIce = (Pi / 4) * (Math.pow((1.98 + 2 * Ice), 2) - Math.pow((1.98), 2)) * 0.032 * FeedLineLength;
                IceWeight = (RadomeIce + BarePoleIce + LugIce + FeedLineIce) * NumberSections + PowerDividerIceWeight;
                Wice = Round(IceWeight, 3);

                //Total Weight
                TotalWeight = Weight + IceWeight;
                TotalWeight = Round(TotalWeight, 3);
            }
            FinalWeight = TotalWeight;
      },
        patternB: function() {
          Dpipe = 3.5;
          twall = 0.25;
          if (!optRadome || optRadome == false) {
            FeedLineLength = 300;
            LugLength = 1.5;
            //PowerDivider(Ice);

            //Area Direction 1
            SlotCovers = (L - 5 + 2 * Ice) * (Dpipe + 2 * Ice) * FlatAppurtDrag(WSpec, Dpipe + 2 * Ice, (L - 5 + 2 * Ice) / 12, z, V, ExpCat, StrCl, Topo);
            BarePole = (5 - 2 * Ice) * (Dpipe + 2 * Ice) * AppurtDrag(WSpec, Dpipe + 2 * Ice, (5 - 2 * Ice) / 12, z, V, Y, ExpCat, StrCl, Topo, IncludeIce);
            LugArea = LugLength * (6.5 + 3 * Ice) * FlatAppurtDrag(WSpec, 3.75 + Ice, (4.1 + Ice) / 12, z, V, ExpCat, StrCl, Topo);
            FeedLineArea = FeedLineLength * (2 + 2 * Ice) * AppurtDrag(WSpec, 2 + 2 * Ice, FeedLineLength / 12, z, V, Y, ExpCat, StrCl, Topo, IncludeIce);
            ElbowArea = (3 * (1.625 + (2 * Ice)) + (6 * (1.625 + (2 * Ice)))) * AppurtDrag(WSpec, 1.625 + (2 * Ice), 3 / 12, z, V, Y, ExpCat, StrCl, Topo, IncludeIce);
            Adir1 = Round(((NumberSections * (SlotCovers + BarePole + LugArea + ElbowArea) + Feedline * numberFeedLines + PowerDividerArea)) / 144, 2);
            Amax = ((NumberSections * (SlotCovers + BarePole + LugArea + ElbowArea) + FeedLineArea * numberFeedLines + PowerDividerArea)) / 144;
            RDir1 = Round(G * Amax * Q, 0);

            //Area Direction 2
            SlotCovers = (L - 5 + 2 * Ice) * (5.375 + 2 * Ice) * FlatAppurtDrag(WSpec, 5.375 + 2 * Ice, (L - 5 + 2 * Ice) / 12, z, V, ExpCat, StrCl, Topo);
            BarePole = (5 - 2 * Ice) * (Dpipe + 2 * Ice) * AppurtDrag(WSpec, (Dpipe + 2 * Ice), (5 - 2 * Ice) / 12, z, V, Y, ExpCat, StrCl, Topo, IncludeIce);
            LugArea = LugLength * (11.5 + 3 * Ice) * FlatAppurtDrag(WSpec, 3.75 + Ice, (11.5 + 3 * Ice) / 12, z, V, ExpCat, StrCl, Topo);
            FeedLineArea = FeedLineLength * (2 + 2 * Ice) * AppurtDrag(WSpec, 2 + 2 * Ice, FeedLineLength / 12, z, V, Y, ExpCat, StrCl, Topo, IncludeIce);
            ElbowArea = (3 * (1.625 + (2 * Ice)) + (6 * (1.625 + (2 * Ice)))) * AppurtDrag(WSpec, 1.625 + (2 * Ice), 3 / 12, z, V, Y, ExpCat, StrCl, Topo, IncludeIce);
            Adir2 = Round(((NumberSections * (SlotCovers + BarePole + LugArea + ElbowArea) + FeedLineArea * numberFeedLines + PowerDividerArea)) / 144, 2);
            A = ((NumberSections * (SlotCovers + BarePole + LugArea + ElbowArea) + FeedLineArea * numberFeedLines + PowerDividerArea)) / 144;
            RDir2 = Round(G * A * Q, 0);
            if (A > Amax) {
                Amax = A;
            };
            RMax = Round(G * Amax * Q, 0);
            Amax = Round(Amax, 2);            

            //Weight
            PoleWeight = ((Pi / 4) * (Math.pow(Dpipe, 2) - Math.pow((Dpipe - 2 * twall), 2)) * L) * 0.1;
            SlotCoverWeight = (L - 5) * (0.03);
            HarnessWeight = (2 * 1.5) + ((0.243 * L) * 0.1) + ((0.075 * (L / 2)) * 0.322) + 0.165;
            LugWeight = 7.5;
            Misc = 5;
            Elbow = 3.5;
            FeedlineWeight = FeedLineLength * 1.04 / 12; //Andrew Catalog
            Weight = (PoleWeight + SlotCoverWeight + HarnessWeight + LugWeight + Misc + Elbow) * NumberSections + (FeedlineWeight) * numberFeedLines + PowerDividerWeight;
            TotalWeight = Round(Weight, 0);

            //Ice Weight
            SlotCoverIce = (L - 5 - 2 * Ice) * (0.032) * (1.5 * (2.89) * (Math.pow((1.59 + Ice), 2) - Math.pow((1.59), 2)) + 1.5 * (0.71) * (Math.pow((2.5 + Ice), 2) - Math.pow((2.5), 2)));
            BarePoleIce = Pi / 4 * (0.032) * (Math.pow((Dpipe + 2 * Ice), 2) - Math.pow(Dpipe, 2)) * (5 - 0.5 - 4 * Ice);
            ElbowIce = (((Pi / 4) * (Math.pow((1.625 + 2 * Ice), 2) - Math.pow((1.625), 2)) * 5.6) + ((Pi / 4) * (Math.pow((1.625 + 2 * Ice), 2) - Math.pow((1.625), 2)) * 2.8)) * 0.032;
            LugIce = 3 * 0.032 * (2 * (11.5 * 6.5 * Ice) + 2 * (11.5 * 0.5 * Ice) + 2 * (6.5 * 0.5 * Ice));
            FeedLineIce = (Pi / 4) * (Math.pow((1.98 + 2 * Ice), 2) - Math.pow((1.98), 2)) * 0.032 * FeedLineLength;
            IceWeight = (SlotCoverIce + BarePoleIce + LugIce + FeedLineIce) * NumberSections + PowerDividerIceWeight;
            Wice = Round(IceWeight, 0);

            //Total Weight
            TotalWeight = Weight + IceWeight;
            TotalWeight = Round(TotalWeight, 3);
          } else if (optRadome == true) {
            FeedLineLength = 300;
            LugLength = 1.5;
            //PowerDivider(Ice);
            //Area Direction 1
            Dradome = RadDia;
            Radome = (L - 5 + 2 * Ice) * (Dradome + 2 * Ice) * AppurtDrag(WSpec, Dradome + 2 * Ice, (L - 5 + 2 * Ice) / 12, z, V, Y, ExpCat, StrCl, Topo, IncludeIce);
            BarePole = (5 - 2 * Ice) * (Dpipe + 2 * Ice) * AppurtDrag(WSpec, Dpipe + 2 * Ice, (5 - 2 * Ice) / 12, z, V, Y, ExpCat, StrCl, Topo, IncludeIce);
            LugArea = LugLength * (6.5 + 3 * Ice) * FlatAppurtDrag(WSpec, 3.75 + Ice, (4.1 + Ice) / 12, z, V, ExpCat, StrCl, Topo);
            FeedLineArea = FeedLineLength * (2 + 2 * Ice) * AppurtDrag(WSpec, 2 + 2 * Ice, FeedLineLength / 12, z, V, Y, ExpCat, StrCl, Topo, IncludeIce);
            ElbowArea = (3 * (1.625 + (2 * Ice)) + (6 * (1.625 + (2 * Ice)))) * AppurtDrag(WSpec, 1.625 + (2 * Ice), 3 / 12, z, V, Y, ExpCat, StrCl, Topo, IncludeIce);
            Adir1 = Round(((NumberSections * (Radome + BarePole + LugArea + ElbowArea) + FeedLineArea * numberFeedLines + PowerDividerArea)) / 144, 2);
            Amax = ((NumberSections * (Radome + BarePole + LugArea + ElbowArea) + FeedLineArea * numberFeedLines + PowerDividerArea)) / 144;
            RDir1 = Round(G * Amax * Q, 0);

            //Area Direction 2
            Radome = (L - 5 + 2 * Ice) * (Dradome + 2 * Ice) * AppurtDrag(WSpec, Dradome + 2 * Ice, (L - 5 + 2 * Ice) / 12, z, V, Y, ExpCat, StrCl, Topo, IncludeIce);
            BarePoleArea = (5 - 2 * Ice) * (Dpipe + 2 * Ice) * AppurtDrag(WSpec, Dpipe + 2 * Ice, (5 - 2 * Ice) / 12, z, V, Y, ExpCat, StrCl, Topo, IncludeIce);
            LugArea = LugLength * (11.5 + 3 * Ice) * FlatAppurtDrag(WSpec, 3.75 + Ice, (11.5 + 3 * Ice) / 12, z, V, ExpCat, StrCl, Topo);
            FeedLineArea = FeedLineLength * (2 + 2 * Ice) * AppurtDrag(WSpec, 2 + 2 * Ice, FeedLineLength / 12, z, V, Y, ExpCat, StrCl, Topo, IncludeIce);
            ElbowArea = (3 * (1.625 + (2 * Ice)) + (6 * (1.625 + (2 * Ice)))) * AppurtDrag(WSpec, 1.625 + (2 * Ice), 3 / 12, z, V, Y, ExpCat, StrCl, Topo, IncludeIce);
            Adir2 = Round(((NumberSections * (Radome + BarePole + LugArea + ElbowArea) + FeedLineArea * numberFeedLines + PowerDividerArea)) / 144, 2);
            A = ((NumberSections * (Radome + BarePole + LugArea + ElbowArea) + FeedLineArea * numberFeedLines + PowerDividerArea)) / 144;
            RDir2 = Round(G * A * Q, 0);
            if (A > Amax) {
                Amax = A;
            }
            RMax = Round(G * Amax * Q, 0);
            Amax = Round(Amax, 2);

            //Weight
            EndSeals = Pi * (Math.pow(Dradome, 2) - Math.pow(Dpipe, 2)) * 1 * 0.034;
            PoleWeight = ((Pi / 4) * (Math.pow(Dpipe, 2) - Math.pow((Dpipe - 2 * twall), 2)) * L) * 0.1;
            RadomeWeight = (0.1544 * Dradome + 2) * (L - 5) / 12;
            HarnessWeight = (2 * 1.5) + ((0.243 * L) * 0.1) + ((0.075 * (L / 2)) * 0.322) + 0.165;
            LugWeight = 7.5;
            Misc = 5;
            Elbow = 3.5;
            FeedlineWeight = FeedLineLength * 1.04 / 12; //Andrew Catalog
            Weight = (EndSeals + PoleWeight + RadomeWeight + Inner + Misc + Elbow) * NumberSections + (FeedlineWeight) * numberFeedLines + PowerDividerWeight;
            TotalWeight = Round(Weight, 0);

            //Ice Weight
            RadomeIce = Pi / 4 * (0.032) * (Math.pow((Dradome + 2 * Ice), 2) - Math.pow(Dradome, 2)) * (L - 5 + 2 * Ice);
            BarePoleIce = Pi / 4 * (0.032) * (Math.pow((Dpipe + 2 * Ice), 2) - Math.pow(Dpipe, 2)) * (5 - 2 * Ice);
            ElbowIce = (((Pi / 4) * (Math.pow((1.625 + 2 * Ice), 2) - Math.pow((1.625), 2)) * 5.6) + ((Pi / 4) * (Math.pow((1.625 + 2 * Ice), 2) - Math.pow((1.625), 2)) * 2.8)) * 0.032;
            LugIce = 3 * 0.032 * (2 * (11.5 * 6.5 * Ice) + 2 * (11.5 * 0.5 * Ice) + 2 * (6.5 * 0.5 * Ice));
            FeedLineIce = (Pi / 4) * (Math.pow((1.98 + 2 * Ice), 2) - Math.pow((1.98), 2)) * 0.032 * FeedLineLength;
            IceWeight = (RadomeIce + BarePoleIce + LugIce + FeedLineIce) * NumberSections + PowerDividerIceWeight;
            Wice = Round(IceWeight, 0);

            //Total Weight
            TotalWeight = Weight + IceWeight;
            TotalWeight = Round(TotalWeight, 3);
            }
          },
            patternC: function() {
              if (!optRadome || optRadome == false) {
                Lfin = 0.8 * lambda + 1;
                FinWidth = (2 * (0.1 * lambda) * Math.cos(5 * Pi / 180) + 3.5);
                FeedLineLength = 300;
                LugLength = 1.5;
                //PowerDivider(Ice);
                //Area Direction 1
                FlatFinnedArea = FinWidth * NLayers * (Lfin + 2 * Ice) * FlatAppurtDrag(WSpec, FinWidth, NLayers * (Lfin + 2 * Ice) / 12, z, V, ExpCat, StrCl, Topo);
                SlotCovers = (Dpipe + 2 * Ice) * (L - NLayers * (Lfin + 2 * Ice)) * FlatAppurtDrag(WSpec, Dpipe + 2 * Ice, (L - NLayers * (Lfin + 2 * Ice)) / 12, z, V, ExpCat, StrCl, Topo);
                LugArea = LugLength * (6.5 + 3 * Ice) * FlatAppurtDrag(WSpec, 3.75 + Ice, (4.1 + Ice) / 12, z, V, ExpCat, StrCl, Topo);
                FeedLineArea = FeedLineLength * (2 + 2 * Ice) * AppurtDrag(WSpec, 2 + 2 * Ice, FeedLineLength / 12, z, V, Y, ExpCat, StrCl, Topo, IncludeIce);
                ElbowArea = (3 * (1.625 + (2 * Ice)) + (6 * (1.625 + (2 * Ice)))) * AppurtDrag(WSpec, 1.625 + (2 * Ice), 3 / 12, z, V, Y, ExpCat, StrCl, Topo, IncludeIce);
                Adir1 = Round(((NumberSections * (FlatFinnedArea + SlotCovers + LugArea + ElbowArea) + FeedLineArea * numberFeedLines + PowerDividerArea)) / 144, 2);
                Amax = ((NumberSections * (FlatFinnedArea + SlotCovers + LugArea + ElbowArea) + FeedLineArea * numberFeedLines + PowerDividerArea)) / 144;
                RDir1 = Round(G * Amax * Q, 0);


                //Area Direction 2
                FinnedHeight = 5.375;
                SlotCovers = (L + 2 * Ice) * (FinnedHeight + 2 * Ice) * FlatAppurtDrag(WSpec, FinnedHeight + 2 * Ice, (L + 2 * Ice) / 12, z, V, ExpCat, StrCl, Topo);
                LugArea = LugLength * (11.5 + 3 * Ice) * FlatAppurtDrag(WSpec, 3.75 + Ice, (11.5 + 3 * Ice) / 12, z, V, ExpCat, StrCl, Topo);
                FeedLineArea = FeedLineLength * (2 + 2 * Ice) * AppurtDrag(WSpec, 2 + 2 * Ice, FeedLineLength / 12, z, V, Y, ExpCat, StrCl, Topo, IncludeIce);
                ElbowArea = (3 * (1.625 + (2 * Ice)) + (6 * (1.625 + (2 * Ice)))) * AppurtDrag(WSpec, 1.625 + (2 * Ice), 3 / 12, z, V, Y, ExpCat, StrCl, Topo, IncludeIce);
                Adir2 = Round(((NumberSections * (SlotCovers + LugArea + ElbowArea) + FeedLineArea * numberFeedLines + PowerDividerArea)) / 144, 2);
                A = ((NumberSections * (SlotCovers + LugArea + ElbowArea) + FeedLineArea * numberFeedLines + PowerDividerArea)) / 144;
                RDir2 = Round(G * A * Q, 0);
                if (A > Amax) {
                    Amax = A;
                }
                RMax = Round(G * Amax * Q, 0);
                Amax = Round(Amax, 2);

                //Weight
                PoleWeight = ((Pi / 4) * (Math.pow(Dpipe, 2) - Math.pow((Dpipe - 2 * twall), 2)) * L) * 0.1;
                SlotCoverWeight = L * 0.03;
                FinWeight = 2 * NLayers * Lfin * 0.125;
                HarnessWeight = (2 * 1.5) + ((0.243 * L) * 0.1) + ((0.075 * (L / 2)) * 0.322) + 0.165;
                LugWeight = 7.5;
                Misc = 5;
                Elbow = 3.5;
                FeedlineWeight = FeedLineLength * 1.04 / 12; //Andrew Catalog
                Weight = (PoleWeight + SlotCoverWeight + FinWeight + HarnessWeight + LugWeight + Misc + Elbow) * NumberSections + (FeedlineWeight) * numberFeedLines + PowerDividerWeight;
                TotalWeight = Round(Weight, 0);

                //Ice Weight
                SlotCoverIce = (L - 1.125 + 2 * Ice) * (0.032) * (1.5 * (2.89) * (Math.pow((1.59 + Ice), 2) - Math.pow((1.59), 2)) + 1.5 * (0.71) * (Math.pow((2.5 + Ice), 2) - Math.pow((2.5), 2)));
                FinnedWeight = (2 * (0.1 * lambda + Ice) * 2 * Ice + (0.5 * (3.04) * (Math.pow((1.35 + Ice), 2) - (Math.pow(1.35, 2))) + 0.5 * (4.66) * (Math.pow((1.75 + Ice), 2) - Math.pow((1.75), 2)))) * NLayers * (Lfin + 2 * Ice) * 0.032;
                ElbowIce = (((Pi / 4) * (Math.pow((1.625 + 2 * Ice), 2) - Math.pow((1.625), 2)) * 5.6) + ((Pi / 4) * (Math.pow((1.625 + 2 * Ice), 2) - Math.pow((1.625), 2)) * 2.8)) * 0.032;
                LugIce = 3 * 0.032 * (2 * (11.5 * 6.5 * Ice) + 2 * (11.5 * 0.5 * Ice) + 2 * (6.5 * 0.5 * Ice));
                FeedLineIce = (Pi / 4) * (Math.pow((1.98 + 2 * Ice), 2) - Math.pow((1.98), 2)) * 0.032 * FeedLineLength;
                IceWeight = (SlotCoverIce + FinnedWeight + LugIce + FeedLineIce) * NumberSections + PowerDividerIceWeight;
                Wice = Round(IceWeight, 0);

                //Total Weight
                TotalWeight = Weight + IceWeight;
                TotalWeight = Round(TotalWeight, 3);

              } else if (optRadome == true) {
                FeedLineLength = 300;
                LugLength = 1.5;
                //PowerDivider(Ice);
                //Area Direction 1
                Dradome = RadDia;
                Radome = (L - 5 + 2 * Ice) * (Dradome + 2 * Ice) * AppurtDrag(WSpec, Dradome + 2 * Ice, (L - 5 + 2 * Ice) / 12, z, V, Y, ExpCat, StrCl, Topo, IncludeIce);
                BarePole = (5 - 2 * Ice) * (Dpipe + 2 * Ice) * AppurtDrag(WSpec, Dpipe + 2 * Ice, (5 - 2 * Ice) / 12, z, V, Y, ExpCat, StrCl, Topo, IncludeIce);
                LugArea = LugLength * (6.5 + 3 * Ice) * FlatAppurtDrag(WSpec, 3.75 + Ice, (4.1 + Ice) / 12, z, V, ExpCat, StrCl, Topo);
                FeedLineArea = FeedLineLength * (2 + 2 * Ice) * AppurtDrag(WSpec, 2 + 2 * Ice, FeedLineLength / 12, z, V, Y, ExpCat, StrCl, Topo, IncludeIce);
                ElbowArea = (3 * (1.625 + (2 * Ice)) + (6 * (1.625 + (2 * Ice)))) * AppurtDrag(WSpec, 1.625 + (2 * Ice), 3 / 12, z, V, Y, ExpCat, StrCl, Topo, IncludeIce);
                Adir1 = Round(((NumberSections * (Radome + BarePole + LugArea + ElbowArea) + FeedLineArea * numberFeedLines + PowerDividerArea)) / 144, 2);
                Amax = ((NumberSections * (Radome + BarePole + LugArea + ElbowArea) + FeedLineArea * numberFeedLines + PowerDividerArea)) / 144;
                RDir1 = Round(G * Amax * Q, 0);

                //Area Direction 2
                Radome = (L - 5 + 2 * Ice) * (Dradome + 2 * Ice) * AppurtDrag(WSpec, Dradome + 2 * Ice, (L - 5 + 2 * Ice) / 12, z, V, Y, ExpCat, StrCl, Topo, IncludeIce);
                BarePoleArea = (5 - 2 * Ice) * (Dpipe + 2 * Ice) * AppurtDrag(WSpec, Dpipe + 2 * Ice, (5 - 2 * Ice) / 12, z, V, Y, ExpCat, StrCl, Topo, IncludeIce);
                LugArea = LugLength * (11.5 + 3 * Ice) * FlatAppurtDrag(WSpec, 3.75 + Ice, (11.5 + 3 * Ice) / 12, z, V, ExpCat, StrCl, Topo);
                FeedLineArea = FeedLineLength * (2 + 2 * Ice) * AppurtDrag(WSpec, 2 + 2 * Ice, FeedLineLength / 12, z, V, Y, ExpCat, StrCl, Topo, IncludeIce);
                ElbowArea = (3 * (1.625 + (2 * Ice)) + (6 * (1.625 + (2 * Ice)))) * AppurtDrag(WSpec, 1.625 + (2 * Ice), 3 / 12, z, V, Y, ExpCat, StrCl, Topo, IncludeIce);
                Adir2 = Round(((NumberSections * (Radome + BarePole + LugArea + ElbowArea) + FeedLineArea * numberFeedLines + PowerDividerArea)) / 144, 2);
                A = ((NumberSections * (Radome + BarePole + LugArea + ElbowArea) + FeedLineArea * numberFeedLines + PowerDividerArea)) / 144;
                RDir2 = Round(G * A * Q, 0);
                if (A > Amax) {
                    Amax = A;
                }
                RMax = Round(G * Amax * Q, 0);
                Amax = Round(Amax, 2);

                //Weight
                EndSeals = Pi * (Math.pow(Dradome, 2) - Math.pow(Dpipe, 2)) * 1 * 0.034;
                PoleWeight = ((Pi / 4) * (Math.pow(Dpipe, 2) - Math.pow((Dpipe - 2 * twall), 2)) * L) * 0.1;
                RadomeWeight = (0.1544 * Dradome + 2) * (L - 5) / 12;
                HarnessWeight = (2 * 1.5) + ((0.243 * L) * 0.1) + ((0.075 * (L / 2)) * 0.322) + 0.165;
                LugWeight = 7.5;
                Misc = 5;
                Elbow = 3.5;
                FeedlineWeight = FeedLineLength * 1.04 / 12 //Andrew Catalog;
                Weight = (EndSeals + PoleWeight + RadomeWeight + Inner + Misc + Elbow) * NumberSections + (FeedlineWeight) * numberFeedLines + PowerDividerWeight;
                TotalWeight = Round(Weight, 0);

                //Ice Weight
                RadomeIce = Pi / 4 * (0.032) * (Math.pow((Dradome + 2 * Ice), 2) - Math.pow(Dradome, 2)) * (L - 5 + 2 * Ice);
                BarePoleIce = Pi / 4 * (0.032) * (Math.pow((Dpipe + 2 * Ice), 2) - Math.pow(Dpipe, 2)) * (5 - 2 * Ice);
                ElbowIce = (((Pi / 4) * (Math.pow((1.625 + 2 * Ice), 2) - Math.pow((1.625), 2)) * 5.6) + ((Pi / 4) * (Math.pow((1.625 + 2 * Ice), 2) - Math.pow((1.625), 2)) * 2.8)) * 0.032;
                LugIce = 3 * 0.032 * (2 * (11.5 * 6.5 * Ice) + 2 * (11.5 * 0.5 * Ice) + 2 * (6.5 * 0.5 * Ice));
                FeedLineIce = (Pi / 4) * (Math.pow((1.98 + 2 * Ice), 2) - Math.pow((1.98), 2)) * 0.032 * FeedLineLength;
                IceWeight = (RadomeIce + BarePoleIce + LugIce + FeedLineIce) * NumberSections + PowerDividerIceWeight;
                Wice = Round(IceWeight, 0);

                //Total Weight
                TotalWeight = Weight + IceWeight;
                TotalWeight = Round(TotalWeight, 3);
              }
          },
          patternD: function() {
              if (!optRadome || optRadome == false) {
                Lfin = 0.8 * lambda + 1;
                FeedLineLength = 300;
                LugLength = 1.5;
                //PowerDivider(Ice);

                //Area Direction 1
                FinWidth = (2 * (0.25 * lambda + Dpipe) * Math.cos(10 * Pi / 180));
                FinnedArea = (FinWidth + 2 * Ice) * NLayers * (Lfin + 2 * Ice) * FlatAppurtDrag(WSpec, FinWidth + 2 * Ice, NLayers * (Lfin + 2 * Ice) / 12, z, V, ExpCat, StrCl, Topo);
                SlotCovers = (Dpipe + 2 * Ice) * (L - NLayers * (Lfin + 2 * Ice)) * FlatAppurtDrag(WSpec, Dpipe + 2 * Ice, (L - NLayers * (Lfin + 2 * Ice)) / 12, z, V, ExpCat, StrCl, Topo);
                LugArea = LugLength * (6.5 + 3 * Ice) * FlatAppurtDrag(WSpec, 3.75 + Ice, (4.1 + Ice) / 12, z, V, ExpCat, StrCl, Topo);
                FeedLineArea = FeedLineLength * (2 + 2 * Ice) * AppurtDrag(WSpec, 2 + 2 * Ice, FeedLineLength / 12, z, V, Y, ExpCat, StrCl, Topo, IncludeIce);
                ElbowArea = (3 * (1.625 + (2 * Ice)) + (6 * (1.625 + (2 * Ice)))) * AppurtDrag(WSpec, 1.625 + (2 * Ice), 3 / 12, z, V, Y, ExpCat, StrCl, Topo, IncludeIce);
                Adir1 = Round(((NumberSections * (FinnedArea + SlotCovers + LugArea + ElbowArea) + FeedLineArea * numberFeedLines + PowerDividerArea)) / 144, 2);
                Amax = ((NumberSections * (FinnedArea + SlotCovers + LugArea + ElbowArea) + FeedLineArea * numberFeedLines + PowerDividerArea)) / 144;
                RDir1 = Round(G * Amax * Q, 0);

                //Area Direction 2
                SlotCovers = (L - 5 + 2 * Ice) * (5.375 + 2 * Ice) * FlatAppurtDrag(WSpec, 5.375 + 2 * Ice, (L - 5 + 2 * Ice) / 12, z, V, ExpCat, StrCl, Topo);
                BarePole = (5 - 2 * Ice) * (Dpipe + 2 * Ice) * AppurtDrag(WSpec, Dpipe + 2 * Ice, (5 - 2 * Ice) / 12, z, V, Y, ExpCat, StrCl, Topo, IncludeIce);
                LugArea = LugLength * (11.5 + 3 * Ice) * FlatAppurtDrag(WSpec, 3.75 + Ice, (11.5 + 3 * Ice) / 12, z, V, ExpCat, StrCl, Topo);
                FeedLineArea = FeedLineLength * (2 + 2 * Ice) * AppurtDrag(WSpec, 2 + 2 * Ice, FeedLineLength / 12, z, V, Y, ExpCat, StrCl, Topo, IncludeIce);
                ElbowArea = (3 * (1.625 + (2 * Ice)) + (6 * (1.625 + (2 * Ice)))) * AppurtDrag(WSpec, 1.625 + (2 * Ice), 3 / 12, z, V, Y, ExpCat, StrCl, Topo, IncludeIce);
                Adir2 = Round(((NumberSections * (SlotCovers + BarePole + LugArea + ElbowArea) + FeedLineArea * numberFeedLines + PowerDividerArea)) / 144, 2);
                A = ((NumberSections * (SlotCovers + BarePole + LugArea + ElbowArea) + FeedLineArea * numberFeedLines + PowerDividerArea)) / 144;
                RDir2 = Round(G * A * Q, 0);
                if (A > Amax) {
                    Amax = A;
                }
                RMax = Round(G * Amax * Q, 0);
                Amax = Round(Amax, 2);

                //Weight
                PoleWeight = ((Pi / 4) * (Math.pow(Dpipe, 2) - Math.pow((Dpipe - 2 * twall), 2)) * L) * 0.1;
                SlotCoverWeight = (L - 5) * (0.03);
                FinWeight = (2 * NLayers) * (0.1) * (0.25 * lambda) * Lfin * 0.125;
                HarnessWeight = (2 * 1.5) + ((0.243 * L) * 0.1) + ((0.075 * (L / 2)) * 0.322) + 0.165;
                LugWeight = 7.5;
                Misc = 5;
                Elbow = 3.5;
                FeedlineWeight = FeedLineLength * 1.04 / 12; //Andrew Catalog
                Weight = (PoleWeight + SlotCoverWeight + FinWeight + HarnessWeight + LugWeight + Misc + Elbow) * NumberSections + (FeedlineWeight) * numberFeedLines + PowerDividerWeight;
                TotalWeight = Round(Weight, 0);

                //Ice Weight
                SlotCoverIce = (L - 5 - 2 * Ice) * (0.032) * (1.5 * (2.89) * (Math.pow((1.59 + Ice), 2) - Math.pow((1.59), 2)) + 1.5 * (0.71) * (Math.pow((2.5 + Ice), 2) - Math.pow((2.5), 2)));
                BarePoleIce = Pi / 4 * (0.032) * (Math.pow((Dpipe + 2 * Ice), 2) - Math.pow(Dpipe, 2)) * (5 - 2 * Ice);
                FinnedWeight = (2 * (0.25 * lambda + Ice) * 2 * Ice + (0.5 * (3.04) * (Math.pow((1.35 + Ice), 2) - (Math.pow(1.35, 2))) + 0.5 * (4.66) * (Math.pow((1.75 + Ice), 2) - Math.pow((1.75), 2)))) * NLayers * (Lfin + 2 * Ice) * 0.032;
                ElbowIce = (((Pi / 4) * (Math.pow((1.625 + 2 * Ice), 2) - Math.pow((1.625), 2)) * 5.6) + ((Pi / 4) * (Math.pow((1.625 + 2 * Ice), 2) - Math.pow((1.625), 2)) * 2.8)) * 0.032;
                LugIce = 3 * 0.032 * (2 * (11.5 * 6.5 * Ice) + 2 * (11.5 * 0.5 * Ice) + 2 * (6.5 * 0.5 * Ice));
                FeedLineIce = (Pi / 4) * (Math.pow((1.98 + 2 * Ice), 2) - Math.pow((1.98), 2)) * 0.032 * FeedLineLength;
                IceWeight = (SlotCoverIce + FinnedWeight + BarePoleIce + LugIce + FeedLineIce) * NumberSections + PowerDividerIceWeight;
                Wice = Round(IceWeight, 0);

                //Total Weight
                TotalWeight = Weight + IceWeight;
                TotalWeight = Round(TotalWeight, 3);

              } else if (optRadome == true) {
                FeedLineLength = 300;
                LugLength = 1.5;
                //PowerDivider(Ice);
                //Area Direction 1
                Dradome = RadDia;
                Radome = (L - 5 + 2 * Ice) * (Dradome + 2 * Ice) * AppurtDrag(WSpec, Dradome + 2 * Ice, (L - 5 + 2 * Ice) / 12, z, V, Y, ExpCat, StrCl, Topo, IncludeIce);
                BarePole = (5 - 2 * Ice) * (Dpipe + 2 * Ice) * AppurtDrag(WSpec, Dpipe + 2 * Ice, (5 - 2 * Ice) / 12, z, V, Y, ExpCat, StrCl, Topo, IncludeIce);
                LugArea = LugLength * (6.5 + 3 * Ice) * FlatAppurtDrag(WSpec, 3.75 + Ice, (4.1 + Ice) / 12, z, V, ExpCat, StrCl, Topo);
                FeedLineArea = FeedLineLength * (2 + 2 * Ice) * AppurtDrag(WSpec, 2 + 2 * Ice, FeedLineLength / 12, z, V, Y, ExpCat, StrCl, Topo, IncludeIce);
                ElbowArea = (3 * (1.625 + (2 * Ice)) + (6 * (1.625 + (2 * Ice)))) * AppurtDrag(WSpec, 1.625 + (2 * Ice), 3 / 12, z, V, Y, ExpCat, StrCl, Topo, IncludeIce);
                Adir1 = Round(((NumberSections * (Radome + BarePole + LugArea + ElbowArea) + FeedLineArea * numberFeedLines + PowerDividerArea)) / 144, 2);
                Amax = ((NumberSections * (Radome + BarePole + LugArea + ElbowArea) + FeedLineArea * numberFeedLines + PowerDividerArea)) / 144;
                RDir1 = Round(G * Amax * Q, 0);

                //Area Direction 2
                Radome = (L - 5 + 2 * Ice) * (Dradome + 2 * Ice) * AppurtDrag(WSpec, Dradome + 2 * Ice, (L - 5 + 2 * Ice) / 12, z, V, Y, ExpCat, StrCl, Topo, IncludeIce);
                BarePoleArea = (5 - 2 * Ice) * (Dpipe + 2 * Ice) * AppurtDrag(WSpec, Dpipe + 2 * Ice, (5 - 2 * Ice) / 12, z, V, Y, ExpCat, StrCl, Topo, IncludeIce);
                LugArea = LugLength * (11.5 + 3 * Ice) * FlatAppurtDrag(WSpec, 3.75 + Ice, (11.5 + 3 * Ice) / 12, z, V, ExpCat, StrCl, Topo);
                FeedLineArea = FeedLineLength * (2 + 2 * Ice) * AppurtDrag(WSpec, 2 + 2 * Ice, FeedLineLength / 12, z, V, Y, ExpCat, StrCl, Topo, IncludeIce);
                ElbowArea = (3 * (1.625 + (2 * Ice)) + (6 * (1.625 + (2 * Ice)))) * AppurtDrag(WSpec, 1.625 + (2 * Ice), 3 / 12, z, V, Y, ExpCat, StrCl, Topo, IncludeIce);
                Adir2 = Round(((NumberSections * (Radome + BarePole + LugArea + ElbowArea) + FeedLineArea * numberFeedLines + PowerDividerArea)) / 144, 2);
                A = ((NumberSections * (Radome + BarePole + LugArea + ElbowArea) + FeedLineArea * numberFeedLines + PowerDividerArea)) / 144;
                RDir2 = Round(G * A * Q, 0);
                if (A > Amax) {
                    Amax = A;
                }
                RMax = Round(G * Amax * Q, 0);
                Amax = Round(Amax, 2);

                //Weight
                EndSeals = Pi * (Math.pow(Dradome, 2) - Math.pow(Dpipe, 2)) * 1 * 0.034;
                PoleWeight = ((Pi / 4) * (Math.pow(Dpipe, 2) - Math.pow((Dpipe - 2 * twall), 2)) * L) * 0.1;
                RadomeWeight = (0.1544 * Dradome + 2) * (L - 5) / 12;
                HarnessWeight = (2 * 1.5) + ((0.243 * L) * 0.1) + ((0.075 * (L / 2)) * 0.322) + 0.165;
                LugWeight = 7.5;
                Misc = 5;
                Elbow = 3.5;
                FeedlineWeight = FeedLineLength * 1.04 / 12; //Andrew Catalog
                Weight = (EndSeals + PoleWeight + RadomeWeight + Inner + Misc + Elbow) * NumberSections + (FeedlineWeight) * numberFeedLines + PowerDividerWeight;
                TotalWeight = Round(Weight, 0);

                //Ice Weight
                RadomeIce = Pi / 4 * (0.032) * (Math.pow((Dradome + 2 * Ice), 2) - Math.pow(Dradome, 2)) * (L - 5 + 2 * Ice);
                BarePoleIce = Pi / 4 * (0.032) * (Math.pow((Dpipe + 2 * Ice), 2) - Math.pow(Dpipe, 2)) * (5 - 2 * Ice);
                ElbowIce = (((Pi / 4) * (Math.pow((1.625 + 2 * Ice), 2) - Math.pow((1.625), 2)) * 5.6) + ((Pi / 4) * (Math.pow((1.625 + 2 * Ice), 2) - Math.pow((1.625), 2)) * 2.8)) * 0.032;
                LugIce = 3 * 0.032 * (2 * (11.5 * 6.5 * Ice) + 2 * (11.5 * 0.5 * Ice) + 2 * (6.5 * 0.5 * Ice));
                FeedLineIce = (Pi / 4) * (Math.pow((1.98 + 2 * Ice), 2) - Math.pow((1.98), 2)) * 0.032 * FeedLineLength;
                IceWeight = (RadomeIce + BarePoleIce + LugIce + FeedLineIce) * NumberSections + PowerDividerIceWeight;
                Wice = Round(IceWeight, 0);

                //Total Weight
                TotalWeight = Weight + IceWeight;
                TotalWeight = Round(TotalWeight, 3);
            }
          },
          patternE: function() {
              if (!optRadome || optRadome == false) {
                Lfin = 0.8 * lambda + 1;
                FeedLineLength = 300;
                LugLength = 1.5;
                //PowerDivider(Ice);
                //Area Direction 1
                FinWidth = (2 * (0.4 * lambda + Dpipe) * Math.cos(10 * Pi / 180))
                FinnedArea = (FinWidth + 2 * Ice) * NLayers * (Lfin + 2 * Ice) * FlatAppurtDrag(WSpec, FinWidth + 2 * Ice, NLayers * (0.8 * lambda + 1 + 2 * Ice) / 12, z, V, ExpCat, StrCl, Topo);
                SlotCovers = (Dpipe + 2 * Ice) * (L - NLayers * (Lfin + 2 * Ice) - 5 - 2 * Ice) * FlatAppurtDrag(WSpec, Dpipe + 2 * Ice, (L - NLayers * (Lfin + 2 * Ice) - 5 - 2 * Ice) / 12, z, V, ExpCat, StrCl, Topo);
                BarePole = (5 - 2 * Ice) * (Dpipe + 2 * Ice) * AppurtDrag(WSpec, Dpipe + 2 * Ice, (5 - 2 * Ice) / 12, z, V, Y, ExpCat, StrCl, Topo, IncludeIce);
                LugArea = LugLength * (6.5 + 3 * Ice) * FlatAppurtDrag(WSpec, 3.75 + Ice, (4.1 + Ice) / 12, z, V, ExpCat, StrCl, Topo);
                FeedLineArea = FeedLineLength * (2 + 2 * Ice) * AppurtDrag(WSpec, 2 + 2 * Ice, FeedLineLength / 12, z, V, Y, ExpCat, StrCl, Topo, IncludeIce);
                ElbowArea = (3 * (1.625 + (2 * Ice)) + (6 * (1.625 + (2 * Ice)))) * AppurtDrag(WSpec, 1.625 + (2 * Ice), 3 / 12, z, V, Y, ExpCat, StrCl, Topo, IncludeIce);
                Adir1 = Round(((NumberSections * (FinnedArea + SlotCovers + BarePole + LugArea + ElbowArea) + FeedLineArea * numberFeedLines + PowerDividerArea)) / 144, 2);
                Amax = ((NumberSections * (FinnedArea + SlotCovers + BarePole + LugArea + ElbowArea) + FeedLineArea * numberFeedLines + PowerDividerArea)) / 144;
                RDir1 = Round(G * Amax * Q, 0);

                //Area Direction 2
                SlotCovers = (L - 5 + 2 * Ice) * (5.375 + 2 * Ice) * FlatAppurtDrag(WSpec, 5.375 + 2 * Ice, (L - 5 + 2 * Ice) / 12, z, V, ExpCat, StrCl, Topo);
                BarePoleArea = (5 - 2 * Ice) * (Dpipe + 2 * Ice) * AppurtDrag(WSpec, Dpipe + 2 * Ice, (5 - 2 * Ice) / 12, z, V, Y, ExpCat, StrCl, Topo, IncludeIce);
                LugArea = LugLength * (11.5 + 3 * Ice) * FlatAppurtDrag(WSpec, 3.75 + Ice, (11.5 + 3 * Ice) / 12, z, V, ExpCat, StrCl, Topo);
                FeedLineArea = FeedLineLength * (2 + 2 * Ice) * AppurtDrag(WSpec, 2 + 2 * Ice, FeedLineLength / 12, z, V, Y, ExpCat, StrCl, Topo, IncludeIce);
                ElbowArea = (3 * (1.625 + (2 * Ice)) + (6 * (1.625 + (2 * Ice)))) * AppurtDrag(WSpec, 1.625 + (2 * Ice), 3 / 12, z, V, Y, ExpCat, StrCl, Topo, IncludeIce);
                Adir2 = Round(((NumberSections * (SlotCovers + BarePole + LugArea + ElbowArea) + FeedLineArea * numberFeedLines + PowerDividerArea)) / 144, 2);
                A = ((NumberSections * (SlotCovers + BarePole + LugArea + ElbowArea) + FeedLineArea * numberFeedLines + PowerDividerArea)) / 144;
                RDir2 = Round(G * A * Q, 0);
                if (A > Amax) {
                    Amax = A;
                }
                RMax = Round(G * Amax * Q, 0);
                Amax = Round(Amax, 2);

                //Weight
                PoleWeight = ((Pi / 4) * (Math.pow(Dpipe, 2) - Math.pow((Dpipe - 2 * twall), 2)) * L) * 0.1;
                SlotCoverWeight = (L - 5) * (0.03);
                FinWeight = (2 * NLayers) * (0.1) * (0.4 * lambda) * Lfin * 0.125;
                HarnessWeight = (2 * 1.5) + ((0.243 * L) * 0.1) + ((0.075 * (L / 2)) * 0.322) + 0.165;
                LugWeight = 7.5;
                Misc = 5;
                Elbow = 3.5;
                FeedlineWeight = FeedLineLength * 1.04 / 12; //Andrew Catalog
                Weight = (PoleWeight + SlotCoverWeight + FinWeight + HarnessWeight + LugWeight + Misc + Elbow) * NumberSections + (FeedlineWeight) * numberFeedLines + PowerDividerWeight;
                TotalWeight = Round(Weight, 0);

                //Ice Weight
                SlotCoverIce = (L - 5 - 2 * Ice) * (0.032) * (1.5 * (2.89) * (Math.pow((1.59 + Ice), 2) - Math.pow((1.59), 2)) + 1.5 * (0.71) * (Math.pow((2.5 + Ice), 2) - Math.pow((2.5), 2)));
                BarePoleIce = Pi / 4 * (0.032) * (Math.pow((Dpipe + 2 * Ice), 2) - Math.pow(Dpipe, 2)) * (5 - 2 * Ice);
                FinnedWeight = (2 * (0.4 * lambda + Ice) * 2 * Ice + (0.5 * (3.04) * (Math.pow((1.35 + Ice), 2) - (Math.pow(1.35, 2))) + 0.5 * (4.66) * (Math.pow((1.75 + Ice), 2) - Math.pow((1.75), 2)))) * NLayers * (Lfin + 2 * Ice) * 0.032;
                ElbowIce = (((Pi / 4) * (Math.pow((1.625 + 2 * Ice), 2) - Math.pow((1.625), 2)) * 5.6) + ((Pi / 4) * (Math.pow((1.625 + 2 * Ice), 2) - Math.pow((1.625), 2)) * 2.8)) * 0.032;
                LugIce = 3 * 0.032 * (2 * (11.5 * 6.5 * Ice) + 2 * (11.5 * 0.5 * Ice) + 2 * (6.5 * 0.5 * Ice));
                FeedLineIce = (Pi / 4) * (Math.pow((1.98 + 2 * Ice), 2) - Math.pow((1.98), 2)) * 0.032 * FeedLineLength;
                IceWeight = (SlotCoverIce + FinnedWeight + BarePoleIce + LugIce + FeedLineIce) * NumberSections + PowerDividerIceWeight;
                Wice = Round(IceWeight, 0);

                //Total Weight
                TotalWeight = Weight + IceWeight;
                TotalWeight = Round(TotalWeight, 3);

              } else if (optRadome == true) {
                FeedLineLength = 300;
                LugLength = 1.5;
                //PowerDivider(Ice);
                  //Area Direction 1
                  Dradome = RadDia;
                  Radome = (L - 5 + 2 * Ice) * (Dradome + 2 * Ice) * AppurtDrag(WSpec, Dradome + 2 * Ice, (L - 5 + 2 * Ice) / 12, z, V, Y, ExpCat, StrCl, Topo, IncludeIce);
                  BarePole = (5 - 2 * Ice) * (Dpipe + 2 * Ice) * AppurtDrag(WSpec, Dpipe + 2 * Ice, (5 - 2 * Ice) / 12, z, V, Y, ExpCat, StrCl, Topo, IncludeIce);
                  LugArea = LugLength * (6.5 + 3 * Ice) * FlatAppurtDrag(WSpec, 3.75 + Ice, (4.1 + Ice) / 12, z, V, ExpCat, StrCl, Topo);
                  FeedLineArea = FeedLineLength * (2 + 2 * Ice) * AppurtDrag(WSpec, 2 + 2 * Ice, FeedLineLength / 12, z, V, Y, ExpCat, StrCl, Topo, IncludeIce);
                  ElbowArea = (3 * (1.625 + (2 * Ice)) + (6 * (1.625 + (2 * Ice)))) * AppurtDrag(WSpec, 1.625 + (2 * Ice), 3 / 12, z, V, Y, ExpCat, StrCl, Topo, IncludeIce);
                  Adir1 = Round(((NumberSections * (Radome + BarePole + LugArea + ElbowArea) + FeedLineArea * numberFeedLines + PowerDividerArea)) / 144, 2);
                  Amax = ((NumberSections * (Radome + BarePole + LugArea + ElbowArea) + FeedLineArea * numberFeedLines + PowerDividerArea)) / 144;
                  RDir1 = Round(G * Amax * Q, 0);

                  //Area Direction 2
                  Radome = (L - 5 + 2 * Ice) * (Dradome + 2 * Ice) * AppurtDrag(WSpec, Dradome + 2 * Ice, (L - 5 + 2 * Ice) / 12, z, V, Y, ExpCat, StrCl, Topo, IncludeIce);
                  BarePoleArea = (5 - 2 * Ice) * (Dpipe + 2 * Ice) * AppurtDrag(WSpec, Dpipe + 2 * Ice, (5 - 2 * Ice) / 12, z, V, Y, ExpCat, StrCl, Topo, IncludeIce);
                  LugArea = LugLength * (11.5 + 3 * Ice) * FlatAppurtDrag(WSpec, 3.75 + Ice, (11.5 + 3 * Ice) / 12, z, V, ExpCat, StrCl, Topo);
                  FeedLineArea = FeedLineLength * (2 + 2 * Ice) * AppurtDrag(WSpec, 2 + 2 * Ice, FeedLineLength / 12, z, V, Y, ExpCat, StrCl, Topo, IncludeIce);
                  ElbowArea = (3 * (1.625 + (2 * Ice)) + (6 * (1.625 + (2 * Ice)))) * AppurtDrag(WSpec, 1.625 + (2 * Ice), 3 / 12, z, V, Y, ExpCat, StrCl, Topo, IncludeIce);
                  Adir2 = Round(((NumberSections * (Radome + BarePole + LugArea + ElbowArea) + FeedLineArea * numberFeedLines + PowerDividerArea)) / 144, 2);
                  A = ((NumberSections * (Radome + BarePole + LugArea + ElbowArea) + FeedLineArea * numberFeedLines + PowerDividerArea)) / 144;
                  RDir2 = Round(G * A * Q, 0);
                  if (A > Amax) {
                      Amax = A;
                  }
                  RMax = Round(G * Amax * Q, 0);
                  Amax = Round(Amax, 2);

                  //Weight
                  EndSeals = Pi * (Math.pow(Dradome, 2) - Math.pow(Dpipe, 2)) * 1 * 0.034;
                  PoleWeight = ((Pi / 4) * (Math.pow(Dpipe, 2) - Math.pow((Dpipe - 2 * twall), 2)) * L) * 0.1;
                  RadomeWeight = (0.1544 * Dradome + 2) * (L - 5) / 12;
                  HarnessWeight = (2 * 1.5) + ((0.243 * L) * 0.1) + ((0.075 * (L / 2)) * 0.322) + 0.165;
                  LugWeight = 7.5;
                  Misc = 5;
                  Elbow = 3.5;
                  FeedlineWeight = FeedLineLength * 1.04 / 12; //Andrew Catalog
                  Weight = (EndSeals + PoleWeight + RadomeWeight + Inner + Misc + Elbow) * NumberSections + (FeedlineWeight) * numberFeedLines + PowerDividerWeight;
                  TotalWeight = Round(Weight, 0);

                  //Ice Weight
                  RadomeIce = Pi / 4 * (0.032) * (Math.pow((Dradome + 2 * Ice), 2) - Math.pow(Dradome, 2)) * (L - 5 + 2 * Ice);
                  BarePoleIce = Pi / 4 * (0.032) * (Math.pow((Dpipe + 2 * Ice), 2) - Math.pow(Dpipe, 2)) * (5 - 2 * Ice);
                  ElbowIce = (((Pi / 4) * (Math.pow((1.625 + 2 * Ice), 2) - Math.pow((1.625), 2)) * 5.6) + ((Pi / 4) * (Math.pow((1.625 + 2 * Ice), 2) - Math.pow((1.625), 2)) * 2.8)) * 0.032;
                  LugIce = 3 * 0.032 * (2 * (11.5 * 6.5 * Ice) + 2 * (11.5 * 0.5 * Ice) + 2 * (6.5 * 0.5 * Ice));
                  FeedLineIce = (Pi / 4) * (Math.pow((1.98 + 2 * Ice), 2) - Math.pow((1.98), 2)) * 0.032 * FeedLineLength;
                  IceWeight = (RadomeIce + BarePoleIce + LugIce + FeedLineIce) * NumberSections + PowerDividerIceWeight;
                  Wice = Round(IceWeight, 0);

                  //Total Weight
                  TotalWeight = Weight + IceWeight;
                  TotalWeight = Round(TotalWeight, 3);
              }
          },
          patternF: function() {
              if (!optRadome || optRadome == false) {
                Lfin = 0.8 * lambda + 1;
                FeedLineLength = 300;
                LugLength = 1.5;
                //PowerDivider(Ice);
                //Area Direction 1
                FinWidth = 2 * (0.4 * lambda) * Math.cos(10 * Pi / 180) + Dpipe
                FinnedArea = (FinWidth + 2 * Ice) * NLayers * (Lfin + 2 * Ice) * FlatAppurtDrag(WSpec, FinWidth + 2 * Ice, NLayers * (0.8 * lambda + 1 + 2 * Ice) / 12, z, V, ExpCat, StrCl, Topo);
                SlotCovers = (Dpipe + 2 * Ice) * (L - NLayers * (Lfin + 2 * Ice) - 5 - 2 * Ice) * FlatAppurtDrag(WSpec, Dpipe + 2 * Ice, (L - NLayers * (Lfin + 2 * Ice) - 5 - 2 * Ice) / 12, z, V, ExpCat, StrCl, Topo);
                BarePole = (5 - 2 * Ice) * (Dpipe + 2 * Ice) * AppurtDrag(WSpec, Dpipe + 2 * Ice, (5 - 2 * Ice) / 12, z, V, Y, ExpCat, StrCl, Topo, IncludeIce);
                LugArea = LugLength * (6.5 + 3 * Ice) * FlatAppurtDrag(WSpec, 3.75 + Ice, (4.1 + Ice) / 12, z, V, ExpCat, StrCl, Topo);
                FeedLineArea = FeedLineLength * (2 + 2 * Ice) * AppurtDrag(WSpec, 2 + 2 * Ice, FeedLineLength / 12, z, V, Y, ExpCat, StrCl, Topo, IncludeIce);
                ElbowArea = (3 * (1.625 + (2 * Ice)) + (6 * (1.625 + (2 * Ice)))) * AppurtDrag(WSpec, 1.625 + (2 * Ice), 3 / 12, z, V, Y, ExpCat, StrCl, Topo, IncludeIce);
                Adir1 = Round(((NumberSections * (FinnedArea + SlotCovers + BarePole + LugArea + ElbowArea) + FeedLineArea * numberFeedLines + PowerDividerArea)) / 144, 2);
                Amax = ((NumberSections * (FinnedArea + SlotCovers + BarePole + LugArea + ElbowArea) + FeedLineArea * numberFeedLines + PowerDividerArea)) / 144;
                RDir1 = Round(G * Amax * Q, 0);

                //Area Direction 2
                SlotCovers = (L - 5 + 2 * Ice) * (6.375 + 2 * Ice) * FlatAppurtDrag(WSpec, 6.375 + 2 * Ice, (L - 5 + 2 * Ice) / 12, z, V, ExpCat, StrCl, Topo);
                BarePole = (5 - 2 * Ice) * (Dpipe + 2 * Ice) * AppurtDrag(WSpec, Dpipe + 2 * Ice, (5 - 2 * Ice) / 12, z, V, Y, ExpCat, StrCl, Topo, IncludeIce);
                LugArea = LugLength * (11.5 + 3 * Ice) * FlatAppurtDrag(WSpec, 3.75 + Ice, (11.5 + 3 * Ice) / 12, z, V, ExpCat, StrCl, Topo);
                FeedLineArea = FeedLineLength * (2 + 2 * Ice) * AppurtDrag(WSpec, 2 + 2 * Ice, FeedLineLength / 12, z, V, Y, ExpCat, StrCl, Topo, IncludeIce);
                ElbowArea = (3 * (1.625 + (2 * Ice)) + (6 * (1.625 + (2 * Ice)))) * AppurtDrag(WSpec, 1.625 + (2 * Ice), 3 / 12, z, V, Y, ExpCat, StrCl, Topo, IncludeIce);
                Adir2 = Round(((NumberSections * (SlotCovers + BarePole + LugArea + ElbowArea) + FeedLineArea * numberFeedLines + PowerDividerArea)) / 144, 2);
                A = ((NumberSections * (SlotCovers + BarePole + LugArea + ElbowArea) + FeedLineArea * numberFeedLines + PowerDividerArea)) / 144;
                RDir2 = Round(G * A * Q, 0);
                if (A > Amax) {
                    Amax = A;
                }
                RMax = Round(G * Amax * Q, 0);
                Amax = Round(Amax, 2);

                //Weight
                PoleWeight = ((Pi / 4) * (Math.pow(Dpipe, 2) - Math.pow((Dpipe - 2 * twall), 2)) * L) * 0.1;
                SlotCoverWeight = (L - 5) * (0.03);
                FinWeight = (2 * NLayers) * (0.1) * (0.4 * lambda) * (0.8 * lambda + 1) * 0.125;
                PatternShaperWeight = (0.5 * 1 + lambda / 2 * 0.125) * (0.8 * lambda + 1) * NLayers * 0.1;
                HarnessWeight = (2 * 1.5) + ((0.243 * L) * 0.1) + ((0.075 * (L / 2)) * 0.322) + 0.165;
                LugWeight = 7.5;
                Misc = 5;
                Elbow = 3.5;
                FeedlineWeight = FeedLineLength * 1.04 / 12; //Andrew Catalog
                Weight = (PoleWeight + SlotCoverWeight + FinWeight + PatternShaperWeight + HarnessWeight + LugWeight + Misc + Elbow) * NumberSections + (FeedlineWeight) * numberFeedLines + PowerDividerWeight;
                TotalWeight = Round(Weight, 0);

                //Ice Weight
                SlotCoverIce = (L - 5 + 2 * Ice) * (0.032) * (1.5 * (2.89) * (Math.pow((1.59 + Ice), 2) - Math.pow((1.59), 2)) + 1.5 * (0.71) * (Math.pow((2.5 + Ice), 2) - Math.pow((2.5), 2)));
                BarePoleIce = Pi / 4 * (0.032) * (Math.pow((Dpipe + 2 * Ice), 2) - Math.pow(Dpipe, 2)) * (5 - 2 * Ice);
                FinnedWeight = (2 * (0.4 * lambda + Ice) * 2 * Ice) * NLayers * (0.8 * lambda + 1 + 2 * Ice) * 0.032;
                PatternShaperIce = (2 * 0.5 * lambda + 2 * Ice) * NLayers * (0.8 * lambda + 1 + 2 * Ice) * 0.032;
                ElbowIce = (((Pi / 4) * (Math.pow((1.625 + 2 * Ice), 2) - Math.pow((1.625), 2)) * 5.6) + ((Pi / 4) * (Math.pow((1.625 + 2 * Ice), 2) - Math.pow((1.625), 2)) * 2.8)) * 0.032;
                LugIce = 3 * 0.032 * (2 * (11.5 * 6.5 * Ice) + 2 * (11.5 * 0.5 * Ice) + 2 * (6.5 * 0.5 * Ice));
                FeedLineIce = (Pi / 4) * (Math.pow((1.98 + 2 * Ice), 2) - Math.pow((1.98), 2)) * 0.032 * FeedLineLength;
                IceWeight = (SlotCoverIce + FinnedWeight + BarePoleIce + LugIce + FeedLineIce) * NumberSections + PowerDividerIceWeight;
                Wice = Round(IceWeight, 0);

                //Total Weight
                TotalWeight = Weight + IceWeight;
                TotalWeight = Round(TotalWeight, 3);

              } else if (optRadome == true) {
                FeedLineLength = 300;
                LugLength = 1.5;
                //PowerDivider(Ice);
                //Area Direction 1
                Dradome = RadDia;
                Radome = (L - 5 + 2 * Ice) * (Dradome + 2 * Ice) * AppurtDrag(WSpec, Dradome + 2 * Ice, (L - 5 + 2 * Ice) / 12, z, V, Y, ExpCat, StrCl, Topo, IncludeIce);
                BarePole = (5 - 2 * Ice) * (Dpipe + 2 * Ice) * AppurtDrag(WSpec, Dpipe + 2 * Ice, (5 - 2 * Ice) / 12, z, V, Y, ExpCat, StrCl, Topo, IncludeIce);
                LugArea = LugLength * (6.5 + 3 * Ice) * FlatAppurtDrag(WSpec, 3.75 + Ice, (4.1 + Ice) / 12, z, V, ExpCat, StrCl, Topo);
                FeedLineArea = FeedLineLength * (2 + 2 * Ice) * AppurtDrag(WSpec, 2 + 2 * Ice, FeedLineLength / 12, z, V, Y, ExpCat, StrCl, Topo, IncludeIce);
                ElbowArea = (3 * (1.625 + (2 * Ice)) + (6 * (1.625 + (2 * Ice)))) * AppurtDrag(WSpec, 1.625 + (2 * Ice), 3 / 12, z, V, Y, ExpCat, StrCl, Topo, IncludeIce);
                Adir1 = Round(((NumberSections * (Radome + BarePole + LugArea + ElbowArea) + FeedLineArea * numberFeedLines + PowerDividerArea)) / 144, 2);
                Amax = ((NumberSections * (Radome + BarePole + LugArea + ElbowArea) + FeedLineArea * numberFeedLines + PowerDividerArea)) / 144;
                RDir1 = Round(G * Amax * Q, 0);

                //Area Direction 2
                Radome = (L - 5 + 2 * Ice) * (Dradome + 2 * Ice) * AppurtDrag(WSpec, Dradome + 2 * Ice, (L - 5 + 2 * Ice) / 12, z, V, Y, ExpCat, StrCl, Topo, IncludeIce);
                BarePoleArea = (5 - 2 * Ice) * (Dpipe + 2 * Ice) * AppurtDrag(WSpec, Dpipe + 2 * Ice, (5 - 2 * Ice) / 12, z, V, Y, ExpCat, StrCl, Topo, IncludeIce);
                LugArea = LugLength * (11.5 + 3 * Ice) * FlatAppurtDrag(WSpec, 3.75 + Ice, (11.5 + 3 * Ice) / 12, z, V, ExpCat, StrCl, Topo);
                FeedLineArea = FeedLineLength * (2 + 2 * Ice) * AppurtDrag(WSpec, 2 + 2 * Ice, FeedLineLength / 12, z, V, Y, ExpCat, StrCl, Topo, IncludeIce);
                ElbowArea = (3 * (1.625 + (2 * Ice)) + (6 * (1.625 + (2 * Ice)))) * AppurtDrag(WSpec, 1.625 + (2 * Ice), 3 / 12, z, V, Y, ExpCat, StrCl, Topo, IncludeIce);
                Adir2 = Round(((NumberSections * (Radome + BarePole + LugArea + ElbowArea) + FeedLineArea * numberFeedLines + PowerDividerArea)) / 144, 2);
                A = ((NumberSections * (Radome + BarePole + LugArea + ElbowArea) + FeedLineArea * numberFeedLines + PowerDividerArea)) / 144;
                RDir2 = Round(G * A * Q, 0);
                if (A > Amax) {
                    Amax = A;
                }
                RMax = Round(G * Amax * Q, 0);
                Amax = Round(Amax, 2);

                //Weight
                EndSeals = Pi * (Math.pow(Dradome, 2) - Math.pow(Dpipe, 2)) * 1 * 0.034;
                PoleWeight = ((Pi / 4) * (Math.pow(Dpipe, 2) - Math.pow((Dpipe - 2 * twall), 2)) * L) * 0.1;
                RadomeWeight = (0.1544 * Dradome + 2) * (L - 5) / 12;
                HarnessWeight = (2 * 1.5) + ((0.243 * L) * 0.1) + ((0.075 * (L / 2)) * 0.322) + 0.165;
                LugWeight = 7.5;
                Misc = 5;
                Elbow = 3.5;
                FeedlineWeight = FeedLineLength * 1.04 / 12 //Andrew Catalog;
                Weight = (EndSeals + PoleWeight + RadomeWeight + Inner + Misc + Elbow) * NumberSections + (FeedlineWeight) * numberFeedLines + PowerDividerWeight;
                TotalWeight = Round(Weight, 0);

                //Ice Weight
                RadomeIce = Pi / 4 * (0.032) * (Math.pow((Dradome + 2 * Ice), 2) - Math.pow(Dradome, 2)) * (L - 5 + 2 * Ice);
                BarePoleIce = Pi / 4 * (0.032) * (Math.pow((Dpipe + 2 * Ice), 2) - Math.pow(Dpipe, 2)) * (5 - 2 * Ice);
                ElbowIce = (((Pi / 4) * (Math.pow((1.625 + 2 * Ice), 2) - Math.pow((1.625), 2)) * 5.6) + ((Pi / 4) * (Math.pow((1.625 + 2 * Ice), 2) - Math.pow((1.625), 2)) * 2.8)) * 0.032;
                LugIce = 3 * 0.032 * (2 * (11.5 * 6.5 * Ice) + 2 * (11.5 * 0.5 * Ice) + 2 * (6.5 * 0.5 * Ice));
                FeedLineIce = (Pi / 4) * (Math.pow((1.98 + 2 * Ice), 2) - Math.pow((1.98), 2)) * 0.032 * FeedLineLength;
                IceWeight = (RadomeIce + BarePoleIce + LugIce + FeedLineIce) * NumberSections + PowerDividerIceWeight;
                Wice = Round(IceWeight, 0);

                //Total Weight
                TotalWeight = Weight + IceWeight;
                TotalWeight = Round(TotalWeight, 3);
              }
          },
          patternG: function() {
              if (!optRadome || optRadome == false) {
                Lfin = 0.8 * lambda + 1;
                FeedLineLength = 300;
                LugLength = 1.5;
                //PowerDivider(Ice);
                //Area Direction 1
                FinnedArea = (0.5 * lambda + 2 * Ice) * NLayers * (Lfin + 2 * Ice) * FlatAppurtDrag(WSpec, 0.5 * lambda + 2 * Ice, NLayers * (Lfin + 2 * Ice) / 12, z, V, ExpCat, StrCl, Topo);
                SlotCovers = (Dpipe + 2 * Ice) * (L - NLayers * (Lfin + 2 * Ice) - 5 - 2 * Ice) * FlatAppurtDrag(WSpec, Dpipe + 2 * Ice, (L - NLayers * (Lfin + 2 * Ice) - 5 - 2 * Ice) / 12, z, V, ExpCat, StrCl, Topo);
                BarePole = (5 - 2 * Ice) * (Dpipe + 2 * Ice) * AppurtDrag(WSpec, Dpipe + 2 * Ice, (5 - 2 * Ice) / 12, z, V, Y, ExpCat, StrCl, Topo, IncludeIce);
                LugArea = LugLength * (6.5 + 3 * Ice) * FlatAppurtDrag(WSpec, 3.75 + Ice, (4.1 + Ice) / 12, z, V, ExpCat, StrCl, Topo);
                FeedLineArea = FeedLineLength * (2 + 2 * Ice) * AppurtDrag(WSpec, 2 + 2 * Ice, FeedLineLength / 12, z, V, Y, ExpCat, StrCl, Topo, IncludeIce);
                ElbowArea = (3 * (1.625 + (2 * Ice)) + (6 * (1.625 + (2 * Ice)))) * AppurtDrag(WSpec, 1.625 + (2 * Ice), 3 / 12, z, V, Y, ExpCat, StrCl, Topo, IncludeIce);
                Adir1 = Round(((NumberSections * (SlotCovers + BarePole + FinnedArea + LugArea + ElbowArea) + FeedLineArea * numberFeedLines + PowerDividerArea)) / 144, 2);
                Amax = ((NumberSections * (SlotCovers + BarePole + FinnedArea + LugArea + ElbowArea) + FeedLineArea * numberFeedLines + PowerDividerArea)) / 144;
                RDir1 = Round(G * Amax * Q, 0);

                //Area Direction 2
                SlotCovers = NLayers * (Lfin + 2 * Ice) * (6.375 + 2 * Ice) * FlatAppurtDrag(WSpec, 6.375 + 2 * Ice, NLayers * (Lfin + 2 * Ice) / 12, z, V, ExpCat, StrCl, Topo);
                FinnedArea = (5.375 + 1.125 + 2 * Ice) * NLayers * (0.8 * lambda + 1 + 2 * Ice) * FlatAppurtDrag(WSpec, 5.375 + 1.125 + 2 * Ice, NLayers * (0.8 * lambda + 1 + 2 * Ice) / 12, z, V, ExpCat, StrCl, Topo);
                BarePole = (5 - 2 * Ice) * (Dpipe + 2 * Ice) * AppurtDrag(WSpec, Dpipe + 2 * Ice, (5 - 2 * Ice) / 12, z, V, Y, ExpCat, StrCl, Topo, IncludeIce);
                LugArea = LugLength * (11.5 + 3 * Ice) * FlatAppurtDrag(WSpec, 3.75 + Ice, (11.5 + 3 * Ice) / 12, z, V, ExpCat, StrCl, Topo);
                FeedLineArea = FeedLineLength * (2 + 2 * Ice) * AppurtDrag(WSpec, 2 + 2 * Ice, FeedLineLength / 12, z, V, Y, ExpCat, StrCl, Topo, IncludeIce);
                ElbowArea = (3 * (1.625 + (2 * Ice)) + (6 * (1.625 + (2 * Ice)))) * AppurtDrag(WSpec, 1.625 + (2 * Ice), 3 / 12, z, V, Y, ExpCat, StrCl, Topo, IncludeIce);
                Adir2 = Round(((NumberSections * (SlotCovers + FinnedArea + BarePole + LugArea + ElbowArea) + FeedLineArea * numberFeedLines + PowerDividerArea)) / 144, 2);
                A = ((NumberSections * (SlotCovers + FinnedArea + BarePole + LugArea + ElbowArea) + FeedLineArea * numberFeedLines + PowerDividerArea)) / 144;
                RDir2 = Round(G * A * Q, 0);
                if (A > Amax) {
                    Amax = A;
                }
                RMax = Round(G * Amax * Q, 0);
                Amax = Round(Amax, 2);

                //Weight
                PoleWeight = ((Pi / 4) * (Math.pow(Dpipe, 2) - Math.pow((Dpipe - 2 * twall), 2)) * L) * 0.1;
                SlotCoverWeight = (L - 5) * (0.03);
                PatternShaperWeight = (0.5 * 1 + lambda / 2 * 0.125) * Lfin * NLayers * 0.1;
                HarnessWeight = (2 * 1.5) + ((0.243 * L) * 0.1) + ((0.075 * (L / 2)) * 0.322) + 0.165;
                LugWeight = 7.5;
                Misc = 5;
                Elbow = 3.5;
                FeedlineWeight = FeedLineLength * 1.04 / 12; //Andrew Catalog
                Weight = (PoleWeight + SlotCoverWeight + PatternShaperWeight + HarnessWeight + LugWeight + Misc + Elbow) * NumberSections + (FeedlineWeight) * numberFeedLines + PowerDividerWeight;
                TotalWeight = Round(Weight, 0);

                //Ice Weight
                SlotCoverIce = (L - 5 - 2 * Ice) * (0.032) * (1.5 * (2.89) * (Math.pow((1.59 + Ice), 2) - Math.pow((1.59), 2)) + 1.5 * (0.71) * (Math.pow((2.5 + Ice), 2) - Math.pow((2.5), 2)));
                BarePoleIce = Pi / 4 * (0.032) * (Math.pow((Dpipe + 2 * Ice), 2) - Math.pow(Dpipe, 2)) * (5 - 2 * Ice);
                PatternShaperIce = (2 * 0.5 * lambda + 2 * Ice) * NLayers * (Lfin + 2 * Ice) * 0.032;
                ElbowIce = (((Pi / 4) * (Math.pow((1.625 + 2 * Ice), 2) - Math.pow((1.625), 2)) * 5.6) + ((Pi / 4) * (Math.pow((1.625 + 2 * Ice), 2) - Math.pow((1.625), 2)) * 2.8)) * 0.032;
                LugIce = 3 * 0.032 * (2 * (11.5 * 6.5 * Ice) + 2 * (11.5 * 0.5 * Ice) + 2 * (6.5 * 0.5 * Ice));
                FeedLineIce = (Pi / 4) * (Math.pow((1.98 + 2 * Ice), 2) - Math.pow((1.98), 2)) * 0.032 * FeedLineLength;
                IceWeight = (SlotCoverIce + BarePoleIce + PatternShaperIce + LugIce + FeedLineIce) * NumberSections + PowerDividerIceWeight;
                Wice = Round(IceWeight, 0);

                //Total Weight
                TotalWeight = Weight + IceWeight;
                TotalWeight = Round(TotalWeight, 3);

              } else if (optRadome == true) {
                FeedLineLength = 300;
                LugLength = 1.5;
                //PowerDivider(Ice);
                //Area Direction 1
                Dradome = RadDia;
                Radome = (L - 5 + 2 * Ice) * (Dradome + 2 * Ice) * AppurtDrag(WSpec, Dradome + 2 * Ice, (L - 5 + 2 * Ice) / 12, z, V, Y, ExpCat, StrCl, Topo, IncludeIce);
                BarePole = (5 - 2 * Ice) * (Dpipe + 2 * Ice) * AppurtDrag(WSpec, Dpipe + 2 * Ice, (5 - 2 * Ice) / 12, z, V, Y, ExpCat, StrCl, Topo, IncludeIce);
                LugArea = LugLength * (6.5 + 3 * Ice) * FlatAppurtDrag(WSpec, 3.75 + Ice, (4.1 + Ice) / 12, z, V, ExpCat, StrCl, Topo);
                FeedLineArea = FeedLineLength * (2 + 2 * Ice) * AppurtDrag(WSpec, 2 + 2 * Ice, FeedLineLength / 12, z, V, Y, ExpCat, StrCl, Topo, IncludeIce);
                ElbowArea = (3 * (1.625 + (2 * Ice)) + (6 * (1.625 + (2 * Ice)))) * AppurtDrag(WSpec, 1.625 + (2 * Ice), 3 / 12, z, V, Y, ExpCat, StrCl, Topo, IncludeIce);
                Adir1 = Round(((NumberSections * (Radome + BarePole + LugArea + ElbowArea) + FeedLineArea * numberFeedLines + PowerDividerArea)) / 144, 2);
                Amax = ((NumberSections * (Radome + BarePole + LugArea + ElbowArea) + FeedLineArea * numberFeedLines + PowerDividerArea)) / 144;
                RDir1 = Round(G * Amax * Q, 0);

                //Area Direction 2
                Radome = (L - 5 + 2 * Ice) * (Dradome + 2 * Ice) * AppurtDrag(WSpec, Dradome + 2 * Ice, (L - 5 + 2 * Ice) / 12, z, V, Y, ExpCat, StrCl, Topo, IncludeIce);
                BarePoleArea = (5 - 2 * Ice) * (Dpipe + 2 * Ice) * AppurtDrag(WSpec, Dpipe + 2 * Ice, (5 - 2 * Ice) / 12, z, V, Y, ExpCat, StrCl, Topo, IncludeIce);
                LugArea = LugLength * (11.5 + 3 * Ice) * FlatAppurtDrag(WSpec, 3.75 + Ice, (11.5 + 3 * Ice) / 12, z, V, ExpCat, StrCl, Topo);
                FeedLineArea = FeedLineLength * (2 + 2 * Ice) * AppurtDrag(WSpec, 2 + 2 * Ice, FeedLineLength / 12, z, V, Y, ExpCat, StrCl, Topo, IncludeIce);
                ElbowArea = (3 * (1.625 + (2 * Ice)) + (6 * (1.625 + (2 * Ice)))) * AppurtDrag(WSpec, 1.625 + (2 * Ice), 3 / 12, z, V, Y, ExpCat, StrCl, Topo, IncludeIce);
                Adir2 = Round(((NumberSections * (Radome + BarePole + LugArea + ElbowArea) + FeedLineArea * numberFeedLines + PowerDividerArea)) / 144, 2);
                A = ((NumberSections * (Radome + BarePole + LugArea + ElbowArea) + FeedLineArea * numberFeedLines + PowerDividerArea)) / 144;
                RDir2 = Round(G * A * Q, 0);
                if (A > Amax) {
                    Amax = A;
                }
                RMax = Round(G * Amax * Q, 0);
                Amax = Round(Amax, 2);

                //Weight
                EndSeals = Pi * (Math.pow(Dradome, 2) - Math.pow(Dpipe, 2)) * 1 * 0.034;
                PoleWeight = ((Pi / 4) * (Math.pow(Dpipe, 2) - Math.pow((Dpipe - 2 * twall), 2)) * L) * 0.1;
                RadomeWeight = (0.1544 * Dradome + 2) * (L - 5) / 12;
                HarnessWeight = (2 * 1.5) + ((0.243 * L) * 0.1) + ((0.075 * (L / 2)) * 0.322) + 0.165;
                LugWeight = 7.5;
                Misc = 5;
                Elbow = 3.5;
                FeedlineWeight = FeedLineLength * 1.04 / 12; //Andrew Catalog
                Weight = (EndSeals + PoleWeight + RadomeWeight + Inner + Misc + Elbow) * NumberSections + (FeedlineWeight) * numberFeedLines + PowerDividerWeight;
                TotalWeight = Round(Weight, 0);

                //Ice Weight
                RadomeIce = Pi / 4 * (0.032) * (Math.pow((Dradome + 2 * Ice), 2) - Math.pow(Dradome, 2)) * (L - 5 + 2 * Ice);
                BarePoleIce = Pi / 4 * (0.032) * (Math.pow((Dpipe + 2 * Ice), 2) - Math.pow(Dpipe, 2)) * (5 - 2 * Ice);
                ElbowIce = (((Pi / 4) * (Math.pow((1.625 + 2 * Ice), 2) - Math.pow((1.625), 2)) * 5.6) + ((Pi / 4) * (Math.pow((1.625 + 2 * Ice), 2) - Math.pow((1.625), 2)) * 2.8)) * 0.032;
                LugIce = 3 * 0.032 * (2 * (11.5 * 6.5 * Ice) + 2 * (11.5 * 0.5 * Ice) + 2 * (6.5 * 0.5 * Ice));
                FeedLineIce = (Pi / 4) * (Math.pow((1.98 + 2 * Ice), 2) - Math.pow((1.98), 2)) * 0.032 * FeedLineLength;
                IceWeight = (RadomeIce + BarePoleIce + LugIce + FeedLineIce) * NumberSections + PowerDividerIceWeight;
                Wice = Round(IceWeight, 0);

                //Total Weight
                TotalWeight = Weight + IceWeight;
                TotalWeight = Round(TotalWeight, 3);
            }
          },
          //end pattern G
          patternH: function() {
              if (!optRadome || optRadome == false) {
                FeedLineLength = 300;
                LugLength = 1.5;
                Lfin = 0.8 * lambda + 1;
                FinWidth = 2 * (1.75 + 0.4 * lambda) * Math.cos(70 * Pi / 180);
                //PowerDivider(Ice);
                //Area Direction 1
                FinnedArea = (FinWidth + 2 * Ice) * NLayers * (Lfin + 2 * Ice) * FlatAppurtDrag(WSpec, FinWidth + 2 * Ice, NLayers * (Lfin + 2 * Ice) / 12, z, V, ExpCat, StrCl, Topo);
                SlotCovers = (Dpipe + 2 * Ice) * (L - NLayers * (Lfin + 2 * Ice) - 5 - 2 * Ice) * FlatAppurtDrag(WSpec, Dpipe + 2 * Ice, (L - NLayers * (Lfin + 2 * Ice) - 5 - 2 * Ice) / 12, z, V, ExpCat, StrCl, Topo);
                BarePole = (5 - 2 * Ice) * (Dpipe + 2 * Ice) * AppurtDrag(WSpec, Dpipe + 2 * Ice, (5 - 2 * Ice) / 12, z, V, Y, ExpCat, StrCl, Topo, IncludeIce);
                LugArea = LugLength * (6.5 + 3 * Ice) * FlatAppurtDrag(WSpec, 3.75 + Ice, (4.1 + Ice) / 12, z, V, ExpCat, StrCl, Topo);
                FeedLineArea = FeedLineLength * (2 + 2 * Ice) * AppurtDrag(WSpec, 2 + 2 * Ice, FeedLineLength / 12, z, V, Y, ExpCat, StrCl, Topo, IncludeIce);
                ElbowArea = (3 * (1.625 + (2 * Ice)) + (6 * (1.625 + (2 * Ice)))) * AppurtDrag(WSpec, 1.625 + (2 * Ice), 3 / 12, z, V, Y, ExpCat, StrCl, Topo, IncludeIce);
                Adir1 = Round(((NumberSections * (SlotCovers + BarePole + FinnedArea + LugArea + ElbowArea) + FeedLineArea * numberFeedLines + PowerDividerArea)) / 144, 2);
                Amax = ((NumberSections * (SlotCovers + BarePole + FinnedArea + LugArea + ElbowArea) + FeedLineArea * numberFeedLines + PowerDividerArea)) / 144;
                RDir1 = Round(G * Amax * Q, 0);

                //Area Direction 2
                FinHeight = (1.75 + 0.4 * lambda) * Math.sin(70 * Pi / 180) + 3.625;
                SlotCovers = (L - NLayers * (Lfin + 2 * Ice) - 5 - 2 * Ice) * (5.375 + 2 * Ice) * FlatAppurtDrag(WSpec, 5.375 + 2 * Ice, (L - NLayers * (Lfin + 2 * Ice) - 5 - 2 * Ice) / 12, z, V, ExpCat, StrCl, Topo);
                FinnedArea = (3.625 + FinHeight + 2 * Ice) * NLayers * (Lfin + 2 * Ice) * FlatAppurtDrag(WSpec, 3.625 + FinHeight + 2 * Ice, (Lfin + 2 * Ice) / 12, z, V, ExpCat, StrCl, Topo);
                BarePole = (5 - 2 * Ice) * (Dpipe + 2 * Ice) * AppurtDrag(WSpec, Dpipe + 2 * Ice, (5 - 2 * Ice) / 12, z, V, Y, ExpCat, StrCl, Topo, IncludeIce);
                LugArea = LugLength * (11.5 + 3 * Ice) * FlatAppurtDrag(WSpec, 3.75 + Ice, (11.5 + 3 * Ice) / 12, z, V, ExpCat, StrCl, Topo);
                FeedLineArea = FeedLineLength * (2 + 2 * Ice) * AppurtDrag(WSpec, 2 + 2 * Ice, FeedLineLength / 12, z, V, Y, ExpCat, StrCl, Topo, IncludeIce);
                ElbowArea = (3 * (1.625 + (2 * Ice)) + (6 * (1.625 + (2 * Ice)))) * AppurtDrag(WSpec, 1.625 + (2 * Ice), 3 / 12, z, V, Y, ExpCat, StrCl, Topo, IncludeIce);
                Adir2 = Round(((NumberSections * (SlotCovers + FinnedArea + BarePole + LugArea + ElbowArea) + FeedLineArea * numberFeedLines + PowerDividerArea)) / 144, 2);
                A = ((NumberSections * (SlotCovers + FinnedArea + BarePole + LugArea + ElbowArea) + FeedLineArea * numberFeedLines + PowerDividerArea)) / 144;
                RDir2 = Round(G * A * Q, 0);
                if (A > Amax) {
                    Amax = A;
                }
                RMax = Round(G * Amax * Q, 0);
                Amax = Round(Amax, 2);

                //Weight
                PoleWeight = ((Pi / 4) * (Math.pow(Dpipe, 2) - Math.pow((Dpipe - 2 * twall), 2)) * L) * 0.1;
                SlotCoverWeight = (L - 5) * (0.03);
                FinWeight = (2 * NLayers) * (0.1) * (0.4 * lambda) * Lfin * 0.125;
                HarnessWeight = (2 * 1.5) + ((0.243 * L) * 0.1) + ((0.075 * (L / 2)) * 0.322) + 0.165;
                LugWeight = 7.5;
                Misc = 5;
                Elbow = 3.5;
                FeedlineWeight = FeedLineLength * 1.04 / 12; //Andrew Catalog
                Weight = (PoleWeight + SlotCoverWeight + FinWeight + HarnessWeight + LugWeight + Misc + Elbow) * NumberSections + (FeedlineWeight) * numberFeedLines + PowerDividerWeight;
                TotalWeight = Round(Weight, 0);

                //Ice Weight
                SlotCoverIce = (L - S - 7 - 2 * Ice) * (0.032) * (1.5 * (2.89) * (Math.pow((1.59 + Ice), 2) - Math.pow((1.59), 2)) + 1.5 * (0.71) * (Math.pow((2.5 + Ice), 2) - Math.pow((2.5), 2)));
                BarePoleIce = Pi / 4 * (0.032) * (Math.pow((Dpipe + 2 * Ice), 2) - Math.pow(Dpipe, 2)) * (5 - 2 * Ice);
                FinnedWeight = (2 * (0.4 * lambda + Ice) * 2 * Ice + (0.5 * (3.04) * (Math.pow((1.35 + Ice), 2) - (Math.pow(1.35, 2))) + 0.5 * (4.66) * (Math.pow((1.75 + Ice), 2) - Math.pow((1.75), 2)))) * NLayers * (Lfin + 2 * Ice) * 0.032;
                ElbowIce = (((Pi / 4) * (Math.pow((1.625 + 2 * Ice), 2) - Math.pow((1.625), 2)) * 5.6) + ((Pi / 4) * (Math.pow((1.625 + 2 * Ice), 2) - Math.pow((1.625), 2)) * 2.8)) * 0.032;
                LugIce = 3 * 0.032 * (2 * (11.5 * 6.5 * Ice) + 2 * (11.5 * 0.5 * Ice) + 2 * (6.5 * 0.5 * Ice));
                FeedLineIce = (Pi / 4) * (Math.pow((1.98 + 2 * Ice), 2) - Math.pow((1.98), 2)) * 0.032 * FeedLineLength;
                IceWeight = (SlotCoverIce + FinnedWeight + BarePoleIce + LugIce + FeedLineIce) * NumberSections + PowerDividerIceWeight;
                Wice = Round(IceWeight, 0);

                //Total Weight
                TotalWeight = Weight + IceWeight;
                TotalWeight = Round(TotalWeight, 3);

              } else if (optRadome == true) {
                FeedLineLength = 300;
                LugLength = 1.5;
                //PowerDivider(Ice);
                //Area Direction 1
                Dradome = RadDia;
                Radome = (L - 5 + 2 * Ice) * (Dradome + 2 * Ice) * AppurtDrag(WSpec, Dradome + 2 * Ice, (L - 5 + 2 * Ice) / 12, z, V, Y, ExpCat, StrCl, Topo, IncludeIce);
                BarePole = (5 - 2 * Ice) * (Dpipe + 2 * Ice) * AppurtDrag(WSpec, Dpipe + 2 * Ice, (5 - 2 * Ice) / 12, z, V, Y, ExpCat, StrCl, Topo, IncludeIce);
                LugArea = LugLength * (6.5 + 3 * Ice) * FlatAppurtDrag(WSpec, 3.75 + Ice, (4.1 + Ice) / 12, z, V, ExpCat, StrCl, Topo);
                FeedLineArea = FeedLineLength * (2 + 2 * Ice) * AppurtDrag(WSpec, 2 + 2 * Ice, FeedLineLength / 12, z, V, Y, ExpCat, StrCl, Topo, IncludeIce);
                ElbowArea = (3 * (1.625 + (2 * Ice)) + (6 * (1.625 + (2 * Ice)))) * AppurtDrag(WSpec, 1.625 + (2 * Ice), 3 / 12, z, V, Y, ExpCat, StrCl, Topo, IncludeIce);
                Adir1 = Round(((NumberSections * (Radome + BarePole + LugArea + ElbowArea) + FeedLineArea * numberFeedLines + PowerDividerArea)) / 144, 2);
                Amax = ((NumberSections * (Radome + BarePole + LugArea + ElbowArea) + FeedLineArea * numberFeedLines + PowerDividerArea)) / 144;
                RDir1 = Round(G * Amax * Q, 0);

                //Area Direction 2
                Radome = (L - 5 + 2 * Ice) * (Dradome + 2 * Ice) * AppurtDrag(WSpec, Dradome + 2 * Ice, (L - 5 + 2 * Ice) / 12, z, V, Y, ExpCat, StrCl, Topo, IncludeIce);
                BarePoleArea = (5 - 2 * Ice) * (Dpipe + 2 * Ice) * AppurtDrag(WSpec, Dpipe + 2 * Ice, (5 - 2 * Ice) / 12, z, V, Y, ExpCat, StrCl, Topo, IncludeIce);
                LugArea = LugLength * (11.5 + 3 * Ice) * FlatAppurtDrag(WSpec, 3.75 + Ice, (11.5 + 3 * Ice) / 12, z, V, ExpCat, StrCl, Topo);
                FeedLineArea = FeedLineLength * (2 + 2 * Ice) * AppurtDrag(WSpec, 2 + 2 * Ice, FeedLineLength / 12, z, V, Y, ExpCat, StrCl, Topo, IncludeIce);
                ElbowArea = (3 * (1.625 + (2 * Ice)) + (6 * (1.625 + (2 * Ice)))) * AppurtDrag(WSpec, 1.625 + (2 * Ice), 3 / 12, z, V, Y, ExpCat, StrCl, Topo, IncludeIce);
                Adir2 = Round(((NumberSections * (Radome + BarePole + LugArea + ElbowArea) + FeedLineArea * numberFeedLines + PowerDividerArea)) / 144, 2);
                A = ((NumberSections * (Radome + BarePole + LugArea + ElbowArea) + FeedLineArea * numberFeedLines + PowerDividerArea)) / 144;
                RDir2 = Round(G * A * Q, 0);
                if (A > Amax) {
                    Amax = A;
                }
                RMax = Round(G * Amax * Q, 0);
                Amax = Round(Amax, 2);

                //Weight
                EndSeals = Pi * (Math.pow(Dradome, 2) - Math.pow(Dpipe, 2)) * 1 * 0.034;
                PoleWeight = ((Pi / 4) * (Math.pow(Dpipe, 2) - Math.pow((Dpipe - 2 * twall), 2)) * L) * 0.1;
                RadomeWeight = (0.1544 * Dradome + 2) * (L - 5) / 12;
                HarnessWeight = (2 * 1.5) + ((0.243 * L) * 0.1) + ((0.075 * (L / 2)) * 0.322) + 0.165;
                LugWeight = 7.5;
                Misc = 5;
                Elbow = 3.5;
                FeedlineWeight = FeedLineLength * 1.04 / 12; //Andrew Catalog
                Weight = (EndSeals + PoleWeight + RadomeWeight + Inner + Misc + Elbow) * NumberSections + (FeedlineWeight) * numberFeedLines + PowerDividerWeight;
                TotalWeight = Round(Weight, 0);


                //Ice Weight
                RadomeIce = Pi / 4 * (0.032) * (Math.pow((Dradome + 2 * Ice), 2) - Math.pow(Dradome, 2)) * (L - 5 + 2 * Ice);
                BarePoleIce = Pi / 4 * (0.032) * (Math.pow((Dpipe + 2 * Ice), 2) - Math.pow(Dpipe, 2)) * (5 - 2 * Ice);
                ElbowIce = (((Pi / 4) * (Math.pow((1.625 + 2 * Ice), 2) - Math.pow((1.625), 2)) * 5.6) + ((Pi / 4) * (Math.pow((1.625 + 2 * Ice), 2) - Math.pow((1.625), 2)) * 2.8)) * 0.032;
                LugIce = 3 * 0.032 * (2 * (11.5 * 6.5 * Ice) + 2 * (11.5 * 0.5 * Ice) + 2 * (6.5 * 0.5 * Ice));
                FeedLineIce = (Pi / 4) * (Math.pow((1.98 + 2 * Ice), 2) - Math.pow((1.98), 2)) * 0.032 * FeedLineLength;
                IceWeight = (RadomeIce + BarePoleIce + LugIce + FeedLineIce) * NumberSections + PowerDividerIceWeight;
                Wice = Round(IceWeight, 0);

                //Total Weight
                TotalWeight = Weight + IceWeight;
                TotalWeight = Round(TotalWeight, 3);
              }
          },
          // end pattern H
          patternI: function() {
              if (!optRadome || optRadome == false) {
                FeedLineLength = 300;
                LugLength = 1.5;
                Lfin = 0.8 * lambda + 1;
                FinWidth = 2 * (1.75 + 0.4 * lambda) * Math.cos(60 * Pi / 180);
                //PowerDivider(Ice);
                //Area Direction 1
                FinnedArea = (FinWidth + 2 * Ice) * NLayers * (Lfin + 2 * Ice) * FlatAppurtDrag(WSpec, FinWidth + 2 * Ice, NLayers * (Lfin + 2 * Ice) / 12, z, V, ExpCat, StrCl, Topo);
                SlotCovers = (Dpipe + 2 * Ice) * (L - NLayers * (Lfin + 2 * Ice) - 5 - 2 * Ice) * FlatAppurtDrag(WSpec, Dpipe + 2 * Ice, (L - NLayers * (Lfin + 2 * Ice) - 5 - 2 * Ice) / 12, z, V, ExpCat, StrCl, Topo);
                BarePole = (5 - 2 * Ice) * (Dpipe + 2 * Ice) * AppurtDrag(WSpec, Dpipe + 2 * Ice, (5 - 2 * Ice) / 12, z, V, Y, ExpCat, StrCl, Topo, IncludeIce);
                LugArea = LugLength * (6.5 + 3 * Ice) * FlatAppurtDrag(WSpec, 3.75 + Ice, (4.1 + Ice) / 12, z, V, ExpCat, StrCl, Topo);
                FeedLineArea = FeedLineLength * (2 + 2 * Ice) * AppurtDrag(WSpec, 2 + 2 * Ice, FeedLineLength / 12, z, V, Y, ExpCat, StrCl, Topo, IncludeIce);
                ElbowArea = (3 * (1.625 + (2 * Ice)) + (6 * (1.625 + (2 * Ice)))) * AppurtDrag(WSpec, 1.625 + (2 * Ice), 3 / 12, z, V, Y, ExpCat, StrCl, Topo, IncludeIce);
                Adir1 = Round(((NumberSections * (SlotCovers + BarePole + FinnedArea + LugArea + ElbowArea) + FeedLineArea * numberFeedLines + PowerDividerArea)) / 144, 2);
                Amax = ((NumberSections * (SlotCovers + BarePole + FinnedArea + LugArea + ElbowArea) + FeedLineArea * numberFeedLines + PowerDividerArea)) / 144;
                RDir1 = Round(G * Amax * Q, 0);

                //Area Direction 2
                FinHeight = (1.75 + 0.4 * lambda) * Math.sin(60 * Pi / 180) + 3.625;
                SlotCovers = (L - NLayers * (Lfin + 2 * Ice) - 5 - 2 * Ice) * (5.375 + 2 * Ice) * FlatAppurtDrag(WSpec, 5.375 + 2 * Ice, (L - NLayers * (Lfin + 2 * Ice) - 5 - 2 * Ice) / 12, z, V, ExpCat, StrCl, Topo);
                FinnedArea = (3.625 + FinHeight + 2 * Ice) * NLayers * (Lfin + 2 * Ice) * FlatAppurtDrag(WSpec, 3.625 + FinHeight + 2 * Ice, (Lfin + 2 * Ice) / 12, z, V, ExpCat, StrCl, Topo);
                BarePole = (5 - 2 * Ice) * (Dpipe + 2 * Ice) * AppurtDrag(WSpec, Dpipe + 2 * Ice, (5 - 2 * Ice) / 12, z, V, Y, ExpCat, StrCl, Topo, IncludeIce);
                LugArea = LugLength * (11.5 + 3 * Ice) * FlatAppurtDrag(WSpec, 3.75 + Ice, (11.5 + 3 * Ice) / 12, z, V, ExpCat, StrCl, Topo);
                FeedLineArea = FeedLineLength * (2 + 2 * Ice) * AppurtDrag(WSpec, 2 + 2 * Ice, FeedLineLength / 12, z, V, Y, ExpCat, StrCl, Topo, IncludeIce);
                ElbowArea = (3 * (1.625 + (2 * Ice)) + (6 * (1.625 + (2 * Ice)))) * AppurtDrag(WSpec, 1.625 + (2 * Ice), 3 / 12, z, V, Y, ExpCat, StrCl, Topo, IncludeIce);
                Adir2 = Round(((NumberSections * (SlotCovers + FinnedArea + BarePoleArea + LugArea + ElbowArea) + FeedLineArea * numberFeedLines + PowerDividerArea)) / 144, 2);
                A = ((NumberSections * (SlotCovers + FinnedArea + BarePole + LugArea + ElbowArea) + FeedLineArea * numberFeedLines + PowerDividerArea)) / 144;
                RDir2 = Round(G * A * Q, 0);
                if (A > Amax) {
                    Amax = A;
                }
                RMax = Round(G * Amax * Q, 0);
                Amax = Round(Amax, 2);

                //Weight
                PoleWeight = ((Pi / 4) * (Math.pow(Dpipe, 2) - Math.pow((Dpipe - 2 * twall), 2)) * L) * 0.1;
                SlotCoverWeight = (L - 5) * (0.03);
                FinWeight = (2 * NLayers) * (0.1) * (0.4 * lambda) * (0.8 * lambda + 1) * 0.125;
                HarnessWeight = (2 * 1.5) + ((0.243 * L) * 0.1) + ((0.075 * (L / 2)) * 0.322) + 0.165;
                LugWeight = 7.5;
                Misc = 5;
                Elbow = 3.5;
                FeedlineWeight = FeedLineLength * 1.04 / 12; //Andrew Catalog
                Weight = (PoleWeight + SlotCoverWeight + FinWeight + HarnessWeight + LugWeight + Misc + Elbow) * NumberSections + (FeedlineWeight) * numberFeedLines + PowerDividerWeight;
                TotalWeight = Round(Weight, 0);

                //Ice Weight
                SlotCoverIce = (L - 5 - 2 * Ice) * (0.032) * (1.5 * (2.89) * (Math.pow((1.59 + Ice), 2) - Math.pow((1.59), 2)) + 1.5 * (0.71) * (Math.pow((2.5 + Ice), 2) - Math.pow((2.5), 2)));
                BarePoleIce = Pi / 4 * (0.032) * (Math.pow((Dpipe + 2 * Ice), 2) - Math.pow(Dpipe, 2)) * (5 - 2 * Ice);
                FinnedWeight = (2 * (0.4 * lambda + Ice) * 2 * Ice + (0.5 * (3.04) * (Math.pow((1.35 + Ice), 2) - (Math.pow(1.35, 2))) + 0.5 * (4.66) * (Math.pow((1.75 + Ice), 2) - Math.pow((1.75), 2)))) * NLayers * (Lfin + 2 * Ice) * 0.032;
                ElbowIce = (((Pi / 4) * (Math.pow((1.625 + 2 * Ice), 2) - Math.pow((1.625), 2)) * 5.6) + ((Pi / 4) * (Math.pow((1.625 + 2 * Ice), 2) - Math.pow((1.625), 2)) * 2.8)) * 0.032;
                LugIce = 3 * 0.032 * (2 * (11.5 * 6.5 * Ice) + 2 * (11.5 * 0.5 * Ice) + 2 * (6.5 * 0.5 * Ice));
                FeedLineIce = (Pi / 4) * (Math.pow((1.98 + 2 * Ice), 2) - Math.pow((1.98), 2)) * 0.032 * FeedLineLength;
                IceWeight = (SlotCoverIce + FinnedWeight + BarePoleIce + LugIce + FeedLineIce) * NumberSections + PowerDividerIceWeight;
                Wice = Round(IceWeight, 0);

                //Total Weight
                TotalWeight = Weight + IceWeight;
                TotalWeight = Round(TotalWeight, 3);

              } else if (optRadome == true) {
                FeedLineLength = 300;
                LugLength = 1.5;
                //PowerDivider(Ice);
                //Area Direction 1
                Dradome = RadDia;
                Radome = (L - 5 + 2 * Ice) * (Dradome + 2 * Ice) * AppurtDrag(WSpec, Dradome + 2 * Ice, (L - 5 + 2 * Ice) / 12, z, V, Y, ExpCat, StrCl, Topo, IncludeIce);
                BarePole = (5 - 2 * Ice) * (Dpipe + 2 * Ice) * AppurtDrag(WSpec, Dpipe + 2 * Ice, (5 - 2 * Ice) / 12, z, V, Y, ExpCat, StrCl, Topo, IncludeIce);
                LugArea = LugLength * (6.5 + 3 * Ice) * FlatAppurtDrag(WSpec, 3.75 + Ice, (4.1 + Ice) / 12, z, V, ExpCat, StrCl, Topo);
                FeedLineArea = FeedLineLength * (2 + 2 * Ice) * AppurtDrag(WSpec, 2 + 2 * Ice, FeedLineLength / 12, z, V, Y, ExpCat, StrCl, Topo, IncludeIce);
                ElbowArea = (3 * (1.625 + (2 * Ice)) + (6 * (1.625 + (2 * Ice)))) * AppurtDrag(WSpec, 1.625 + (2 * Ice), 3 / 12, z, V, Y, ExpCat, StrCl, Topo, IncludeIce);
                Adir1 = Round(((NumberSections * (Radome + BarePole + LugArea + ElbowArea) + FeedLineArea * numberFeedLines + PowerDividerArea)) / 144, 2);
                Amax = ((NumberSections * (Radome + BarePole + LugArea + ElbowArea) + FeedLineArea * numberFeedLines + PowerDividerArea)) / 144;
                RDir1 = Round(G * Amax * Q, 0);

                //Area Direction 2
                Radome = (L - 5 + 2 * Ice) * (Dradome + 2 * Ice) * AppurtDrag(WSpec, Dradome + 2 * Ice, (L - 5 + 2 * Ice) / 12, z, V, Y, ExpCat, StrCl, Topo, IncludeIce);
                BarePoleArea = (5 - 2 * Ice) * (Dpipe + 2 * Ice) * AppurtDrag(WSpec, Dpipe + 2 * Ice, (5 - 2 * Ice) / 12, z, V, Y, ExpCat, StrCl, Topo, IncludeIce);
                LugArea = LugLength * (11.5 + 3 * Ice) * FlatAppurtDrag(WSpec, 3.75 + Ice, (11.5 + 3 * Ice) / 12, z, V, ExpCat, StrCl, Topo);
                FeedLineArea = FeedLineLength * (2 + 2 * Ice) * AppurtDrag(WSpec, 2 + 2 * Ice, FeedLineLength / 12, z, V, Y, ExpCat, StrCl, Topo, IncludeIce);
                ElbowArea = (3 * (1.625 + (2 * Ice)) + (6 * (1.625 + (2 * Ice)))) * AppurtDrag(WSpec, 1.625 + (2 * Ice), 3 / 12, z, V, Y, ExpCat, StrCl, Topo, IncludeIce);
                Adir2 = Round(((NumberSections * (Radome + BarePole + LugArea + ElbowArea) + FeedLineArea * numberFeedLines + PowerDividerArea)) / 144, 2);
                A = ((NumberSections * (Radome + BarePole + LugArea + ElbowArea) + FeedLineArea * numberFeedLines + PowerDividerArea)) / 144;
                RDir2 = Round(G * A * Q, 0);
                if (A > Amax) {
                    Amax = A;
                }
                RMax = Round(G * Amax * Q, 0);
                Amax = Round(Amax, 2);

                //Weight
                EndSeals = Pi * (Math.pow(Dradome, 2) - Math.pow(Dpipe, 2)) * 1 * 0.034;
                PoleWeight = ((Pi / 4) * (Math.pow(Dpipe, 2) - Math.pow((Dpipe - 2 * twall), 2)) * L) * 0.1;
                RadomeWeight = (0.1544 * Dradome + 2) * (L - 5) / 12;
                HarnessWeight = (2 * 1.5) + ((0.243 * L) * 0.1) + ((0.075 * (L / 2)) * 0.322) + 0.165;
                LugWeight = 7.5;
                Misc = 5;
                Elbow = 3.5;
                FeedlineWeight = FeedLineLength * 1.04 / 12; //Andrew Catalog
                Weight = (EndSeals + PoleWeight + RadomeWeight + Inner + Misc + Elbow) * NumberSections + (FeedlineWeight) * numberFeedLines + PowerDividerWeight;
                TotalWeight = Round(Weight, 0);

                //Ice Weight
                RadomeIce = Pi / 4 * (0.032) * (Math.pow((Dradome + 2 * Ice), 2) - Math.pow(Dradome, 2)) * (L - 5 + 2 * Ice);
                BarePoleIce = Pi / 4 * (0.032) * (Math.pow((Dpipe + 2 * Ice), 2) - Math.pow(Dpipe, 2)) * (5 - 2 * Ice);
                ElbowIce = (((Pi / 4) * (Math.pow((1.625 + 2 * Ice), 2) - Math.pow((1.625), 2)) * 5.6) + ((Pi / 4) * (Math.pow((1.625 + 2 * Ice), 2) - Math.pow((1.625), 2)) * 2.8)) * 0.032;
                LugIce = 3 * 0.032 * (2 * (11.5 * 6.5 * Ice) + 2 * (11.5 * 0.5 * Ice) + 2 * (6.5 * 0.5 * Ice));
                FeedLineIce = (Pi / 4) * (Math.pow((1.98 + 2 * Ice), 2) - Math.pow((1.98), 2)) * 0.032 * FeedLineLength;
                IceWeight = (RadomeIce + BarePoleIce + LugIce + FeedLineIce) * NumberSections + PowerDividerIceWeight;
                Wice = Round(IceWeight, 0);

                //Total Weight
                TotalWeight = Weight + IceWeight;
                TotalWeight = Round(TotalWeight, 3);
            }
          },
          patternJ: function() {
              if (!optRadome || optRadome == false) {
                FeedLineLength = 300;
                LugLength = 1.5;
                Lfin = 0.8 * lambda + 1;
                FinWidth = 2 * (1.75 + 0.4 * lambda) * Math.cos(32.5 * Pi / 180);
                //PowerDivider(Ice);
                //Area Direction 1
                FinnedArea = (FinWidth + 2 * Ice) * NLayers * (Lfin + 2 * Ice) * FlatAppurtDrag(WSpec, FinWidth + 2 * Ice, NLayers * (Lfin + 2 * Ice) / 12, z, V, ExpCat, StrCl, Topo);
                SlotCovers = (Dpipe + 2 * Ice) * (L - NLayers * (Lfin + 2 * Ice) - 5 - 2 * Ice) * FlatAppurtDrag(WSpec, Dpipe + 2 * Ice, (L - NLayers * (Lfin + 2 * Ice) - 5 - 2 * Ice) / 12, z, V, ExpCat, StrCl, Topo);
                BarePole = (5 - 2 * Ice) * (Dpipe + 2 * Ice) * AppurtDrag(WSpec, Dpipe + 2 * Ice, (5 - 2 * Ice) / 12, z, V, Y, ExpCat, StrCl, Topo, IncludeIce);
                LugArea = LugLength * (6.5 + 3 * Ice) * FlatAppurtDrag(WSpec, 3.75 + Ice, (4.1 + Ice) / 12, z, V, ExpCat, StrCl, Topo);
                FeedLineArea = FeedLineLength * (2 + 2 * Ice) * AppurtDrag(WSpec, 2 + 2 * Ice, FeedLineLength / 12, z, V, Y, ExpCat, StrCl, Topo, IncludeIce);
                ElbowArea = (3 * (1.625 + (2 * Ice)) + (6 * (1.625 + (2 * Ice)))) * AppurtDrag(WSpec, 1.625 + (2 * Ice), 3 / 12, z, V, Y, ExpCat, StrCl, Topo, IncludeIce);
                Adir1 = Round(((NumberSections * (SlotCovers + BarePole + FinnedArea + LugArea + ElbowArea) + FeedLineArea * numberFeedLines + PowerDividerArea)) / 144, 2);
                Amax = ((NumberSections * (SlotCovers + BarePole + FinnedArea + LugArea + ElbowArea) + FeedLineArea * numberFeedLines + PowerDividerArea)) / 144;
                RDir1 = Round(G * Amax * Q, 0);

                //Area Direction 2
                FinHeight = (1.75 + 0.4 * lambda) * Math.sin(32.5 * Pi / 180) + 3.625;
                SlotCovers = (L - NLayers * (Lfin + 2 * Ice) - 5 - 2 * Ice) * (5.375 + 2 * Ice) * FlatAppurtDrag(WSpec, 5.375 + 2 * Ice, (L - NLayers * (Lfin + 2 * Ice) - 5 - 2 * Ice) / 12, z, V, ExpCat, StrCl, Topo);
                FinnedArea = (3.625 + FinHeight + 2 * Ice) * NLayers * (Lfin + 2 * Ice) * FlatAppurtDrag(WSpec, 3.625 + FinHeight + 2 * Ice, (Lfin + 2 * Ice) / 12, z, V, ExpCat, StrCl, Topo);
                BarePole = (5 - 2 * Ice) * (Dpipe + 2 * Ice) * AppurtDrag(WSpec, Dpipe + 2 * Ice, (5 - 2 * Ice) / 12, z, V, Y, ExpCat, StrCl, Topo, IncludeIce);
                LugArea = LugLength * (11.5 + 3 * Ice) * FlatAppurtDrag(WSpec, 3.75 + Ice, (11.5 + 3 * Ice) / 12, z, V, ExpCat, StrCl, Topo);
                FeedLineArea = FeedLineLength * (2 + 2 * Ice) * AppurtDrag(WSpec, 2 + 2 * Ice, FeedLineLength / 12, z, V, Y, ExpCat, StrCl, Topo, IncludeIce);
                ElbowArea = (3 * (1.625 + (2 * Ice)) + (6 * (1.625 + (2 * Ice)))) * AppurtDrag(WSpec, 1.625 + (2 * Ice), 3 / 12, z, V, Y, ExpCat, StrCl, Topo, IncludeIce);
                Adir2 = Round(((NumberSections * (SlotCovers + FinnedArea + BarePole + LugArea + ElbowArea) + FeedLineArea * numberFeedLines + PowerDividerArea)) / 144, 2);
                A = ((NumberSections * (SlotCovers + FinnedArea + BarePole + LugArea + ElbowArea) + FeedLineArea * numberFeedLines + PowerDividerArea)) / 144;
                RDir2 = Round(G * A * Q, 0);
                if (A > Amax) {
                    Amax = A;
                }
                RMax = Round(G * Amax * Q, 0);
                Amax = Round(Amax, 2);

                //Weight
                PoleWeight = ((Pi / 4) * (Math.pow(Dpipe, 2) - Math.pow((Dpipe - 2 * twall), 2)) * L) * 0.1;
                SlotCoverWeight = (L - 5) * (0.03);
                FinWeight = (2 * NLayers) * (0.1) * (0.4 * lambda) * (0.8 * lambda + 1) * 0.125;
                HarnessWeight = (2 * 1.5) + ((0.243 * L) * 0.1) + ((0.075 * (L / 2)) * 0.322) + 0.165;
                LugWeight = 7.5;
                Misc = 5;
                Elbow = 3.5;
                FeedlineWeight = FeedLineLength * 1.04 / 12; //Andrew Catalog
                Weight = (PoleWeight + SlotCoverWeight + FinWeight + HarnessWeight + LugWeight + Misc + Elbow) * NumberSections + (FeedlineWeight) * numberFeedLines + PowerDividerWeight;
                TotalWeight = Round(Weight, 0);

                //Ice Weight
                SlotCoverIce = (L - 5 - 2 * Ice) * (0.032) * (1.5 * (2.89) * (Math.pow((1.59 + Ice), 2) - Math.pow((1.59), 2)) + 1.5 * (0.71) * (Math.pow((2.5 + Ice), 2) - Math.pow((2.5), 2)));
                BarePoleIce = Pi / 4 * (0.032) * (Math.pow((Dpipe + 2 * Ice), 2) - Math.pow(Dpipe, 2)) * (5 - 2 * Ice);
                FinnedWeight = (2 * (0.4 * lambda + Ice) * 2 * Ice + (0.5 * (3.04) * (Math.pow((1.35 + Ice), 2) - (Math.pow(1.35, 2))) + 0.5 * (4.66) * (Math.pow((1.75 + Ice), 2) - Math.pow((1.75), 2)))) * NLayers * (Lfin + 2 * Ice) * 0.032;
                ElbowIce = (((Pi / 4) * (Math.pow((1.625 + 2 * Ice), 2) - Math.pow((1.625), 2)) * 5.6) + ((Pi / 4) * (Math.pow((1.625 + 2 * Ice), 2) - Math.pow((1.625), 2)) * 2.8)) * 0.032;
                LugIce = 3 * 0.032 * (2 * (11.5 * 6.5 * Ice) + 2 * (11.5 * 0.5 * Ice) + 2 * (6.5 * 0.5 * Ice));
                FeedLineIce = (Pi / 4) * (Math.pow((1.98 + 2 * Ice), 2) - Math.pow((1.98), 2)) * 0.032 * FeedLineLength;
                IceWeight = (SlotCoverIce + FinnedWeight + BarePoleIce + LugIce + FeedLineIce) * NumberSections + PowerDividerIceWeight;
                Wice = Round(IceWeight, 0);

                //Total Weight
                TotalWeight = Weight + IceWeight;
                TotalWeight = Round(TotalWeight, 3);

              } else if (optRadome == true) {
                FeedLineLength = 300;
                LugLength = 1.5;
                //PowerDivider(Ice);
                //Area Direction 1
                Dradome = RadDia;
                Radome = (L - 5 + 2 * Ice) * (Dradome + 2 * Ice) * AppurtDrag(WSpec, Dradome + 2 * Ice, (L - 5 + 2 * Ice) / 12, z, V, Y, ExpCat, StrCl, Topo, IncludeIce);
                BarePole = (5 - 2 * Ice) * (Dpipe + 2 * Ice) * AppurtDrag(WSpec, Dpipe + 2 * Ice, (5 - 2 * Ice) / 12, z, V, Y, ExpCat, StrCl, Topo, IncludeIce);
                LugArea = LugLength * (6.5 + 3 * Ice) * FlatAppurtDrag(WSpec, 3.75 + Ice, (4.1 + Ice) / 12, z, V, ExpCat, StrCl, Topo);
                FeedLineArea = FeedLineLength * (2 + 2 * Ice) * AppurtDrag(WSpec, 2 + 2 * Ice, FeedLineLength / 12, z, V, Y, ExpCat, StrCl, Topo, IncludeIce);
                ElbowArea = (3 * (1.625 + (2 * Ice)) + (6 * (1.625 + (2 * Ice)))) * AppurtDrag(WSpec, 1.625 + (2 * Ice), 3 / 12, z, V, Y, ExpCat, StrCl, Topo, IncludeIce);
                Adir1 = Round(((NumberSections * (Radome + BarePole + LugArea + ElbowArea) + FeedLineArea * numberFeedLines + PowerDividerArea)) / 144, 2);
                Amax = ((NumberSections * (Radome + BarePole + LugArea + ElbowArea) + FeedLineArea * numberFeedLines + PowerDividerArea)) / 144;
                RDir1 = Round(G * Amax * Q, 0);

                //Area Direction 2
                Radome = (L - 5 + 2 * Ice) * (Dradome + 2 * Ice) * AppurtDrag(WSpec, Dradome + 2 * Ice, (L - 5 + 2 * Ice) / 12, z, V, Y, ExpCat, StrCl, Topo, IncludeIce);
                BarePoleArea = (5 - 2 * Ice) * (Dpipe + 2 * Ice) * AppurtDrag(WSpec, Dpipe + 2 * Ice, (5 - 2 * Ice) / 12, z, V, Y, ExpCat, StrCl, Topo, IncludeIce);
                LugArea = LugLength * (11.5 + 3 * Ice) * FlatAppurtDrag(WSpec, 3.75 + Ice, (11.5 + 3 * Ice) / 12, z, V, ExpCat, StrCl, Topo);
                FeedLineArea = FeedLineLength * (2 + 2 * Ice) * AppurtDrag(WSpec, 2 + 2 * Ice, FeedLineLength / 12, z, V, Y, ExpCat, StrCl, Topo, IncludeIce);
                ElbowArea = (3 * (1.625 + (2 * Ice)) + (6 * (1.625 + (2 * Ice)))) * AppurtDrag(WSpec, 1.625 + (2 * Ice), 3 / 12, z, V, Y, ExpCat, StrCl, Topo, IncludeIce);
                Adir2 = Round(((NumberSections * (Radome + BarePole + LugArea + ElbowArea) + FeedLineArea * numberFeedLines + PowerDividerArea)) / 144, 2);
                A = ((NumberSections * (Radome + BarePole + LugArea + ElbowArea) + FeedLineArea * numberFeedLines + PowerDividerArea)) / 144;
                RDir2 = Round(G * A * Q, 0);
                if (A > Amax) {
                    Amax = A;
                }
                RMax = Round(G * Amax * Q, 0);
                Amax = Round(Amax, 2);

                //Weight
                EndSeals = Pi * (Math.pow(Dradome, 2) - Math.pow(Dpipe, 2)) * 1 * 0.034;
                PoleWeight = ((Pi / 4) * (Math.pow(Dpipe, 2) - Math.pow((Dpipe - 2 * twall), 2)) * L) * 0.1;
                RadomeWeight = (0.1544 * Dradome + 2) * (L - 5) / 12;
                HarnessWeight = (2 * 1.5) + ((0.243 * L) * 0.1) + ((0.075 * (L / 2)) * 0.322) + 0.165;
                LugWeight = 7.5;
                Misc = 5;
                Elbow = 3.5;
                FeedlineWeight = FeedLineLength * 1.04 / 12; //Andrew Catalog
                Weight = (EndSeals + PoleWeight + RadomeWeight + Inner + Misc + Elbow) * NumberSections + (FeedlineWeight) * numberFeedLines + PowerDividerWeight;
                TotalWeight = Round(Weight, 0);

                //Ice Weight
                RadomeIce = Pi / 4 * (0.032) * (Math.pow((Dradome + 2 * Ice), 2) - Math.pow(Dradome, 2)) * (L - 5 + 2 * Ice);
                BarePoleIce = Pi / 4 * (0.032) * (Math.pow((Dpipe + 2 * Ice), 2) - Math.pow(Dpipe, 2)) * (5 - 2 * Ice);
                ElbowIce = (((Pi / 4) * (Math.pow((1.625 + 2 * Ice), 2) - Math.pow((1.625), 2)) * 5.6) + ((Pi / 4) * (Math.pow((1.625 + 2 * Ice), 2) - Math.pow((1.625), 2)) * 2.8)) * 0.032;
                LugIce = 3 * 0.032 * (2 * (11.5 * 6.5 * Ice) + 2 * (11.5 * 0.5 * Ice) + 2 * (6.5 * 0.5 * Ice));
                FeedLineIce = (Pi / 4) * (Math.pow((1.98 + 2 * Ice), 2) - Math.pow((1.98), 2)) * 0.032 * FeedLineLength;
                IceWeight = (RadomeIce + BarePoleIce + LugIce + FeedLineIce) * NumberSections + PowerDividerIceWeight;
                Wice = Round(IceWeight, 0);

                //Total Weight
                TotalWeight = Weight + IceWeight;
                TotalWeight = Round(TotalWeight, 3);
            }
          },
          patternM: function() {
              if (!optRadome || optRadome == false) {
                FeedLineLength = 300;
                LugLength = 1.5;
                Lfin = 0.8 * lambda + 1;
                FinWidth = 2 * (1.75 + 0.4 * lambda) * Math.cos(15 * Pi / 180);
                //PowerDivider(Ice);
                //Area Direction 1
                FinnedArea = (FinWidth + 2 * Ice) * NLayers * (Lfin + 2 * Ice) * FlatAppurtDrag(WSpec, FinWidth + 2 * Ice, NLayers * (Lfin + 2 * Ice) / 12, z, V, ExpCat, StrCl, Topo);
                SlotCovers = (Dpipe + 2 * Ice) * (L - NLayers * (Lfin + 2 * Ice) - 5 - 2 * Ice) * FlatAppurtDrag(WSpec, Dpipe + 2 * Ice, (L - NLayers * (Lfin + 2 * Ice) - 5 - 2 * Ice) / 12, z, V, ExpCat, StrCl, Topo);
                BarePole = (5 - 2 * Ice) * (Dpipe + 2 * Ice) * AppurtDrag(WSpec, Dpipe + 2 * Ice, (5 - 2 * Ice) / 12, z, V, Y, ExpCat, StrCl, Topo, IncludeIce);
                LugArea = LugLength * (6.5 + 3 * Ice) * FlatAppurtDrag(WSpec, 3.75 + Ice, (4.1 + Ice) / 12, z, V, ExpCat, StrCl, Topo);
                FeedLineArea = FeedLineLength * (2 + 2 * Ice) * AppurtDrag(WSpec, 2 + 2 * Ice, FeedLineLength / 12, z, V, Y, ExpCat, StrCl, Topo, IncludeIce);
                ElbowArea = (3 * (1.625 + (2 * Ice)) + (6 * (1.625 + (2 * Ice)))) * AppurtDrag(WSpec, 1.625 + (2 * Ice), 3 / 12, z, V, Y, ExpCat, StrCl, Topo, IncludeIce);
                Adir1 = Round(((NumberSections * (SlotCovers + BarePole + FinnedArea + LugArea + ElbowArea) + FeedLineArea * numberFeedLines + PowerDividerArea)) / 144, 2);
                Amax = ((NumberSections * (SlotCovers + BarePole + FinnedArea + LugArea + ElbowArea) + FeedLineArea * numberFeedLines + PowerDividerArea)) / 144;
                RDir1 = Round(G * Amax * Q, 0);

                //Area Direction 2
                FinHeight = (1.75 + 0.4 * lambda) * Math.sin(15 * Pi / 180) + 3.625;
                SlotCovers = (L - NLayers * (Lfin + 2 * Ice) - 5 - 2 * Ice) * (5.375 + 2 * Ice) * FlatAppurtDrag(WSpec, 5.375 + 2 * Ice, (L - NLayers * (Lfin + 2 * Ice) - 5 - 2 * Ice) / 12, z, V, ExpCat, StrCl, Topo);
                FinnedArea = (3.625 + FinHeight + 2 * Ice) * NLayers * (Lfin + 2 * Ice) * FlatAppurtDrag(WSpec, 3.625 + FinHeight + 2 * Ice, (Lfin + 2 * Ice) / 12, z, V, ExpCat, StrCl, Topo);
                BarePole = (5 - 2 * Ice) * (Dpipe + 2 * Ice) * AppurtDrag(WSpec, Dpipe + 2 * Ice, (5 - 2 * Ice) / 12, z, V, Y, ExpCat, StrCl, Topo, IncludeIce);
                LugArea = LugLength * (11.5 + 3 * Ice) * FlatAppurtDrag(WSpec, 3.75 + Ice, (11.5 + 3 * Ice) / 12, z, V, ExpCat, StrCl, Topo);
                FeedLineArea = FeedLineLength * (2 + 2 * Ice) * AppurtDrag(WSpec, 2 + 2 * Ice, FeedLineLength / 12, z, V, Y, ExpCat, StrCl, Topo, IncludeIce);
                ElbowArea = (3 * (1.625 + (2 * Ice)) + (6 * (1.625 + (2 * Ice)))) * AppurtDrag(WSpec, 1.625 + (2 * Ice), 3 / 12, z, V, Y, ExpCat, StrCl, Topo, IncludeIce);
                Adir2 = Round(((NumberSections * (SlotCovers + FinnedArea + BarePole + LugArea + ElbowArea) + FeedLineArea * numberFeedLines + PowerDividerArea)) / 144, 2);
                A = ((NumberSections * (SlotCovers + FinnedArea + BarePole + LugArea + ElbowArea) + FeedLineArea * numberFeedLines + PowerDividerArea)) / 144;
                RDir2 = Round(G * A * Q, 0);
                if (A > Amax) {
                    Amax = A;
                }
                RMax = Round(G * Amax * Q, 0);
                Amax = Round(Amax, 2);

                //Weight
                PoleWeight = ((Pi / 4) * (Math.pow(Dpipe, 2) - Math.pow((Dpipe - 2 * twall), 2)) * L) * 0.1;
                SlotCoverWeight = (L - 5) * (0.03);
                FinWeight = (2 * NLayers) * (0.1) * (0.4 * lambda) * (0.8 * lambda + 1) * 0.125;
                HarnessWeight = (2 * 1.5) + ((0.243 * L) * 0.1) + ((0.075 * (L / 2)) * 0.322) + 0.165;
                LugWeight = 7.5;
                Misc = 5;
                Elbow = 3.5;
                FeedlineWeight = FeedLineLength * 1.04 / 12; //Andrew Catalog
                Weight = (PoleWeight + SlotCoverWeight + FinWeight + HarnessWeight + LugWeight + Misc + Elbow) * NumberSections + (FeedlineWeight) * numberFeedLines + PowerDividerWeight;
                TotalWeight = Round(Weight, 0);

                //Ice Weight
                SlotCoverIce = (L - 5 - 2 * Ice) * (0.032) * (1.5 * (2.89) * (Math.pow((1.59 + Ice), 2) - Math.pow((1.59), 2)) + 1.5 * (0.71) * (Math.pow((2.5 + Ice), 2) - Math.pow((2.5), 2)));
                BarePoleIce = Pi / 4 * (0.032) * (Math.pow((Dpipe + 2 * Ice), 2) - Math.pow(Dpipe, 2)) * (5 - 2 * Ice);
                FinnedWeight = (2 * (0.4 * lambda + Ice) * 2 * Ice + (0.5 * (3.04) * (Math.pow((1.35 + Ice), 2) - (Math.pow(1.35, 2))) + 0.5 * (4.66) * (Math.pow((1.75 + Ice), 2) - Math.pow((1.75), 2)))) * NLayers * (Lfin + 2 * Ice) * 0.032;
                ElbowIce = (((Pi / 4) * (Math.pow((1.625 + 2 * Ice), 2) - Math.pow((1.625), 2)) * 5.6) + ((Pi / 4) * (Math.pow((1.625 + 2 * Ice), 2) - Math.pow((1.625), 2)) * 2.8)) * 0.032;
                LugIce = 3 * 0.032 * (2 * (11.5 * 6.5 * Ice) + 2 * (11.5 * 0.5 * Ice) + 2 * (6.5 * 0.5 * Ice));
                FeedLineIce = (Pi / 4) * (Math.pow((1.98 + 2 * Ice), 2) - Math.pow((1.98), 2)) * 0.032 * FeedLineLength;
                IceWeight = (SlotCoverIce + FinnedWeight + BarePoleIce + LugIce + FeedLineIce) * NumberSections + PowerDividerIceWeight;
                Wice = Round(IceWeight, 0);

                //Total Weight
                TotalWeight = Weight + IceWeight;
                TotalWeight = Round(TotalWeight, 3);

              } else if (optRadome == true) {
                FeedLineLength = 300;
                LugLength = 1.5;
                //PowerDivider(Ice);
                //Area Direction 1
                Dradome = RadDia;
                Radome = (L - 5 + 2 * Ice) * (Dradome + 2 * Ice) * AppurtDrag(WSpec, Dradome + 2 * Ice, (L - 5 + 2 * Ice) / 12, z, V, Y, ExpCat, StrCl, Topo, IncludeIce);
                BarePole = (5 - 2 * Ice) * (Dpipe + 2 * Ice) * AppurtDrag(WSpec, Dpipe + 2 * Ice, (5 - 2 * Ice) / 12, z, V, Y, ExpCat, StrCl, Topo, IncludeIce);
                LugArea = LugLength * (6.5 + 3 * Ice) * FlatAppurtDrag(WSpec, 3.75 + Ice, (4.1 + Ice) / 12, z, V, ExpCat, StrCl, Topo);
                FeedLineArea = FeedLineLength * (2 + 2 * Ice) * AppurtDrag(WSpec, 2 + 2 * Ice, FeedLineLength / 12, z, V, Y, ExpCat, StrCl, Topo, IncludeIce);
                ElbowArea = (3 * (1.625 + (2 * Ice)) + (6 * (1.625 + (2 * Ice)))) * AppurtDrag(WSpec, 1.625 + (2 * Ice), 3 / 12, z, V, Y, ExpCat, StrCl, Topo, IncludeIce);
                Adir1 = Round(((NumberSections * (Radome + BarePole + LugArea + ElbowArea) + FeedLineArea * numberFeedLines + PowerDividerArea)) / 144, 2);
                Amax = ((NumberSections * (Radome + BarePole + LugArea + ElbowArea) + FeedLineArea * numberFeedLines + PowerDividerArea)) / 144;
                RDir1 = Round(G * Amax * Q, 0);

                //Area Direction 2
                Radome = (L - 5 + 2 * Ice) * (Dradome + 2 * Ice) * AppurtDrag(WSpec, Dradome + 2 * Ice, (L - 5 + 2 * Ice) / 12, z, V, Y, ExpCat, StrCl, Topo, IncludeIce);
                BarePoleArea = (5 - 2 * Ice) * (Dpipe + 2 * Ice) * AppurtDrag(WSpec, Dpipe + 2 * Ice, (5 - 2 * Ice) / 12, z, V, Y, ExpCat, StrCl, Topo, IncludeIce);
                LugArea = LugLength * (11.5 + 3 * Ice) * FlatAppurtDrag(WSpec, 3.75 + Ice, (11.5 + 3 * Ice) / 12, z, V, ExpCat, StrCl, Topo);
                FeedLineArea = FeedLineLength * (2 + 2 * Ice) * AppurtDrag(WSpec, 2 + 2 * Ice, FeedLineLength / 12, z, V, Y, ExpCat, StrCl, Topo, IncludeIce);
                ElbowArea = (3 * (1.625 + (2 * Ice)) + (6 * (1.625 + (2 * Ice)))) * AppurtDrag(WSpec, 1.625 + (2 * Ice), 3 / 12, z, V, Y, ExpCat, StrCl, Topo, IncludeIce);
                Adir2 = Round(((NumberSections * (Radome + BarePole + LugArea + ElbowArea) + FeedLineArea * numberFeedLines + PowerDividerArea)) / 144, 2);
                A = ((NumberSections * (Radome + BarePole + LugArea + ElbowArea) + FeedLineArea * numberFeedLines + PowerDividerArea)) / 144;
                RDir2 = Round(G * A * Q, 0);
                if (A > Amax) {
                    Amax = A;
                }
                RMax = Round(G * Amax * Q, 0);
                Amax = Round(Amax, 2);

                //Weight
                EndSeals = Pi * (Math.pow(Dradome, 2) - Math.pow(Dpipe, 2)) * 1 * 0.034;
                PoleWeight = ((Pi / 4) * (Math.pow(Dpipe, 2) - Math.pow((Dpipe - 2 * twall), 2)) * L) * 0.1;
                RadomeWeight = (0.1544 * Dradome + 2) * (L - 5) / 12;
                HarnessWeight = (2 * 1.5) + ((0.243 * L) * 0.1) + ((0.075 * (L / 2)) * 0.322) + 0.165;
                LugWeight = 7.5;
                Misc = 5;
                Elbow = 3.5;
                FeedlineWeight = FeedLineLength * 1.04 / 12; //Andrew Catalog
                Weight = (EndSeals + PoleWeight + RadomeWeight + Inner + Misc + Elbow) * NumberSections + (FeedlineWeight) * numberFeedLines + PowerDividerWeight;
                TotalWeight = Round(Weight, 0);

                //Ice Weight
                RadomeIce = Pi / 4 * (0.032) * (Math.pow((Dradome + 2 * Ice), 2) - Math.pow(Dradome, 2)) * (L - 5 + 2 * Ice);
                BarePoleIce = Pi / 4 * (0.032) * (Math.pow((Dpipe + 2 * Ice), 2) - Math.pow(Dpipe, 2)) * (5 - 2 * Ice);
                ElbowIce = (((Pi / 4) * (Math.pow((1.625 + 2 * Ice), 2) - Math.pow((1.625), 2)) * 5.6) + ((Pi / 4) * (Math.pow((1.625 + 2 * Ice), 2) - Math.pow((1.625), 2)) * 2.8)) * 0.032;
                LugIce = 3 * 0.032 * (2 * (11.5 * 6.5 * Ice) + 2 * (11.5 * 0.5 * Ice) + 2 * (6.5 * 0.5 * Ice));
                FeedLineIce = (Pi / 4) * (Math.pow((1.98 + 2 * Ice), 2) - Math.pow((1.98), 2)) * 0.032 * FeedLineLength;
                IceWeight = (RadomeIce + BarePoleIce + LugIce + FeedLineIce) * NumberSections + PowerDividerIceWeight;
                Wice = Round(IceWeight, 0);

                //Total Weight
                TotalWeight = Weight + IceWeight;
                TotalWeight = Round(TotalWeight, 3);
            }
          },
          patternN: function() {
              if (!optRadome || optRadome == false) {
                FeedLineLength = 300;
                LugLength = 1.5;
                Lfin = 0.8 * lambda + 1;
                FinWidth = 0.5 * lambda;
                //PowerDivider(Ice);
                //Area Direction 1
                FinnedArea = (FinWidth + 2 * Ice) * NLayers * (Lfin + 2 * Ice) * FlatAppurtDrag(WSpec, FinWidth + 2 * Ice, NLayers * (Lfin + 2 * Ice) / 12, z, V, ExpCat, StrCl, Topo);
                SlotCovers = (Dpipe + 2 * Ice) * (L - NLayers * (Lfin + 2 * Ice) - 5) * FlatAppurtDrag(WSpec, Dpipe + 2 * Ice, (L - NLayers * (Lfin + 2 * Ice) - 5) / 12, z, V, ExpCat, StrCl, Topo);
                BarePole = (5 - 2 * Ice) * (Dpipe + 2 * Ice) * AppurtDrag(WSpec, Dpipe + 2 * Ice, (5 - 2 * Ice) / 12, z, V, Y, ExpCat, StrCl, Topo, IncludeIce);
                LugArea = LugLength * (6.5 + 3 * Ice) * FlatAppurtDrag(WSpec, 6.5 + 3 * Ice, LugLength / 12, z, V, ExpCat, StrCl, Topo);
                FeedLineArea = FeedLineLength * (2 + 2 * Ice) * AppurtDrag(WSpec, 2 + 2 * Ice, FeedLineLength / 12, z, V, Y, ExpCat, StrCl, Topo, IncludeIce);
                ElbowArea = (3 * (1.625 + (2 * Ice)) + (6 * (1.625 + (2 * Ice)))) * AppurtDrag(WSpec, 1.625 + (2 * Ice), 3 / 12, z, V, Y, ExpCat, StrCl, Topo, IncludeIce);
                Adir1 = Round(((NumberSections * (SlotCovers + BarePole + FinnedArea + LugArea + ElbowArea) + FeedLineArea * numberFeedLines + PowerDividerArea)) / 144, 2);
                Amax = ((NumberSections * (SlotCovers + BarePole + FinnedArea + LugArea + ElbowArea) + FeedLineArea * numberFeedLines + PowerDividerArea)) / 144;
                RDir1 = Round(G * Amax * Q, 0);

                //Area Direction 2
                SlotCovers = (L - 5 + 2 * Ice) * (6.5 + 2 * Ice) * FlatAppurtDrag(WSpec, 6.5 + 2 * Ice, (L - 5 + 2 * Ice) / 12, z, V, ExpCat, StrCl, Topo);
                FinnedArea = (6.5 + 2 * Ice) * NLayers * (Lfin + 2 * Ice) * FlatAppurtDrag(WSpec, 6.5 + 2 * Ice, NLayers * (Lfin + 2 * Ice) / 12, z, V, ExpCat, StrCl, Topo);
                BarePole = (5 - 2 * Ice) * (Dpipe + 2 * Ice) * AppurtDrag(WSpec, Dpipe + 2 * Ice, (5 - 2 * Ice) / 12, z, V, Y, ExpCat, StrCl, Topo, IncludeIce);
                LugArea = LugLength * (11.5 + 3 * Ice) * FlatAppurtDrag(WSpec, 3.75 + Ice, (11.5 + 3 * Ice) / 12, z, V, ExpCat, StrCl, Topo);
                FeedLineArea = FeedLineLength * (2 + 2 * Ice) * AppurtDrag(WSpec, 2 + 2 * Ice, FeedLineLength / 12, z, V, Y, ExpCat, StrCl, Topo, IncludeIce);
                ElbowArea = (3 * (1.625 + (2 * Ice)) + (6 * (1.625 + (2 * Ice)))) * AppurtDrag(WSpec, 1.625 + (2 * Ice), 3 / 12, z, V, Y, ExpCat, StrCl, Topo, IncludeIce);
                Adir2 = Round(((NumberSections * (SlotCovers + BarePole + LugArea + ElbowArea) + FeedLineArea * numberFeedLines + PowerDividerArea)) / 144, 2);
                A = ((NumberSections * (SlotCovers + BarePole + LugArea + ElbowArea) + FeedLineArea * numberFeedLines + PowerDividerArea)) / 144;
                RDir2 = Round(G * A * Q, 0);
                if (A > Amax) {
                    Amax = A;
                }
                RMax = Round(G * Amax * Q, 0);
                Amax = Round(Amax, 2);

                //Weight
                PoleWeight = ((Pi / 4) * (Math.pow(Dpipe, 2) - Math.pow((Dpipe - 2 * twall), 2)) * L) * 0.1;
                SlotCoverWeight = (L - 5) * (0.03);
                PatternShaperWeight = (0.5 * 1 + lambda / 2 * 0.125) * Lfin * NLayers * 0.1;
                HarnessWeight = (2 * 1.5) + ((0.243 * L) * 0.1) + ((0.075 * (L / 2)) * 0.322) + 0.165;
                LugWeight = 7.5;
                Misc = 5;
                Elbow = 3.5;
                FeedlineWeight = FeedLineLength * 1.04 / 12; //Andrew Catalog
                Weight = (PoleWeight + SlotCoverWeight + PatternShaperWeight + HarnessWeight + LugWeight + Misc + Elbow) * NumberSections + (FeedlineWeight) * numberFeedLines + PowerDividerWeight;
                TotalWeight = Round(Weight, 0);

                //Ice Weight
                SlotCoverIce = (L - 5 + 2 * Ice) * (0.032) * (1.5 * (2.89) * (Math.pow((1.59 + Ice), 2) - Math.pow((1.59), 2)) + 1.5 * (0.71) * (Math.pow((2.5 + Ice), 2) - Math.pow((2.5), 2)));
                BarePoleIce = Pi / 4 * (0.032) * (Math.pow((Dpipe + 2 * Ice), 2) - Math.pow(Dpipe, 2)) * (5 - 2 * Ice);
                PatternShaperIce = (2 * 0.5 * lambda + 2 * Ice) * NLayers * (Lfin + 2 * Ice) * 0.032;
                ElbowIce = (((Pi / 4) * (Math.pow((1.625 + 2 * Ice), 2) - Math.pow((1.625), 2)) * 5.6) + ((Pi / 4) * (Math.pow((1.625 + 2 * Ice), 2) - Math.pow((1.625), 2)) * 2.8)) * 0.032;
                LugIce = 3 * 0.032 * (2 * (11.5 * 6.5 * Ice) + 2 * (11.5 * 0.5 * Ice) + 2 * (6.5 * 0.5 * Ice));
                FeedLineIce = (Pi / 4) * (Math.pow((1.98 + 2 * Ice), 2) - Math.pow((1.98), 2)) * 0.032 * FeedLineLength;
                IceWeight = (SlotCoverIce + BarePoleIce + PatternShaperIce + LugIce + FeedLineIce) * NumberSections + PowerDividerIceWeight;
                Wice = Round(IceWeight, 0);

                //Total Weight
                TotalWeight = Weight + IceWeight;
                TotalWeight = Round(TotalWeight, 3);

              } else if (optRadome == true) {
                FeedLineLength = 300;
                LugLength = 1.5;
                //PowerDivider(Ice);
                //Area Direction 1
                Dradome = RadDia;
                Radome = (L - 5 + 2 * Ice) * (Dradome + 2 * Ice) * AppurtDrag(WSpec, Dradome + 2 * Ice, (L - 5 + 2 * Ice) / 12, z, V, Y, ExpCat, StrCl, Topo, IncludeIce);
                BarePole = (5 - 2 * Ice) * (Dpipe + 2 * Ice) * AppurtDrag(WSpec, Dpipe + 2 * Ice, (5 - 2 * Ice) / 12, z, V, Y, ExpCat, StrCl, Topo, IncludeIce);
                LugArea = LugLength * (6.5 + 3 * Ice) * FlatAppurtDrag(WSpec, 3.75 + Ice, (4.1 + Ice) / 12, z, V, ExpCat, StrCl, Topo);
                FeedLineArea = FeedLineLength * (2 + 2 * Ice) * AppurtDrag(WSpec, 2 + 2 * Ice, FeedLineLength / 12, z, V, Y, ExpCat, StrCl, Topo, IncludeIce);
                ElbowArea = (3 * (1.625 + (2 * Ice)) + (6 * (1.625 + (2 * Ice)))) * AppurtDrag(WSpec, 1.625 + (2 * Ice), 3 / 12, z, V, Y, ExpCat, StrCl, Topo, IncludeIce);
                Adir1 = Round(((NumberSections * (Radome + BarePole + LugArea + ElbowArea) + FeedLineArea * numberFeedLines + PowerDividerArea)) / 144, 2);
                Amax = ((NumberSections * (Radome + BarePole + LugArea + ElbowArea) + FeedLineArea * numberFeedLines + PowerDividerArea)) / 144;
                RDir1 = Round(G * Amax * Q, 0);

                //Area Direction 2
                Radome = (L - 5 + 2 * Ice) * (Dradome + 2 * Ice) * AppurtDrag(WSpec, Dradome + 2 * Ice, (L - 5 + 2 * Ice) / 12, z, V, Y, ExpCat, StrCl, Topo, IncludeIce);
                BarePoleArea = (5 - 2 * Ice) * (Dpipe + 2 * Ice) * AppurtDrag(WSpec, Dpipe + 2 * Ice, (5 - 2 * Ice) / 12, z, V, Y, ExpCat, StrCl, Topo, IncludeIce);
                LugArea = LugLength * (11.5 + 3 * Ice) * FlatAppurtDrag(WSpec, 3.75 + Ice, (11.5 + 3 * Ice) / 12, z, V, ExpCat, StrCl, Topo);
                FeedLineArea = FeedLineLength * (2 + 2 * Ice) * AppurtDrag(WSpec, 2 + 2 * Ice, FeedLineLength / 12, z, V, Y, ExpCat, StrCl, Topo, IncludeIce);
                ElbowArea = (3 * (1.625 + (2 * Ice)) + (6 * (1.625 + (2 * Ice)))) * AppurtDrag(WSpec, 1.625 + (2 * Ice), 3 / 12, z, V, Y, ExpCat, StrCl, Topo, IncludeIce);
                Adir2 = Round(((NumberSections * (Radome + BarePole + LugArea + ElbowArea) + FeedLineArea * numberFeedLines + PowerDividerArea)) / 144, 2);
                A = ((NumberSections * (Radome + BarePole + LugArea + ElbowArea) + FeedLineArea * numberFeedLines + PowerDividerArea)) / 144;
                RDir2 = Round(G * A * Q, 0);
                if (A > Amax) {
                    Amax = A;
                }
                RMax = Round(G * Amax * Q, 0);
                Amax = Round(Amax, 2);

                //Weight
                EndSeals = Pi * (Math.pow(Dradome, 2) - Math.pow(Dpipe, 2)) * 1 * 0.034;
                PoleWeight = ((Pi / 4) * (Math.pow(Dpipe, 2) - Math.pow((Dpipe - 2 * twall), 2)) * L) * 0.1;
                RadomeWeight = (0.1544 * Dradome + 2) * (L - 5) / 12;
                HarnessWeight = (2 * 1.5) + ((0.243 * L) * 0.1) + ((0.075 * (L / 2)) * 0.322) + 0.165;
                LugWeight = 7.5;
                Misc = 5;
                Elbow = 3.5;
                FeedlineWeight = FeedLineLength * 1.04 / 12; //Andrew Catalog
                Weight = (EndSeals + PoleWeight + RadomeWeight + Inner + Misc + Elbow) * NumberSections + (FeedlineWeight) * numberFeedLines + PowerDividerWeight;
                TotalWeight = Round(Weight, 0);

                //Ice Weight
                RadomeIce = Pi / 4 * (0.032) * (Math.pow((Dradome + 2 * Ice), 2) - Math.pow(Dradome, 2)) * (L - 5 + 2 * Ice);
                BarePoleIce = Pi / 4 * (0.032) * (Math.pow((Dpipe + 2 * Ice), 2) - Math.pow(Dpipe, 2)) * (5 - 2 * Ice);
                ElbowIce = (((Pi / 4) * (Math.pow((1.625 + 2 * Ice), 2) - Math.pow((1.625), 2)) * 5.6) + ((Pi / 4) * (Math.pow((1.625 + 2 * Ice), 2) - Math.pow((1.625), 2)) * 2.8)) * 0.032;
                LugIce = 3 * 0.032 * (2 * (11.5 * 6.5 * Ice) + 2 * (11.5 * 0.5 * Ice) + 2 * (6.5 * 0.5 * Ice));
                FeedLineIce = (Pi / 4) * (Math.pow((1.98 + 2 * Ice), 2) - Math.pow((1.98), 2)) * 0.032 * FeedLineLength;
                IceWeight = (RadomeIce + BarePoleIce + LugIce + FeedLineIce) * NumberSections + PowerDividerIceWeight;
                Wice = Round(IceWeight, 0);

                //Total Weight
                TotalWeight = Weight + IceWeight;
                TotalWeight = Round(TotalWeight, 3);
            }
          },
          patternO: function() {
              if (!optRadome || optRadome == false) {
                FeedLineLength = 300;
                LugLength = 1.5;
                //PowerDivider(Ice);
                  //Area Direction 1
                  Lfin = 0.8 * lambda + 1;
                  FinWidth = 2 * 0.25 * lambda * Math.cos(55 * Pi / 180);
                  FinnedArea = (FinWidth + 2 * Ice) * NLayers * (Lfin + 2 * Ice) * FlatAppurtDrag(WSpec, FinWidth + 2 * Ice, NLayers * (Lfin + 2 * Ice) / 12, z, V, ExpCat, StrCl, Topo);
                  SlotCovers = (3.5 + 2 * Ice) * (L - NLayers * (0.8 * lambda + 1 + 2 * Ice) - 5 - 2 * Ice) * FlatAppurtDrag(WSpec, 3.5 + 2 * Ice, (L - NLayers * (0.8 * lambda + 1 + 2 * Ice) - 5 - 2 * Ice) * NumberSections / 12, z, V, ExpCat, StrCl, Topo);
                  BarePole = (2 + S - 0.5 - 4 * Ice) * (3.5 + 2 * Ice) * AppurtDrag(WSpec, (3.5 + 2 * RadDia + 0.1875 + 2 * Ice), SingleSectionH2, z, V, Y, ExpCat, StrCl, Topo, IncludeIce);
                  LugArea = LugLength * (6.5 + 3 * Ice) * FlatAppurtDrag(WSpec, 3.75 + Ice, (4.1 + Ice) / 12, z, V, ExpCat, StrCl, Topo);
                  FeedLineArea = FeedLineLength * (2 + 2 * Ice) * AppurtDrag(WSpec, 2 + 2 * Ice, FeedLineLength / 12, z, V, Y, ExpCat, StrCl, Topo, IncludeIce);
                  ElbowArea = (3 * (1.625 + (2 * Ice)) + (6 * (1.625 + (2 * Ice)))) * AppurtDrag(WSpec, 1.625 + (2 * Ice), 3 / 12, z, V, Y, ExpCat, StrCl, Topo, IncludeIce);
                  Adir1 = Round(((NumberSections * (FinnedArea + SlotCovers + BarePole + LugArea + ElbowArea) + FeedLineArea * numberFeedLines + PowerDividerArea)) / 144, 2);
                  Amax = ((NumberSections * (FinnedArea + SlotCovers + BarePole + LugArea + ElbowArea) + FeedLineArea * numberFeedLines + PowerDividerArea)) / 144;
                  RDir1 = Round(G * Amax * Q, 0);

                  //Area Direction 2
                  FinHeight = 0.25 * lambda * Math.sin(55 * Pi / 180) + 6.375;
                  FinnedArea = (FinHeight + 2 * Ice) * NLayers * (Lfin + 2 * Ice) * FlatAppurtDrag(WSpec, FinHeight + 2 * Ice, NLayers * (Lfin + 2 * Ice) / 12, z, V, ExpCat, StrCl, Topo);
                  SlotCovers = (6.375 + 2 * Ice) * (L - NLayers * (Lfin + 2 * Ice) - 5) * FlatAppurtDrag(WSpec, 6.375 + 2 * Ice, (L - NLayers * (Lfin + 2 * Ice) - 5) / 12, z, V, ExpCat, StrCl, Topo);
                  BarePole = (5 - 2 * Ice) * (Dpipe + 2 * Ice) * AppurtDrag(WSpec, Dpipe + 2 * Ice, SingleSectionH2, z, V, Y, ExpCat, StrCl, Topo, IncludeIce);
                  LugArea = LugLength * (11.5 + 3 * Ice) * FlatAppurtDrag(WSpec, 3.75 + Ice, (5 - 2 * Ice) / 12, z, V, ExpCat, StrCl, Topo);
                  FeedLineArea = FeedLineLength * (2 + 2 * Ice) * AppurtDrag(WSpec, 2 + 2 * Ice, FeedLineLength / 12, z, V, Y, ExpCat, StrCl, Topo, IncludeIce);
                  ElbowArea = (3 * (1.625 + (2 * Ice)) + (6 * (1.625 + (2 * Ice)))) * AppurtDrag(WSpec, 1.625 + (2 * Ice), 3 / 12, z, V, Y, ExpCat, StrCl, Topo, IncludeIce);
                  Adir2 = Round(((NumberSections * (FinnedArea + SlotCovers + BarePole + LugArea + ElbowArea) + FeedLineArea * numberFeedLines + PowerDividerArea)) / 144, 2);
                  A = ((NumberSections * (FinnedArea + SlotCovers + BarePole + LugArea + ElbowArea) + FeedLineArea * numberFeedLines + PowerDividerArea)) / 144;
                  RDir2 = Round(G * A * Q, 0);
                  if (A > Amax) {
                      Amax = A;
                  }
                  RMax = Round(G * Amax * Q, 0);
                  Amax = Round(Amax, 2);

                  //Weight
                  PoleWeight = ((Pi / 4) * (Math.pow(Dpipe, 2) - Math.pow((Dpipe - 2 * twall), 2)) * L) * 0.1;
                  SlotCoverWeight = (L - 5) * (0.03);
                  FinWeight = ((2 * 0.3 * lambda * 0.125 + 1 * 0.5) * Lfin) * 0.1 * NLayers;
                  HarnessWeight = (2 * 1.5) + ((0.243 * L) * 0.1) + ((0.075 * (L / 2)) * 0.322) + 0.165;
                  LugWeight = 7.5;
                  Misc = 5;
                  Elbow = 3.5;
                  FeedlineWeight = FeedLineLength * 1.04 / 12; //Andrew Catalog
                  Weight = (PoleWeight + SlotCoverWeight + FinWeight + HarnessWeight + LugWeight + Misc + Elbow) * NumberSections + (FeedlineWeight) * numberFeedLines + PowerDividerWeight;
                  TotalWeight = Round(Weight, 0);

                  //Ice Weight
                  SlotCoverIce = (L - 5 - 2 * Ice) * (0.032) * (1.5 * (2.89) * (Math.pow((1.59 + Ice), 2) - Math.pow((1.59), 2)) + 1.5 * (0.71) * (Math.pow((2.5 + Ice), 2) - Math.pow((2.5), 2)));
                  FinIce = 2 * (2 * (0.3 * lambda + Ice) + 1) * NLayers * (Lfin + 2 * Ice) * 0.032;
                  BarePoleIce = Pi / 4 * (0.032) * (Math.pow((Dpipe + 2 * Ice), 2) - Math.pow(Dpipe, 2)) * (5 - 2 * Ice);
                  ElbowIce = (((Pi / 4) * (Math.pow((1.625 + 2 * Ice), 2) - Math.pow((1.625), 2)) * 5.6) + ((Pi / 4) * (Math.pow((1.625 + 2 * Ice), 2) - Math.pow((1.625), 2)) * 2.8)) * 0.032;
                  LugIce = 3 * 0.032 * (2 * (11.5 * 6.5 * Ice) + 2 * (11.5 * 0.5 * Ice) + 2 * (6.5 * 0.5 * Ice));
                  FeedLineIce = (Pi / 4) * (Math.pow((1.98 + 2 * Ice), 2) - Math.pow((1.98), 2)) * 0.032 * FeedLineLength;
                  IceWeight = (SlotCoverIce + FinIce + BarePoleIce + LugIce + FeedLineIce) * NumberSections + PowerDividerIceWeight;
                  Wice = Round(IceWeight, 0);

                  //Total Weight
                  TotalWeight = Weight + IceWeight;
                  TotalWeight = Round(TotalWeight, 3);

              } else if (optRadome == true) {
                FeedLineLength = 300;
                LugLength = 1.5;
                //PowerDivider(Ice);
                  //Area Direction 1
                  Dradome = RadDia;
                  Radome = (L - 5 + 2 * Ice) * (Dradome + 2 * Ice) * AppurtDrag(WSpec, Dradome + 2 * Ice, (L - 5 + 2 * Ice) / 12, z, V, Y, ExpCat, StrCl, Topo, IncludeIce);
                  BarePole = (5 - 2 * Ice) * (Dpipe + 2 * Ice) * AppurtDrag(WSpec, Dpipe + 2 * Ice, (5 - 2 * Ice) / 12, z, V, Y, ExpCat, StrCl, Topo, IncludeIce);
                  LugArea = LugLength * (6.5 + 3 * Ice) * FlatAppurtDrag(WSpec, 3.75 + Ice, (4.1 + Ice) / 12, z, V, ExpCat, StrCl, Topo);
                  FeedLineArea = FeedLineLength * (2 + 2 * Ice) * AppurtDrag(WSpec, 2 + 2 * Ice, FeedLineLength / 12, z, V, Y, ExpCat, StrCl, Topo, IncludeIce);
                  ElbowArea = (3 * (1.625 + (2 * Ice)) + (6 * (1.625 + (2 * Ice)))) * AppurtDrag(WSpec, 1.625 + (2 * Ice), 3 / 12, z, V, Y, ExpCat, StrCl, Topo, IncludeIce);
                  Adir1 = Round(((NumberSections * (Radome + BarePole + LugArea + ElbowArea) + FeedLineArea * numberFeedLines + PowerDividerArea)) / 144, 2);
                  Amax = ((NumberSections * (Radome + BarePole + LugArea + ElbowArea) + FeedLineArea * numberFeedLines + PowerDividerArea)) / 144;
                  RDir1 = Round(G * Amax * Q, 0);

                  //Area Direction 2
                  Radome = (L - 5 + 2 * Ice) * (Dradome + 2 * Ice) * AppurtDrag(WSpec, Dradome + 2 * Ice, (L - 5 + 2 * Ice) / 12, z, V, Y, ExpCat, StrCl, Topo, IncludeIce);
                  BarePoleArea = (5 - 2 * Ice) * (Dpipe + 2 * Ice) * AppurtDrag(WSpec, Dpipe + 2 * Ice, (5 - 2 * Ice) / 12, z, V, Y, ExpCat, StrCl, Topo, IncludeIce);
                  LugArea = LugLength * (11.5 + 3 * Ice) * FlatAppurtDrag(WSpec, 3.75 + Ice, (11.5 + 3 * Ice) / 12, z, V, ExpCat, StrCl, Topo);
                  FeedLineArea = FeedLineLength * (2 + 2 * Ice) * AppurtDrag(WSpec, 2 + 2 * Ice, FeedLineLength / 12, z, V, Y, ExpCat, StrCl, Topo, IncludeIce);
                  ElbowArea = (3 * (1.625 + (2 * Ice)) + (6 * (1.625 + (2 * Ice)))) * AppurtDrag(WSpec, 1.625 + (2 * Ice), 3 / 12, z, V, Y, ExpCat, StrCl, Topo, IncludeIce);
                  Adir2 = Round(((NumberSections * (Radome + BarePole + LugArea + ElbowArea) + FeedLineArea * numberFeedLines + PowerDividerArea)) / 144, 2);
                  A = ((NumberSections * (Radome + BarePole + LugArea + ElbowArea) + FeedLineArea * numberFeedLines + PowerDividerArea)) / 144;
                  RDir2 = Round(G * A * Q, 0);
                  if (A > Amax) {
                      Amax = A;
                  }
                  RMax = Round(G * Amax * Q, 0);
                  Amax = Round(Amax, 2);

                  //Weight
                  EndSeals = Pi * (Math.pow(Dradome, 2) - Math.pow(Dpipe, 2)) * 1 * 0.034;
                  PoleWeight = ((Pi / 4) * (Math.pow(Dpipe, 2) - Math.pow((Dpipe - 2 * twall), 2)) * L) * 0.1;
                  RadomeWeight = (0.1544 * Dradome + 2) * (L - 5) / 12;
                  HarnessWeight = (2 * 1.5) + ((0.243 * L) * 0.1) + ((0.075 * (L / 2)) * 0.322) + 0.165;
                  LugWeight = 7.5;
                  Misc = 5;
                  Elbow = 3.5;
                  FeedlineWeight = FeedLineLength * 1.04 / 12; //Andrew Catalog
                  Weight = (EndSeals + PoleWeight + RadomeWeight + Inner + Misc + Elbow) * NumberSections + (FeedlineWeight) * numberFeedLines + PowerDividerWeight;
                  TotalWeight = Round(Weight, 0);

                  //Ice Weight
                  RadomeIce = Pi / 4 * (0.032) * (Math.pow((Dradome + 2 * Ice), 2) - Math.pow(Dradome, 2)) * (L - 5 + 2 * Ice);
                  BarePoleIce = Pi / 4 * (0.032) * (Math.pow((Dpipe + 2 * Ice), 2) - Math.pow(Dpipe, 2)) * (5 - 2 * Ice);
                  ElbowIce = (((Pi / 4) * (Math.pow((1.625 + 2 * Ice), 2) - Math.pow((1.625), 2)) * 5.6) + ((Pi / 4) * (Math.pow((1.625 + 2 * Ice), 2) - Math.pow((1.625), 2)) * 2.8)) * 0.032;
                  LugIce = 3 * 0.032 * (2 * (11.5 * 6.5 * Ice) + 2 * (11.5 * 0.5 * Ice) + 2 * (6.5 * 0.5 * Ice));
                  FeedLineIce = (Pi / 4) * (Math.pow((1.98 + 2 * Ice), 2) - Math.pow((1.98), 2)) * 0.032 * FeedLineLength;
                  IceWeight = (RadomeIce + BarePoleIce + LugIce + FeedLineIce) * NumberSections + PowerDividerIceWeight;
                  Wice = Round(IceWeight, 0);

                  //Total Weight
                  TotalWeight = Weight + IceWeight;
                  TotalWeight = Round(TotalWeight, 3);
              }
            },
            DL_8: function() {

                DL_Length = L - 1.125 + 7 + 0.25;
                SlotCoverLength = 2 * (((n / 2) - 1) * lambda + SlotLength + 3);
                BarePoleLength = DL_Length - SlotCoverLength;


                //Area Direction 1;
                SlotCoverAreaFlat = (SlotCoverLength * (3.5 + 2 * Ice));
                BarePoleArea = BarePoleLength * (3.5 + 2 * Ice);



                ArevCmax = (SlotCoverAreaFlat + (BarePoleArea * (2 / 3))) / 144;
                ArevFmax = ((SlotCoverAreaFlat * 2) + (BarePoleArea * 1.2)) / 144;

                //Area Direction 2;
                SlotCoverAreaFlat = (SlotCoverLength * (3.5 + Ice));
                SlotCoverAreaRound = (SlotCoverLength * (1.75 + Ice));
                BarePole = BarePoleLength * (3.5 + 2 * Ice);




                ArevC = (SlotCoverAreaFlat + (SlotCoverAreaRound + BarePole) * (2 / 3)) / 144;
                ArevF = (SlotCoverAreaFlat * 2 + (SlotCoverAreaRound + BarePole) * 1.2) / 144;


                if (ArevC > ArevCmax) {
                        ArevCmax = ArevC;
                }
                if (ArevF > ArevFmax) {
                        ArevFmax = ArevF;
                }



                //Weight;
                LugWeight = (((Math.PI/ 4) * ( Math.pow(3.5, 2)) * 3.5) + ((Math.PI/ 4) * (( Math.pow(3.5, 2)) - ( Math.pow(1.63, 2))) * 3.5)) * 0.1;
                SlotCoverWeight = (0.722 * SlotCoverLength) * 0.04;
                PoleWeight = ((Math.PI/ 4) * ( Math.pow(3.5, 2) -  Math.pow(3, 2)) * (L - 1.125 + 0.25)) * 0.1;
                HarnessWeight = ((0.243 * DL_Length) * 0.1) + ((0.075 * (DL_Length / 2)) * 0.322) + 0.165;
                Misc = 5;

                //Ice Weight;

                SlotCoverIce = 0.032 * (((2.22 * Ice) + (2.22 * Ice) + (2.625 * Ice) + (0.75 * (Math.PI/ 4) * ( Math.pow(3.5 + 2 * Ice, 2) -  Math.pow(3.5, 2)))) * SlotCoverLength + 2 * (2.22 * 2.625 * Ice));
                BarePoleIce = ((Math.PI/ 4) * ( Math.pow(3.5 + 2 * Ice, 2) -  Math.pow(3.5, 2))) * BarePoleLength * 0.032;

                if (Ice == 0) {
                IceWeight = 0;
                } else {
                IceWeight = SlotCoverIce + BarePoleIce;
                }

                FinalWeight = (SlotCoverWeight + PoleWeight + Misc + HarnessWeight + LugWeight + IceWeight);

            },
            DL_12: function() {

                DL_Length = L - 1.125 + 7 + 0.25;
                SlotCoverLength = 2 * (((n / 2) - 1) * lambda + SlotLength + 3);
                BarePoleLength = DL_Length - SlotCoverLength;


                //Area Direction 1;
                SlotCoverAreaFlat = (SlotCoverLength * (3.5 + 2 * Ice));
                BarePoleArea = BarePoleLength * (3.5 + 2 * Ice);



                ArevCmax = (SlotCoverAreaFlat + (BarePoleArea * (2 / 3))) / 144;
                ArevFmax = ((SlotCoverAreaFlat * 2) + (BarePoleArea * 1.2)) / 144;

                //Area Direction 2;
                SlotCoverAreaFlat = (SlotCoverLength * (3.5 + Ice));
                SlotCoverAreaRound = (SlotCoverLength * (1.75 + Ice));
                BarePole = BarePoleLength * (3.5 + 2 * Ice);




                ArevC = (SlotCoverAreaFlat + (SlotCoverAreaRound + BarePole) * (2 / 3)) / 144;
                ArevF = (SlotCoverAreaFlat * 2 + (SlotCoverAreaRound + BarePole) * 1.2) / 144;


                if (ArevC > ArevCmax) {
                        ArevCmax = ArevC;
                }
                if (ArevF > ArevFmax) {
                        ArevFmax = ArevF;
                }



                //Weight;
                LugWeight = (((Math.PI/ 4) * ( Math.pow(3.5, 2)) * 3.5) + ((Math.PI/ 4) * (( Math.pow(3.5, 2)) - ( Math.pow(1.63, 2))) * 3.5)) * 0.1;
                SlotCoverWeight = (0.722 * SlotCoverLength) * 0.04;
                PoleWeight = ((Math.PI/ 4) * ( Math.pow(3.5, 2) -  Math.pow(3, 2)) * (L - 1.125 + 0.25)) * 0.1;
                HarnessWeight = ((0.243 * DL_Length) * 0.1) + ((0.075 * (DL_Length / 2)) * 0.322) + 0.165;
                Misc = 5;

                //Ice Weight;

                SlotCoverIce = 0.032 * (((2.22 * Ice) + (2.22 * Ice) + (2.625 * Ice) + (0.75 * (Math.PI/ 4) * ( Math.pow(3.5 + 2 * Ice, 2) -  Math.pow(3.5, 2)))) * SlotCoverLength + 2 * (2.22 * 2.625 * Ice));
                BarePoleIce = ((Math.PI/ 4) * ( Math.pow(3.5 + 2 * Ice, 2) -  Math.pow(3.5, 2))) * BarePoleLength * 0.032;

                if (Ice == 0) {
                IceWeight = 0;
                } else {
                IceWeight = SlotCoverIce + BarePoleIce;
                }

                FinalWeight = (SlotCoverWeight + PoleWeight + Misc + HarnessWeight + LugWeight + IceWeight);

            },
            TLS: function() {                
                TLS_Length = (3.5 * lambda) + 6;

                //Area Direction 1;
                BarePoleArea = TLS_Length * (3.686 + 2 * Ice);

                ArevCmax = ((BarePoleArea * (2 / 3))) / 144;
                ArevFmax = ((BarePoleArea * 1.2)) / 144;

                //Area Direction 2;
                BarePole = TLS_Length * (3.686 + 2 * Ice);

                ArevC = (BarePole) * (2 / 3) / 144;
                ArevF = (BarePole) * 1.2 / 144;

                if (ArevC > ArevCmax) {
                        ArevCmax = ArevC;
                }
                if (ArevF > ArevFmax) {
                        ArevFmax = ArevF;
                }

                //Weight;
                PoleWeight = ((Math.PI/ 4) * ( Math.pow(3.5, 2) -  Math.pow(3, 2)) * TLS_Length) * 0.1;

                Misc = 5;

                //Ice Weight;
                BarePoleIce = ((Math.PI/ 4) * ( Math.pow(3.686 + 2 * Ice, 2) -  Math.pow(3.686, 2))) * TLS_Length * 0.032;

                if (Ice == 0) {
                IceWeight = 0;
                } else {
                IceWeight = BarePoleIce;
                }

                FinalWeight = (PoleWeight + Misc + IceWeight);
            },

            PatternSuperM: function() {
                FinnedLength = n * (0.8 * lambda + 1);
                FinnedLengthIce = n * (0.8 * lambda + 1 + 2 * Ice);

                SlotCoverLength = (2 * (((n / 2) - 1) * lambda + SlotLength + 3));
                SlotCoverLengthExposed = SlotCoverLength - FinnedLengthIce;
                LugLength = 1.5;
                BarePoleLength = L - FinnedLengthIce - SlotCoverLengthExposed - LugLength;
                FeedLineLength = Feedline * 300;

                //Area Wind Direction1;
                FlatFinnedArea = n * (((3.4 + 2.5) *  Math.cos(10 * Math.PI/ 180) * 2) * (0.8 * lambda + 1 + 2 * Ice));
                SlotCoverAreaFlat = 0;
                FeedLineArea = FeedLineLength * (2 + 2 * Ice); //Andrew Catalog;
                BarePoleArea = (BarePoleLength + SlotCoverLengthExposed) * (5 + 2 * Ice);
                LugArea = LugLength * (6.5 + 3 * Ice);
                ElbowArea = (3 * (1.625 + (2 * Ice)) + (6 * (1.625 + (2 * Ice))));

                ArevCmax = (numberOfSections * ((BarePoleArea + ElbowArea) * (2 / 3)) + numberOfSections * (SlotCoverAreaFlat + LugArea + FlatFinnedArea) + ((FeedLineArea + PowerDividerArea) * (2 / 3))) / 144;
                ArevFmax = ((numberOfSections * (SlotCoverAreaFlat) * 1.4) + (numberOfSections * (BarePoleArea) * 1.2) + (numberOfSections * (LugArea + FlatFinnedArea) * 1.4) + (numberOfSections * (ElbowArea) * 0.8) + ((FeedLineArea + PowerDividerArea) * 1.2)) / 144;

                //Area Wind Direction2;

                FlatFinnedArea = n * ((((3.4 + 2.5 + Ice) *  Math.sin(10 * Math.PI/ 180)) + 3.5) * (0.8 * lambda + 1 + 2 * Ice));

                SlotCoverAreaFlat = 0;
                SlotCoverAreaRound = 0;
                BarePole = (BarePoleLength + SlotCoverLengthExposed) * (7.5 + 2 * Ice);
                LugArea = LugLength * (11.5 + 3 * Ice);
                ElbowArea = (3 * (1.625 + (2 * Ice)) + (6 * (1.625 + (2 * Ice))));

                ArevC = (numberOfSections * ((SlotCoverAreaRound + BarePole + ElbowArea) * (2 / 3)) + numberOfSections * (SlotCoverAreaFlat + LugArea + FlatFinnedArea) + ((FeedLineArea + PowerDividerArea) * (2 / 3))) / 144;
                ArevF = ((numberOfSections * (SlotCoverAreaFlat) * 1.4) + (numberOfSections * (SlotCoverAreaRound + BarePoleArea) * 1.2) + (numberOfSections * (LugArea + FlatFinnedArea) * 1.4) + (numberOfSections * (ElbowArea) * 0.8) + ((FeedLineArea + PowerDividerArea) * 1.2)) / 144;

                if (ArevC > ArevCmax) {
                        ArevCmax = ArevC;
                }
                if (ArevF > ArevFmax) {
                        ArevFmax = ArevF;
                }

               //Weight;
                LugWeight = 7.5;
                SlotCoverWeight = (0.722 * SlotCoverLength) * 0.04;
                PoleWeight = ((Math.PI/ 4) * ( Math.pow(5, 2) -  Math.pow(4.5, 2)) * L) * 0.1;
                    HarnessWeight = (2 * 1.5) + ((0.243 * L) * 0.1) + ((0.075 * (L / 2)) * 0.322) + 0.165;

                FinnedWeight = (((FinnedLength * (0.4 * lambda) * 0.25) * 0.1) * 4);

                ElbowWeight = 3.5 //catalog value;
                FeedlineWeight = Feedline * 26 //Andrew Catalog;
                Misc = 5;

               //Ice Weight;

                SlotCoverIce = 0;
                BarePoleIce = ((Math.PI/ 4) * ( Math.pow(5 + 2 * Ice, 2) -  Math.pow(5, 2))) * BarePoleLength * 0.032;

                FinIce = 0.032 * (n * (2 * (2 * (3.4 * (0.8 * lambda + 1) * Ice) + 2 * (0.188 * (0.3 * lambda) * Ice) + (0.188 * (0.8 * lambda + 1) * Ice))));

                LugIce = 3 * 0.032 * (2 * (10.125 * 5 * Ice) + 2 * (10.125 * 0.5 * Ice) + 2 * (5 * 0.5 * Ice));
                FeedLineIce = (Math.PI/ 4) * ( Math.pow(1.98 + 2 * Ice, 2) -  Math.pow(1.98, 2)) * 0.032 * FeedLineLength;
                ElbowIce = (((Math.PI/ 4) * ( Math.pow(1.625 + 2 * Ice, 2) -  Math.pow(1.625, 2)) * 5.6) + ((Math.PI/ 4) * ( Math.pow(1.625 + 2 * Ice, 2) -  Math.pow(1.625, 2)) * 2.8)) * 0.032;

                if (Ice == 0) {
                IceWeight = 0;
                } else {
                IceWeight = SlotCoverIce + BarePoleIce + LugIce + ElbowIce + FinIce;
                }

                FinalWeight = (SlotCoverWeight + PoleWeight + Misc + ElbowWeight + HarnessWeight + FinnedWeight + LugWeight + IceWeight) * numberOfSections + PowerDividerWeight + FeedLineIce + FeedlineWeight;

            },
            PatternSuperB: function() {
            //B Pattern With Radome;

                RadSpace = CDbl(txtRadome.Text);
                PipeDia = 3.5;
                FeedLineLength = Feedline * 300;
                LugLength = 1.5;

                //Area Direction 1;
                Radome = (L - S - 3.875 + 2 * Ice) * (RadSpace + PipeDia + 0.25 + 2 * Ice);
                BarePoleArea = (2 + S - 0.5 - 4 * Ice) * (PipeDia + 2 * Ice);
                LugArea = LugLength * (6.5 + 3 * Ice);
                FeedLineArea = FeedLineLength * (2 + 2 * Ice); //Andrew Catalog;
                ElbowArea = (3 * (1.625 + (2 * Ice)) + (6 * (1.625 + (2 * Ice))));

                ArevCmax = ((numberOfSections * (Radome + BarePoleArea + LugArea + ElbowArea) + PowerDividerArea + FeedLineArea) * 2 / 3) / 144;
                ArevFmax = ((numberOfSections * (Radome + BarePoleArea + LugArea + ElbowArea) + PowerDividerArea + FeedLineArea) * 1.2) / 144;

                //Area Direction 2;
                Radome = (L - S - 3.875 + 2 * Ice) * (RadSpace + PipeDia + 0.25 + 2 * Ice);
                BarePoleArea = (2 + S - 0.5 - 4 * Ice) * (PipeDia + 2 * Ice);
                LugArea = LugLength * (11.5 + 3 * Ice);
                FeedLineArea = FeedLineLength * (2 + 2 * Ice); //Andrew Catalog;
                ElbowArea = (3 * (1.625 + (2 * Ice)) + (6 * (1.625 + (2 * Ice))));

                ArevC = (((numberOfSections * (Radome + BarePoleArea + ElbowArea) + PowerDividerArea + FeedLineArea) * 2 / 3) + numberOfSections * LugArea) / 144;
                ArevF = (((numberOfSections * (Radome + BarePoleArea + ElbowArea) + PowerDividerArea + FeedLineArea) * 1.2) + numberOfSections * LugArea * 1.4) / 144;

                if (ArevC > ArevCmax) {
                    ArevCmax = ArevC;
                }
                if (ArevF > ArevFmax) {
                    ArevFmax = ArevF;
                }

               //Weight;
                EndSeals = Math.PI* ( Math.pow(RadSpace + PipeDia, 2) -  Math.pow(PipeDia, 2)) * 1 * 0.034;
                switch (PipeDia) {
                case 3.5:
                PoleWeight = ((Math.PI/ 4) * ( Math.pow(3.5, 2) -  Math.pow(3, 2)) * L) * 0.1;
                HarnessWeight = (2 * 1.5) + ((0.243 * L) * 0.1) + ((0.075 * (L / 2)) * 0.322) + 0.165;
                case 5:
                PoleWeight = ((Math.PI/ 4) * ( Math.pow(5, 2) -  Math.pow(4.5, 2)) * L) * 0.1;
                HarnessWeight = (2 * 1.5) + ((0.243 * L) * 0.1) + ((0.075 * (L / 2)) * 0.322) + 0.165;
                case 6:
                PoleWeight = ((Math.PI/ 4) * ( Math.pow(6, 2) -  Math.pow(5, 2)) * L) * 0.1;
                HarnessWeight = (2 * 1.5) + ((0.243 * L) * 0.1) + ((0.075 * (L / 2)) * 0.322) + 0.165;
                case 8:
                PoleWeight = ((Math.PI/ 4) * ( Math.pow(8, 2) -  Math.pow(7, 2)) * L) * 0.1;
                HarnessWeight = (2 * 1.5) + ((0.243 * L) * 0.1) + ((0.075 * (L / 2)) * 0.322) + 0.165;
                case 10.75:
                PoleWeight = ((Math.PI/ 4) * ( Math.pow(10.75, 2) -  Math.pow(9.75, 2)) * L) * 0.1;
                HarnessWeight = (2 * 1.5) + ((0.243 * L) * 0.1) + ((0.075 * (L / 2)) * 0.322) + 0.165;
                }

                RadomeWeight = 0.1544 * (2 * RadSpace + PipeDia + 0.25) * (L - S - 3.875) / 12;
                LugWeight = 7.5;
                Misc = 5;
                ElbowWeight = 3.5;
                FeedlineWeight = Feedline * 26; //Andrew Catalog

               //Ice Weight
                RadomeIce = Math.PI/ 4 * (0.032) * ( Math.pow(((2 * RadSpace + PipeDia) + 2 * Ice), 2) -  Math.pow(2 * RadSpace + PipeDia, 2)) * (L - S - 3.875 + 2 * Ice);
                BarePoleIce = Math.PI/ 4 * (0.032) * ( Math.pow(PipeDia + 2 * Ice, 2) -  Math.pow(PipeDia, 2)) * (2 + S - 0.5 - 4 * Ice);
                ElbowIce = (((Math.PI/ 4) * ( Math.pow(1.625 + 2 * Ice, 2) -  Math.pow(1.625, 2)) * 5.6) + ((Math.PI/ 4) * ( Math.pow(1.625 + 2 * Ice, 2) -  Math.pow(1.625, 2)) * 2.8)) * 0.032;
                LugIce = 3 * 0.032 * (2 * (11.5 * 6.5 * Ice) + 2 * (11.5 * 0.5 * Ice) + 2 * (6.5 * 0.5 * Ice));
                FeedLineIce = (Math.PI/ 4) * ( Math.pow(1.98 + 2 * Ice, 2) -  Math.pow(1.98, 2)) * 0.032 * FeedLineLength;

                if (Ice == 0) {

                  IceWeight = 0;
                  } else {
                  IceWeight = RadomeIce + BarePoleIce + LugIce;

                }
                FinalWeight = (EndSeals + PoleWeight + RadomeWeight + HarnessWeight + Misc + ElbowWeight + LugWeight + IceWeight) * numberOfSections + FeedLineIce + FeedlineWeight + PowerDividerWeight;

            },
            PatternRadome: function() {
            //A Pattern

                    RadSpace = CDbl(txtRadome.Text);
                    PipeDia = cmbPipeSize.Value;
                    FeedLineLength = Feedline * 300;
                    LugLength = 1.5;


                    //Area Direction 1
                    Radome = (L - S - 3.875 + 2 * Ice) * (2 * RadSpace + PipeDia + 0.25 + 2 * Ice);
                    BarePoleArea = (2 + S - 0.5 - 4 * Ice) * (PipeDia + 2 * Ice);
                    LugArea = LugLength * (6.5 + 3 * Ice);
                    FeedLineArea = FeedLineLength * (2 + 2 * Ice); //Andrew Catalog
                    ElbowArea = (3 * (1.625 + (2 * Ice)) + (6 * (1.625 + (2 * Ice))));


                    ArevCmax = ((numberOfSections * (Radome + BarePoleArea + LugArea + ElbowArea) + PowerDividerArea + FeedLineArea) * 2 / 3) / 144;
                    ArevFmax = ((numberOfSections * (Radome + BarePoleArea + LugArea + ElbowArea) + PowerDividerArea + FeedLineArea) * 1.2) / 144;

                    //Area Direction 2
                    Radome = (L - S - 3.875 + 2 * Ice) * (2 * RadSpace + PipeDia + 0.25 + 2 * Ice);
                    BarePoleArea = (2 + S - 0.5 - 4 * Ice) * (PipeDia + 2 * Ice);
                    LugArea = LugLength * (11.5 + 3 * Ice);
                    FeedLineArea = FeedLineLength * (2 + 2 * Ice); //Andrew Catalog
                    ElbowArea = (3 * (1.625 + (2 * Ice)) + (6 * (1.625 + (2 * Ice))));


                    ArevC = (((numberOfSections * (Radome + BarePoleArea + ElbowArea) + PowerDividerArea + FeedLineArea) * 2 / 3) + numberOfSections * LugArea) / 144;
                    ArevF = (((numberOfSections * (Radome + BarePoleArea + ElbowArea) + PowerDividerArea + FeedLineArea) * 1.2) + numberOfSections * LugArea * 1.4) / 144;

                    if (ArevC > ArevCmax) {
                        ArevCmax = ArevC;
                    }
                    if (ArevF > ArevFmax) {
                        ArevFmax = ArevF;
                    }



                   //Weight
                    EndSeals = Math.PI* ( Math.pow(2 * RadSpace + PipeDia, 2) -  Math.pow(PipeDia, 2)) * 1 * 0.034;
                    switch (PipeDia) {
                    case 3.5:
                    PoleWeight = ((Math.PI/ 4) * ( Math.pow(3.5, 2) -  Math.pow(3, 2)) * L) * 0.1;
                    HarnessWeight = (2 * 1.5) + ((0.243 * L) * 0.1) + ((0.075 * (L / 2)) * 0.322) + 0.165;
                    case 5:
                    PoleWeight = ((Math.PI/ 4) * ( Math.pow(5, 2) -  Math.pow(4.5, 2)) * L) * 0.1;
                    HarnessWeight = (2 * 1.5) + ((0.243 * L) * 0.1) + ((0.075 * (L / 2)) * 0.322) + 0.165;
                    case 6:
                    PoleWeight = ((Math.PI/ 4) * ( Math.pow(6, 2) -  Math.pow(5, 2)) * L) * 0.1;
                    HarnessWeight = (2 * 1.5) + ((0.243 * L) * 0.1) + ((0.075 * (L / 2)) * 0.322) + 0.165;
                    case 7:
                    PoleWeight = ((Math.PI/ 4) * ( Math.pow(7, 2) -  Math.pow(6, 2)) * L) * 0.1;
                    HarnessWeight = (2 * 1.5) + ((0.243 * L) * 0.1) + ((0.075 * (L / 2)) * 0.322) + 0.165;
                    case 8:
                    PoleWeight = ((Math.PI/ 4) * ( Math.pow(8, 2) -  Math.pow(7, 2)) * L) * 0.1;
                    HarnessWeight = (2 * 1.5) + ((0.243 * L) * 0.1) + ((0.075 * (L / 2)) * 0.322) + 0.165;
                    case 10.75:
                    PoleWeight = ((Math.PI/ 4) * ( Math.pow(10.75, 2) -  Math.pow(9.75, 2)) * L) * 0.1;
                    HarnessWeight = (2 * 1.5) + ((0.243 * L) * 0.1) + ((0.075 * (L / 2)) * 0.322) + 0.165;
                    }

                    RadomeWeight = 0.1544 * (2 * RadSpace + PipeDia + 0.25) * (L - S - 3.875) / 12;
                    LugWeight = 7.5;
                    Misc = 5;
                    ElbowWeight = 3.5;
                    FeedlineWeight = Feedline * 26 //Andrew Catalog

                   //Ice Weight
                    RadomeIce = Math.PI/ 4 * (0.032) * ( Math.pow(((2 * RadSpace + PipeDia) + 2 * Ice), 2) -  Math.pow(2 * RadSpace + PipeDia, 2)) * (L - S - 3.875 + 2 * Ice);
                    BarePoleIce = Math.PI/ 4 * (0.032) * ( Math.pow(PipeDia + 2 * Ice, 2) -  Math.pow(PipeDia, 2)) * (2 + S - 0.5 - 4 * Ice);
                    ElbowIce = (((Math.PI/ 4) * ( Math.pow(1.625 + 2 * Ice, 2) -  Math.pow(1.625, 2)) * 5.6) + ((Math.PI/ 4) * ( Math.pow(1.625 + 2 * Ice, 2) -  Math.pow(1.625, 2)) * 2.8)) * 0.032;
                    LugIce = 3 * 0.032 * (2 * (11.5 * 6.5 * Ice) + 2 * (11.5 * 0.5 * Ice) + 2 * (6.5 * 0.5 * Ice));
                    FeedLineIce = (Math.PI/ 4) * ( Math.pow(1.98 + 2 * Ice, 2) -  Math.pow(1.98, 2)) * 0.032 * FeedLineLength;

                    if (Ice == 0) {

                      IceWeight = 0;
                      } else {
                      IceWeight = RadomeIce + BarePoleIce + LugIce;

                    }
                    FinalWeight = (EndSeals + PoleWeight + RadomeWeight + HarnessWeight + Misc + ElbowWeight + LugWeight + IceWeight) * numberOfSections + FeedLineIce + FeedlineWeight + PowerDividerWeight;
                }
            },
            "BB4": {
              get: function(_channel, azimuthPattern, elevationPattern, params) {
                optBrdBnd = true;
                return self['TLS-V'].get(_channel, azimuthPattern, elevationPattern, params);
              }
            },
            "BB8": {
              get: function(_channel, azimuthPattern, elevationPattern, params) {
                optBrdBnd = true;
                return self['TLS-V'].get(_channel, azimuthPattern, elevationPattern, params);
              }
            },
            "BB12": {
              get: function(_channel, azimuthPattern, elevationPattern, params) {
                optBrdBnd = true;
                return self['TLS-V'].get(_channel, azimuthPattern, elevationPattern, params);
              }
            },
            "TLS-V": {
              get: function(_channel, azimuthpattern, elevationpattern, params) {
                RadDia = 17.125; //this seems to be hard-coded in files Jim Butts provided
                if (defined(params))
                  return this.calculate.apply(this, params);
              },
              SectionWeight: function() { // TLSSectionWeight function, add VPolWeight after
                VPolWeight = Truth(chkVPol) ? 20 : 0;
                var result = 465 - 15 * (channel - 7) + VPolWeight;
                if (Truth(optRadome)) {
                  var weights = {
                  7: 560,
                  8: 525,
                  9: 505,
                  10: 490,
                  11: 465,
                  12: 445,
                  13: 425
                  };
                  if (weights[channel]) 
                    result = weights[channel] + VPolWeight;
                  };
              return result;
            },
              setupPatterns: function(Ice_bool) {
                // windLoad function in JCB files
              //read in antenna information
              Channel = channel; // CInt(Channel)
              var NumberSections = numberOfSections; // cmbNSections.Value
              NLayers = n; // cmbNLayers.Value
              GainNumber = gain; // cmbNLayers.Value * cmbNSections.Value
              Pattern = pattern; // cmbPattern.Value - currently it is whichever pattern the function is... patternA pattern = 'A'
              h = self.values.h;
              z = self.values.z;
              V = self.values.V;
              y = h;
              Ice = 0;
              Qzg = self.helpers.Qzg;
              Qzf = self.helpers.Qzf;
              Qzc = self.helpers.Qzc;
              AppurtDrag = self.helpers.AppurtDrag;
              FlatAppurtDrag = self.helpers.FlatAppurtDrag;
              chkIce = self.values.chkIce;
              G = self.helpers.Gust(windSpec, true, h);

              if (Truth(optBrdBnd)) {
                  Channel = 10; //the weight for a broadband TLS is roughly that of a channel 10 the length is a bit different
              } else optBrdBnd = false;
              // NLayers = 4;

              //get the wind spec related data
              WSpec = windSpec;
              if (windSpec == "C") {
                  ZoneC = ["A", "B", "C"][ Math. Math.round(2 *  Math.random())]; //ZoneC (combo button);
                  Q = Qzc(ZoneC, z);
                  G = 1;
                  if (chkIce == true) {
                      Ice = self.values.Ice;
                      Q = QiC;
                  }

              } else if (windSpec == "F") {
                  Q = Qzf(z, h, V);
                  Ice = 0;

                  if (chkIce == true) {
                      Ice = self.values.Ice;
                      Q = 0.75 * Q;
                  }
              } else if (windSpec == "G") {
                  StrCl = self.values.StrCl;
                  ExpCat = self.values.ExpCat;
                  Topo = self.values.Topo;
                  Q = Qzg(z, V, y, ExpCat, StrCl, Topo);
                  IncludeIce = false;
                  if (chkIce == true) {
                      IncludeIce = true;
                      V = self.values.V_ice;
                      DesignIce = self.values.Ice;
                      Ice = self.helpers.tiz(DesignIce, z, y, ExpCat, Topo, StrCl);
                      Q = Qzg(z, V, y, ExpCat, StrCl, Topo);
                  }
              }

              //Report mechanical data based on antenna type

              var VpolText = (Truth(chkVPol)) ? "/VP" : ""; // text to indicate selection of VPol for antenna designation
              var RadomeText = (Truth(optRadome)) ? "-R " : " "; // text to indicate selection of radome for antenna designation
              var AntTypePostfix = (Truth(optBrdBnd)) ? "Broadband" : Channel;
              // gain = NLayers * NumberSections;
              AntennaDesignation = "TLS-V" + gain + VpolText + RadomeText + "(" + AntTypePostfix + ")";
              AntType = AntennaDesignation;
              /*
              //Fill in antenna designation -- delete this section once verify functionality of AntennaDesignation
              // if (optBrdBnd == true) {
              // AntTypePostfix = "Broadband"
              // else 
              // AntTypePostfix = Channel;
              // }
              // AntennaDesignation = "TLS-V" + (NLayers * NumberSections) + "-R " + "(" + AntTypePostfix + ""
              // if (optRadome == true) {
              // if (chkVPol = False) {
              // AntennaDesignation = "TLS-V" + (NLayers * NumberSections) + "-R " + "(" + AntTypePostfix + ""
              // AntType = AntennaDesignation;
              // else 
              // AntennaDesignation = "TLS-V" + (NLayers * NumberSections) + "/VP-R " + "(" + AntTypePostfix + ""
              // AntType = AntennaDesignation;
              // }
              // else if (optSlotCover == true) {
              // if (chkVPol = False) {
              // AntennaDesignation = "TLS-V" + (NLayers * NumberSections) + "(" + AntTypePostfix + ""
              // AntType = AntennaDesignation;
              // else 
              // AntennaDesignation = "TLS-V" + (NLayers * NumberSections) + "/VP " + "(" + AntTypePostfix + ""
              // AntType = AntennaDesignation;
              // }
              // }
                */
              //calculate antenna length
              if (Truth(optBrdBnd)) {
                  lambda = (254.11 - 13) / 4;
              } else {
                lambda = self.helpers.lambda(frequency);
              }

              L = lambda * NLayers + 13;
              AntennaH2 = (NumberSections * NLayers * lambda + (NumberSections - 1) * lambda / 2 + 13) / 12;
              H2 = Round(AntennaH2, 1);

              //get power divider data
              // Call TLSPowerDivider(NumberSections, Ice, PowerDividerArea, PowerDividerWeight, PowerDividerIceWeight, numberFeedLines)

              //Add radome or slotcover note
              RadomeNote = "";
              if (!Truth(optRadome) || optSlotCover == true) {
                  RadomeNote = "Slot Covered";
              } else {
                RadomeNote = "Full " + Round(RadDia, 2) + " dia Radome";
              }
              // lblRdmNote.Caption = RadomeNote;

              //Calculate wind loads
              if (!Truth(optRadome) || optSlotCover == true) {
                  //Area Direction 1
                  SlotCovers = (L + 2 * Ice) * (10.75 + 2 * Ice) * AppurtDrag(WSpec, 10.75 + 2 * Ice, (L + 2 * Ice) / 12, z, V, y, ExpCat, StrCl, Topo, IncludeIce);
                  Feedline = (300) * (2 + 2 * Ice) * AppurtDrag(WSpec, 2 + 2 * Ice, 300 / 12, z, V, y, ExpCat, StrCl, Topo, IncludeIce);
                  Adir1 = Round(((NumberSections * (SlotCovers) + Feedline * numberFeedLines + PowerDividerArea)) / 144, 2);
                  Amax = ((NumberSections * (SlotCovers) + Feedline * numberFeedLines + PowerDividerArea)) / 144;
                  //Calculate the shear force
                  RDir1 = Round(G * Amax * Q, 0);

                  //Area Direction 2
                  InputTee = (20) * (1.625 + 2 * Ice) * AppurtDrag(WSpec, 1.625 + 2 * Ice, 20 / 12, z, V, y, ExpCat, StrCl, Topo, IncludeIce);
                  Adir2 = Round(((NumberSections * (SlotCovers + InputTee) + Feedline * numberFeedLines + PowerDividerArea)) / 144, 2);
                  A = ((NumberSections * (SlotCovers + InputTee) + Feedline * numberFeedLines + PowerDividerArea)) / 144;
                  //Calculate the shear force
                  RDir2 = Round(G * A * Q, 0);
                  if (A > Amax) {
                      Amax = A;
                  }
                  RMax = Round(G * Amax * Q, 0);
                  Amax = Round(Amax, 2);
                  //Weight
                  if (chkVPol == true)
                      VPolWeight = 20;
                  else
                      VPolWeight = 0;

                  SectionWeight = self.calc["TLS-V"].SectionWeight(/*chkVPol, optRadome, Channel*/);
                  FeedlineWeight = 300 * 0.148;
                  Weight = SectionWeight * NumberSections + (FeedlineWeight) * numberFeedLines + PowerDividerWeight;
                  Weight = Round(Weight, 3);

                  //Ice Weight
                  SectionIceWeight = Pi / 4 * ( Math.pow(10.75 + 2 * Ice, 2) -  Math.pow(10.75, 2)) * L * 0.032;
                  TLIce = Pi / 4 * (0.032) * ( Math.pow(1.625 + 2 * Ice, 2) -  Math.pow(1.625, 2)) * (20);
                  FeedLineIce = Pi / 4 * (0.032) * ( Math.pow(3.01 + 2 * Ice, 2) -  Math.pow(3.01, 2)) * 300;
                  IceWeight = (SectionIceWeight + TLIce) * NumberSections + PowerDividerIceWeight;
                  Wice = Round(IceWeight, 3);

                  //Total Weight
                  TotalWeight = Weight + IceWeight;
                  TotalWeight = Round(TotalWeight, 3);
                  // console.log({
                  //   SectionWeight: SectionWeight,
                  //   NumberSections: NumberSections,
                  //   FeedlineWeight: FeedlineWeight,
                  //   numberFeedLines: numberFeedLines,
                  //   PowerDividerWeight: PowerDividerWeight,
                  //   SectionIceWeight: SectionIceWeight,
                  //   TLIce: TLIce,
                  //   FeedLineIce: FeedLineIce,
                  //   IceWeight: IceWeight,
                  //   L: L
                  // });
                  } else if (optRadome == true) { //Radomed
                      //Area Direction 1
                      Radomes = (L + 2 * Ice) * (RadDia + 2 * Ice) * AppurtDrag(WSpec, RadDia + 2 * Ice, (L + 2 * Ice) / 12, z, V, y, ExpCat, StrCl, Topo, IncludeIce);
                      Feedline = (300) * (2 + 2 * Ice) * AppurtDrag(WSpec, 2 + 2 * Ice, 300 / 12, z, V, y, ExpCat, StrCl, Topo, IncludeIce);
                      Adir1 = Round(((NumberSections * (Radomes) + Feedline * numberFeedLines + PowerDividerArea)) / 144, 2);
                      Amax = ((NumberSections * (Radomes) + Feedline * numberFeedLines + PowerDividerArea)) / 144;
                      //Calculate the shear force
                      RDir1 = Round(G * Amax * Q, 0);

                      //Area Direction 2
                      InputTee = (20) * (1.625 + 2 * Ice) * AppurtDrag(WSpec, 1.625 + 2 * Ice, (20) / 12, z, V, y, ExpCat, StrCl, Topo, IncludeIce);
                      Adir2 = Round(((NumberSections * (Radomes + InputTee) + Feedline * numberFeedLines + PowerDividerArea)) / 144, 2);
                      A = ((NumberSections * (Radomes + InputTee) + Feedline * numberFeedLines + PowerDividerArea)) / 144;
                      //Calculate the shear force
                      RDir2 = Round(G * A * Q, 0);
                      if (A > Amax) {
                          Amax = A;
                      }
                      RMax = Round(G * Amax * Q, 0);
                      Amax = Round(Amax, 2);
                      //Weight
                      if (chkVPol == true)
                          VPolWeight = 20; // where is this used?
                      else 
                        VPolWeight = 0;
                      
                      SectionWeight = self.calc["TLS-V"].SectionWeight(/*chkVPol, optRadome, Channel*/); //Problem here, function undefined
                      FeedlineWeight = 300 * 0.148;
                      Weight = SectionWeight * NumberSections + (FeedlineWeight) * numberFeedLines + PowerDividerWeight;
                      Weight = Round(Weight, 3);

                      //Ice Weight
                      SectionIceWeight = Pi / 4 * ( Math.pow(RadDia + 2 * Ice, 2) -  Math.pow(RadDia, 2)) * L * 0.032;
                      TLIce = Pi / 4 * (0.032) * ( Math.pow(1.625 + 2 * Ice, 2) -  Math.pow(1.625, 2)) * (20);
                      FeedLineIce = Pi / 4 * (0.032) * ( Math.pow(3.01 + 2 * Ice, 2) -  Math.pow(3.01, 2)) * 300;
                      IceWeight = (SectionIceWeight + TLIce) * NumberSections + PowerDividerIceWeight;
                      Wice = Round(IceWeight, 3);

                      //Total Weight
                      TotalWeight = Weight + IceWeight;
                      TotalWeight = Round(TotalWeight, 3);
                    }
            // Public Sub TLSPowerDivider(NumberSections, Ice, PowerDividerArea, PowerDividerWeight, PowerDividerIceWeight, numberFeedLines)
            },
            powerDivider: function(NumberSections) {              
                  numberFeedLines = NumberSections;
                  PowerDividerWeight = NumberSections * 50;

                  switch (NumberSections) {
                    case 1:
                      PowerDividerArea = 0;
                      PowerDividerIceWeight = 0;
                      PowerDividerWeight = 0;
                      numberFeedLines = 0;
                      break;
                    case 2:
                        //PN
                      PowerDividerArea = (24 + 12 + 7.38 - 2 * (1 + 2 * Ice)) * (4.125 + 2 * Ice) + 2 * (1 + 2 * Ice) * (6.3 + 2 * Ice) + (35 - 2 * Ice) * (3.28 + 2 * Ice) + (23 - 2 * Ice) * (3.28 + 2 * Ice) + 2 * (0.5 + Ice) * (5.19 + 2 * Ice);
                      PowerDividerIceWeight = Pi / 4 * 0.032 * (( Math.pow(4.125 + 2 * Ice, 2) - ( Math.pow(4.125, 2))) * (24 + 12 + 7.38) + ( Math.pow(3.28 + 2 * Ice, 2) - ( Math.pow(3.28, 2))) * (60));
                      // PowerDividerWeight = 100;
                      break;
                    case 3:
                      //PN R102148
                      PowerDividerArea = (24 + 13 - 2 * Ice) * (6.23 + 2 * Ice) + 2 * (0.5 + Ice) * 3 * (8.125 + 2 * Ice) + (2.66 - 2 * Ice) * (4.125 + 2 * Ice) + (17.4 - 2 * Ice) * (4.125 + 2 * Ice) + 2 * 2 * (0.5 + Ice) * (6.3 + 2 * Ice) + (3.08 - 2 * Ice) * (3.28 + 2 * Ice) + (24 - 2 * Ice) * (3.28 + 2 * Ice) + 2 * (0.5 + Ice) * (5.18 + 2 * Ice) + (5.25 + 12 - 2 * Ice - 1) * (3.28 + 2 * Ice) + (34.3 - 2 * Ice) * (3.28 + 2 * Ice) + 6 * (0.5 + Ice) * (5.19 + 2 * Ice);
                      PowerDividerIceWeight = Pi / 4 * 0.032 * (( Math.pow(6.23 + 2 * Ice, 2) - ( Math.pow(6.23, 2))) * (24 + 13) + ( Math.pow(3.28 + 2 * Ice, 2) - ( Math.pow(3.28, 2))) * (80.83) + ( Math.pow(4.125 + 2 * Ice, 2) - ( Math.pow(4.125, 2))) * (19.3)); //by measurement 1-21-2 JCB;
                      // PowerDividerWeight = 150;
                      break;
                    case 4:
                      //PN
                      PowerDividerArea = (24 + 13 - 2 * Ice) * (6.23 + 2 * Ice) + 2 * (0.5 + Ice) * 3 * (8.125 + 2 * Ice) + (2.66 - 2 * Ice) * (4.125 + 2 * Ice) + (17.4 - 2 * Ice) * (4.125 + 2 * Ice) + 2 * 2 * (0.5 + Ice) * (6.3 + 2 * Ice) + (5.6 - 2 * Ice) * (3.28 + 2 * Ice) + 2 * (35 - 2 * Ice) * (3.28 + 2 * Ice) + 4 * (1 + 2 * Ice) * (5.18 + 2 * Ice) + (46 - 2 * Ice) * (3.28 + 2 * Ice) + 4 * (0.5 + Ice) * (5.19 + 2 * Ice);
                      PowerDividerIceWeight = Pi / 4 * 0.032 * (( Math.pow(6.23 + 2 * Ice, 2) - ( Math.pow(6.23, 2))) * (24 + 13) + ( Math.pow(3.28 + 2 * Ice, 2) - ( Math.pow(3.28, 2))) * (125.6) + ( Math.pow(4.125 + 2 * Ice, 2) - ( Math.pow(4.125, 2))) * (19.3)); //by measurement 1-21-2 JCB;
                      // PowerDividerWeight = 200;
                      break;
                  }
              },
              calculate: function(WindSpec, Ice_bool, t_ice, V_basic, V_basic_ice, NumberOfSections, TowerElevation, TowerHeight, TowerClearance, ExposeCat, TopoCat, StructClass, azimuthPattern, elevationPattern, Channel, Frequency, hasRadome, useVpolCheck) {
                optRadome = hasRadome;
                chkVPol = useVpolCheck;
                self.values = {
                  WSpec: WindSpec,
                  chkIce: Ice_bool,
                  Ice: t_ice,
                  V: V_basic,
                  V_ice: V_basic_ice,
                  numSections: NumberOfSections,
                  z: TowerElevation,
                  h: TowerHeight,
                  baseClearance: TowerClearance, 
                  ExpCat: ExposeCat,
                  Topo: TopoCat,
                  StrCl: StructClass,
                  azimuthPattern: azimuthPattern,
                  elevationPattern: elevationPattern,
                  Channel: Channel
                };
                frequency = Frequency;
                optSlotCover = !Truth(optRadome);
                channel = Channel;
                windSpec = WindSpec;  // WSpec in pattern functions
                if (Ice_bool == false) Ice = 0;
                else Ice = t_ice;
                gain = parseInt(elevationPattern.substr(0, 2)); // GainNumber
                  //Determine number of slots per section;
                n = 4;                
                if (typeof NumberSections == 'undefined') self.values.numSections = gain / n;
                numberOfSections = self.values.numSections;
                // if (azimuthPattern) pattern = azimuthPattern.substr(azimuthPattern.lastIndexOf('-') + 1).toUpperCase();             

                // lambda = 11802.9 / (channel * 6 + 389);
                lambda = self.helpers.lambda(frequency);
                
                // L = (lambda * (n + 1) + 2.25);
                S = 0.7 * lambda - 2;
                SingleSectionH2 = (lambda * (n + 1) + 2.25) / 12;
                //antennaH2 = (lambda * (n * numberOfSections + 1.5 * numberOfSections - 0.5) + 2.25) / 12;
                antennaH2 = numberOfSections * SingleSectionH2 + (numberOfSections - 1) / 6;
                this.powerDivider(numberOfSections);
                this.SectionWeight();
                this.setupPatterns(Ice_bool);
                // self.helpers.calcRadDia(pattern/*, optStdRad, optOptIce, optUserInp, OptBestFit*/);
                if (!FinalWeight || typeof FinalWeight == 'undefined') FinalWeight = TotalWeight;
                self._data = {
                    totalWeight: FinalWeight,
                    weight: (Ice_bool == true) ? TotalWeight : Weight,
                    iceWeight:(Ice_bool == true) ? IceWeight : 0,
                    areaFlats: (typeof ArevCMax != 'undefined') ? ArevCMax : null,
                    forceCoef: Amax,
                    momentArm: null
                };
                self._data.RadomeNote = RadomeNote;
                self._data.AntennaDesignation = AntennaDesignation;
                // self.helpers.DisplayCount();
                return self._data;
            }

          },           
            
WB: {
        get: function(_channel, azimuthpattern, elevationpattern, params) {
          if (defined(params))
            return this.calculate.apply(this, params);
        },
        calculate: function(WindSpec, Ice_bool, t_ice, V_basic, V_basic_ice, NumberOfSections, TowerElevation, TowerHeight, TowerClearance, ExposeCat, TopoCat, StructClass, azimuthPattern, elevationPattern, Channel, Frequency, hasRadome, useVpolCheck) { //make sure to only pass in the windSpeed that will be used when calling this function (based on which WindSpec was selected)
            optRadome = hasRadome;
            chkVPol = useVpolCheck;
            numSections = NumberOfSections;
            // for 1 section no power divider is used, for more sections need power divider
            //get rid of this part!  Once testing is finished....
            // var Test = false;
            // if (!(WindSpec && t_ice && V_basic && V_basic_ice && NumberOfSections && TowerElevation && TowerHeight && TowerClearance && ExposeCat && TopoCat && StructClass))
            // {
            //     WindSpec = 'G';
            //     Ice = false;
            //     t_ice = 1;
            //     V_basic = 90;
            //     V_basic_ice = 40;
            //     numSections = 2;
            //     TowerElevation = 1000;
            //     TowerHeight = 1000;
            //     TowerClearance = 80;
            //     ExposeCat = 'C';
            //     TopoCat = 1;
            //     StructClass = 'II';
            //     Test = true;
            // }//end part that needs deleting once testing is complete
            frequency = Frequency;
            var length_feedline = 15;
            var powerDivider = numSections == 1 ? false : true;
            var pd_weight = 0,
            tIce = 0;
            // ice thickness at elevation (t_iz)
            var t_iz = self.helpers.tiz(t_ice, TowerElevation, TowerHeight, ExposeCat, TopoCat, StructClass);  
            var Pi = Math.PI;
            // select ice thickness based on spec, 'F' vs 'G'.  Needs to be a switch to handle future specs
            switch (WindSpec) {
                case 'F':
                    tIce = t_ice;
                    break;
                case 'G':
                    tIce = t_iz;                    
                    break;
            }
            var D_radome = !Ice_bool ? 17 : 17 + 2 * tIce;
            var H2 = ((7*21+26)/12)*numSections+((numSections-1)*1.25)/12;
            var OD_feedline = !Ice_bool ? 4.06 : 4.06 + 2 * tIce;
            var iceVal = !Ice_bool ? 0 : Pi/4*(  Math.pow(D_radome, 2)- Math.pow(D_radome-2*tIce, 2) ) * 0.032 * 12 * H2 + numSections * Pi / 4 * (  Math.pow(OD_feedline, 2)- Math.pow(OD_feedline -2 * tIce, 2) ) * 0.032 * 12 * length_feedline;
            if (powerDivider) {
                pd_weight = numSections == 2 ? 125 : 150;
            }
            var weight = (482 + (15 * 5.5)) * numSections + pd_weight + iceVal;
            
            var pd_length2 = 2;
            var pd_outerD2 = !Ice_bool? 4.06 : 4.06 + 2 * tIce;
            var pd_length1 = 6;
            var pd_outerD1 = !Ice_bool? 6.13 : 6.13 + 2 * tIce;
            var feedline_Drag_Ca = self.helpers.AppurtDrag(WindSpec,OD_feedline,length_feedline,TowerElevation,V_basic,TowerHeight,ExposeCat,StructClass,TopoCat,Ice);
            var feedline_EPA = (OD_feedline / 12) * length_feedline * feedline_Drag_Ca;
            var pd_Drag_Ca1 = self.helpers.AppurtDrag(WindSpec,pd_outerD1,pd_length1,TowerElevation,V_basic,TowerHeight,ExposeCat,StructClass,TopoCat,Ice);
            var pd_EPA1 = pd_outerD1 / 12 * pd_length1 * pd_Drag_Ca1;
            var pd_Drag_Ca2 = self.helpers.AppurtDrag(WindSpec,pd_outerD2,pd_length2,TowerElevation,V_basic,TowerHeight,ExposeCat,StructClass,TopoCat,Ice);
            var pd_EPA2 = pd_outerD2 / 12 * pd_length2 * pd_Drag_Ca2; 
            
            var Drag_Ca = self.helpers.AppurtDrag(WindSpec,D_radome,H2,TowerElevation,V_basic,TowerHeight,ExposeCat,StructClass,TopoCat,Ice);
             
            var EPAorCaAc = Drag_Ca * (D_radome /*+ 2 * tIce)*/ /12)*H2+feedline_EPA*numSections;
            EPAorCaAc += numSections > 1 ? numSections*pd_EPA2+pd_EPA1 : 0; 

            var windSheer, windPressure;
            var gustFactor = self.helpers.Ghf(TowerHeight);

            // Calculate wind sheer
            if (WindSpec == 'F') {
                windPressure = self.helpers.Qzf(TowerElevation, TowerHeight, V_basic);
                windSheer = windPressure * gustFactor * EPAorCaAc;
            }
            else if (WindSpec == 'G') {
                windPressure = self.helpers.Qzg(TowerElevation, V_basic, TowerHeight, ExposeCat, StructClass,TopoCat);
                windSheer = windPressure * EPAorCaAc;
            }
            var result = {
                weight: weight,
                iceWeight: iceVal,
                forceCoef: EPAorCaAc,
                H2: H2,
                windSheer: windSheer
            };
            // console.log({ //this also serves as a map between their vars and ours
            //     'Feedline OD': OD_feedline,
            //     'Feedline Length': length_feedline, 
            //     'AR': 'TBD',
            //     'Ca_g': feedline_Drag_Ca, 
            //     'EPA': feedline_EPA,
            //     'CaF': 'TBD',
            //     'CaAc': 'TBD',
            //     'OD1': pd_outerD1,
            //     'L1': pd_length1,
            //     'AR1': 'TBD',
            //     'Ca_g1': pd_Drag_Ca1, 
            //     'EPA1': pd_EPA1,
            //     'CaF': 'TBD',
            //     'CaAc': 'TBD',
            //     'OD2': pd_outerD2,
            //     'L2': pd_length2,
            //     'AR2': 'TBD - probably for F spec',
            //     'Ca_g2': pd_Drag_Ca2,
            //     'EPA2': pd_EPA2,
            //     'CaF': 'TBD',
            //     'CaAc': 'TBD'
            // },
            //     result);
            return result;
        },
        powerDivider: function() {

        }
      }
    }


  Object.keys(self.calc).forEach(function(calcFun) {
    self[calcFun] = self.calc[calcFun];
  });
};
