module.exports = function($timeout, $parse) {
  return {
    scope: {
      model: '=ngModel',
      disabled: '=ngDisabled',
      checked: '=ngChecked'
    },
    link: function($scope, element, $attrs, ngModel) {
      return $timeout(function() {
          var value = $attrs['value'];

          $scope.$watch($attrs['ngModel'], function(newValue){
              $(element).iCheck('update');
          })
        jQuery(element)
          .iCheck({
		        radioClass: 'iradio_square-blue'
          })
          .on('ifChanged', function(event) {
            if ($(event.target).is(':checked')) {
              $scope.$apply(function()  {
                $scope.model = value;
              });
            }
          });

        if ($scope.checked) {
          $scope.$apply(function() {
            $scope.model = value;
          });
          jQuery(element).iCheck('check');
          jQuery(element).iCheck('update');
        };
      });
    }
  };
};
