(function($) {
	$.fn.sbgMessageBox = function(options) {
		var self = this;

		var stylesheet = $('#sbg-messagebox-styles');
		if (stylesheet.length === 0) {
			var head = document.head || document.getElementsByTagName('head')[0];
			stylesheet = document.createElement('style');
			var styles = '.sbg-messagebox-overlay { position: fixed; top: 0; left: 0; right: 0; bottom: 0; background-color:#000; opacity: .5 }';
			styles += '.sbg-messagebox { z-index: 1000; position: absolute; top: 50%; left: 0; width: 100%; min-height: 200px; height: auto; margin-top: -100px; text-align: center; background-color: #000 }';
			styles += '.sbg-messagebox-content { position: relative; width: 600px; margin: 0 auto; text-align: left; color: #fff }';
			styles += '.sbg-messagebox-buttons { float: right; clear: both; margin-bottom: 10px }';
			styles += '.sbg-messagebox-buttons button { margin-right: 10px; color: #555 !important; }';
			if (stylesheet.styleSheet)
				stylesheet.styleSheet.cssText = styles;
			else
				stylesheet.appendChild(document.createTextNode(styles));
			head.appendChild(stylesheet);
		}

		var defaults = {
			overlayTemplate: '<div class="sbg-messagebox-overlay"></div>',
			template: [
				'<div class="sbg-messagebox">',
				'<div class="sbg-messagebox-content">',
				'<h3></h3>',
				'<p></p>',
				'<div class="sbg-messagebox-buttons"></div>',
				'</div>',
				'</div>'
			].join(''),	
			buttonTemplate: '<button type="button" class="btn"></button>',
			buttons: ['Yes', 'No'],
			onClick: null,
			animate: true
		};

		if (options)
			options = $.extend(defaults, options);

		var $overlay = $(options.overlayTemplate);
		var $el = $(options.template);

		$el.find('.sbg-messagebox-content > h3').html(options.header);
		$el.find('.sbg-messagebox-content > p').html(options.content);

		var $btnContainer = $el.find('.sbg-messagebox-buttons');

		for (var i = 0; i < options.buttons.length; i++) {
			var $btn = $(options.buttonTemplate);
			$btn.html(options.buttons[i]);
			$btn.on('click', function() {
				if (options.onClick) {
					var selection = $(this).html();
					options.onClick(selection);
				}
				if (options.animate) {
					$el.animate({
						top: 0,
						opacity: 0
					},
					            {
						            duration: 500
					            }
					           );
					$overlay.delay(500).fadeOut({
						duration: 500,
						complete: function() {
							self.closeMessageBox();
						}
					});
				}
				else {
					self.closeMessageBox();
				}
			});
			$btnContainer.append($btn);
		}
		$('body').append($overlay);
		$('body').append($el);

		if (options.animate) {
			$overlay.css({opacity: 0}).animate({opacity: .5}, 500);
			$el.css({top: 0, opacity: 0}).animate({top: $(window).height() / 2 - $el.height() / 2, opacity: 1}, 500);
		}

		self.closeMessageBox = function() {
			$el.remove();
			$overlay.remove();
		};

	  return self;
	}
})(jQuery);
