function TVAntennaService($http, $q, $interval) {
  var self = this;

  var dataStruct = {
    azimuth: null,
    elevation: null,
    antennas: null,
    antennaClass: null,
    antennaType: null,
    azimuthShape: null,
    maxBWS: null,
    poleDiam: null,
    radome: false,
    maxTVPower: null
  };

  var getter = function(target) {
    return function(selectors) {        
      var defer = $q.defer();

      self.ready
        .then(function() {
          var result = self.filterAgainst(selectors)[target];
          defer.resolve(result);
        });

      return defer.promise;
    }.bind(self);
  };

  self.ready = null;
  self.data = angular.copy(dataStruct);

  self.filterMemo = {};
  self.filterMemoTimeout = 10000;

  self._pickValues = function(from, which) {
    var result = [];

    from.forEach(function(item) {
      if(item[which] !== null) result = result.concat(item[which]);
    });
    return result.sort().unique();
  };

  self.fetch = function(path) {
    return $http({
      method: 'GET',
      url: path
    });
  };

  self.addDerivedListsTo = function(source) {
    source.antennaClass = self._pickValues(source.azimuth, 'Class');
    source.antennaType = self._pickValues(source.antennas, 'antennaType');
    source.azimuthShape = self._pickValues(source.azimuth, 'Shape')
      .filter(function(e) {
        return (typeof e !== 'undefined');
      });
    source.azimuthPattern = self._pickValues(source.azimuth, 'AzPat');
    source.elevationPattern = self._pickValues(source.elevation, 'ElPat');
    source.beamTilt = self._pickValues(source.elevation, 'BT')
      .map(function(el) {
        return parseFloat(el);
      })
      .unique();
    source.mountType = self._pickValues(source.elevation, 'Mount');
    source.maxBWS = self._pickValues(source.tvMechData, 'MaxBWS');
    source.poleDiam = self._pickValues(source.tvMechData, 'PoleDiam');
    source.hasRadome = self._pickValues(source.tvMechData, 'Radome')[0];
    source.maxTVPower = self._pickValues(source.tvMechData, 'MaxTVPower');
  };

  self.fetchAll = function() {
    var defer = $q.defer();
    $q.all([self.fetch('/antenna-data/tvazimuthdata.json'),
            self.fetch('/antenna-data/tvelevationdata.json'),
            self.fetch('/antenna-data/tvantennas.json'),
            self.fetch('/antenna-data/tvMechData.json')])
      .then(function(results) {
        self.data.azimuth = results[0].data;
        self.data.elevation = results[1].data;
        self.data.antennas = results[2].data;
        self.data.tvMechData = results[3].data.data;
        defer.resolve(self.data);
      })
      .catch(function(err) {
        
        defer.reject(err);
      });
    return defer.promise;
  };

  self.filter = {
    withinRange: function(value, min, max) {
      return (value === null || typeof value === 'undefined' ||
              (min <= value && max >= value));
    },
    matchesString: function(value, test) {
      return (value === null || typeof value === 'undefined' ||
              value.toLowerCase() === test.toLowerCase());
    },
    matchesFloat: function(value, test) {
      return (value === null || typeof value === 'undefined' ||
              parseFloat(value) === parseFloat(test));
    },
    matchesElement: function(value, tests) {
      return (value === null || typeof value === 'undefined' ||
              (tests.length === 0) ||
              tests
              .map(function(el) {
                return el.toLowerCase();
              })
              .indexOf(value.toLowerCase()) !== -1);
    }
  };

  self.trimOldMemos = function() {
    var threshold = Math.floor((Date.now() - self.filterMemoTimeout) / 1000);
    Object.keys(self.filterMemo).forEach(function(key) {
      if (self.filterMemo[key].time < threshold) {
        delete self.filterMemo[key];
      };
    });
  };

  self.filterAgainst = function(selectors) {
    var filtered = null;
    var filterKey = JSON.stringify(selectors);

    if (self.filterMemo[filterKey]) {
      filtered = self.filterMemo[filterKey].data;
    } else {
      filtered = self.runFilters(selectors);
      self.filterMemo[filterKey] = {
        time: Math.floor(Date.now() / 1000),
        data: filtered
      };
    };

    return filtered;
  };
  self.find = function(source, key, value) {
    var sourceArr = self.data[source];
    for (var i = 0; i < sourceArr.length; i++) {
      if (sourceArr[i][key] == value) {
        return sourceArr[i];
      }
    }
  };
  self.runFilters = function(selectors) {
    var filtered = angular.copy(dataStruct);

    var selectedDesigns = {
      azimuth: self.data.azimuth
      .filter(function(item) {
        return (item.AzPat === selectors.azimuthPattern);
      })
      .map(function(item) {
        return item.Design;
      }),
      elevation: self.data.elevation
      .filter(function(item) {
        return (item.ElPat === selectors.elevationPattern);
      })
      .map(function(item) {
        return item.Design;
      })
    };

    var designsForSelectedChannel = [];

    filtered.azimuth = self.data.azimuth
      .map(function(azimuth) {
        if (self.filter.withinRange(selectors.channel, azimuth.MinChannel, azimuth.MaxChannel)
            && self.filter.matchesElement(selectors.antennaClass, azimuth.Class)
            && self.filter.matchesString(selectors.azimuthShape, azimuth.Shape)
            && self.filter.matchesElement(azimuth.Design, selectedDesigns.elevation)) {
          return azimuth;
        } else {
          return null;
        };
      })
      .filter(function(result) {
        return (result !== null);
      });

    designsForSelectedChannel = self
      ._pickValues(filtered.azimuth, 'Design')
      .unique();

      var elevDesigns = [];
    filtered.elevation = self.data.elevation
      .map(function(elev) {
        if (self.filter.matchesString(selectors.antennaType, elev.Type)
            && self.filter.matchesElement(selectors.antennaClass, elev.Class)
            && self.filter.matchesString(selectors.mountType, elev.Mount)
            && self.filter.matchesFloat(selectors.beamTilt, elev.BT)
            && self.filter.matchesElement(elev.Design, designsForSelectedChannel)
            && self.filter.matchesElement(elev.Design, selectedDesigns.azimuth)) {
          elevDesigns.push(elev.Type);
          elevDesigns = $.unique(elevDesigns);
          return elev;
        } else {
          return null;
        };
      })
      .filter(function(result) {
        return (result !== null);
      });
    filtered.antennas = self.data.antennas
      .map(function(antenna) {
        if (self.filter.withinRange(selectors.channel, antenna.minChannel, antenna.maxChannel)
            && self.filter.matchesString(selectors.antennaType, antenna.antennaType)
            && self.filter.matchesElement(selectors.antennaClass, antenna.antennaClass)
            && self.filter.matchesElement(selectors.azimuthShape, antenna.azimuthShape) // the following conditions added to filter based on elevation designs
            && (selectors.mountType === null 
              || (selectors.mountType !== null
              && self.filter.matchesElement(antenna.antennaType, elevDesigns)))) {                  
          return antenna;
        } else {
          return null;
        };
      })
      .filter(function(result) {
        return (result !== null);
      });

    /***************************************************
     * PERFORM CROSS-FILTERING ACROSS AZ/EL PATTERNS TO
     * ADDRESS FILTER OPTIONS THAT DO NOT APPLY TO THEM
     ***************************************************/

    // First we need the designs for the available elevation patterns
    var elevationDesigns = filtered.elevation
      .map(function(el) {
        return el.Design;
      });

    // Then we need the designs for the available azimuth patterns
    var azimuthDesigns = filtered.azimuth
      .map(function(az) {
        return az.Design;
      });

    // Then we need to find the matching designs between the two
    var matchingDesigns = azimuthDesigns.diff(elevationDesigns).unique();
    
    filtered.elevation = filtered.elevation
      .map(function(el) {
        if (self.filter.matchesElement(el.Design, matchingDesigns))
          return el;
        else
          return null;
      })
      .filter(function(result) {
        return result !== null;
      });

    filtered.azimuth = filtered.azimuth
      .map(function(az) {
        if (self.filter.matchesElement(az.Design, matchingDesigns))
          return az;
        else
          return null;
      })
      .filter(function(result) {
        return result !== null;
      });

    /***************************************************
     * END CROSS-FILTERING
     ***************************************************/

    var matchingElevation = filtered.elevation
          .map(function(elev) {
            if (self.filter.matchesString(selectors.elevationPattern, elev.ElPat)
                && self.filter.matchesString(matchingDesigns[0], elev.Design)) {
              return elev;
            } else {
              return null;
            };
          })
          .filter(function(result) {
            return (result !== null);
          })[0];
    
    var matchingAzimuth = filtered.azimuth
          .map(function(az) {
            if (self.filter.matchesString(selectors.azimuthPattern, az.AzPat)
                && self.filter.matchesString(matchingDesigns[0], az.Design)) {
              return az;
            } else {
              return null;
            };
          })
          .filter(function(result) {
            return (result !== null);
          })[0];

    filtered.matchingAzimuth = matchingAzimuth;
    filtered.matchingElevation = matchingElevation;

    filtered.tvMechData = self.data.tvMechData
      .map(function(mech) {
        if (self.filter.withinRange(selectors.channel, mech.MinChannel, mech.MaxChannel)
            && self.filter.matchesFloat((matchingAzimuth || {}).PoleDiameter, mech.PoleDiam)
            && self.filter.matchesString(matchingDesigns[0], mech.Design)
            && self.filter.matchesFloat((matchingAzimuth || {}).MatchCode, mech.MatchCode)
            && self.filter.matchesFloat((selectors.elevationPattern + '').substr(0, 2), mech.Layers)
           ) {
          return mech;
        } else {
          return null;
        };
      })
      .filter(function(result) {
        return (result !== null);
      });
    
    self.addDerivedListsTo(filtered);

    return filtered;
  };

  self.get = {
    azimuth: getter('azimuth'),
    elevation: getter('elevation'),
    antennas: getter('antennas'),
    antennaClass: getter('antennaClass'),
    antennaType: getter('antennaType'),
    azimuthShape: getter('azimuthShape'),
    azimuthPattern: getter('azimuthPattern'),
    elevationPattern: getter('elevationPattern'),
    beamTilt: getter('beamTilt'),
    mountType: getter('mountType'),
    maxTVPower: getter('maxTVPower'),
    matchingAzimuth: getter('matchingAzimuth'),
    matchingElevation: getter('matchingElevation'),
    radome: getter('radome'),
    hasRadome: getter('hasRadome')
  };

  self.filterMemoCleanup =
    $interval(self.trimOldMemos.bind(self), self.filterMemoTimeout);

  self.ready = self.fetchAll();

  return self;
};

module.exports = TVAntennaService;
