module.exports = function() {
  var self = this;
  var calculations = {};
  var values = {};

  self.clear = function() {
    Object.keys(values).forEach(function(key) {
      values[key] = undefined;
    });
  };
  
  self.add = function(items, options) {
    options = options || {};
    
    Object.keys(items).forEach(function(itemKey) {
      if ((!options.replace) && typeof calculations[itemKey] !== 'undefined') {
        // item already defined & we're not replacing -- no-op
     } else {
        calculations[itemKey] = items[itemKey];
      };
    });
  };

  self.get = function(key, $scope) {
    if (typeof calculations[key] === 'undefined') {
      return undefined;
    };
    
    if ($scope && typeof values[key] === 'undefined') {
      self.update($scope, key);
    };
    
    return values[key];
  };

  self.getAll = function($scope) {
    if ($scope) {
      Object.keys(calculations).forEach(function(itemKey) {
        if (typeof values[itemKey] === 'undefined') {
          self.update($scope, itemKey);
        };
      });
    };

    return values;
  };

  self.update = function($scope, key) {
    var depsReady;
    
    if (typeof calculations[key] === 'undefined') {
      return undefined;
    };

    depsReady = calculations[key].dependencies($scope);

    if (depsReady !== null && typeof depsReady === 'object' && typeof depsReady.then === 'function') {
      // async dependency - update (asynchronously) once it's resolved
      return depsReady.then(function(ready) {
        if (ready) {
          calculations[key].update($scope)
            .then(function(result) {
              values[key] = result;
            });
        };
      });
    } else if (depsReady) {
      // sync dependency - update immediately
      values[key] = calculations[key].update($scope);
      return values[key];
    } else {
      // deps not available - return existing value
      return values[key];
    };
  };

  self.updateAll = function($scope) {
    Object.keys(calculations).forEach(function(itemKey) {
      self.update($scope, itemKey);
    });
  };
};
