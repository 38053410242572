module.exports = function($http, $q, PatternParserService) {
  this.search = function(callLetters) {
    var defer = $q.defer();

    $http({
      method: 'GET',
      url: '/pattern-data/fcc/fcc_stations.json'
    })
      .then(function(res) {
        var data = res.data.filter(function(station) {
          return (station.call_letter.toLowerCase().indexOf(callLetters.toLowerCase()) !== -1);
        });
        defer.resolve(data);
      })
      .catch(function(err) {
        defer.reject(err);
      });

    return defer.promise;
  };

  this.get = function(patternId) {
    var defer = $q.defer();

    $http({
      method: 'GET',
      url: '/pattern-data/fcc/fcc_' + patternId + '.az'
    })
      .then(function(res) {
        var pattern = null;
        try {
          pattern = PatternParserService.parse(res.data, 'az');
        }
        catch(ex) {

        }
        defer.resolve(pattern);
      })
      .catch(function(err) {
        defer.reject(err);
      });

    return defer.promise;
  };
};
