module.exports = function($timeout, $document) {
  return {
    restrict: 'E',
    scope: {},
    transclude: true,
    template: [
      '<div class="scroller">',
      '  <div class="scroller-content" ng-transclude>',
      '  </div>',
      '  <div class="scroller-scrollbar">',
      '    <div class="scroller-thumb">',
      '    </div>',
      '  </div>',
      '</div>'
    ].join(''),
    link: function(scope, element, attrs) {
      var elements = {
        container: jQuery(element),
        scroller: jQuery(element).find('.scroller'),
        track: jQuery(element).find('.scroller-scrollbar'),
        thumb: jQuery(element).find('.scroller-thumb'),
        content: jQuery(element).find('.scroller-content > ul')
      };
      var resizeThumb;
      var scrollSelectedIntoView;
      var observer;

		  resizeThumb = function(evt) {
        $timeout(function() {
			    var offset = elements.content.scrollTop() * -1;
			    var innerHeight = elements.content.get(0).scrollHeight;
			    var outerHeight = elements.scroller.height();
			    var percentage = outerHeight / innerHeight;
			    if (percentage == 1) {
				    elements.track.css({display: 'none'});
			    } else {
				    elements.track.css({display: 'block'});
          };

			    elements.thumb.css({height: (outerHeight * percentage) + 'px'});
        }, 0);
		  };

      scrollSelectedIntoView = function() {
        var selected = elements.content.find('.active');
        var viewport = elements.content;
        var bottom;
        var nudge = 0;

        if (selected.length > 0) {
          bottom = selected.position().top + selected.height();
          if (bottom > viewport.height()) {
            nudge = selected.height();
          };
          
          viewport.scrollTop(selected.position().top);
        };
      };
      
			// Monitor DOM Mutations for the scroller (items added/removed)
			// and resize the thumb bar accordingly
			observer = new MutationObserver(function(mutations) {
        scrollSelectedIntoView();
			  resizeThumb();
			});
			observer.observe(elements.content.get(0), {
        attributes: true,
        childList: true
      });

			resizeThumb();

		  elements.content.on('scroll', function(evt) {
			  var outerHeight = elements.scroller.height();
			  var innerHeight = elements.content.get(0).scrollHeight;
			  var offset = elements.content.scrollTop();
			  var totalScroll = innerHeight - outerHeight;
			  var percentage = offset / totalScroll;

			  elements.thumb.css({
          marginTop: ((outerHeight - elements.thumb.height()) * percentage) + 'px'
        });

			  if (percentage == 1) {
          evt.stopPropagation();
          evt.preventDefault();
				  evt.cancelBubble = true;
				  evt.returnValue = false;
				  return false;
			  }
        
        return true;
		  });
      
		  elements.track.on('wheel', function(evt) {
			  elements.content.scrollTop(elements.content.scrollTop() + evt.originalEvent.deltaY);
			  // Disable text selection while dragging
			  if(evt.stopPropagation) evt.stopPropagation();
			  if(evt.preventDefault) evt.preventDefault();
			  evt.cancelBubble=true;
			  evt.returnValue=false;
			  return false;
		  });
      
		  elements.thumb.on('mousedown', function(evt) {
			  // Add the active class to the scrollbar so it doesn't disappear while dragging
			  elements.track.addClass('active');
			  // Begin dragging and start logging mouse info
			  var scrollerObj = { element: this, position: evt.pageY };

			  var scrollerMouseMove = function(evt) {
				  if (scrollerObj) {
					  var mouseY = evt.pageY;
					  // Get the current marginTop for the thumb bar
					  var currThumbY = parseInt(elements.thumb.css('margin-top'));
					  // Calculate the new marginTop
					  // Current marginTop minus the difference between the current mouseY and the last mouseY
					  var thumbY = currThumbY + (mouseY - scrollerObj.position);
					  // Constrain the Y position between 0 and the height of the  scrollbar minus the height of the thumb bar
					  var upperLimit = Math.min(thumbY, elements.thumb.parent().height() - elements.thumb.height());
					  var cleanThumbY = Math.max(upperLimit, 0);
					  elements.thumb.css({marginTop: cleanThumbY + 'px'});
					  // Update the scrollerObject's last position to that of the current mouse position
					  scrollerObj.position = evt.pageY;

					  // Adjust the scroller content's scroll position
					  var percentage = cleanThumbY / (elements.track.height() - elements.thumb.height());
					  var scrollPos = (elements.content.get(0).scrollHeight - elements.scroller.height()) * percentage;
					  elements.content.scrollTop(scrollPos);

					  // Disable text selection while dragging
					  evt.stopPropagation();
					  evt.preventDefault();
					  evt.cancelBubble=true;
					  evt.returnValue=false;
					  return false;
				  }
          
          return true;
			  };
        
			  var scrollerMouseUp = function() {
				  if (scrollerObj) {		
					  // Free up dragging events and objects
					  elements.track.removeClass('active');
					  scrollerObj = null;
					  $document.off('mousemove', scrollerMouseMove);
					  $document.off('mouseup', scrollerMouseUp);
				  }
			  };

			  $document.on('mousemove', scrollerMouseMove);

			  $document.on('mouseup', scrollerMouseUp);
		  });
    }
  };
};
