module.exports = function($window, $filter) {
  this.override = false;
  
  this.register = function($scope, defaults, models, warning) {
    var self = this;
    
    warning = warning || 'Leaving this page will discard your changes.';
    warning = $filter('translate')(warning);
    
    var listener = function(event) {
      var dirty = Object.keys(models)
            .some(function(key) {
              return models[key] !== defaults[key];
            });

      if (!self.override && dirty) {
        event.returnValue = warning;
        return warning;
      } else {
        return undefined;
      };
    };

    $scope.$on('$destroy', function() {
      $window.removeEventListener('beforeunload', listener);
    });
    
    $window.addEventListener('beforeunload', listener);
  };
};
