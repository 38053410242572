var filters = {
  'decibels': require('./decibels'),
  'metric': require('./metric')
};

module.exports = function(app) {
  app.filter('decibels', [function() {
    return filters.decibels;
  }]);

  Object.keys(filters.metric).forEach(function(filterName) {
    var filterFunction = filters.metric[filterName];
   
    app.filter(filterName, [function() {
      return filterFunction;
    }]);
  });
};
