module.exports = function() {
  return {
    restrict: 'E',
    scope: {
      model: '=ngModel',
      disabled: '=disabled'
    },
    template: [
      '<input class="tgl tgl-flat"',
      '       ng-class="{disabled: disabled}"',
      '       type="checkbox" id="" />',
      '<label class="tgl-btn"',
      '       ng-class="{disabledToggle: disabled}"',
      '       for=""></label>',
    ].join(''),
    compile: function(tElement, tAttrs) {
      var id = 'toggle_' + Math.random().toString(36).slice(2);
      
      jQuery(tElement).find('> *').map(function(i, el) {
        var element = jQuery(el);
        var tagName = element.prop('tagName').toLowerCase();
        var targetProp = (tagName === 'input' ? 'id' : 'for');
        element.prop(targetProp, id);
      });
      
      return function(scope, element, attrs) {
        var checkbox = element.find('input');

        element.on('click', function(event) {
          if (scope.disabled) {
            event.stopPropagation();
            event.preventDefault();
          };
        });
        
        scope.$watch(
          function() {
            return scope.model;
          },
          function(newValue, oldValue) {
            if (newValue) {
              checkbox.prop('checked', true);
            } else {
              checkbox.prop('checked', false);
            };
          });

        checkbox.on('change', function() {
          scope.$apply(function() {
            if (checkbox.prop('checked')) {
              scope.model = true;
            } else {
              scope.model = false;
            };
          });
        });
      };
    }
  };
};
