module.exports = function($scope, $state, $window, $document, BusinessLogicService, PDFService, Values) {
  var document = $document[0];

  $scope.tabElementValues = Values.preserve($scope, 'tabElementValues', {
    exhibitNumberPrefix: null,
    exhibitNumbers: null
  });

  $scope.pages = PDFService.preparePages();

  $scope.save = function() {
    PDFService.scopeMethods.save($scope.pages);
  };

  $scope.print = function() { //this print function only for Print All tab
    PDFService.scopeMethods.print($scope.pages);
  };

  $scope.$watch(
    function() {
      return $scope.tabElementValues;
    },
    function(newValue, oldValue) {
      var newlySet = [];
      Object.keys(oldValue).forEach(function(key) {
        if (newValue[key] !== null && newValue[key] !== oldValue[key]) {
          newlySet.push(key);
        };
      });

      updateExhibitNumbers();
    }, true);

  $scope.$watch(
    function() {
      return Object.keys($scope.pages).map(function(key) {
        return $scope.pages[key].export;
      });
    },
    function(newValue, oldValue) {
      updateExhibitNumbers();
    }, true);

  var updateExhibitNumbers = function() {
    var suffix = 1;

    if ($scope.tabElementValues.exhibitNumbers === 'identical') {
      $scope.pages.forEach(function(pageInfo) {
        pageInfo.exhibitNumber = $scope.tabElementValues.exhibitNumberPrefix;
      });
      return;
    };

    if ($scope.tabElementValues.exhibitNumbers === 'sequential') {
      $scope.pages.forEach(function(pageInfo) {
        if (pageInfo.enabled && pageInfo.export) {
          pageInfo.exhibitNumber =
            ($scope.tabElementValues.exhibitNumberPrefix || '') + suffix.toString();
          suffix += 1;
        } else {
          pageInfo.exhibitNumber = null;
        };
      });
      return;
    };
  };
};
