module.exports = function($http, $q, PatternParserService) {
  var self = this;
  
  self.fetch = function(name, type, polarization) {
    var defer = $q.defer();
    var extension = self.generateExtension(type, polarization);
    var filename = name + '.' + extension;
    
    $http({
      method: 'GET',
      url: '/pattern-data/' + filename.toLowerCase()
    })
      .then(function(httpResult) {
        var parsedResult;
        
        try {
          defer.resolve(PatternParserService.parse(httpResult.data, extension));
        } catch (err) {
          defer.reject(err);
        };
      })
      .catch(function(err) {
        defer.reject(err);
      });

    return defer.promise;
  };

  self.generateExtension = function(type, polarization) {
    var extension;
    polarization = polarization || 'horizontal';

    if (type.toLowerCase() === 'azimuth') {
      extension = 'az';
    } else {
      extension = 'el';
    };

    if (polarization.toLowerCase() === 'vertical') {
      extension = 'v.' + extension;
    };

    return extension;
  };

  self.rotatePatternData = function(patternData, rotation) {
    var rotatedData = patternData;
    var rotationStep = rotation;
    while (rotationStep > 0) {
      rotatedData.unshift(rotatedData.pop());
      rotationStep -= 1;
    };
    return rotatedData;
  };
};
