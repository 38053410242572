module.exports = function($compile, $filter, PatternDataService) {
  return {
    restrict: 'E',
    scope: {
      values: '=values',
      model: '=ngModel'
    },
    template: [
      '<h5 class="{{name}}">{{name | translate}} {{ "Patterns" | translate}}</h5>',
      '<scroller>',
      '  <ul>',
      '    <li ng-repeat="value in values"',
      '        ng-class="{active: (model === value)}"',
      '        ng-click="setModel(value)">',
      '      {{ displayPatternName(value) }}',
      '    </li>',
      '  </ul>',
      '</scroller>',
      '<chart></chart>',
      '<div class="summary-details"',
      '     ng-show="model">',
      '  <span ng-repeat="(name, value) in details">',
      '    <span class="name">{{name | translate}}: </span>',
      '    <span class="value">{{value}} </span>',
      '  </span>',
      '</div>'
    ].join(''),
    link: function(scope, element, attrs) {
      var extension;
      var filters = {
        dB: $filter('decibels'),
        round: function(input, places) {
          places = places || 1;
          return $filter('number')(input, places);
        }
      };
      var chartOptions = {
        radar: {
          animation: true,
          responsive: false,
          angleShowLineOut: true,
          angleLineInterval: 30,
          scaleShowLine: true,
          scaleOverride: true,
          scaleSteps: 10,
          scaleStepWidth: 10,
          scaleStartValue: 0,
          scaleShowLabels: false,
          pointLabelFontSize: 0,
          pointDot: false,
          forceAngle: true,
          showTooltips: false
        },
        line: {
          animation: true,
          responsive: false,
          pointLabelFontSize: 0,
          pointDot: false,
          showTooltips: false,
          scaleOverride: true,
          scaleSteps: 102,
          scaleStepWidth: 1,
          scaleStartValue: 0,
          scaleShowLabels: false,
          scaleFontSize: 0,
          scaleShowGridLines : true,
          scaleGridLineWidth: 1,
          scaleGridLineColor: 'rgba(1, 1, 1, 0)',
          scaleLineWidth: 0,
          scaleLineColor: 'rgba(1, 1, 1, 0)',
          scaleShowVerticalLines: false,
          scaleShowHorizontalLines: false,
          verticalGridCount: 10
        }
      };

      scope.displayPatternName = function(value) {
        return value.replace(/[a-z]$/, '');
      }

      scope.setModel = function(value) {
        if (scope.model === value) {
          scope.model = null;
        } else {
          scope.model = value;
        };
      };

      scope.name = attrs.name;
      scope.details = {};
      scope.chart = {
        data: {
          labels: [],
          datasets: [{data: []}]
        },
        options: chartOptions[attrs['type']]
      };

      var chartHTML = [
        '<canvas class="thumbnail-pattern chart cjs-' + attrs['type'] + '"',
        '        ng-show="model"',
        '        dataset="chart.data"',
        '        options="chart.options"',
        '        height="150" width="170"></canvas>',
      ].join('');
      var chart = $compile(chartHTML)(scope);
      element.find('chart').replaceWith(chart);

      scope.$watch(
        function() {
          return scope.model;
        },
        function(newValue, oldValue) {
          if (!newValue) {
            scope.chart.data.datasets[0].data = [];
          } else {
            var pattern = newValue;
            if (attrs['name'].toLowerCase() == 'elevation' && scope.$parent.patternRecords.elevation.Design == 'DCR') {
              pattern = pattern.substr(0, 1) + 'x' + pattern.substr(2, 2) + 'x' + pattern.substr(5);
            }
            PatternDataService
              .fetch(pattern, attrs['name'])
              .then(function(patternData) {
                var values;

                if (Array.isArray(patternData.data)) {
                  values = patternData.data;
                } else {
                  values = Object.keys(patternData.data)
                    .map(function(key) {
                      return patternData.data[key];
                    });
                };

                scope.details = {};

                scope.details.Pattern = patternData.drawingNumber;

                scope.details.Gain =
                  filters.round(patternData.gain)
                  + ' '
                  + '('
                  + filters.round(filters.dB(patternData.gain), 2)
                  + ' dB'
                  + ')';

                if (typeof patternData.beamTilt === 'number') {
                  scope.details['Beam Tilt'] =
                    filters.round(patternData.beamTilt) + '°';
                };

                scope.chart.data.labels = Array(values.length);

                scope.chart.data.datasets[0] = {
                  fillColor: attrs['fillColor'],
                  strokeColor: attrs['strokeColor'],
                  data: values.map(function(item) {
                    return item.relative;
                  })
                };
              })
              .catch(function(err) {
                console.error(err);
              });
          };
        });
    }
  };
};
