module.exports = function() {
  return {
    restrict: 'E',
    scope: {
      values: '=values',
      model: '=ngModel'
    },
    template: [
      '<div>',
      '  <span class="unfilter-button glyphicon glyphicon-remove"',
      '        title="Remove filter"',
      '        ng-show="model !== null"></span>',
      '  <span class="decrement-button glyphicon glyphicon-arrow-left"',
      '        ng-class="{atLimit: valueIndex === -1}"',
      '        title="Reduce"></span>',
      '  <span class="value">{{model}}</span>',
      '  <span class="increment-button glyphicon glyphicon-arrow-right"',
      '        ng-class="{atLimit: valueIndex === (values.length-1)}"',
      '        title="Increase"></span>',
      '</div>'
    ].join(''),
    link: function(scope, element, attrs) {
      var templateElements = {
        clear: jQuery(element).find('div span.unfilter-button'),
        left: jQuery(element).find('div span.decrement-button'),
        value: jQuery(element).find('div span.value'),
        right: jQuery(element).find('div span.increment-button')
      };
      scope.valueIndex = -1;

      function setModel(newIndex, apply) {
        var newValue;
        
        if (scope.valueIndex === -1) {
          newValue = null;
        } else {
          newValue = scope.values[scope.valueIndex];
        };

        if (apply) {
          scope.$apply(function() {
            scope.model = newValue;
          });
        } else {
          scope.model = newValue;
        };
      };

      function updateValue(isIncrement) {
        var isDecrement = !isIncrement;
        
        if (isDecrement && scope.valueIndex === -1) {
          return false;
        };

        if (isIncrement && scope.valueIndex === scope.values.length - 1) {
          return false;
        };

        if (isIncrement) {
          scope.valueIndex += 1;
        } else {
          scope.valueIndex -= 1;
        };

        return setModel(scope.valueIndex, true);
      };

      templateElements.left.click(function(event) {
        updateValue(false);
      });
      
      templateElements.right.click(function(event) {
        updateValue(true);
      });

      templateElements.clear.click(function(event) {
        scope.valueIndex = -1;
        setModel(scope.valueIndex, true);
      });

      scope.$watch(
        function() {
          return scope.model;
        },
        function(newValue, oldValue) {
          // update scope.valueIndex to match new model value
          var newValueIndex = scope.values.indexOf(newValue);
          if (scope.valueIndex !== newValueIndex) {
            scope.valueIndex = newValueIndex;
            setModel(scope.valueIndex);
          };
        }
      );

      scope.$watch(
        function() {
          return scope.values;
        },
        function(newValue, oldValue) {
          var newValueIndex = newValue.indexOf(scope.model);
          if (scope.valueIndex !== newValueIndex) {
            scope.valueIndex = newValueIndex;
            setModel(scope.valueIndex);
          };
        }
      );
    }
  };
};
