require('./force-toplevel')(window.location);
require('angular');

var envName = (typeof window.process === 'object' && window.process.__nwjs
               ? 'offline'
               : 'web');

if (envName === 'web' && !window.buildDate) {
  window.buildDate = 1; // If the buildDate was not set during the build process, set to 1
} else if (envName === 'offline' && !window.buildDate) {
  // eval() because we don't want Browserify to see this
  /* jshint -W061 */
  var fs = eval('require(\'fs\')');
  window.buildDate = fs.statSync('./js/app.js').mtime.getTime();
  console.log(buildDate);
};

window.jQuery = window.$ = require('jquery');
var icheck = require('icheck');

var bootstrap = require('bootstrap');
var angularCookies = require('angular-cookies');
var angularUIRouter = require('angular-ui-router');
var angularUIBootstrap = require('angular-bootstrap-npm');
var angularTranslate = require('angular-translate');
var angularTranslateLoaderStaticFiles = require('angular-translate-loader-static-files');
var angulartics = require('angulartics');
var angularticsGoogleAnalytics = require('angulartics-google-analytics');
var webcomponents = require('webcomponents.js');
var sbgMessageBox = require('sbg-messagebox');
var Decimal =require('decimal.js');

var chartjs = require('chart.js');
window.Chart = chartjs;

var AngularChartJs = require('angular-chartjs');

require('./prototype-patcher')(Array);

var app = angular.module('DASP',
                         ['ui.bootstrap', 'ui.router',
                          'ngCookies',
                          'chartjs',
                          'pascalprecht.translate',
                          'angulartics',
                          'angulartics.google.analytics']);

app.constant('ENVIRONMENT', envName);
app.constant('BUILDDATE', window.buildDate);

app.config(['$stateProvider', '$urlRouterProvider',
            require('./routes')]);

app.config(['$analyticsProvider',
          function($analyticsProvider){
            $analyticsProvider.firstPageview(true);
          }
]);

app.config(['$translateProvider',
            function($translateProvider) {
              var supportedLanguages = ["en","es","pt","de"];
              //old list: supportedLanguages = ["en","en-us","en-gb","en-ca","es","es-ar","es-bo","es-cl","es-co","es-cr","es-do","es-ec","es-gt","es-hn","es-mx","es-ni","es-pa","es-pe","es-pr","es-py","es-sv","es-uy","es-ve","es-419","pt","pt-pt","pt-br","de"];
              var browserLanguage = (Array.isArray(navigator.languages)
                                     ? navigator.languages[0]
                                     : (navigator.language 
                                        || navigator.userLanguage));
              browserLanguage = browserLanguage ? browserLanguage.split('-')[0].toLowerCase() : 'en';
              var chosenLanguage;
              
              if (supportedLanguages.indexOf(browserLanguage) === -1) {
                chosenLanguage = supportedLanguages[0];
              } else {
                chosenLanguage = browserLanguage;
              };

              $translateProvider.preferredLanguage(chosenLanguage);

              $translateProvider.useStaticFilesLoader({
                prefix: '/lang/',
                suffix: '.lang'
              });

              $translateProvider.useSanitizeValueStrategy('escape');
            }]);

require('./filter')(app);
require('./directive')(app);
require('./service')(app);
require('./controller')(app);
