module.exports = function() {
  this.calculate = function(design, channel, frequency, elevationPattern) {//can we just pass in design?
    if (channel === 0 || !channel) {
      console.error('Invalid channel passed to FM antenna length service');
      return {
        H2: 0,
        H3: 0,
        H4: 0
      };
    };
    
    var H2, H3, H4 = null;
    var Frequency = frequency || null;
    var Mch = channel; //note, channel is used for FM, Mch for television
    var Wavelength = function(Frequency) {
      return 11802.9 / Frequency / 12;
    }; //provides the wavelength in feet of a particular channel (really the frequency of the channel)
    var WaveL = Wavelength(Frequency);  //added, WaveL may be somehting else, but for now it's equal to Wavelength(Frequency)
    var elev = elevationPattern;
    var MDCRSeries = elev.substring(4,5);
    var MDCBRPanelType = MDCRSeries; //likely can set these to the same thing, which will be type from elevation data, have to find it first, tho
    var F = elev.substring(0,1) === "F";
    var Mslots = parseInt(F ? elev.substring(2,4) : elev.substring(0,2)); //setting Mslots and NumOfBays to the same value
    var NumOfBays = Mslots;

    if(design.substring(0, 3) == "DCR") { //within design type.  They took left 4 digits: DesType = Left(Mdesign, 4) 'this is done to trim off the channel range designation letter
      var ElementLength = [20 / 12, 20 / 12, 29 / 12, 33 / 12];                 
      var Spacing = parseInt(F ? elev.substring(5,7) : 0);
      Spacing /= (Spacing === 10 ? 10 : 100); //didn't realize that /= works the same as += except dividing instead of adding
      console.log('Spacing may be off by up to 0.005 as it is derived from the elevationPattern name');
      var Ptype = MDCRSeries;
      var PtypeObj = {
        H: 0, C: 1, M: 2, L: 3};
      var elmLength = 0;
      if (NumOfBays > 0) {
        var elmHelper = PtypeObj[Ptype];
        elmLength = ElementLength[elmHelper];
      };
      H2 = WaveL * Spacing * (NumOfBays - 1) + elmLength; //seems like this should be inside the if statement, but it wasn't in Dielectric's code
    }
    else if(design.substring(0, 4) == "DCBR") {
      if (MDCBRPanelType == "A") H2 = ((Mslots -1) * 114 + 108) / 12;
      else if (MDCBRPanelType == "B") H2 = ((Mslots -1) * 114 + 76) / 12;
    }
    else if(design.substring(0, 4) == "DCPE" || design.substring(0, 4) == "DCPJ") {
      H2 = ((Mslots - 1) * 118 + 87.4) / 12;
    }
    else if(design.substring(0, 4) == "FMVE") {
      //'mslots = number of layers
      var FMVselector = (Mslots / 2) - 1;
      var H2array = [17.66, 35, 52.33, 69.66, 87, 104.5];
      if (Mslots % 2 === 0) H2 = H2array[FMVselector];
    }
    
    if (H2 !== 0) {
      H3 = H2 / 2;
    };
    
    if (design.substring(0, 4) == "FMVE") {
      H4 = H2 + 4;
    } else {
      H4 = H2;
    };

    return { //H2 is antenna length, H3 is center of radiation, H4 is with lightning protector
      H2: H2,
      H3: H3,
      H4: H4
    };
  };
};
