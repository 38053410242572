module.exports = function($timeout) {
  return {
    scope: {
      model: '=ngModel',
      disabled: '=ngDisabled',
      checked: '=ngChecked'
    },
    link: function(scope, element, attrs) {
      return $timeout(function() {
        jQuery(element).iCheck({
          checkboxClass: 'icheckbox_square-blue'
        })
          .on('ifChecked', function(event) {
            if (scope.model !== true) {
              scope.$apply(function() {
                scope.model = true;
              });
            };
          })
          .on('ifUnchecked', function(event) {
            if (scope.model !== false) {
              scope.$apply(function() {
                scope.model = false;
              });
            };
          });

        scope.$watch(
          function() {
            return scope.model;
          },
          function(newValue, oldValue) {
            if (newValue) {
              jQuery(element).iCheck('check');
            } else {
              jQuery(element).iCheck('uncheck');
            };
            jQuery(element).iCheck('update');
          });

        if (scope.checked) {
          scope.$apply(function() {
            scope.model = true;
          });
          jQuery(element).iCheck('check');
          jQuery(element).iCheck('update');
        };
      }, 0);
    }
  };
};
