module.exports = function($scope, $rootScope, $q, AntennaService, AntennaLengthService, CalculationService, BusinessLogicService, Values, PatternDataService, MechanicalService, ExportService) {
  $scope.uiElementValues = {};
  Values.attach('tv', 'uiElementValues')
    .then(function(result) {
      $scope.uiElementValues = result;
      updateControllerBusinessLogic();
    });

  $scope.root = $rootScope;
  $scope.antennaTypeRecord = {};
  Values.attach('tv', 'antennaTypeRecord')
    .then(function(result) {
      $scope.antennaTypeRecord = result;
    });

  $scope.patternData = Values.preserve($scope, 'patternData', {
    azimuth: null,
    azimuthVpol: null,
    elevation: null
  });
  
  $scope.savedPatternData = {
    azimuth: null,
    elevation: null
  };

  $scope.patternRecords = Values.preserve($scope, 'patternRecords', {
    azimuth: null,
    elevation: null
  });

  $scope.uiElementOptions = {
    azimuthPattern: [],
    elevationPattern: []
  };

  // Returns true if we have vpol data, and either:
  //   a) we haven't been to the detail modal yet, or
  //   b) we've been there and the use vpol box is checked
  $scope.useVpol = function() {
    var allValues = Values.fetchAll();// ('tv.detail', 'detailElementValues');
    var detailElementValues = Values.fetch('tv.detail', 'detailElementValues');
    var result = true;
    if (typeof allValues['tv.detail'] != 'undefined' && typeof allValues['tv.detail'].detailElementValues != 'undefined') {
      result = allValues['tv.detail'].detailElementValues.useVpolCheck;
    } 
    return result;
  };

  $scope.configuredERP_hpol = function() {
    var summaryTabValues = Values.fetch('tv.detail.summary', 'tabElementValues') || {};
    var hpol_bl = BusinessLogicService.get('ERPfromTPO', {});
    var hpol_ui = summaryTabValues.ERP;
    var hpol = (summaryTabValues.ERPtoTPO
                ? hpol_ui
                : hpol_bl);

    if (!hpol) {
      return null;
    } else {
      return hpol;
    };
  };

  $scope.configuredERP_vpol = function() {
    var vpol = BusinessLogicService.get('ERPfromTPO_vpol', {});
    if (!vpol) {
      return null;
    } else {
      return vpol;
    };
  };

  $scope.canEnableDetailsButton = function() {
    return $scope.uiElementValues.channel
      && $scope.uiElementValues.antennaType
      && $scope.uiElementValues.antennaClass;
  };

  $scope.detailsButtonTitle = function() {
    var missing = [];

    if (!$scope.uiElementValues.channel) {
      missing.push('Channel');
    };

    if (!$scope.uiElementValues.antennaType) {
      missing.push('Antenna Type');
    };

    if (!$scope.uiElementValues.antennaClass) {
      missing.push('Antenna Class');
    };

    if (!$scope.patternData.azimuth) {
      missing.push('Azimuth Pattern');
    };
    
    if (!$scope.patternData.elevation) {
      missing.push('Elevation Pattern');
    };

    if (missing.length > 0) {
      return 'Required to review antenna system details:\n'
        + missing.map(function(str) {
          return ' - ' + str + '\n';
        })
        .join('');
    } else {
      return 'Click to review antenna system details';
    };
  };

  BusinessLogicService.clear();

  $scope.businessLogicValues = {};

  BusinessLogicService.add({
    signalType: {
      dependencies: function($scope) {
        return (typeof $scope.uiElementValues.isDigital !== undefined);
      },
      update: function($scope) {
        switch ($scope.uiElementValues.isDigital) {
        case true:
          return 'digital-tv';
        case false:
          return 'analog-tv';
        case null:
          return 'fm';
        default:
          throw new Error('Business logic signal type broke the world');
        };
      }
    },
    antennaType: {
      dependencies: function($scope) {
        return (($rootScope.beastMode == true)
          && ($scope.patternData.azimuth !== null)
          && ($scope.patternData.elevation !== null))
          || (($scope.uiElementValues.azimuthPattern !== null)
          && ($scope.uiElementValues.antennaType !== null)
          && ($scope.uiElementValues.antennaClass !== null)
          && ($scope.uiElementValues.channel !== null)
          && ($scope.uiElementValues.elevationPattern !== null)
          && ($scope.patternData.azimuth !== null)
          && ($scope.patternData.elevation !== null));
      },
      update: function($scope) {
        return getAntennaType($scope);
      }
    },
    maxTVPower: {
      dependencies: function($scope) {
        return AntennaService.ready;
      },
      update: function($scope) {
        return AntennaService.get.maxTVPower($scope.uiElementValues)
          .then(function(result) {
            return result[0];
          });
      }
    },
    hasRadome: {
      dependencies: function($scope) {
        return AntennaService.ready;
      },
      update: function($scope) {
        return AntennaService.get.hasRadome($scope.uiElementValues).then(function(result) {
            return result;
          });
      }
    },
    maxERP: {
      dependencies: function($scope) {
        return AntennaService.ready
          .then(function(result) {
            return (($scope.uiElementValues.channel !== null)
                    && ($scope.patternRecords.azimuth !== null)
                    && ($scope.patternRecords.elevation !== null)
                    && $scope.businessLogicValues.maxTVPower
                    && BusinessLogicService.update($scope, 'gain'));
          });
      },
      update: function($scope) {
        return AntennaService.get.maxTVPower($scope.uiElementValues)
          .then(function(result) {
            var initialInputPower = result;
            var channel = $scope.uiElementValues.channel;
            var azimuth = $scope.patternRecords.azimuth;
            var elevation = $scope.patternRecords.elevation;
            var DTV = $scope.uiElementValues.isDigital;
            var gain = BusinessLogicService.update($scope, 'gain');

            return (gain * CalculationService.maximumInputPower_TV(initialInputPower, channel, azimuth, elevation, DTV));
          });
      }
    },
    maxERP_vpol: {
      dependencies: function($scope) {
        return AntennaService.ready
          .then(function(result) {
            return (($scope.uiElementValues.channel !== null)
                    && ($scope.patternRecords.azimuth !== null)
                    && ($scope.patternRecords.elevation !== null)
                    && $scope.businessLogicValues.maxTVPower
                    && BusinessLogicService.update($scope, 'gain_vpol'));
          });
      },
      update: function($scope) {
        return AntennaService.get.maxTVPower($scope.uiElementValues)
          .then(function(result) {
            var initialInputPower = result;
            var channel = $scope.uiElementValues.channel;
            var azimuth = $scope.patternRecords.azimuth;
            var elevation = $scope.patternRecords.elevation;
            var DTV = $scope.uiElementValues.isDigital;
            var gain_vpol = BusinessLogicService.update($scope, 'gain_vpol');

            return (gain_vpol * CalculationService.maximumInputPower_TV(initialInputPower, channel, azimuth, elevation, DTV));
          });
      }
    },
    rmsMainLobeGain: {
      dependencies: function($scope) {
        return ($scope.patternData.azimuth !== null)
          && ($scope.patternRecords.azimuth !== null)
          && ($scope.patternData.elevation !== null)
          && ($scope.patternRecords.elevation !== null);
      },
      update: function($scope) {
        var gain = $scope.patternData.elevation.mainLobeGain;
        
        //if ($scope.patternRecords.azimuth.Design.match(/^(?:TUL|TDM|TCL)/)) {
        //  gain /= 2;
        //};
        
        return gain;
      }
    },
    rmsHorizontalGain: {
      dependencies: function($scope) {
        return ($scope.patternData.azimuth !== null)
          && ($scope.patternRecords.azimuth !== null)
          && ($scope.patternData.elevation !== null)
          && ($scope.patternRecords.elevation !== null);
      },
      update: function($scope) {
        var gain = $scope.patternData.elevation.horizontalGain;

        //if ($scope.patternRecords.azimuth.Design.match(/^(?:TUL|TDM|TCL)/)) {
        //  gain /= 2;
        //};
        
        return gain;
      }
    },
    rmsHorizontalGain_vpol: {
      dependencies: function($scope) {
        return ($scope.patternData.azimuthVpol !== null)
          && ($scope.patternRecords.azimuth !== null)
          && ($scope.patternData.elevation !== null)
          && ($scope.patternRecords.elevation !== null);
      },
      update: function($scope) {
        var gain = $scope.patternData.elevation.horizontalGain;

        //if ($scope.patternRecords.azimuth.Design.match(/^(?:TUL|TDM|TCL)/)) {
        //  gain /= 2;
        //};
        
        return gain;
      }
    },
    peakMainLobeGain: {
      dependencies: function($scope) {
        return ($scope.patternData.azimuth !== null)
          && ($scope.patternRecords.azimuth !== null)
          && ($scope.patternData.elevation !== null)
          && ($scope.patternRecords.elevation !== null);
      },
      update: function($scope) {
        var gain = $scope.patternData.azimuth.gain
              * $scope.patternData.elevation.mainLobeGain;

        //if ($scope.patternRecords.azimuth.Design.match(/^(?:TUL|TDM|TCL)/)) {
        //  gain /= 2;
        //};
        
        return gain;
      }
    },
    peakHorizontalGain: {
      dependencies: function($scope) {
        return ($scope.patternData.azimuth !== null)
          && ($scope.patternRecords.azimuth !== null)
          && ($scope.patternData.elevation !== null)
          && ($scope.patternRecords.elevation !== null);
      },
      update: function($scope) {
        var gain = $scope.patternData.azimuth.gain
              * $scope.patternData.elevation.horizontalGain;

        //if ($scope.patternRecords.azimuth.Design.match(/^(?:TUL|TDM|TCL)/)) {
        //  gain /= 2;
        //};
        
        return gain;
      }
    },
    peakHorizontalGain_vpol: {
      dependencies: function($scope) {
        return ($scope.patternData.azimuthVpol !== null)
          && ($scope.patternRecords.azimuth !== null)
          && ($scope.patternData.elevation !== null)
          && ($scope.patternRecords.elevation !== null);
      },
      update: function($scope) {
        var gain = $scope.patternData.azimuthVpol.gain
              * $scope.patternData.elevation.horizontalGain;

        //if ($scope.patternRecords.azimuth.Design.match(/^(?:TUL|TDM|TCL)/)) {
        //  gain /= 2;
        //};
        
        return gain;
      }
    },
    gain: {
      dependencies: function($scope) {
        return ($scope.patternData.azimuth !== null)
          && ($scope.patternRecords.azimuth !== null);
      },
      update: function($scope) {
        var azimuth = $scope.patternRecords.azimuth;
        var signalType = BusinessLogicService.get('signalType', $scope);
        var usePeak = !$scope.uiElementValues.showRMSGain;

        if (usePeak) {
          return BusinessLogicService.update($scope, 'peakMainLobeGain');
        } else {
          return BusinessLogicService.update($scope, 'rmsMainLobeGain');
        };
      }
    },
    gain_vpol: {
      dependencies: function($scope) {
        return ($scope.patternData.azimuthVpol !== null)
          && ($scope.patternRecords.azimuth !== null)
          && ($scope.patternData.elevation !== null);
      },
      update: function($scope) {
        var azimuth = $scope.patternRecords.azimuth;
        var signalType = BusinessLogicService.get('signalType', $scope);
        var usePeak = !$scope.uiElementValues.showRMSGain;

        if (usePeak) {
          return BusinessLogicService.update($scope, 'peakMainLobeGain_vpol');
        } else {
          return BusinessLogicService.update($scope, 'rmsMainLobeGain_vpol');
        };
      }
    },
    peakMainLobeGain_vpol: {
      dependencies: function($scope) {
        return ($scope.patternData.azimuthVpol !== null)
          && ($scope.patternRecords.azimuth !== null)
          && ($scope.patternData.elevation !== null)
          && ($scope.patternRecords.elevation !== null);
      },
      update: function($scope) {
        var gain = ($scope.patternData.azimuthVpol.gain)
              * $scope.patternData.elevation.mainLobeGain;

        //if ($scope.patternRecords.azimuth.Design.match(/^(?:TUL|TDM|TCL)/)) {
        //  gain /= 2;
        //};

        return gain;
      }
    },
    rmsMainLobeGain_vpol: {
      dependencies: function($scope) {
        return ($scope.patternData.azimuthVpol !== null)
          && ($scope.patternRecords.azimuth !== null)
          && ($scope.patternData.elevation !== null)
          && ($scope.patternRecords.elevation !== null);
      },
      update: function($scope) {
        var gain = $scope.patternData.elevation.mainLobeGain;

        //if ($scope.patternRecords.azimuth.Design.match(/^(?:TUL|TDM|TCL)/)) {
        //  gain /= 2;
        //};
        
        return gain;
      }
    },
    horizontalGain: {
      dependencies: function($scope) {
        return ($scope.patternData.elevation !== null);
      },
      update: function($scope) {
        var usePeak = !$scope.uiElementValues.showRMSGain;

        if (usePeak) {
          return BusinessLogicService.update($scope, 'peakHorizontalGain');
        } else {
          return BusinessLogicService.update($scope, 'rmsHorizontalGain');
        };
      }
    },
    horizontalGain_vpol: {
      dependencies: function($scope) {
        return ($scope.patternData.azimuthVpol !== null)
          && ($scope.patternData.elevation !== null);
      },
      update: function($scope) {
        var usePeak = !$scope.uiElementValues.showRMSGain;

        if (usePeak) {
          return BusinessLogicService.update($scope, 'peakHorizontalGain_vpol');
        } else {
          return BusinessLogicService.update($scope, 'rmsHorizontalGain_vpol');
        };
      }
    },
    maxAvgPower: {
      dependencies: function($scope) {
        return AntennaService.ready
          .then(function(result) {
            return (($scope.uiElementValues.channel !== null)
                    && ($scope.patternRecords.azimuth !== null)
                    && ($scope.patternRecords.elevation !== null));
          });
      },
      update: function($scope) {
        return AntennaService.get.maxTVPower($scope.uiElementValues)
          .then(function(result) {
            var initialInputPower = result;
            var channel = $scope.uiElementValues.channel;
            var azimuth = $scope.patternRecords.azimuth;
            var elevation = $scope.patternRecords.elevation;
            var DTV = $scope.uiElementValues.isDigital;

            if (Array.isArray(initialInputPower)) {
              initialInputPower = initialInputPower[0];
            };

            return CalculationService.maximumInputPower_TV(initialInputPower, channel, azimuth, elevation, DTV);
          });
      }
    },
    maxWindSpeed: {
      dependencies: function($scope) {
        return true;
      },
      update: function($scope) {
        return 70; // FIXME port length fetch from FM
      }
    },
    antennaLengthData: {
      dependencies: function($scope) {
        return AntennaService.ready
          .then(function() {
            return (($scope.uiElementValues.channel !== null)
                    && ($scope.uiElementValues.frequency !== null)
                    && ($scope.uiElementValues.elevationPattern !== null)
                    && ($scope.patternRecords.azimuth !== null));
          });
      },
      update: function($scope) {
        var defer = $q.defer();

        var azimuth = $scope.patternRecords.azimuth;
        var elevation = $scope.patternRecords.elevation;
        var design = azimuth.Design;
        var azimuthShape = azimuth.Shape;
        var channel = $scope.uiElementValues.channel;
        var frequency = $scope.uiElementValues.frequency;
        var elevationPattern = $scope.uiElementValues.elevationPattern;

        var lengths = AntennaLengthService.calculate(design, channel, frequency, elevationPattern, azimuthShape);
        defer.resolve({
          'length': lengths.H2,
          'centerOfRadiation': lengths.H3,
          'lengthWithProtector': elevation.Mount != 'Side' ? lengths.H4 : null,
          'lightningProtectorLength': (lengths.H4 - lengths.H2)
        });

        return defer.promise;
      }
    },
    mechanicalData: {
      dependencies: function($scope) {
        return AntennaService.ready
          .then(function() {
            return (($scope.uiElementValues.channel !== null)
                    && ($scope.patternRecords.elevation !== null)
                    && ($scope.patternData.azimuth !== null)
                    && ($scope.patternData.elevation !== null)
                    && (window.location.hash == '#/tv' || window.location.hash == '#/fm'));
          });
      },
      update: function($scope) {
        var defer = $q.defer();
        var design = $scope.patternRecords.elevation.Design;
        var patRec = $scope.patternRecords;
        var uiEV = $scope.uiElementValues;
        var params = [
          'G',
          false,
          0,
          90,
          40,
          parseInt(patRec.elevation.ElPat.substr(0, 2)) / 8,
          1000, 
          1000,
          80,
          'C', 
          '1', 
          'II',
          patRec.azimuth.AzPat,
          patRec.elevation.ElPat,
          uiEV.channel,
          uiEV.frequency,
          uiEV.radome,
          false
      ];
        var mechanicalData = MechanicalService
              .byDesign(design)
              .get($scope.uiElementValues.channel,
                   $scope.patternData.azimuth.drawingNumber,
                   $scope.patternData.elevation.drawingNumber, params);
        defer.resolve(mechanicalData);
        return defer.promise;
      }
    },
    maxPeakVpol: {
      dependencies: function($scope) {
        return $scope.patternData.azimuth
          && $scope.patternData.azimuthVpol
          && $scope.patternData.elevation;
      },
      update: function($scope) {
        return CalculationService.maxPeakVpol($scope.patternData.azimuth,
                                              $scope.patternData.azimuthVpol,
                                              $scope.patternRecords.azimuth,
                                              $scope.patternData.elevation);
      }
    }
  }, {replace: true});

  var updateControllerBusinessLogic = function() {
    BusinessLogicService.updateAll($scope);
    $scope.businessLogicValues = BusinessLogicService.getAll();
  };

  var updateControllerPatternData = function() {
    if ($scope.uiElementValues.azimuthPattern) {
      fetchPatternData('azimuth');
    };
    
    if ($scope.uiElementValues.elevationPattern) {
      fetchPatternData('elevation');
    };
  };

  var updateControllerPatternRecords = function() {
    AntennaService.get.matchingAzimuth($scope.uiElementValues)
      .then(function(result) {
        $scope.patternRecords.azimuth = result;
      });
    
    AntennaService.get.matchingElevation($scope.uiElementValues)
      .then(function(result) {
        $scope.patternRecords.elevation = result;
      });
  };

  $scope.exportAz = function($event, type) {
    var link = angular.element($event.currentTarget);
    var result = ExportService.formats[type].azimuth($scope.patternData, $scope);
    if($scope.patternData.azimuth.drawingNumber == ' '){
      result.filename = result.filename.replace(' ', $scope.uiElementValues.azimuthPattern);
    }else if($scope.patternData.elevation.drawingNumber == ' '){
      result.filename = result.filename.replace(' ', $scope.uiElementValues.elevationPattern);
    }

    if (window.navigator && window.navigator.msSaveBlob) {
      window.navigator.msSaveBlob(result.contents, result.filename);
    }
    else {
      link.attr('href', URL.createObjectURL(result.contents));
      link.attr('download', result.filename);
    }
  };

  $scope.exportEl = function($event, type) {
    var link = angular.element($event.currentTarget);
    var result = ExportService.formats[type].elevation($scope.patternData, $scope);
    if($scope.patternData.elevation.drawingNumber == ' '){
      result.filename = result.filename.replace(' ', $scope.uiElementValues.elevationPattern);
    }else if($scope.patternData.azimuth.drawingNumber == ' '){
      result.filename = result.filename.replace(' ', $scope.uiElementValues.azimuthPattern);
    }
    
    if (window.navigator && window.navigator.msSaveBlob) {
      window.navigator.msSaveBlob(result.contents, result.filename);
    }
    else {
      link.attr('href', URL.createObjectURL(result.contents));
      link.attr('download', result.filename);
    }
  };

  var fetchPatternData = function(type) {
    var azRecord;
    
    PatternDataService.fetch($scope.uiElementValues[type + 'Pattern'], type)
      .then(function(result) {
        $scope.patternData[type] = result;
      })
      .catch(function(err) {
        $scope.patternData[type] = null;
        console.error(err);
      });

    if (type === 'azimuth') {
      azRecord = AntennaService.get.matchingAzimuth($scope.uiElementValues)
        .then(function(azimuthRecord) {
          if (azimuthRecord && azimuthRecord.Vpol) {
            return PatternDataService.fetch(azimuthRecord.Vpol.replace(/\-V$/, ''),
                                            'azimuth',
                                            'vertical');
          } else {
            return null;
          };
        })
        .then(function(result) {
          $scope.patternData.azimuthVpol = result;
        })
        .catch(function(err) {
          $scope.patternData.azimuthVpol = null;
          console.error(err);
        });
    };

    // Always reset the displayed AZ pattern back to HPol 
    // when selecting a new azimuth or elevation pattern
    var azimuthElementValues = Values.fetch('tv.detail.azimuth', 'tabElementValues');
    if (azimuthElementValues)
      azimuthElementValues.useVpol = false;

    var detailElementValues = Values.fetch('tv.detail', 'detailElementValues');
    if (detailElementValues)
      detailElementValues.useVpolCheck = null;
  };

  var refilter = function(key) {
    AntennaService.get[key]($scope.uiElementValues)
      .then(function(result) {
        $scope.uiElementOptions[key] = result;
        if (result.indexOf($scope.uiElementValues[key]) === -1) {
          $scope.uiElementValues[key] = null;
        };
      });
  };

  function getAntennaType($scope) { // to simplify getting antennaType for watcher and antennaType when added into BusinessLogic
    var BeastMode = (typeof $rootScope.beastMode !== 'undefined' && $rootScope.beastMode == true) ? 1 : 0;
    var uiEV = $scope.uiElementValues;
    var patData = $scope.patternData;
    function check(variable) {
      if (typeof variable !== 'undefined' && variable !== null) {
        return variable;
      } else return '';
    }
    var values = { //left side for !BeastMode (0), right side for BeastMode (1)
      azType: [check(uiEV.azimuthPattern).toUpperCase(), 'custom'],
      antType: [check(uiEV.antennaType).toUpperCase(), patData.elevation.antennaType],
      antClass: check(uiEV.antennaClass).toUpperCase(),
      channel: uiEV.channel,
      azPattern: [uiEV.azimuthPattern, patData.azimuth.drawingNumber],
      elPattern: [uiEV.elevationPattern, patData.elevation.drawingNumber],
      useVpol: [$scope.useVpol(), false]
    };

    var antennaProperties = {
          withRadome: uiEV.radome,
          design: check(patData.azimuth.drawingNumber).split(/\-/)[0],
          azType: values.azType[BeastMode],
          elType: patData.elevation.antennaType,
          antType: values.antType[BeastMode],
          antClass: values.antClass,
          channel: values.channel,
          azimuthPattern: values.azPattern[BeastMode],
          elevationPattern: values.elPattern[BeastMode],
          useVpol: values.useVpol[BeastMode]
        };

        return CalculationService.antennaType.tv(antennaProperties);
  };

  $scope.$watch(
    function() {
      return $scope.uiElementValues;
    },
    function(newValue, oldValue) {
      var newlySet = [];
      Object.keys(oldValue).forEach(function(key) {
        if (newValue[key] !== null && newValue[key] !== oldValue[key]) {
          newlySet.push(key);
        };
      });

      if ((newValue.azimuthPattern || newValue.elevationPattern)
          && (!newValue.channel || !newValue.antennaType || !newValue.antennaClass)) {
        $scope.uiElementValues.azimuthPattern = null;
        $scope.uiElementValues.elevationPattern = null;
      };

      var beastModeChange = (['#/tv', '#/fm'].indexOf(window.location.hash) == -1 && $rootScope.beastMode); // variable to indicate whether channel was changed in beastMode

      if(newlySet.indexOf('design') !== -1) return;
      if(newlySet.indexOf('radome') !== -1) {
        $scope.businessLogicValues.antennaType = getAntennaType($scope);        
      };
      // group items that only need to update controller business logic in one place
      var needle = ['isDigital', 'showRMSGain', 'antennaType', 'antennaClass', 'mountType'];
      for (i = 0; i < needle.length; i++) {
        if (newlySet.indexOf(needle[i]) !== -1) {
          updateControllerBusinessLogic();
          break;
        };
      };
      if ((newlySet.indexOf('antennaType') !== -1 || newlySet.indexOf('channel') !== -1 || newlySet.indexOf('frequency') !== -1) && !beastModeChange) {
        $scope.uiElementValues.azimuthPattern = null;
        $scope.uiElementValues.elevationPattern = null;
        BusinessLogicService.clear();
      };

      if (newlySet.indexOf('azimuthPattern') === -1) {
        refilter('azimuthPattern');
      };
      
      if (newlySet.indexOf('elevationPattern') === -1) {
        refilter('elevationPattern');
      };

      if (newlySet.indexOf('azimuthPattern') !== -1 || newlySet.indexOf('elevationPattern') !== -1) {
        BusinessLogicService.clear();
        updateControllerPatternData();
        updateControllerPatternRecords();
        updateControllerBusinessLogic();
      };
    },
    true);

  $scope.$watch(
    function() {
      return ($scope.patternData.azimuth !== null && $scope.patternData.azimuth.drawingNumber)
        + ($scope.patternData.elevation !== null && $scope.patternData.elevation.drawingNumber);
    },
    function(newValue, oldValue) {
      //Toggle RMS Gain vs Peak Gain when selecting an Omni pattern
      if ($scope.patternRecords.azimuth && $scope.patternRecords.azimuth.Shape == 'Omni') {
        $scope.uiElementValues.showRMSGain = true;
      }else if ($scope.patternRecords.azimuth && $scope.patternRecords.azimuth.Shape == 'Trilobe') {
        $scope.uiElementValues.showRMSGain = true;
      }
      else {
        $scope.uiElementValues.showRMSGain = false;
      }
      if (newValue !== oldValue) {
        updateControllerBusinessLogic();
      };
    });

  $scope.$watch(
    function() {
      return $scope.businessLogicValues.maxTVPower + $scope.uiElementValues.isDigital;
    },
    function(newValue, oldValue) {
      $scope.businessLogicValues.maxAvgPower
        = BusinessLogicService.update($scope, 'maxAvgPower');
      $scope.businessLogicValues.maxERP
        = BusinessLogicService.update($scope, 'maxERP');
    });
};
