module.exports = function($scope, $rootScope, PDFService, TransmissionLineService, CalculationService, BusinessLogicService, Values, $filter) {
  $scope.tabElementValues = Values.preserve($scope, 'tabElementValues', {
    ERPtoTPO: false,
    lineType: null,
    lineSize: null,
    lineLength: null,
    ERP: null,
    TPO: null,
    deratingFactor: null,
    relative: false
  });
  $scope.root = $rootScope;
  $scope.tabElementOptions = Values.preserve($scope, 'tabElementOptions', {
    lineType: [],
    lineSize: []
  });

  if (typeof $scope.tabElementValues.useVpol === 'undefined' 
    && typeof $scope.detailElementValues !== 'undefined')
    $scope.tabElementValues.useVpol = $scope.detailElementValues.useVpolCheck;
  $scope.selectedTransmissionLine = Values.preserve($scope, 'selectedTransmissionLine', null);
  $scope.businessLogicValues = {};

  $scope.canPrint = function() {
    return PDFService.canPreparePages(['summary']);
  };

  $scope.printButtonTitle = function() {
    return ($scope.canPrint() ? '' : 'Complete this tab to print results.');
  };
  
  $scope.print = function() {
    var pages = PDFService.preparePages(['summary']);
    PDFService.scopeMethods.print(pages);
  };

  $scope.maxLinePowerSuffix = ($scope.uiElementValues.isDigital ? 'average' : 'peak');
  $scope.userEntry = true; //variable for determining whether ensureWithinRating should flash warning modal
  $scope.showExceededWarning = false;
  
  $scope.setToMax = function(initiator) { //function for Optimize button
    var BLvalues = $scope.businessLogicValues;
    var efficiency = BLvalues.efficiency / 100;
    var maxLinePower = BLvalues.maxLinePower;
    var antennaPower = BLvalues.maxAvgPower;
    var TEvalues = $scope.tabElementValues;
    var reqLinePower = antennaPower/efficiency;
    $scope.userEntry = (initiator == 'user' || initiator == 'suggestion') ? true : false; 
    
    if (!$scope.userEntry) {
      if ((reqLinePower >= maxLinePower) || !TEvalues.lineType) {
        $scope.businessLogicValues.efficiency = efficiency * 100;
        $scope.setLineType(reqLinePower, 1);
      }
      if ((((maxLinePower -10) > reqLinePower) || (reqLinePower >= maxLinePower) 
        || !TEvalues.lineSize) && initiator != 'suggestion') {
        $scope.businessLogicValues.efficiency = efficiency * 100;
        return $scope.tlSizePicker($scope.tabElementValues.lineType);
      }
    }
    $scope.selectedTransmissionLine =
      TransmissionLineService.data[TEvalues.lineType][TEvalues.lineSize];
    
    if (!TEvalues.lineLength && !$scope.userEntry) TEvalues.lineLength = 1;

    if (! maxLinePower) {
      maxLinePower = BusinessLogicService.update($scope, 'maxLinePower');
    };
    
    var valueName = $scope.tabElementValues.ERPtoTPO ? "ERP" : "TPO";
    var maximumValue = 1; //BLvalues.ratedMaxima[valueName];
    var otherName = valueName == 'TPO' ? 'ERP' : 'TPO';
    var otherValue = 0;
    var maxPower = Math.min(antennaPower, maxLinePower * efficiency);
    var maxTPO = Math.min(maxLinePower, antennaPower / efficiency);   

    if (valueName === 'ERP') {
      maximumValue = maxPower * BLvalues.scaledPeakGain_hpol;
      otherValue = (maximumValue / BLvalues.scaledPeakGain_hpol) / efficiency;
    } else {
      maximumValue = maxTPO;
      otherValue = maximumValue * efficiency * BLvalues.scaledPeakGain_hpol;
    };        
    $scope.tabElementValues[otherName] = Number(otherValue - 0.005).roundDown(1);
    $scope.tabElementValues[valueName] = Number(maximumValue - 0.005).roundDown(1);//.toFixed(2);
    updateControllerBusinessLogic();
    $scope.userEntry = true;
  };
  
  $scope.setLineType = function(reqLinePower, optimize) { //sets the type of transmission line based on maximum power for the antenna
      if (typeof optimize !== 'undefined' && optimize) { 
        var tlType = 'Flexline Foam';
        if (reqLinePower > 30 || $scope.tabElementValues.lineLength > 2000) tlType = 'Transmission Line';
        else if (reqLinePower > 6.5) {
          tlType = 'Flexline Air';
        }
        $scope.tabElementValues.lineType = tlType;
      }
      if (!optimize) optimize = null;  
        $scope.tlSizePicker(tlType, optimize);
  };

  $scope.tlSizePicker = function(lineType, optimize) { //fills out size, length and TPO based on lineType
    var length = $scope.tabElementValues.lineLength ? $scope.tabElementValues.lineLength : 300;
    var antennaPower = $scope.businessLogicValues.maxAvgPower;
    var frequency = $scope.uiElementValues.frequency;
    var tlArray = $scope.tabElementOptions.lineSize;
    var size = "";
    
    for (i = 1; i < Object.keys(tlArray).length; i++) {
      var sizeArray = Object.keys(tlArray).map(function (key) {
        return tlArray[key];
      });
      size = sizeArray[i];
      var tl = TransmissionLineService.data[lineType][size];
      var linePressure = tl.linePressure ? tl.linePressure : 0; 
      var linePower = CalculationService.calc.maxAvgPower(length, frequency, linePressure, tl, lineType);
      var efficiency = CalculationService.calc.efficiency(length, frequency, tl, lineType);
      if ((linePower * efficiency) > (antennaPower + 3)) {
        break;
       }
    };
    $scope.tabElementValues.lineSize = size;
    if (optimize !== null && !$scope.userEntry) {
      $scope.tabElementValues.lineLength = length;
      $scope.tabElementValues.TPO = ((antennaPower / efficiency) - 0.005)/*.roundDown(1)*/;
    }
  };

  BusinessLogicService.add({
    relative: {
      dependencies: function($scope) {
        return $scope.tabElementValues;
      },
      update: function($scope) {
        return $scope.tabElementValues.relative;
      }
    },
    attenuation: {
      dependencies: function($scope) {
        return ($scope.tabElementValues)
          && ($scope.tabElementValues.lineLength !== null)
          && ($scope.uiElementValues.frequency !== null)
          && ($scope.tabElementValues.lineType !== null)
          && ($scope.tabElementValues.lineSize !== null)
          && ($scope.selectedTransmissionLine);
      },
      update: function($scope) {
        var length = $scope.tabElementValues.lineLength;
        var frequency = $scope.uiElementValues.frequency;
        var tl = $scope.selectedTransmissionLine;
        var tlType = $scope.tabElementValues.lineType;
        return CalculationService.attenuation(length, frequency, tl, tlType);
      }
    },
    efficiency: {
      dependencies: function($scope) {
        return ($scope.tabElementValues)
          && ($scope.tabElementValues.lineLength !== null)
          && ($scope.uiElementValues.frequency !== null)
          && ($scope.tabElementValues.lineType !== null)
          && ($scope.selectedTransmissionLine);
      },
      update: function($scope) {
        var length = $scope.tabElementValues.lineLength;
        var frequency = $scope.uiElementValues.frequency;
        var tl = $scope.selectedTransmissionLine;
        var tlType = $scope.tabElementValues.lineType;
        return CalculationService.efficiency(length, frequency, tl, tlType) * 100;
      }
    },
    inputPower: {
      dependencies: function($scope) {
        return ($scope.tabElementValues)
          && ((!$scope.tabElementValues.ERPtoTPO && $scope.tabElementValues.TPO)
              || (($scope.tabElementValues.ERPtoTPO) && $scope.tabElementValues.ERP))
          && $scope.businessLogicValues.gain
          && ($scope.uiElementValues.frequency !== null)
          && ($scope.tabElementValues.lineLength !== null)
          && ($scope.tabElementValues.lineType !== null)
          && ($scope.selectedTransmissionLine);
      },
      update: function($scope) {
        var TPO = $scope.tabElementValues.TPO;
        var ERP = $scope.tabElementValues.ERP;
        var ERPtoTPO = $scope.tabElementValues.ERPtoTPO;
        var gain = $scope.businessLogicValues.gain;
        var frequency = $scope.uiElementValues.frequency;
        var length = $scope.tabElementValues.lineLength;
        var tlType = $scope.tabElementValues.lineType;
        var tl = $scope.selectedTransmissionLine;
        var efficiency = CalculationService.efficiency(length, frequency, tl, tlType);

        if (ERPtoTPO) {
          return ERP / (gain * $scope.businessLogicValues.percentPower_hpol);
        } else {
          return TPO * efficiency;
        };
      }
    },
    maxLinePower: {
      dependencies: function($scope) {
        return ($scope.tabElementValues)
          && ($scope.tabElementValues.lineLength !== null)
          && ($scope.selectedTransmissionLine)
          && ($scope.tabElementValues.deratingFactor !== null)
          && ($scope.tabElementValues.lineType !== null)
          && (BusinessLogicService.get('signalType', $scope));
      },
      update: function($scope) {
        var length = $scope.tabElementValues.lineLength;
        var frequency = $scope.uiElementValues.frequency;
        var tl = $scope.selectedTransmissionLine;
        var psig = tl.LinePressure;
        var psig0 = 0;  //request by Dielectric feature/#211 (9/23/16)
        var signalType = BusinessLogicService.get('signalType', $scope);
        var tlType = $scope.tabElementValues.lineType;
        var derate = $scope.tabElementValues.deratingFactor;

        switch (signalType) {
        case 'digital-tv':
          return CalculationService.maxDTVPower(length, frequency, psig0, tl, tlType, derate);
        case 'analog-tv':
          return CalculationService.peakTVPower(length, frequency, psig0, tl, tlType, derate);
        case 'fm':
          return CalculationService.maxAvgPower(length, frequency, psig0, tl, tlType);
        default:
          throw new Error('Bogus signal type', signalType);
        };
      }
    },
    asymmetricalLoss_hpol: {
      dependencies: function($scope) {
        return $scope.businessLogicValues.gain;
      },
      update: function($scope) {
        var gain = $scope.businessLogicValues.gain;
        return CalculationService.asymmetricalLoss_hpol(gain);
      }
    },
    asymmetricalLoss_vpol: {
      dependencies: function($scope) {
        return $scope.businessLogicValues.gain_vpol
          && $scope.detailElementValues
          && $scope.businessLogicValues.PeakVpol;
      },
      update: function($scope) {
        var stem = $scope.businessLogicValues;
        var gain = stem.gain_vpol;  
        var vpolFactor = Math.sqrt(stem.ERP_Vpol_percentage / stem.ERP_Hpol_percentage);
        return CalculationService.asymmetricalLoss_vpol(gain, vpolFactor);
      }
    },
    percentPower_hpol: {
      dependencies: function($scope) {
        return $scope.detailElementValues
          && BusinessLogicService.update($scope, 'asymmetricalLoss_hpol');
      },
      update: function($scope) {
        var loss_hpol = BusinessLogicService.update($scope, 'asymmetricalLoss_hpol');
        var loss_vpol = $scope.detailElementValues.useVpolCheck
              ? BusinessLogicService.update($scope, 'asymmetricalLoss_vpol')
              : 0;
        return CalculationService.percentPower_hpol(loss_hpol, loss_vpol);
      }
    },
    percentPower_vpol: {
      dependencies: function($scope) {
        return BusinessLogicService.update($scope, 'asymmetricalLoss_hpol')
          && $scope.detailElementValues
          && $scope.detailElementValues.useVpolCheck;
      },
      update: function($scope) {
        var loss_hpol = BusinessLogicService.update($scope, 'asymmetricalLoss_hpol');
        var loss_vpol = BusinessLogicService.update($scope, 'asymmetricalLoss_vpol');
        return CalculationService.percentPower_vpol(loss_hpol, loss_vpol);
      }
    },
    scaledPeakGain_hpol: {
      dependencies: function($scope) {
        return BusinessLogicService.update($scope, 'percentPower_hpol')
        && $scope.businessLogicValues.gain;
      },
      update: function($scope) {
        var percentPower = BusinessLogicService.update($scope, 'percentPower_hpol');
        return $scope.businessLogicValues.gain * percentPower;
      }
    },
    scaledPeakGain_vpol: {
      dependencies: function($scope) {
        return BusinessLogicService.update($scope, 'percentPower_vpol')
        && $scope.businessLogicValues.gain_vpol;
      },
      update: function($scope) {
        var percentPower = BusinessLogicService.update($scope, 'percentPower_vpol');
        return $scope.businessLogicValues.gain_vpol * percentPower;
      }
    },
    ERPfromTPO: {
      dependencies: function($scope) {
        return ($scope.tabElementValues)
          && ($scope.tabElementValues.ERPtoTPO === false)
          && ($scope.tabElementValues.TPO !== null)
          && ($scope.tabElementValues.lineLength !== null)
          && ($scope.uiElementValues.frequency !== null)
          && ($scope.tabElementValues.lineType !== null)
          && ($scope.selectedTransmissionLine)
          && ($scope.businessLogicValues.gain);
      },
      update: function($scope) {
        var tpo = $scope.tabElementValues.TPO;
        var length = $scope.tabElementValues.lineLength;
        var frequency = $scope.uiElementValues.frequency;
        var tlType = $scope.tabElementValues.lineType;
        var percentPower = BusinessLogicService.update($scope, 'percentPower_hpol');
        var gain = $scope.businessLogicValues.gain * percentPower;
        var tl = $scope.selectedTransmissionLine;
        var psig = tl.LinePressure;
        var psig0 = 0;  //request by Dielectric feature/#211 (9/23/16)
        return Math.round(CalculationService.ERPfromTPO(tpo, gain, length, frequency, tl, tlType, psig0) * 100) / 100;
      }
    },
    ERPfromTPO_vpol: {
      dependencies: function($scope) {
        return ($scope.detailElementValues)
          && ($scope.detailElementValues.useVpolCheck)
          && ($scope.tabElementValues)
          && ($scope.tabElementValues.TPO !== null)
          && ($scope.tabElementValues.lineLength !== null)
          && ($scope.uiElementValues.frequency !== null)
          && ($scope.tabElementValues.lineType !== null)
          && ($scope.selectedTransmissionLine)
          && ($scope.businessLogicValues.gain_vpol);
      },
      update: function($scope) {
        var tpo = $scope.tabElementValues.TPO;
        var length = $scope.tabElementValues.lineLength;
        var frequency = $scope.uiElementValues.frequency;
        var tlType = $scope.tabElementValues.lineType;
        var percentPower = BusinessLogicService.update($scope, 'percentPower_vpol');
        var gain = $scope.businessLogicValues.gain_vpol * percentPower;
        var tl = $scope.selectedTransmissionLine;
        var psig = tl.LinePressure;
        var psig0 = 0;  //request by Dielectric feature/#211 (9/23/16)
        return Math.round(CalculationService.ERPfromTPO(tpo, gain, length, frequency, tl, tlType, psig0) * 100) / 100;
      }
    },
    PeakVpol: {
      dependencies: function($scope) {
        return $scope.businessLogicValues.ERP_Vpol_percentage;
      },
      update: function($scope) {
        var stem = $scope.businessLogicValues;
        var hpol = stem.ERP_Hpol_percentage || 100;
        return Math.pow((stem.ERP_Vpol_percentage / hpol), 0.5) * 100;
      }
    },
    ERP_Hpol_percentage: {
      dependencies: function($scope) {
        return ($scope.businessLogicValues.ERP_Vpol_percentage) 
        && typeof($scope.businessLogicValues.relative) !== 'undefined';
      },
      update: function($scope) {
        // Need to update based on some tabElementValue (I think) to represent absolute vs relative.  If absolute 100 - vpol, otherwise, 100
        var relative = $scope.businessLogicValues.relative;
        var result = relative == true ? 100 : (100 - $scope.businessLogicValues.ERP_Vpol_percentage);
        return result;
      }
    },

    TPOfromERP: {
      dependencies: function($scope) {
        return ($scope.tabElementValues)
          && ($scope.tabElementValues.ERPtoTPO === true)
          && ($scope.tabElementValues.ERP !== null)
          && ($scope.tabElementValues.lineLength !== null)
          && ($scope.uiElementValues.frequency !== null)
          && ($scope.tabElementValues.lineType !== null)
          && ($scope.selectedTransmissionLine)
          && ($scope.businessLogicValues.gain);
      },
      update: function($scope) {
        var erp = $scope.tabElementValues.ERP;
        var percentPower = BusinessLogicService.update($scope, 'percentPower_hpol');
        var length = $scope.tabElementValues.lineLength;
        var frequency = $scope.uiElementValues.frequency;
        var tlType = $scope.tabElementValues.lineType;
        var gain = $scope.businessLogicValues.gain * percentPower;
        var tl = $scope.selectedTransmissionLine;
        var psig = tl.LinePressure;
        var psig0 = 0;  //request by Dielectric feature/#211 (9/23/16)
        return Math.round(CalculationService.TPOfromERP(erp, gain, length, frequency, tl, tlType, psig0) * 100) / 100;
      }
    },
    linePressure: {
      dependencies: function($scope) {
        return ($scope.selectedTransmissionLine);
      },
      update: function($scope) {
        return $scope.selectedTransmissionLine.LinePressure || 0; 
      }
    },
    innerConductorTemperature: {
      dependencies: function($scope) {
        return ($scope.selectedTransmissionLine);
      },
      update: function($scope) {
        return $scope.selectedTransmissionLine.Temperature || 100; 
      }
    },
    ratedMaxima: {
      dependencies: function($scope) {
        return $scope.tabElementValues
          && BusinessLogicService.update($scope, 'maxLinePower')
          && $scope.businessLogicValues.maxAvgPower
          && $scope.businessLogicValues.maxERP
          && $scope.tabElementValues.lineType
          && $scope.tabElementValues.lineLength
          && $scope.uiElementValues.frequency
          && $scope.selectedTransmissionLine;
      },
      update: function($scope) {
        var length = $scope.tabElementValues.lineLength;
        var frequency = $scope.uiElementValues.frequency;
        var tl = $scope.selectedTransmissionLine;
        var tlType = $scope.tabElementValues.lineType;
        var efficiency = CalculationService
              .efficiency(length, frequency, tl, tlType);

        var maxInputPower = $scope.businessLogicValues.maxAvgPower;
        var maxLinePower = BusinessLogicService.update($scope, 'maxLinePower');
        var TPOforMaxInputPower = maxInputPower / efficiency;
        var inputPowerForMaxTPO = maxLinePower * efficiency;
        var maxTPO;

        if (inputPowerForMaxTPO > maxInputPower) {
          maxTPO = TPOforMaxInputPower;
        } else {
          maxTPO = maxLinePower;
        };

        var gain = $scope.businessLogicValues.gain;
        var psig = $scope.selectedTransmissionLine.LinePressure;
        var psig0 = 0;  //request by Dielectric feature/#211 (9/23/16)
        var maxERPForMaxTPO = CalculationService.ERPfromTPO(maxTPO, gain, length, frequency, tl, tlType, psig0);
        var maxERPForAntenna = $scope.businessLogicValues.maxERP;
        var maxERP = Math.min(maxERPForMaxTPO, maxERPForAntenna);

        return {
          TPO: maxTPO,
          ERP: maxERP
        };
      }
    }
  });

  function setTheoreticalMaxima() {
    if ($scope.detailElementValues.useVpolCheck) {
      setTheoreticalMaxima_withVpol();
    } else {
      setTheoreticalMaxima_hpolOnly();
    };
  };

  function setTheoreticalMaxima_withVpol() {
    var efficiency = 1;
    var inputPower = $scope.businessLogicValues.maxAvgPower;
    var relative = $scope.businessLogicValues.relative;
    var vpolScaleFactorOld = $scope.detailElementValues.ERPVpolSplit ? Math
          .pow(($scope.detailElementValues.ERPVpolSplit / 100), 0.5) : Math.pow(0.50, 0.5);
    var vpolScaleFactor = Math.sqrt($scope.businessLogicValues.ERP_Vpol_percentage 
          / $scope.businessLogicValues.ERP_Hpol_percentage) || 1;
    var gain = {
      h: $scope.businessLogicValues.gain,
      v: $scope.businessLogicValues.gain_vpol
    };
    
    var loss = {
      h: CalculationService.asymmetricalLoss_hpol(gain.h),
      v: CalculationService.asymmetricalLoss_vpol(gain.v, vpolScaleFactor)
    };
    var percentPower = {
      h: CalculationService.percentPower_hpol(loss.h, loss.v),
      v: CalculationService.percentPower_vpol(loss.h, loss.v)
    };
    var ERP = {
      h: (gain.h * percentPower.h) * inputPower,
      v: (gain.v * percentPower.v) * inputPower
    };
    var ERP_percent = {
      h: relative ? 100 : (100 - $scope.detailElementValues.ERPVpolSplit),
      v: $scope.detailElementValues.ERPVpolSplit
    };
    var TPO = inputPower * (gain.h * percentPower.h);
    $scope.businessLogicValues.TPOfromERP = TPO;
    $scope.businessLogicValues.ERPfromTPO = ERP.h;
    $scope.businessLogicValues.ERPfromTPO_vpol = ERP.v;
    $scope.businessLogicValues.scaledPeakGain_hpol = gain.h * percentPower.h;
    $scope.businessLogicValues.scaledPeakGain_vpol = gain.v * percentPower.v;
    $scope.businessLogicValues.ERP_Hpol_percentage = ERP_percent.h;
    $scope.businessLogicValues.ERP_Vpol_percentage = ERP_percent.v;
    $scope.businessLogicValues.inputPower = $scope.businessLogicValues.maxAvgPower;
    $scope.businessLogicValues.efficiency = 100;
    $scope.businessLogicValues.attenuation = 0;
  };
  
  function setTheoreticalMaxima_hpolOnly() {
    var efficiency = 1;
    var inputPower = $scope.businessLogicValues.maxAvgPower;
    var gain = $scope.businessLogicValues.gain;
    var ERP = gain * inputPower;
    var TPO = inputPower;

    $scope.businessLogicValues.TPOfromERP = TPO;
    $scope.businessLogicValues.ERPfromTPO = ERP;
    $scope.businessLogicValues.inputPower = $scope.businessLogicValues.maxAvgPower;
    $scope.businessLogicValues.efficiency = 100;
    $scope.businessLogicValues.attenuation = 0;
  };

  function updateControllerBusinessLogic() {
    BusinessLogicService.updateAll($scope);
    $scope.businessLogicValues = BusinessLogicService.getAll();
    if ($scope.tabElementValues.ERPtoTPO) {
      $scope.tabElementValues.TPO = $scope.businessLogicValues.TPOfromERP ? $scope.businessLogicValues.TPOfromERP/*.roundDown(1)*/ : 0;
    } else {
      $scope.tabElementValues.ERP = $scope.businessLogicValues.ERPfromTPO;
    };

    ['TPO', 'ERP'].forEach(function(field) {
      if (typeof $scope.tabElementValues[field] === 'number') {
        $scope.tabElementValues[field] = $scope.tabElementValues[field].toFixed(5);
      };                                                              ///////This^ Fixed IT puting a 5 there!!!!!!!!
    });

    if (!$scope.selectedTransmissionLine || !$scope.tabElementValues.lineLength) {
      setTheoreticalMaxima();
    };
  };

  function clearBusinessLogic() {
    $scope.businessLogicValues.attenuation = null;
    $scope.businessLogicValues.efficiency = null;
    $scope.businessLogicValues.maxLinePower = null;
    $scope.businessLogicValues.innerConductorTemperature = null;
    $scope.businessLogicValues.linePressure = null;
    $scope.tabElementValues.TPO = null;
    $scope.tabElementValues.ERP = null;
    if ($scope.tabElementValues.ERPtoTPO) {
      $scope.businessLogicValues.inputPower = null;
    };
    setTheoreticalMaxima();
  };

  function setDefaultDeratingFactor() {
    $scope.tabElementValues.deratingFactor = ($scope.uiElementValues.isDigital ? 0.9 : 1.0);
  };

  $scope.exceeded = {};

  function checkWithinRating(valueName, timer) {
    var configuredValue = $scope.tabElementValues[valueName];
    var ratedMaximumValue = $scope.businessLogicValues.ratedMaxima[valueName];
    var exceededRating;
    if (parseFloat(parseFloat(configuredValue).toFixed(2)) > parseFloat(parseFloat(ratedMaximumValue).toFixed(2))) {
      //$scope.setToMax('user');
      // $scope.tabElementValues[valueName] = (ratedMaximumValue - 0.005).toFixed(2); //to prevent the warning message from repeatedly showing up on toggle (ERPtoTPO)
      //if ($scope.userEntry) {  
        if (valueName === 'TPO' && configuredValue > $scope.businessLogicValues.maxLinePower) {
          exceededRating = 'transmission line power';
        } else {
          exceededRating = 'antenna input power';
        };
        $scope.showExceededWarning = true;
        $scope.exceeded = {
          article: (valueName === 'ERP' ? 'An' : 'A'),
          name: valueName,
          value: configuredValue,
          rating: exceededRating
        };
        console.log($scope.exceeded);
        // jQuery('#clampWarningModal').modal('show');
        // jQuery('#clampWarningModal').on('hidden.bs.modal', function(event) {
        //   $scope.exceeded = {};
        //   event.stopPropagation(); // so we don't also close the antenna modal
        // });
      //};

      // Add a timer to update the UI when user is
      // typing too quickly
      if (!timer) {
        window.setTimeout(function() {
          checkWithinRating(valueName, 250);
          $scope.$digest();
        }, timer);
      }
    }
    else {
      $scope.showExceededWarning = false;
    };
  };

  $scope.ensureWithinRating = function() {
    $scope.setToMax();
    jQuery('#clampWarningModal').modal('hide');
  }

  $scope.showInfo = false;

  $scope.getInfo = function() {
    if($scope.showInfo == true){
      $scope.showInfo = false;
      document.getElementById('summaryInfoButton').style.color = '#ff9933';
      document.getElementById('summaryInfoButton').title = $filter('translate')('Click for details about this section');
    }else if($scope.showInfo == false){
      $scope.showInfo = true;
      document.getElementById('summaryInfoButton').style.color = '#bf0d3e';
      document.getElementById('summaryInfoButton').title = $filter('translate')('Click to close Help window');
    }
  };

  $scope.hideClampWarningModal = function() {
    jQuery('#clampWarningModal').modal('hide');
  };

  $scope.$watch(
    function() {
      return $scope.tabElementValues;
    },
    function(newValue, oldValue) {
      var oldJson = JSON.stringify(oldValue);
      var newJson = JSON.stringify(newValue);
      if (oldJson == newJson) {
        return;
      }
      var newlySet = [];
      Object.keys(oldValue).forEach(function(key) {
        if (newValue[key] !== oldValue[key]) {
          newlySet.push(key);
        };
      });

      if (newlySet.length === 0) return;

      if (newlySet.indexOf('lineType') === -1 && newlySet.indexOf('lineSize') === -1) {
        updateControllerBusinessLogic();
      };
      
      if ($scope.businessLogicValues.ratedMaxima && 
            (newlySet.indexOf('lineLength') !== -1
              || (newlySet.indexOf('lineSize') !== -1 && $scope.tabElementValues.lineLength))) {
        if ($scope.tabElementValues.TPO) {
          checkWithinRating('TPO');
        }
        else {
          $scope.setToMax('user');
        }
      };

      if ($scope.businessLogicValues.ratedMaxima && newlySet.indexOf('ERP') !== -1) {
        checkWithinRating('TPO');
      };
      
      if ($scope.businessLogicValues.ratedMaxima && newlySet.indexOf('TPO') !== -1) {
        checkWithinRating('ERP');
      };
    }, true);

  $scope.$watch(
    function() {
      return $scope.detailElementValues.ERPVpolSplit;
    },
    function(newValue, oldValue) {
      if (newValue == oldValue) {
        return;
      }
      $scope.businessLogicValues.ERP_Vpol_percentage = newValue;
      updateControllerBusinessLogic();
    }
  );


  $scope.$watch(
    function() {
      return $scope.tabElementValues.relative;
    },
    function(newValue, oldValue) {
      if (newValue == oldValue) {
        return;
      }
    //some reason I have to update the logic before grabbing the data, but then it works.  Better way of handling?
    updateControllerBusinessLogic();    
    var stem = $scope.businessLogicValues;
    var vpolScaleFactor = Math.sqrt(stem.ERP_Vpol_percentage 
          / stem.ERP_Hpol_percentage);  
    var inputPower = stem.inputPower;
    var gain = {
      h: stem.gain,
      v: stem.gain_vpol
    };
    var loss = {
      h: CalculationService.asymmetricalLoss_hpol(gain.h),
      v: CalculationService.asymmetricalLoss_vpol(gain.v, vpolScaleFactor)
    };
    var percentPower = {
      h: CalculationService.percentPower_hpol(loss.h, loss.v),
      v: CalculationService.percentPower_vpol(loss.h, loss.v)
    };
    var ERP = {
      h: (gain.h * percentPower.h) * inputPower,
      v: (gain.v * percentPower.v) * inputPower
    };
    stem.scaledPeakGain_hpol = gain.h * percentPower.h;
    stem.scaledPeakGain_vpol = gain.v * percentPower.v;
    stem.ERPfromTPO = ERP.h;
    stem.ERPfromTPO_vpol = ERP.v;
    }
  );
  
  $scope.$watch(
    function() {
      return $scope.businessLogicValues.maxAvgPower;
    },
    function(newValue, oldValue) {
      if (newValue == oldValue) {
        return;
      }
      $scope.setLineType(newValue, 1);
    });
  
  $scope.$watch(
    function() {
      return $scope.uiElementValues.showRMSGain;
    },
    function(newValue, oldValue) {
      updateControllerBusinessLogic();
      
      if ($scope.businessLogicValues.ratedMaxima) {
        // $scope.setToMax();
        if ($scope.tabElementValues.ERPtoTPO) {          
          checkWithinRating('ERP');
          // ensureWithinRating('TPO');
        };
      };
    });

  $scope.$watch(
    function() {
      return $scope.tabElementValues.lineType;
    },
    function(newValue, oldValue) {
      if (newValue == oldValue) {
        return;
      }
      TransmissionLineService.getLineSizesFor(newValue)
        .then(function(result) {
          var TEvalues = $scope.tabElementValues;
          $scope.tabElementOptions.lineSize = [null].concat(result).unique();

          if (!$scope.userEntry) { //variable tracking whether user input to a field triggered ensureWithinRating 
            $scope.tlSizePicker(TEvalues.lineType, 1);
          } else if ($scope.tabElementOptions.lineSize.indexOf(TEvalues.lineSize) === -1) {            
            TEvalues.lineSize = null;
            $scope.selectedTransmissionLine = null;
          };
        })
        .catch(function(err) {
          console.error(err);
        });
    });

  $scope.$watch(
    function() {
      return $scope.tabElementValues.lineSize;
    },
    function(newValue, oldValue) {
      if (oldValue == newValue) {
        return;
      }

      if (oldValue && !newValue) {
        clearBusinessLogic();
      };

      if (!newValue) {
        $scope.selectedTransmissionLine = null;
      } else {
        $scope.selectedTransmissionLine =
          $.extend({}, TransmissionLineService.data[$scope.tabElementValues.lineType][newValue]);
        updateControllerBusinessLogic();
      }
    }
  );

  TransmissionLineService.hasData
    .then(function() {
      TransmissionLineService.getLineTypes()
        .then(function(result) {
          $scope.tabElementOptions.lineType = [null].concat(result);
        })
        .catch(function(err) {
          console.error(err);
        });
    })
    .catch(function(err) {
      console.error(err);
    });

  setDefaultDeratingFactor();
  updateControllerBusinessLogic();
  setTheoreticalMaxima();
};
