module.exports = function() {
  var self = this;

  self.parseCache = {};

  self.parse = function(data, extension) {
    var cacheKey = data.split(/\n/)[1];

    if (self.parseCache[cacheKey]) {
      return self.parseCache[cacheKey];
    } else {
      var parsed = self.parsers[extension](data.split(/\n/));
      self.parseCache[cacheKey] = parsed;
      return parsed;
    };
  };

  self.parsers = {};
  
  self.parsers.az = function(lines) {
    var match;
    var result = {
      drawingNumber: null,
      gain: null,
      data: []
    };

    match = lines[1].match(/Gain:,\s*([\d.]+)\s*,/);
    if (match) {
      result.gain = parseFloat(match[1]);
    } else {
      throw new Error('Invalid azimuth file: missing gain');
    };

    match = lines[1].match(/Drawing\s*#: (.*?)\s*\,/);
    if (match) {
      result.drawingNumber = match[1];
    } else {
      throw new Error('Invalid azimuth file: missing drawing number');
    };

    lines.slice(2).forEach(function(line) {
      if (! line.match(/^\s*$/)) {
        var parts = line
              .replace(/^\s*/, '')            // trim leading whitespace
              .replace(/\s*$/, '')            // trim trailing whitespace
              .split(/(?:\s+|\,)/)            // split by comma or whitespace
              .filter(function(part) {
                return part !== '';           // filter empty parts
              })
              .map(function(part) {           // from each part:
                return part
                  .replace(/^\s*\,?\s*/, '')  // trim leading space + comma
                  .replace(/\s*\,?\s*$/, ''); // trim trailing space + comma
              });
        var degree = parts[0];
        var relative = parts[1];
        var decibel = parts[2];

        result.data[degree] = {
          relative: parseFloat(relative) * 100,
          decibel: parseFloat(decibel)
        };
      };
    });

    if (result.data.length < 360) {
      result.data = self.interpolateData(result.data);
    };

    return result;
  };
  
  self.parsers['v.az'] = self.parsers.az,
  
  self.parsers.el = function(lines) {
    var match;
    var result = {
      antennaType: null,
      drawingNumber: null,
      gain: null,
      horizontalGain: null,
      mainLobeGain: null,
      beamTilt: null,
      minimumAngle: null,
      data: {}
    };

    match = lines[0].match(/Elevation Pattern for,\s*([^\)]+)$/);
    if (match) {
      result.antennaType = match[1];
    } else {
      throw new Error('Invalid elevation file: missing antenna type');
    };

    match = lines[1].match(/Gain:\,\s*([\d.]+)\s*\,/);
    if (match) {
      result.gain = parseFloat(match[1]);
    } else {
      throw new Error('Invalid elevation file: missing gain');
    };

    match = lines[1].match(/Drawing Number:\,\s*(.+?)\s*\,/);
    if (match) {
      result.drawingNumber = match[1];
    } else {
      throw new Error('Invalid elevation file: missing drawing number');
    };

    match = lines[1].match(/Beam Tilt:\,\s*([\d.]+)\s*\,/);
    if (match) {
      result.beamTilt = parseFloat(match[1]);
    } else {
      throw new Error('Invalid elevation file: missing beam tilt');
    };

    lines.slice(3).forEach(function(line) {
      if (! line.match(/^\s*$/)) {
        var parts = line
              .replace(/^\s*/, '')
              .replace(/\s*$/, '')
              .split(/\s*\,?\s+/);
        var angle = parts[0];
        var gain = parts[1];

        result.data[parseFloat(angle).toFixed(3).toString()] = {
          relative: parseFloat(gain) * 100
        };
      };
    });

    result.minimumAngle = Object.keys(result.data)
      .map(function(key) {
        return parseFloat(key);
      })
      .sort()[0];

    result.mainLobeGain = result.gain; // axiomatic
    result.horizontalGain = result.gain * Math.pow((result.data['0.000'].relative / 100), 2);

    return result;
  };
  
  self.interpolateData = function(dataset) {
    var i = 0;
    var iterations = 0;
    var lastDataPoint = null;
    var nextDataPoint = null;
    var curveAmt = 5;
    while (i < 360 && iterations < 360) {
      if (typeof dataset[i] === 'undefined') {
        var v1 = dataset[lastDataPoint].relative;
        var v2 = null;
        var next = i + 1;
        while ((v2 == null || typeof v2 == 'undefined') && next <= 359) {
          nextDataPoint = next;
          v2 = (dataset[next] || {}).relative;
          next++;
          if (next == 360) {
            next = 0;
            nextDataPoint = 0;
            break;
          }

        }

        while (i < nextDataPoint) {
          var pointDiff = nextDataPoint - lastDataPoint;
          var valueDiff = dataset[nextDataPoint].relative - dataset[lastDataPoint].relative;
          var increment = valueDiff / pointDiff;
          var position = i - lastDataPoint;
          var curvePosition = curveAmt / (pointDiff / 2);
          dataset[i] = {
            relative: dataset[lastDataPoint].relative + (increment * position)
          };
          
          i++;
        }
      }
      else {
        lastDataPoint = i;
        i++;
      }
      
      iterations++;
    }

    return dataset;
  };
};
