module.exports = function(Array) {
  if (typeof Array.prototype.unique === 'undefined') {
    Object.defineProperty(Array.prototype, 'unique', {
      configurable: false,
      enumerable: false,
      value: function() {
        var known = [];
        this.forEach(function(element) {
          if (known.indexOf(element) === -1) {
            known.push(element);
          };
        });
        return known;
      }
    });
  };

  if (typeof Array.range === 'undefined') {
    Object.defineProperty(Array, 'range', {
      configurable: false,
      enumerable: false,
      value: function(m, n) {
        var result = [];
        for (var i = m; i <= n; i++) {
          result.push(i);
        };
        return result;
      }
    });
  };

  if (typeof Array.prototype.diff !== 'function') {
    Object.defineProperty(Array.prototype, 'diff', {
      configurable: false,
      enumerable: false,
      value: function(arr2) {
        var ret = [];
        this.sort();
        arr2.sort();
        for(var i = 0; i < this.length; i += 1) {
          if(arr2.indexOf( this[i] ) > -1){
            ret.push( this[i] );
          }
        }
        return ret;
      }
    });
  };


  Number.prototype.roundDown = function(decimals) {
    var num = this.toString();
    if (num.indexOf('.') === -1 && decimals > 0) {
      num += '.';
    }
    // Pad the number with zeros
    for (var i = 0; i < decimals; i++) { num += '0'; }

    num = num.match(new RegExp("^\\d+(?:\\.\\d{" + decimals + "})?"))[0];
    return num;
  }
};
