module.exports = function() {
  return {
    restrict: 'E',
    scope: {
      min: '=min',
      max: '=max',
      step: '=step',
      model: '=ngModel'
    },
    template: [
      '<input class="half-width form-control"',
      '       min=""',
      '       max=""',
      '       step=""',
      '       type="number" />'
    ].join(''),
    link: function(scope, element, attrs) {
      var input = element[0].querySelector('input');
      var min = !isNaN(parseFloat(scope.min)) ? parseFloat(scope.min) : -Math.pow(2, 32);
      var max = !isNaN(parseFloat(scope.max)) ? parseFloat(scope.max) : Math.pow(2, 32);
      var step = !isNaN(parseFloat(scope.step)) ? parseFloat(scope.step) : 1;
      var previousValue = null;

      var updateModel = function(newValue) {
        previousValue = null;
        scope.$apply(function() {
          scope.model = input.value;
        });
      };

      input.setAttribute('min', min);
      input.setAttribute('max', max);
      input.setAttribute('step', step);
      input.setAttribute('value', scope.model);

      scope.$watch( 
        function() {
          return scope.model;
        },
        function(newValue, oldValue) {
          input.setAttribute('value', newValue);
        });

      // Capture original value on first keydown
      input.addEventListener('keydown', function() {
        if (previousValue === null) {
          previousValue = parseFloat(input.value);
        };
      });

      // If new value isn't valid, restore captured original value
      input.addEventListener('change', function() {
        var value = parseFloat(input.value);
        
        if (value > max || value < min) {
          input.value = previousValue;
        };

        updateModel(input.value);
      });

      // Propagate clicks on the up/down scrollers to the model
      input.addEventListener('click', function() {
        updateModel(input.value);
      });
    }
  };
};
