module.exports = function($scope, $filter, FCCOverlayDataService, PDFService, Values, ExportService, patternDataService, CalculationService) {
  $scope.tabElementValues = Values.preserve($scope, 'tabElementValues', {
    patternRotation: 0,
    useVpol: false,
    plotType: 'linear',
    logScale: -25,
    exhibitNumber: null,
    chartColor: 0,
    remarks: ''
  });

  $scope.tabElementProperties = Values.preserve($scope, 'tabElementProperties', {
    useVpol: {
      disabled: true
    }
  });

  $scope.fccValues = Values.preserve($scope, 'fccValues', {
    editMode: null,
    callLetters: null,
    searchResult: [],
    selectedOverlay: {}
  });

  $scope.azimuthDatasets = Values.preserve($scope, 'azimuthDatasets', {
    horizontal: [],
    vertical: []
  });

  $scope.datasets = Values.preserve($scope, 'datasets', [
    { // selected azimuth pattern
      fillColor: 'rgba(191, 13, 62, 0.2)',
      strokeColor: 'rgba(191, 13, 62, 1)',
      data: []
    },
    { // FCC overlay pattern, if any
      fillColor: 'rgba(191, 191, 191, 0.2)',
      strokeColor: 'rgba(191, 191, 191, 1)',
      data: []
    }
  ]);

  $scope.gain = function() {
    if ($scope.tabElementValues.useVpol) {
      return $scope.patternData.azimuthVpol.gain;
    } else {
      return $scope.patternData.azimuth.gain;
    };
  };

  $scope.canPrint = function() {
    return PDFService.canPreparePages(['azimuth']);
  };

  $scope.printButtonTitle = function() {
    return ($scope.canPrint() ? '' : 'Complete this tab to print results.');
  };
  
  $scope.print = function() {
    var pages = PDFService.preparePages(['azimuth']);
    PDFService.scopeMethods.print(pages);
  };

  $scope.export = function($event, type) {
    var link = angular.element($event.currentTarget);
    var result = ExportService.formats[type].azimuth($scope.patternData, $scope);
    if($scope.patternData.azimuth.drawingNumber == ' '){
      result.filename = result.filename.replace(' ', $scope.uiElementValues.azimuthPattern);
    }else if($scope.patternData.elevation.drawingNumber == ' '){
      result.filename = result.filename.replace(' ', $scope.uiElementValues.elevationPattern);
    }

    if (window.navigator && window.navigator.msSaveBlob) {
      window.navigator.msSaveBlob(result.contents, result.filename);
    }
    else {
      link.attr('href', URL.createObjectURL(result.contents));
      link.attr('download', result.filename);
    }
  };

  $scope.fccMethods = {
    search: function() {
      FCCOverlayDataService.search($scope.fccValues.callLetters)
        .then(function(res) {
          $scope.fccValues.searchResult = res;
        })
        .catch(function(err) {
          console.error(err);
        });
    },
    display: function(station, antenna) {
      FCCOverlayDataService.get(antenna.antenna_id)
        .then(function(res) {
          $scope.fccValues.selectedDataset = res.data;
          $scope.fccValues.selectedOverlay = {
            station: station,
            antenna: antenna
          };
          $scope.fccValues.editMode = false;
          updateChart();
        })
        .catch(function(err) {
          console.error(err);
          $scope.fccValues.editMode = false;
        });
    },
    clear: function() {
      $scope.chart.data.datasets[1].data = [];
      $scope.fccValues.selectedDataset = null;
      $scope.fccValues.callLetters = null;
      $scope.fccValues.searchResult = [];
      $scope.fccValues.selectedOverlay = {};
    }
  };

  $scope.chart = {
    data: {
      labels: Array.range(0, 359).map(function(n) {
        return (n % 30 === 0 ? n + '°' : '');
      }),
      datasets: $scope.datasets
    },
    options: {
      animation: false,
      responsive: false,
			angleShowLineOut: true,
      angleLineInterval: 30,
			scaleShowLine: true,
      scaleOverride: true,
      scaleSteps: 10,
      scaleStepWidth: 10,
      scaleStartValue: 0,
			scaleShowLabels: true,
			pointLabelFontSize: 10,
			pointDot: false,
      pointDotRadius: 1,
      pointDotStrokeWidth : 0,
			forceAngle: true,
			showTooltips: false,
      datasetStrokeWidth : 1,
      scaleLabel: generateScaleLabel
    },
    colors: [
      [191, 13, 62, 0.2],
      [33, 91, 168, 0.2],
      [255, 153, 51, 0.2],
      [76, 78, 86, 0.2],
      [28, 131, 0, 0.2]
    ]
  };
  
  var chartScales = {
    relative: {
      scaleSteps: 10,
      scaleStepWidth: 10,
      scaleStartValue: 0
    },
    decibel: {
      scaleSteps: 5,
      scaleStepWidth: 5,
      scaleStartValue: -25
    }
  };

  function generateScaleLabel(data) {
    data.value = parseFloat(data.value);
    var label = data.value.toString();
    
    if ($scope.tabElementValues.plotType === 'logarithmic') {
      label = (data.value > 0 ? '-' : '') + label + ' dB';
    };
    
    return label;
  }

  function scaleVpolPatternData(patternData) {
    var peakScaleFactor = $scope.businessLogicValues.PeakVpol / 100;
    
    var stem = $scope.businessLogicValues;

    var vpolERP = $scope.businessLogicValues.ERPfromTPO_vpol
          || $scope.businessLogicValues.maxERP_vpol;
    var hpolERP = $scope.businessLogicValues.ERPfromTPO
          || $scope.businessLogicValues.maxERP;
    var vpolGain = $scope.businessLogicValues.gain_vpol;
    var hpolGain = $scope.businessLogicValues.gain;
    var rmsScaleFactor = Math.sqrt(vpolERP/hpolERP)
          * Math.sqrt(vpolGain/hpolGain);
    
    var scaleFactor = (($scope.patternRecords.azimuth.Shape === 'Omni'
                        && $scope.uiElementValues.showRMSGain)
                       ? peakScaleFactor
                       : peakScaleFactor);

    if (scaleFactor === 1) {
      return patternData;
    } else {
      return patternData.map(function(item) {
        var relative = item.relative * scaleFactor;
        return {
          relative: relative,
          decibel: 2 * $filter('decibels')(relative/100)
        };
      });
    };
  };

  function applyFCCDataset(dataKey) {
    if ($scope.fccValues.selectedDataset) {
      $scope.chart.data.datasets[1].data = $scope.fccValues.selectedDataset
        .map(function(item) {
          return item[dataKey];
        });
    };
  };

  function updateChart() {
    var patternDataKey;
    var datasetKey;
    if ($scope.tabElementValues.useVpol) {
      patternDataKey = 'azimuthVpol';
      datasetKey = 'vertical';
    } else {
      patternDataKey = 'azimuth';
      datasetKey = 'horizontal';
    };

    var patternData = $scope.patternData[patternDataKey].data;
    var rotation = $scope.tabElementValues.patternRotation || 0;
    var dataKey = ($scope.tabElementValues.plotType === 'linear'
                   ? 'relative'
                   : 'decibel');
    var dataValueMin = ($scope.tabElementValues.plotType === 'linear'
                        ? 0
                        : $scope.tabElementValues.logScale);
    var transformedData = angular.copy(patternData);

    chartScales.decibel.scaleStartValue = parseFloat($scope.tabElementValues.logScale);
    chartScales.decibel.scaleStepWidth =
      Math.abs($scope.tabElementValues.logScale) / chartScales.decibel.scaleSteps;
    angular.extend($scope.chart.options, chartScales[dataKey]);
    
    applyFCCDataset(dataKey);

    $scope.azimuthDatasets[datasetKey].length = 0;

    transformedData = patternDataService.rotatePatternData(transformedData, rotation);
    if ($scope.tabElementValues.useVpol) {
      transformedData = scaleVpolPatternData(transformedData);
    };

    transformedData.forEach(function(item, index) {
      $scope.azimuthDatasets[datasetKey].push(Math.max(item[dataKey], dataValueMin));
    });

    $scope.datasets[0].data = $scope.azimuthDatasets[datasetKey];
  };

  $scope.$watch(
    function() {
      return $scope.businessLogicValues.PeakVpol;
    },
    function(newValue, oldValue) {
      var patternDataKey;
      if ($scope.tabElementValues.useVpol) {
        patternDataKey = 'azimuthVpol';
      } else {
        patternDataKey = 'azimuth';
      };
      $scope.detailElementValues.PeakVpol = $scope.businessLogicValues.PeakVpol;
      if (typeof $scope.patternData[patternDataKey] === 'object') {
        updateChart();
      };
    });

  $scope.$watch(
    function() {
      return $scope.tabElementValues;
    },
    function(newValue, oldValue) {
      var patternDataKey;
      var datasetKey;
      if ($scope.tabElementValues.useVpol) {
        patternDataKey = 'azimuthVpol';
        datasetKey = 'vertical';
      } else {
        patternDataKey = 'azimuth';
        datasetKey = 'horizontal';
      };
      
      if (typeof $scope.patternData[patternDataKey] === 'object') {
        updateChart();
      };
    },
    true);

  $scope.$watch(
    function() {
      return $scope.patternData.azimuthVpol || null;
    },
    function(newValue, oldValue) {
      $scope.tabElementProperties.useVpol.disabled = (newValue === null);
    }
  );

  $scope.$watch(
    function() {
      return $scope.detailElementValues.useVpolCheck;
    },
    function(newValue) {
      $scope.tabElementValues.useVpol = newValue;
      if ($scope.detailElementValues.useVpolCheck) {
        $scope.tabElementProperties.useVpol.disabled = false;
      } else {
        // $scope.tabElementValues.useVpol = false;
        $scope.tabElementProperties.useVpol.disabled = true;
      };

      var patData = $scope.patternData;
      if (typeof patData.elevation !== 'undefined') {
        var tEV = $scope.tabElementValues;
        var dEV = $scope.detailElementValues;
        var uiEV = $scope.uiElementValues;
        var bLV = $scope.businessLogicValues;

        var antennaProperties = {
            withRadome: uiEV.radome,
            design: patData.azimuth.drawingNumber.split(/\-/)[0],
            azType: patData.azimuth.drawingNumber.split(/\-/)[0],
            elType: patData.elevation.antennaType,
            antType: uiEV.antennaType.toUpperCase(),
            antClass: uiEV.antennaClass.toUpperCase(),
            channel: uiEV.channel,
            azimuthPattern: patData.azimuth.drawingNumber,
            elevationPattern: patData.elevation.drawingNumber,
            useVpol: tEV.useVpol
        };

        bLV.antennaType = CalculationService.antennaType.tv(antennaProperties);
      };
    });
  $scope.$watch(
    function() {
      return $scope.detailElementValues.ERPVpolSplit;
    },
    function(newValue, oldValue) {
      var stem = $scope.businessLogicValues;
      var relative = stem.relative;
      var hpol = stem.relative ? 100 : (100 - newValue);
      stem.PeakVpol = Math.pow(newValue / hpol, 0.5) * 100;
      // updateChart();
    });

};
