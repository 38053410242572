module.exports = function($scope, AntennaService, CalculationService, UnloadWarningService, Values) {
  var uiElementDefaults = {
    frequency: null,
    mountType: null,
    antennaType: null,
    feedType: null,
    spacing: null,
    beamTilt: null,
    azimuthShape: null,
    azimuthPattern: null,
    elevationPattern: null,
    isDigital: null
  };
  
  $scope.uiElementValues = Values.expose($scope, 'uiElementValues',
                                         angular.copy(uiElementDefaults));

  UnloadWarningService.register($scope, uiElementDefaults, $scope.uiElementValues);

  window.debug = {
    scope: $scope,
    antennaService: AntennaService
  };

  $scope.uiMasterOptions = {
    mountType: [],
    antennaType: [],
    feedType: [],
    azimuthShape: []
  };

  $scope.uiElementOptions = {
    mountType: [],
    antennaType: [],
    feedType: [],
    azimuthShape: [],
    spacing: [],
    beamTilt: []
  };

  $scope.unfilter = function() {
    angular.extend($scope.uiElementValues, uiElementDefaults);
  };

  $scope.canUnfilter = false;

  AntennaService.ready.then(function() {
    Object.keys($scope.uiMasterOptions)
      .forEach(function(key) {
        AntennaService.get[key]({})
          .then(function(result) {
            $scope.uiMasterOptions[key] = result;
          });
      });
  });

  var refilter = function(key) {
    AntennaService.get[key]($scope.uiElementValues)
      .then(function(result) {
        $scope.uiElementOptions[key] = result;
        if (result.indexOf($scope.uiElementValues[key]) === -1) {
          $scope.uiElementValues[key] = null;
        };
      });
  };

  $scope.$watch(
    function() {
      return $scope.uiElementValues;
    },
    function(newValue, oldValue) {
      var canUnfilter = false;
      var newlySet = [];
      Object.keys(oldValue).forEach(function(key) {
        if (newValue[key] !== uiElementDefaults[key]) {
          canUnfilter = true;
        };
        
        if (newValue[key] !== null && newValue[key] !== oldValue[key]) {
          newlySet.push(key);
        };
      });

      if ($scope.canUnfilter !== canUnfilter) {
        $scope.canUnfilter = canUnfilter;
      };

      refilter('azimuthShape');
      refilter('feedType');

      if (newlySet.indexOf('mountType') === -1) {
        refilter('mountType');
      };

      if (newlySet.indexOf('spacing') === -1) {
        refilter('spacing');
      };

      if (newlySet.indexOf('antennaType') === -1 && newlySet.indexOf('elevationPattern') === -1) {
        refilter('antennaType');
      };

      if (newlySet.indexOf('spacing') === -1 && newlySet.indexOf('elevationPattern') === -1) {
        refilter('spacing');
      }

      if (newlySet.indexOf('beamTilt') === -1 && newlySet.indexOf('elevationPattern') === -1) {
        refilter('beamTilt');
      }
    }, true);
};
