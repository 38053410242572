function log10(value) {
  return 10 * (Math.log(parseFloat(value)) / Math.log(10));
};

module.exports = function(input) {
  var dbValue;

  if (input && !isNaN(parseFloat(input))) {
    dbValue = log10(input);
    return dbValue;
  } else {
    return input;
  };
};
