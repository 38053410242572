module.exports = function(ENVIRONMENT, $timeout, $scope, $rootScope, $translate, UnloadWarningService, $filter) {
  $scope.translations = {
    'en': 'English',
    'es': 'Español',
    'pt': 'Português',
    'de': 'Deutsche'
  };
  $rootScope.beastMode = false;
  $scope.root = $rootScope;
  $scope.showDownloadOffline = (ENVIRONMENT === 'web');

  var obscurity = 'randomized string';
  var code = 'dasp2017';
  var userPass = '';
  $(window).off('keypress').on('keypress', function(e) {
    if ($rootScope.beastMode)
      return;

    var char = e.key;
    userPass += char;
    if (userPass != code.substr(0, userPass.length)) {
      userPass = '';
    }

    if (userPass == code) {
      $scope.$apply(function() {
        $scope.toggleBeastMode();
        userPass = '';
      })
    }
  });

  $scope.toggleLanguage = function(lang) {
    $translate.use(lang);
  };

  $scope.toggleBeastMode = function() {
    if (typeof $rootScope.beastMode == 'undefined') $rootScope.beastMode = false;
    $rootScope.beastMode = !$rootScope.beastMode; //toggle beastMode
  };

  $scope.showDownloadModal = function() {
    var setRemoveOverride = function() {
      $timeout(function() {
        UnloadWarningService.override = false;
      }, 250);
    };
    
    var msgbox = $(this).sbgMessageBox({
      header: $filter('translate')('Download the Offline Version'),
      content: $filter('translate')('Offline Description'),
      buttons: [$filter('translate')('Windows Installer'), $filter('translate')('Manual Install'), $filter('translate')('Cancel')],
      onClick: function(value) {
        var platform = navigator.platform ? navigator.platform.toLowerCase() : 'win32';
        if (value == $filter('translate')('Windows Installer')) {
          UnloadWarningService.override = true;
          window.location.href = '/offline/dasp_' + platform + '_setup.zip';
        setRemoveOverride();
        }
        else if (value == $filter('translate')('Manual Install')) {
          UnloadWarningService.override = true;
          window.location.href = '/offline/dasp_' + platform + '.zip';
          setRemoveOverride();
        } else {
          msgbox.closeMessageBox();
        }
      }
    });
  }
};
