module.exports = function($state, Values, patternDataService) {
  var self = this;

  self.getBlob = function(content, contentType) {
    if (!contentType)
      contentType = 'text/plain';
    return new Blob([content], {type : contentType});
  };
//need to convert patternData to show tranformations so exported data reflects power and rotation
  
  function getAzimuthData(azimuth) {
    //need to get rotation;
    var type = $state.$current.name.split(/\./)[0];
    var values = Values.fetchAll();
    var tEV = values[type + '.detail.azimuth'].tabElementValues;
    var rotation = tEV.patternRotation;
    var result = patternDataService.rotatePatternData(azimuth, rotation);
    return result;
  };
  var tab = String.fromCharCode(9);
  function tabText(string) {
    if (!string || typeof string == 'undefined') string ='';
    return tab + string + tab;
  };
  self.formats = {
    dielectric: {
      azimuth: function(patternData) {
        var azimuth = angular.copy(patternData.azimuth); //copy the object so I don't end up rotating it multiple times
        azimuth.data = getAzimuthData(azimuth.data);

      	var content = "Tabulation of Azimuth Pattern\r\n";
      	content += "Azimuth Pattern Drawing #: " + azimuth.drawingNumber + ", Gain:, " + azimuth.gain + ", Calculated\r\n";

      	for (var i = 0; i < azimuth.data.length; i++) {
      		var angleSpaces = Array(3 - i.toString().length).join(" ");
      		content += i + angleSpaces + "          " + (azimuth.data[i].relative / 100).toFixed(6) + "          " + azimuth.data[i].decibel + "\r\n";
      	}

      	return {
          filename: azimuth.drawingNumber + '.az',
          contents: self.getBlob(content)
        }
      },
      elevation: function(patternData) {
        var elevation = patternData.elevation
        var content = tab + "Elevation Pattern for,"+ tab + elevation.drawingNumber + "\r\n";
        content += "Gain:," + tab + elevation.gain + ", " + tabText("Beam Tilt:,") + elevation.beamTilt + ", " + tabText("Drawing Number:,") + elevation.drawingNumber + ", " + tab + "Calculated\r\n";
        content += "El. Angle   " + tab + "Value\r\n";

        for (var angle in elevation.data) {
          var angleSpaces = Array(8 - angle.length).join(" ");
          content += tab + angle + /*angleSpaces +*/",  " + tab + (elevation.data[angle].relative / 100).toFixed(3) + "\r\n";
        }

        return {
          filename: elevation.drawingNumber + '.el',
          contents: self.getBlob(content)
        }
      }
    },
    vsoft: {
      azimuth: function(patternData) {
        var azimuth = angular.copy(patternData.azimuth);
        azimuth.data = getAzimuthData(azimuth.data);
        var content = "360,0,1\r\n";

        for (var i = 0; i < azimuth.data.length; i++) {
          content += (azimuth.data[i].relative / 100).toFixed(7) + "\r\n";
        }

        return {
          filename: azimuth.drawingNumber + '.az.pat',
          contents: self.getBlob(content)
        }
      },
      elevation: function(patternData, SomeObj, max) {
        var elevation = patternData.elevation;
        var dataKeys = Object.keys(elevation.data); //array of all the keys
        var dataLength = dataKeys.length;
        var limit = max ? max : 20;  

        var content = "Vertical Elevation Pattern " + elevation.drawingNumber + " (V-Soft Communications)" +
        "\r\nElectrical Beam Tilt = " + elevation.beamTilt + "\r\nMechanical Beam Tilt = 0\r\nMechanical Tilt Azimuth = 0\r\n";
        
        for (var i = 0; i < dataLength; i++) {
          var vKey = dataKeys[i];
          var vData = Math.round(parseFloat(vKey) * 10) / 10;
          var num = elevation.data[vKey].relative / 100;

          if ((vData * 10) % 2 == 0 && vData <= limit)
            content += vData.toFixed(1) + tabText() + num.toFixed(3) + "\r\n";
          else if (vData > limit) 
            i = dataLength;
        }

        return {
          filename: elevation.drawingNumber + '.el.VEP',
          contents: self.getBlob(content)
        }
      },
    },
    csv: {
      azimuth: function(patternData) {
        var azimuth = angular.copy(patternData.azimuth);
        azimuth.data = getAzimuthData(azimuth.data);
        var content = '';

        for (var i = 0; i < azimuth.data.length; i++) {
          content += i + ',' + (azimuth.data[i].relative / 100).toFixed(4) + "\r\r\n";
        };

        return {
          filename: azimuth.drawingNumber + '.csv',
          contents: self.getBlob(content)
        };
      },
      elevation: function(patternData) {
        var elevation = patternData.elevation;
        var content = '';
        for (var i = -100; i <= 900; i++) {
          content += (i/10).toFixed(1) + ',' + (elevation.data[(i/10).toFixed(3)].relative / 100).toFixed(3) + "\r\r\n";
        };

        return {
          filename: elevation.drawingNumber + '.csv',
          contents: self.getBlob(content)
        };
      }
    },
    edx: {
      azimuth: function(patternData) {
        var azimuth = angular.copy(patternData.azimuth);
        azimuth.data = getAzimuthData(azimuth.data);
        var elevation = patternData.elevation;
        var antennaType = azimuth.drawingNumber + " " + elevation.drawingNumber;
        antennaType = antennaType.substr(0, Math.min(antennaType.length, 20));

        var content = "'" + antennaType + "'," + azimuth.gain + ",1\r\n";

        for (var i = 0; i < azimuth.data.length; i++) {
          content += i + "," + (azimuth.data[i].relative / 100).toFixed(7) + "\r\n";
        }

        var el_content = "";
        var num_points = 0;
        for (var steps = 0; steps < 2; steps++) {
          el_content += " " + (steps * 180) + "\r\n";
          for (var angle in elevation.data) {
            if (parseFloat(angle) >= -3 && parseFloat(angle) <= 20) {
              el_content += (-1 * angle) + "," + (elevation.data[angle].relative / 100).toFixed(7) + "\r\n";
              num_points++;
            }
          }
        }

        content += " 999\r\n2," + num_points + "\r\n";
        content += el_content;

        return {
          filename: azimuth.drawingNumber + '.' + elevation.drawingNumber + '.az.pat',
          contents: self.getBlob(content)
        }
      },
      elevation: function(patternData) {
        return self.formats.edx.azimuth(patternData);
      }

    },
    radiosoft: {
      azimuth: function(patternData, $scope) {
        var azimuth = angular.copy(patternData.azimuth);
        azimuth.data = getAzimuthData(azimuth.data);
        var elevation = patternData.elevation;

        var antennaType = azimuth.drawingNumber + " " + elevation.drawingNumber;
        antennaType = antennaType.substr(0, Math.min(antennaType.length, 20));

        var elAngles = Object.keys(elevation.data).sort(function(a, b) {
          return a - b;
        });

        var numRadials = 359 + elAngles.length + 100 + 900;

        var content = "RadioSoft ComStudy 2.0 .PT2 File. (c) Mountain Tower, Ltd. All Rights Reserved!\r\n";
        content += "MODEL: " + antennaType + "\r\n";
        content += "GAIN:    " + (Math.round(10 * (Math.log($scope.businessLogicValues.gain) / Math.log(10)) * 1000000) / 1000000) + "\r\n";
        content += "FREQ:   " + $scope.uiElementValues.frequency + "\r\n";
        content += "RADIALS: " + numRadials + "\r\n\r\n";

        content += "RADIAL VALUES\r\n";

        for (var i = 0; i < azimuth.data.length; i++) {
          var angleSpaces = Array(3 - i.toString().length + 1).join(" ");
          var field = Math.round(azimuth.data[i].relative * elevation.data[elAngles[100]].relative / 10);
          var fieldSpaces = Array(4 - field.toString().length + 1).join(" ");
          content += "HORZ:  " + angleSpaces + i + "00   VERT:      0   FIELD: " + fieldSpaces + field + "\r\n";
        }

        // -10° up until 0 but in reverse and absolute (ie. 0 to 10°)
        for (var i = 99; i >= 0; i--) {
            var angle = elAngles[i];
            var field = Math.round(elevation.data[angle + ""].relative * azimuth.data[0].relative / 10);  // Elevation angle * Azimuth at 0 degrees
            field = Math.max(field, 0.001);                                     // Set the lower limit to 0.001
            var largeAngle = Math.abs(parseFloat(angle) * 10);
            var angleSpaces = Array(5 - largeAngle.toString().length + 1).join(" ");
            var fieldSpaces = Array(4 - field.toString().length + 1).join(" ");
            content += "HORZ:    000   VERT: " + angleSpaces + largeAngle + "0   FIELD: " + fieldSpaces + field + "\r\n";
        }

        // Use -10° to 90° as our 170° to 270
        for (var i = 0; i < elAngles.length; i++) {
            var angle = elAngles[i];
            var field = Math.round(elevation.data[angle + ""].relative * azimuth.data[180].relative / 10);  // Elevation angle * Azimuth at 0 degrees
            field = Math.max(field, 0.001);                                     // Set the lower limit to 0.001
            var largeAngle = (parseFloat(angle) + 180) * 10;
            var angleSpaces = Array(5 - largeAngle.toString().length + 1).join(" ");
            var fieldSpaces = Array(4 - field.toString().length + 1).join(" ");
            content += "HORZ:    000   VERT: " + angleSpaces + largeAngle + "0   FIELD: " + fieldSpaces + field + "\r\n";
        }

        // Use 90° to 0° as our 270° to 359.9
        for (var i = elAngles.length - 2; i > 100; i--) {
            var angle = elAngles[i];
            var field = Math.round(elevation.data[angle + ""].relative * azimuth.data[180].relative / 10);  // Elevation angle * Azimuth at 0 degrees
            field = Math.max(field, 0.001);                                     // Set the lower limit to 0.001
            var largeAngle = (360 - parseFloat(angle)) * 10;
            var angleSpaces = Array(5 - largeAngle.toString().length + 1).join(" ");
            var fieldSpaces = Array(4 - field.toString().length + 1).join(" ");
            content += "HORZ:    000   VERT: " + angleSpaces + largeAngle + "0   FIELD: " + fieldSpaces + field + "\r\n";
        }

        return {
          filename: azimuth.drawingNumber + '.' + elevation.drawingNumber + '.pt2',
          contents: self.getBlob(content)
        }
      },
      elevation: function(patternData, $scope) {
        return self.formats.radiosoft.azimuth(patternData, $scope);
      }
    },
    tvstudy: { //modeled after csv
      azimuth: function(patternData, $scope) {   //setting $scope.identity to whatever id should be there will make file correct     
        var azimuth = angular.copy(patternData.azimuth);
        azimuth.data = getAzimuthData(azimuth.data);
        var content = '';
        var id = null;
        var date = new Date();
        var digit2 = function(date) {
          if (date.toString().length < 2) date = '0' + date.toString();
          return date.toString();
        }
        if ($scope.identity) id = $scope.identity;
        else id = 0;        
        date = digit2(date.getMonth()) + '/' + digit2(date.getDate()) + '/' + date.getFullYear();
        console.log('date: ', date);

        for (var i = 0; i < azimuth.data.length; i++) {
          if (i % 10 === 0)  {//only output of multiples of 10
            content += id + '|' +  i + '|' + (azimuth.data[i].relative / 100).toFixed(4) + '||' + date + '|^|' + '\r\n';
          }
        };

        return {
          filename: azimuth.drawingNumber + '.tvs', //is this file extension appropriate?
          contents: self.getBlob(content)
        };
      }
    }
  }
};
