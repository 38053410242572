module.exports = function($scope, $q, AntennaService, AntennaLengthService, CalculationService, BusinessLogicService, Values, PatternDataService) {
  $scope.uiElementValues = {};
  Values.attach('fm', 'uiElementValues')
    .then(function(result) {
      $scope.uiElementValues = result;
      updateControllerBusinessLogic();
    });
  
  $scope.patternData = Values.preserve($scope, 'patternData', {
    azimuth: null,
    azimuthVpol: null,
    elevation: null
  });

  $scope.savedPatternData = {
    azimuth: null,
    elevation: null
  };

  $scope.patternRecords = Values.preserve($scope, 'patternRecords', {
    azimuth: null,
    elevation: null
  });

  $scope.uiElementOptions = {
    azimuthPattern: [],
    elevationPattern: []
  };

  $scope.configuredERP_hpol = function() {
    var summaryTabValues = Values.fetch('fm.detail.summary', 'tabElementValues') || {};
    var hpol_bl = BusinessLogicService.get('ERPfromTPO', {});
    var hpol_ui = summaryTabValues.ERP;
    var hpol = (summaryTabValues.ERPtoTPO
                ? hpol_ui
                : hpol_bl);

    if (!hpol) {
      return null;
    } else {
      return hpol;
    };
  };

  $scope.canEnableDetailsButton = function() {
    return $scope.uiElementValues.frequency
      && $scope.uiElementValues.antennaType
      && $scope.uiElementValues.mountType;
  };

  BusinessLogicService.clear();

  $scope.businessLogicValues = {};

  BusinessLogicService.add({
    signalType: {
      dependencies: function($scope) {
        return true;
      },
      update: function($scope) {
        return 'fm';
      }
    },
    antennaType: {
      dependencies: function($scope) {
        return ($scope.uiElementValues.azimuthPattern !== null)
          && ($scope.uiElementValues.elevationPattern !== null)
          && ($scope.patternData.azimuth !== null)
          && ($scope.patternData.elevation !== null)
          && ($scope.patternRecords.azimuth !== null)
          && ($scope.patternRecords.elevation !== null);
      },
      update: function($scope) {
        var antennaProperties = {
          numBays: $scope.patternRecords.elevation.Bays,
          slotsPerLayer: $scope.patternRecords.azimuth.BaysPerLayer,
          withRadome: false,
          design: $scope.patternRecords.azimuth.Design.replace(new RegExp("[a-z]$"), '').toUpperCase(),
          azType: $scope.uiElementValues.azimuthPattern.toUpperCase().split('-')[1],
          elType: $scope.patternData.elevation.antennaType,
          antType: $scope.uiElementValues.antennaType,
          channel: $scope.uiElementValues.channel,
          azimuthPattern: $scope.uiElementValues.azimuthPattern,
          elevationPattern: $scope.uiElementValues.elevationPattern
        };

        return CalculationService.antennaType.fm(antennaProperties);
      }
    },
    maxTVPower: {
      dependencies: function($scope) {
        return AntennaService.ready;
      },
      update: function($scope) {
        return AntennaService.get.maxTVPower($scope.uiElementValues)
          .then(function(result) {
            return result[0];
          });
      }
    },
    maxAvgPower: {
      dependencies: function($scope) {
        return AntennaService.ready.then(function(result) {
          return ($scope.patternRecords.azimuth !== null)
          && ($scope.patternRecords.elevation !== null);
        })
        
      },
      update: function($scope) {
        return AntennaService.get.maxTVPower($scope.uiElementValues)
        .then(function(result){
          var azimuth = $scope.patternRecords.azimuth;
          var elevation = $scope.patternRecords.elevation;

        return CalculationService.maximumInputPower_FM(azimuth, elevation); 
        })        
      }
    },
    maxERP: {
      dependencies: function($scope) {
        return ($scope.patternRecords.azimuth !== null)
          && ($scope.patternRecords.elevation !== null);
      },
      update: function($scope) {
        var azimuth = $scope.patternRecords.azimuth;
        var elevation = $scope.patternRecords.elevation;
        var gain = $scope.businessLogicValues.rmsGain;

        return (gain * CalculationService.maximumInputPower_FM(azimuth, elevation));
      }
    },
    rmsGain: {
      dependencies: function($scope) {
        return ($scope.patternData.azimuth !== null);
      },
      update: function($scope) {
        return $scope.patternData.azimuth.gain;
      }
    },
    horizontalGain: {
      dependencies: function($scope) {
        return ($scope.patternData.elevation !== null);
      },
      update: function($scope) {
        return $scope.patternData.elevation.horizontalGain;
      }
    },
    maxWindSpeed: {
      dependencies: function($scope) {
        return AntennaService.ready;
      },
      update: function($scope) {
        var defer = $q.defer();
        
        defer.resolve(AntennaService._pickValues(AntennaService.data.fmMechData, 'MaxBWS')
                      .filter(function(m) {
                        return (m !== null && m > 0);
                      })
                      .reduce(function(m, n) {
                        return Math.min(m, n);
                      }));

        return defer.promise;
      }
    },
    antennaLengthData: {
      dependencies: function($scope) {
        return AntennaService.ready
          .then(function() {
            return (($scope.uiElementValues.frequency !== null)
                    && ($scope.uiElementValues.elevationPattern !== null)
                    && ($scope.patternRecords.azimuth !== null));
          });
      },
      update: function($scope) {
        var defer = $q.defer();

        var design = $scope.patternRecords.azimuth.Design.toUpperCase();
        var frequency = $scope.uiElementValues.frequency;
        var channel = CalculationService.frequencyToChannel(frequency);
        var elevationPattern = $scope.uiElementValues.elevationPattern;

        var lengths = AntennaLengthService.calculate(design, channel, frequency, elevationPattern);

        defer.resolve({
          'length': lengths.H2,
          'centerOfRadiation': lengths.H3,
          'lengthWithProtector': lengths.H4,
          'lightningProtectorLength': (lengths.H4 - lengths.H2)
        });

        return defer.promise;
      }
    }
  }, {replace: true});

  var fetchPatternData = function(type) {
    var pattern = $scope.uiElementValues[type + 'Pattern'];

    if (type == 'elevation' && $scope.patternRecords.elevation.Design == 'DCR') {
      pattern = pattern.substr(0, 1) + 'x' + pattern.substr(2, 2) + 'x' + pattern.substr(5);
    }

    PatternDataService.fetch(pattern, type)
      .then(function(result) {
        $scope.patternData[type] = result;
        if (type === 'azimuth')
          $scope.patternData.azimuthVpol = null;
      })
      .catch(function(err) {
        $scope.patternData[type] = null;
        console.error(err);
      });
  };

  var refilter = function(key) {
    AntennaService.get[key]($scope.uiElementValues)
      .then(function(result) {
        $scope.uiElementOptions[key] = result;
        if (result.indexOf($scope.uiElementValues[key]) === -1) {
          $scope.uiElementValues[key] = null;
        };
      });
  };

  function updateControllerPatternData() {
    if ($scope.uiElementValues.azimuthPattern) {
      fetchPatternData('azimuth');
    };
    
    if ($scope.uiElementValues.elevationPattern) {
      fetchPatternData('elevation');
    };
  };

  var updateControllerPatternRecords = function() {
    AntennaService.get.matchingAzimuth($scope.uiElementValues)
      .then(function(result) {
        $scope.patternRecords.azimuth = result;
      });
    
    AntennaService.get.matchingElevation($scope.uiElementValues)
      .then(function(result) {
        $scope.patternRecords.elevation = result;
      });
  };

  function updateControllerBusinessLogic() {
    BusinessLogicService.updateAll($scope);
    $scope.businessLogicValues = BusinessLogicService.getAll();
  };

  $scope.$watch(
    function() {
      return $scope.uiElementValues;
    },
    function(newValue, oldValue) {
      var newlySet = [];
      Object.keys(oldValue).forEach(function(key) {
        if (newValue[key] !== null && newValue[key] !== oldValue[key]) {
          newlySet.push(key);
        };
      });

      if (newlySet.indexOf('antennaType') !== -1) {
        $scope.uiElementValues.azimuthPattern = null;
        $scope.uiElementValues.elevationPattern = null;
        BusinessLogicService.clear();
      };
      
      if (newlySet.indexOf('azimuthPattern') === -1) {
        refilter('azimuthPattern');
      };
      
      if (newlySet.indexOf('elevationPattern') === -1) {
        refilter('elevationPattern');
      };

      if (newlySet.indexOf('azimuthPattern') !== -1 || newlySet.indexOf('elevationPattern') !== -1) {
        BusinessLogicService.clear();
        updateControllerPatternRecords();
        updateControllerPatternData();
        updateControllerBusinessLogic();
      };
    }, true);
  
  $scope.$watch(
    function() {
      return ($scope.patternData.azimuth !== null && $scope.patternData.elevation !== null);
    },
    function(newValue, oldValue) {
      if (newValue === true) {
        updateControllerPatternRecords();
        updateControllerBusinessLogic();
      };
    });
};
