module.exports = function(app) {
  app.controller('disclaimer',
                 ['ENVIRONMENT', 'BUILDDATE', '$scope', '$cookies', '$window', require('./disclaimer')]);
  
  app.controller('navbar',
                 ['ENVIRONMENT', '$timeout', '$scope', '$rootScope', '$translate', 'unload-warning','$filter', 'values', require('./navbar')]);
  
  app.controller('tv-sidebar',
                 ['$scope',  'antenna.tv', 'calculation', 'unload-warning', 'values',
                  require('./tv-sidebar')]);
  app.controller('tv-summary',
                 ['$scope', '$rootScope', '$q', 'antenna.tv', 'antenna-length.tv', 'calculation', 'business-logic', 'values', 'pattern-data', 'mechanical','export',
                  require('./tv-summary')]);
  
  app.controller('fm-sidebar',
                 ['$scope', 'antenna.fm', 'calculation', 'unload-warning', 'values',
                  require('./fm-sidebar')]);
  app.controller('fm-summary',
                 ['$scope', '$q', 'antenna.fm', 'antenna-length.fm', 'calculation', 'business-logic', 'values', 'pattern-data',
                  require('./fm-summary')]);
  
  app.controller('detail',
                 ['$scope', '$rootScope', '$state', 'values', 'calculation', 'antenna.tv', require('./detail')]);
  app.controller('detail.azimuth', 
                 ['$scope', '$filter', 'fcc-overlay-data', 'pdf', 'values', 'export', 'pattern-data', 'calculation', require('./detail/azimuth')]);
  app.controller('detail.elevation', 
                 ['$scope', 'pdf', 'values', 'export', require('./detail/elevation')]);
  app.controller('detail.summary', 
                 ['$scope', '$rootScope', 'pdf', 'transmission-line', 'calculation', 'business-logic', 'values', '$filter', require('./detail/summary')]);
  app.controller('detail.specs', 
                 ['$scope', '$rootScope', 'pdf', 'calculation', 'business-logic', 'values', 'mechanical', 'antenna-length.tv', require('./detail/specs')]);
  app.controller('detail.print', 
                 ['$scope', '$state', '$window', '$document', 'business-logic', 'pdf', 'values', require('./detail/print')]);
};
