module.exports = function($filter, $location, $window, PatternParserService) {
	return {
	  restrict: 'E',
	  scope: {
	    extension: '=extension',
	    name: '=name',
      	patternData: '=patternData',
      	savedPatternData: '=savedPatternData',
      	patternType: '=patternType'
	  },
	  template: [
			'<div class="pattern-uploader {{name}}">',
			'	<a href="#" class="btn">{{name}}</a>',
			'	<input type="file" accept="{{ extension }}"/>',
			'</div>'
	  ].join(''),
	  link: function(scope, element, attrs) {
		  $(element).on('change', function (evt) {
			  var file = evt.target.files[0]; // File object
			  var filename_parts = file.name.split('.');
			  var extension = filename_parts[filename_parts.length - 1].toLowerCase();
			  
			  if (extension != 'az' && extension != 'el') {
			    $window.alert('You are attempting to import an invalid pattern file. Please use a .az or .el file!');
					return false;
			  }

			  var reader = new FileReader();
				reader.onload = (function(theFile) {
					return function(e) {
						var content = e.target.result;
						var formatErrorMsg = 'We\'re sorry but we were unable to read your pattern file. Please try another.';
						if (content == '') {
							$window.alert(formatErrorMsg);
							return false;
						}

						var data = null;
						try {
							data = PatternParserService.parse(content, extension);
						}
						catch (ex) {
							// do nothing
						}

						if (!data) {
							$window.alert(formatErrorMsg);
							return false;
						}

		        var dataType = scope.patternType;
		        if (!dataType && extension == 'az')
		          dataType = 'azimuth';
		        else if (!dataType && extension == 'el')
		          dataType = 'elevation';

		        if (scope.patternData[dataType]) {
              scope.savedPatternData[dataType] = scope.patternData[dataType];
            };
		        scope.patternData[dataType] = data;
		        $location.path( '/tv/detail/' + dataType.replace('Vpol', '') );
		        var input = $(element).find('input');
		        var newUpload = input.clone();
		        input.replaceWith(newUpload);

            return true;
					};
				})(file);

				// Read in the image file as a data URL.
				reader.readAsText(file);
			});
	  }
	};
};
