module.exports = function($parse, $timeout) {
  return {
    scope: {
      update: '=update',
      model: '=ngModel',
      values: '=values'
    },
    link: function(scope, element, attrs) {
      var minimumValue = parseInt(element.attr('min'));
      var maximumValue = parseInt(element.attr('max'));
      var createFinished = false;
      var initialValue;

      if (Array.isArray(scope.values) && scope.values.indexOf(scope.model) !== -1) {
        initialValue = scope.values.indexOf(scope.model);
      } else if (! Array.isArray(scope.values) && scope.model) {
        initialValue = scope.model;
      } else {
        initialValue = parseInt(element.attr('min'));
      };
      
      function setValue(value) {
        value = parseInt(value);
        if (Array.isArray(scope.values) && scope.values[value]) {
          scope.model = scope.values[value];
        } else {
          scope.model = value;
        };
      };
      
      attrs.$observe('max', function(newMaximum) {
        var sliderPosition = parseInt(jQuery(element).slider('value'));
        jQuery(element).slider('option', 'max', parseInt(newMaximum));
        if (sliderPosition > newMaximum) {
          scope.model = null;
        };
      });

      scope.$watch(
        function() {
          return scope.model;
        },
        function(newValue, oldValue) {
          if (!createFinished) return;
          
          if (newValue !== null) {
            jQuery(element).slider('value', parseInt(newValue));
          } else {
            jQuery(element).slider('value', minimumValue);
          };
        });

      var opts = {
        min: minimumValue,
        max: maximumValue,
        value: initialValue,
        animate: true,
        create: function() {
          if (element.attr('value')) {
            setValue(element.attr('value'));
          };
          
          $timeout(function() {
            createFinished = true;
          }, 0);
        },
        slide: function(event, ui) {
          var value = parseInt(ui.value);
          scope.$apply(function() {
            setValue(value);
            if (typeof scope.update === 'function') {
              scope.update(value);
            };
          });
        }
      };
      
      jQuery(element).slider(opts);
    }
  };  
};
