module.exports = function($scope, AntennaService, CalculationService, UnloadWarningService, Values) {
  var uiElementDefaults = {
    channel: null,
    frequency: null,
    antennaClass: null,
    azimuthShape: null,
    mountType: null,
    antennaType: null,
    beamTilt: null,
    isDigital: true,
    azimuthPattern: null,
    elevationPattern: null,
    showRMSGain: false,
    radome: false
  };

  // ex $scope.selectors
  $scope.uiElementValues = Values.expose($scope, 'uiElementValues',
                                         angular.copy(uiElementDefaults));
  
  UnloadWarningService.register($scope, uiElementDefaults, $scope.uiElementValues);

  $scope.antennaTypeRecord = Values.expose($scope, 'antennaTypeRecord', {});

  // unfiltered lists for enable/disable checking
  $scope.uiMasterOptions = {
    antennaClass: [],
    azimuthShape: [],
    mountType: [],
    antennaType: []
  };
  
  // ex $scope.filterOptions
  $scope.uiElementOptions = {
    antennaClass: [],
    azimuthShape: [],
    mountType: [],
    antennaType: [],
    beamTilt: []
  };

  $scope.unfilter = function() {
    angular.extend($scope.uiElementValues, uiElementDefaults);

    $scope.patternData = Values.fetch('tv','patternData');
    $scope.patternData.azimuth = null;
    $scope.patternData.azimuthVpol = null;
    $scope.patternData.elevation = null;
    Values.preserve($scope, 'patternData', $scope.patternData, 'tv');
    updateControllerUIState();
  };

  $scope.canUnfilter = false;
  
  AntennaService.ready.then(function() {
    var keyCount = 0;
    var keys = Object.keys($scope.uiMasterOptions);
      keys.forEach(function(key) {
        AntennaService.get[key]({})
          .then(function(result) {
            $scope.uiMasterOptions[key] = result;
            keyCount++;
            if (keys.length == keyCount)
                $scope.$root.dataLoaded = true;
          });
      });
  });

  var refilter = function(key) {
    AntennaService.get[key]($scope.uiElementValues)
      .then(function(result) {
        $scope.uiElementOptions[key] = result;
        if (result.indexOf($scope.uiElementValues[key]) === -1) {
          $scope.uiElementValues[key] = null;
        };
      });
  };

  var updateChannel = function(channel) {
    if (channel === 1) {
      $scope.uiElementValues.channel = null;
      channel = null;
    };
    if (channel === null) {
      $scope.uiElementValues.frequency = null;
    } else {
      $scope.uiElementValues.frequency =
        CalculationService.channelToFrequency(channel);
    };
  };

  var updateFrequency = function(frequency) {
    if (frequency === null) {
      $scope.uiElementValues.frequency = null;
    } else {
      $scope.uiElementValues.channel = 
        CalculationService.frequencyToChannel(frequency);
    };
  };

  var updateControllerUIState = function() {
    if ($scope.uiElementValues.channel) {
      updateChannel($scope.uiElementValues.channel);
    };
    
    if ($scope.uiElementValues.frequency) {
      updateFrequency($scope.uiElementValues.frequency);
    };
  };
  
  $scope.$watch(
    function() {
      return $scope.uiElementValues;
    },
    function(newValue, oldValue) {
      var canUnfilter = false;
      var newlySet = [];
      Object.keys(oldValue).forEach(function(key) {
        if (newValue[key] !== uiElementDefaults[key]) {
          canUnfilter = true;
        };
        
        if (newValue[key] !== null && newValue[key] !== oldValue[key]) {
          newlySet.push(key);
        };
      });
      
      if ($scope.canUnfilter !== canUnfilter) {
        $scope.canUnfilter = canUnfilter;
      };

      refilter('antennaClass');
      refilter('azimuthShape');
      
      if (newlySet.indexOf('beamTilt') === -1) {
        refilter('beamTilt');
      };

      if (newlySet.indexOf('mountType') === -1) {
        refilter('mountType');
      };

      if (newlySet.indexOf('antennaType') === -1) {
        refilter('antennaType');
      };

      if (newlySet.indexOf('antennaType') !== -1) {
        AntennaService.get['antennas']($scope.uiElementValues)
          .then(function(result) {
            if (result.length === 1) {
              // assign keys iteratively instead of just assigning the
              // object so that we don't change the reference, which
              // is shared via expose/attach with the summary
              // controller
              Object.keys(result[0]).forEach(function(key) {
                $scope.antennaTypeRecord[key] = result[0][key];
              });
            };
          });
      };

      if (newlySet.indexOf('channel') !== -1) {
        updateChannel(newValue.channel);
      };
      
      if (newlySet.indexOf('frequency') !== -1) {
        updateFrequency(newValue.frequency);
      };
    },
    true);

  updateControllerUIState();
};
