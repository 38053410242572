module.exports = function($http, $q, $interval) {
  var self = this;    

  var dataStruct = {
    azimuth: null,
    elevation: null,
    mountType: null,
    feedType: null,
    spacing: null,
    elevation: null,
    antennas: null,
    antennaType: null,
    azimuthShape: null,
    maxBWS: null,
    poleDiam: null,
    radome: null
  };

  self.ready = null;
  self.data = angular.copy(dataStruct);

  self.filterMemo = {};
  self.filterMemoTimeout = 10000;

  var getter = function(target) {
    return function(selectors) {        
      var defer = $q.defer();

      self.ready
        .then(function() {
          var result = self.filterAgainst(selectors)[target];
          defer.resolve(result);
        });

      return defer.promise;
    }.bind(self);
  };

  self._pickValues = function(from, which) {
    var result = [];

    from.forEach(function(item) {
      if(item[which] !== null) result = result.concat(item[which]);
    });

    result = result.sort().unique();
    return result;
  };

  self.fetch = function(path) {
    return $http({
      method: 'GET',
      url: path
    });
  };

  self.addDerivedListsTo = function(source) {
    source.antennaType =
      self._pickValues(source.antennas, 'antennaType');
    source.azimuthShape =
      self._pickValues(source.antennas, 'shapes')
      .filter(function(e) {
        return (typeof e !== 'undefined');
      });
    source.azimuthPattern =
      self._pickValues(source.azimuth, 'AzPat').unique();
    source.elevationPattern =
      self._pickValues(source.elevation, 'ElPat').unique();
    source.beamTilt =
      self._pickValues(source.elevation, 'BeamTilt')
      .map(function(el) {
        return parseFloat(el);
      })
      .unique();
    source.mountType =
      self._pickValues(source.antennas, 'mount').unique();
    source.azimuthShape =
      self._pickValues(source.azimuth, 'Shape').unique();
    source.feedType =
      self._pickValues(source.elevation, 'FeedType').unique();
    source.spacing =
      self._pickValues(source.elevation, 'Spacing')
      .map(function(el) {
        return parseFloat(el);
      })
      .unique();
    source.maxBWS = self._pickValues(source.fmMechData, 'MaxBWS');
    source.poleDiam = self._pickValues(source.fmMechData, 'PoleDiam');
    source.radome = self._pickValues(source.fmMechData, 'Radome');
    source.maxTVPower = self._pickValues(source.fmMechData, 'MaxTVPower');
  };

  self.expandElevationList = function(azimuth, elevation) {
    var designs = azimuth.map(function(az) {
      return az.Design;
    }).unique();
    var el;
    var elClone;
    var i, j;
    
    // Split Elevation data into multiple elevation patterns based on antenna type
    i = 0;
    while (typeof elevation[i] !== 'undefined') {
      el = elevation[i];
      if (el.ElPat.indexOf('Fx') === 0) {
        if (el.Type.length > 1) {
          for (j = 1; j < el.Type.length; j++) {
            elClone = angular.copy(el);
            elClone.Type = [el.Type[j]];
            elevation.splice(i+1, 0, elClone);
          }
          el.Type = [el.Type[0]];
        }
        elevation[i].ElPat = el.ElPat.replace(/x([^x]*)$/, el.Type[0].replace('Series', '') + '$1');
      }
      i++;
    };

    // Split Elevation data into multiple elevation patterns based on feed type
    i = 0;
    while (typeof elevation[i] !== 'undefined') {
      el = elevation[i];
      if (el.ElPat.indexOf('Fx') === 0) {
        if (el.FeedType.length > 1) {
          for (j = 1; j < el.FeedType.length; j++) {
            elClone = angular.copy(el);
            elClone.FeedType = [el.FeedType[j]];
            elevation.splice(i+1, 0, elClone);
          }
          el.FeedType = [el.FeedType[0]];
        }
        elevation[i].ElPat = el.ElPat.replace('x', el.FeedType[0].substr(0, 1));
      }
      i++;
    };

    // Split Elevation data into multiple elevation patterns based on design
    i = 0;
    while (typeof elevation[i] !== 'undefined') {
      var el = elevation[i];
      if (el.Design.indexOf('*') > -1) {
        var regexp = new RegExp(el.Design.replace(new RegExp("\\*"), '.'));
        for (var j = 1; j < designs.length; j++) {
          if (designs[j].match(regexp)) {
            var elClone = $.extend({}, el);
            elClone.Design = designs[j];
            // Also replace the Type if it uses a wildcard
            if (elClone.Type[0].indexOf('*') > -1) {
              elClone.Type = [ elClone.Design.substr(0, elClone.Type[0].length) ];
            }
            elevation.splice(i+1, 0, elClone);
          }
        }
        elevation.splice(i, 1);
      }
      i++;
    };
    
    return elevation;
  };

  self.fetchAll = function() {
    var defer = $q.defer();
    
    $q.all([self.fetch('/antenna-data/fmazimuthdata.json'),
            self.fetch('/antenna-data/fmelevationdata.json'),
            self.fetch('/antenna-data/fmantennas.json'),
            self.fetch('/antenna-data/tvMechData.json')])
      .then(function(results) {
        self.data.azimuth = results[0].data;
        self.data.elevation = self.expandElevationList(self.data.azimuth, results[1].data);
        self.data.antennas = results[2].data;
        self.data.fmMechData = results[3].data.data;

        self.addDerivedListsTo(self.data);
        defer.resolve(self.data);
      })
      .catch(function(err) {
        
        defer.reject(err);
      });

    return defer.promise;
  };

  self.filter = {
    withinRange: function(value, min, max) {
      return (value === null || typeof value === 'undefined' ||
              (min <= value && max >= value));
    },
    matchesString: function(value, test) {
      return (value === null || typeof value === 'undefined' ||
              value.toLowerCase() === test.toLowerCase());
    },
    matchesFloat: function(value, test) {
      return (value === null || typeof value === 'undefined' ||
              parseFloat(value) === parseFloat(test));
    },
    matchesElement: function(value, tests) {
      return (value === null || typeof value === 'undefined' ||
              tests
              .map(function(el) {
                return el.toLowerCase();
              })
              .indexOf(value.toLowerCase()) !== -1);
    },
    matchesWildcard: function(value, tests) {
      var reg = new RegExp(value.toLowerCase());
      return (value === null || typeof value === 'undefined' ||
              tests.map(function(el) {
                return typeof el !== 'undefined' && el !== null ? el.toLowerCase() : '';
              })
              .filter(function(item){
                return typeof item === 'string' && item.match(reg);
              }).length > 0);
    },
    matchesWildcardReverse: function(value, tests) {
      return (value === null || typeof value === 'undefined' || typeof value !== 'string' || 
              tests.filter(function(item) {
                return value.toLowerCase().match(item) !== null;
              }).length > 0);
    }
  };

  self.trimOldMemos = function() {
    var threshold = Math.floor((Date.now() - self.filterMemoTimeout) / 1000);
    Object.keys(self.filterMemo).forEach(function(key) {
      if (self.filterMemo[key].time < threshold) {
        delete self.filterMemo[key];
      };
    });
  };

  self.filterAgainst = function(selectors) {
    var filtered = null;
    var filterKey = JSON.stringify(selectors);

    if (self.filterMemo[filterKey]) {
      filtered = self.filterMemo[filterKey].data;
    } else {
      filtered = self.runFilters(selectors);
      self.filterMemo[filterKey] = {
        time: Math.floor(Date.now() / 1000),
        data: filtered
      };
    };

    return filtered;
  };

  self.runFilters = function(selectors) {
    var filtered = angular.copy(dataStruct);
    var designRegexes = [];
    var antennaTypes = [];

    filtered.antennas = self.data.antennas
      .map(function(antenna) {
        if (self.filter.matchesElement(selectors.antennaType, antenna.antennaType)            
            && self.filter.matchesElement(selectors.azimuthShape, antenna.shapes)            
            && self.filter.matchesString(selectors.mountType, antenna.mount)) {
          return antenna;
        } else {
          return null;
        };
      })
      .filter(function(result) {
        return (result !== null);
      });

    antennaTypes = self._pickValues(filtered.antennas, 'antennaType');

    filtered.elevation = self.data.elevation
      .map(function(elev) {
        var typeFilter = elev.Type[0].replace(/\*/, '.');
        
        if (self.filter.matchesFloat(selectors.beamTilt, elev.BeamTilt)
            && self.filter.matchesFloat(selectors.spacing, elev.Spacing)
            && self.filter.matchesElement(selectors.feedType, elev.FeedType)
            && self.filter.matchesElement(selectors.antennaType, elev.Type)) {
          return elev;
        } else {
          return null;
        };
      })
      .filter(function(result) {
        return (result !== null);
      });

    designRegexes = self._pickValues(filtered.elevation, 'Design')
      .sort()
      .unique()
      .map(function(design) {
        return design.toLowerCase().replace(new RegExp("\\*"), '.');
      });

    filtered.azimuth = self.data.azimuth
      .map(function(azimuth) {
        if (self.filter.withinRange(selectors.frequency, azimuth.MinFreq, azimuth.MaxFreq)
            && self.filter.matchesString(selectors.azimuthShape, azimuth.Shape)
            && (('' + selectors.antennaType).indexOf('DC') !== 0
                || self.filter.matchesWildcard(selectors.antennaType + '.', [azimuth.Design]))
            && self.filter.matchesWildcardReverse(azimuth.Design, designRegexes)) {
          return azimuth;
        } else {
          return null;
        };
      })
      .filter(function(result) {
        return (result !== null);
      });

    /***************************************************
     * PERFORM CROSS-FILTERING ACROSS AZ/EL PATTERNS TO
     * ADDRESS FILTER OPTIONS THAT DO NOT APPLY TO THEM
     ***************************************************/

    // First we need the designs for the available elevation patterns
    var elevationDesigns = filtered.elevation
      .map(function(el) {
        return el.Design;
      });

    // Then we need the designs for the available azimuth patterns
    var azimuthDesigns = filtered.azimuth
      .map(function(az) {
        return az.Design;
      });

    // Then we need to find the matching designs between the two
    var matchingDesigns = azimuthDesigns.diff(elevationDesigns).unique();

    filtered.elevation = filtered.elevation
      .map(function(el) {
        if (self.filter.matchesElement(el.Design, matchingDesigns))
          return el;
        else
          return null;
      })
      .filter(function(result) {
        return result !== null;
      });

    filtered.azimuth = filtered.azimuth
      .map(function(az) {
        if (self.filter.matchesElement(az.Design, matchingDesigns)) {
          az.Vpol = null;
          return az;
        }
        else
          return null;
      })
      .filter(function(result) {
        return result !== null;
      });

    /***************************************************
     * END CROSS-FILTERING
     ***************************************************/

    var matchingElevation = filtered.elevation
          .map(function(elev) {
            if (self.filter.matchesString(selectors.elevationPattern, elev.ElPat)
                && self.filter.matchesString(matchingDesigns[0], elev.Design)) {
              return elev;
            } else {
              return null;
            };
          })
          .filter(function(result) {
            return (result !== null);
          })[0];
    
    var matchingAzimuth = filtered.azimuth
          .map(function(az) {
            if (self.filter.matchesString(selectors.azimuthPattern, az.AzPat)
                && self.filter.matchesString(matchingDesigns[0], az.Design)) {
              az.Vpol = null;
              return az;
            } else {
              return null;
            };
          })
          .filter(function(result) {
            return (result !== null);
          })[0];

    filtered.matchingAzimuth = matchingAzimuth;
    filtered.matchingElevation = matchingElevation;

    filtered.fmMechData = self.data.fmMechData
      .map(function(mech) {
        if ((!selectors.design || self.filter.matchesString(selectors.design, mech.Design))
            && (!selectors.PoleDiam || self.filter.matchesElement(selectors.poleDiam, mech.PoleDiam))
            // && self.filter.matchesFloat(matchingAzimuth.MatchCode, mech.MatchCode) //match code not in fmazimuthdata.json
            // && self.filter.withinRange(selectors.frequency, mech.MinFreq, mech.MaxFreq) //frequency not in fmmechdata.json
            && (!selectors.elevationPattern || self.filter.matchesFloat(parseFloat(selectors.elevationPattern.substr(0,2)), mech.Layers))) {
          return mech;
        } else {
          return null;
        };
      })
      .filter(function(result) {
        return (result !== null);
      });

    self.addDerivedListsTo(filtered);

    return filtered;
  };

  self.get = {
    azimuth: getter('azimuth'),
    elevation: getter('elevation'),
    antennaType: getter('antennaType'),
    azimuthShape: getter('azimuthShape'),
    azimuthPattern: getter('azimuthPattern'),
    elevationPattern: getter('elevationPattern'),
    beamTilt: getter('beamTilt'),
    feedType: getter('feedType'),
    spacing: getter('spacing'),
    mountType: getter('mountType'),
    maxTVPower: getter('maxTVPower'),
    matchingAzimuth: getter('matchingAzimuth'),
    matchingElevation: getter('matchingElevation'),
    fmMechData: getter('fmMechData')
  };

  self.filterMemoCleanup =
    $interval(self.trimOldMemos.bind(self), self.filterMemoTimeout);

  self.ready = self.fetchAll();
};
